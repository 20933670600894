import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { SkillListResponse, SkillResponse } from './types'
import type { CreateResponse, UpdateResponse, DeleteResponse } from '../types'

export const getSkillList = async (): Promise<SkillListResponse> => {
  const url = '/skills'

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const getSkill = async (skillId: number): Promise<SkillResponse> => {
  const url = `/skills/${skillId}`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const createSkill = async (name: string): Promise<CreateResponse> => {
  const url = '/skills'

  const response = await axiosClientWithTenantId.post(url, { name })
  return response.data
}

export const updateSkill = async (skillId: number, name: string): Promise<UpdateResponse> => {
  const url = `/skills/${skillId}`

  const response = await axiosClientWithTenantId.patch(url, { name })
  return response.data
}

export const deleteSkill = async (skillId: number): Promise<DeleteResponse> => {
  const url = `/skills/${skillId}`

  const response = await axiosClientWithTenantId.delete(url)
  return response.data
}
