import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { importShift, selectPlansStatus } from 'slices/plansSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import { CustomModal, FileInput } from 'components/common'

import type { LoadFileProps } from 'hooks/useImportCsv'
import { useImportCsv } from 'hooks/useImportCsv'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const ShiftImport = ({ isOpen, onSuccess, onCancel }: Props) => {
  const { importDisabled, setFile, importCsvFile, modalErrorMessage, setModalErrorMessage } = useImportCsv()
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectPlansStatus, shallowEqual)

  const handleSaveClick = () => {
    const handleFileLoad = (data: LoadFileProps) => {
      dispatch(importShift(data.fileName, data.content))
      setSubmitted(true)
    }
    importCsvFile(handleFileLoad)
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      onSuccess()
    } else {
      if (ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
        setModalErrorMessage(undefined)
      } else {
        setModalErrorMessage('保存できませんでした。')
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, setModalErrorMessage])

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    setSubmitted(false)
    onCancel()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="シフトインポート"
      approveDisabled={importDisabled}
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
      submitName="shift-import-submit"
    >
      <div className="pb-4">
        <div className="mb-3">
          インポートフォーマットに適したCSVファイルを選択してください。
          フォーマットが異なる場合は正しく取り込みできません。
        </div>
        <FileInput id="shiftFile" accept=".csv" onChange={f => setFile(f)} />
      </div>
    </CustomModal>
  )
}

export default ShiftImport
