import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { OfficialDutiesDetailResponse, OfficialDutiesEditData, OfficialDutiesListResponse } from './types'
import type { CreateResponse, DeleteResponse, UpdateResponse } from '../types'

export const getOfficialDutiesList = async (): Promise<OfficialDutiesListResponse> => {
  const url = '/official-duties'

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const getOfficialDuties = async (id: number): Promise<OfficialDutiesDetailResponse> => {
  const url = `/official-duties/${id}`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const createOfficialDuties = async (data: OfficialDutiesEditData): Promise<CreateResponse> => {
  const url = '/official-duties'

  const response = await axiosClientWithTenantId.post(url, data)
  return response.data
}

export const updateOfficialDuties = async (id: number, data: OfficialDutiesEditData): Promise<UpdateResponse> => {
  const url = `/official-duties/${id}`

  const response = await axiosClientWithTenantId.patch(url, data)
  return response.data
}

export const deleteOfficialDuties = async (id: number): Promise<DeleteResponse> => {
  const url = `/official-duties/${id}`

  const response = await axiosClientWithTenantId.delete(url)
  return response.data
}
