import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'

import { ROLE } from 'api/users/constants'

import { login, selectSessionStatus, clearErrorMessage } from 'slices/sessionSlice'
import { ERROR_STATUS_CODE } from 'slices/utils'

import { InputFormat, SinglePageCard } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'

import styles from './Signup.module.scss'

import type { MouseEvent } from 'react'

const Signup = () => {
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [emailValidity, setEmailValidity] = useState(false)
  const [passwordValidity, setPasswordValidity] = useState(false)

  const dispatch = useDispatch()
  const { loggedIn, activated, user, errorMessage } = useSelector(selectSessionStatus, shallowEqual)
  const navigate = useNavigate()
  const onSubmit = (e: MouseEvent) => {
    e.preventDefault()
    if (email && password) {
      dispatch(login({ email, password }))
    }
  }
  useEffect(() => {
    if (!loggedIn) {
      return
    }
    if (!activated) {
      return navigate('/activate')
    }

    if (user.userHasTenants[0].role === ROLE.TENANT_ADMIN) {
      navigate('/tenants')
    } else {
      if (user.userHasTenants[0].isActive) {
        navigate('/dashboard')
      } else {
        navigate('/suspension')
      }
    }
  }, [navigate, loggedIn, activated, user])

  useEffect(() => {
    if (errorMessage === ERROR_STATUS_CODE.UNAUTHORIZED) {
      setModalErrorMessage('メールアドレスかパスワードが間違っています。')
    } else if (errorMessage !== '') {
      setModalErrorMessage('サインアップに失敗しました。')
    } else {
      setModalErrorMessage(undefined)
    }
  }, [errorMessage])

  const disabled = useMemo(
    () => !(email && emailValidity && password && passwordValidity),
    [email, emailValidity, password, passwordValidity]
  )

  return (
    <>
      <SinglePageCard
        title="サインアップ"
        errorMessage={modalErrorMessage}
        onHideNotification={() => {
          setModalErrorMessage(undefined)
          dispatch(clearErrorMessage())
        }}
      >
        <Container>
          <InputFormat
            label="メールアドレス"
            placeholder="メールアドレス"
            value={email}
            size={COLUMN_SIZES.LARGE}
            maxLength={100}
            formText="招待されたメールアドレスを入力してください"
            onChange={value => setEmail(value)}
            validations={[Rules.Required, Rules.Email]}
            onValidate={setEmailValidity}
            className="mb-3"
          />

          <InputFormat
            label="仮パスワード"
            type="password"
            placeholder="仮パスワード"
            value={password}
            size={COLUMN_SIZES.LARGE}
            maxLength={32}
            onChange={value => setPassword(value)}
            validations={[Rules.MinLength(8), Rules.Password]}
            onValidate={setPasswordValidity}
          />

          <Row>
            <Col className="mt-3">
              この先続行することで
              <a
                className="text-decoration-none"
                href="https://help.smileboard.jp/license-agreement"
                target="_blank"
                rel="noreferrer"
              >
                利用規約
              </a>
              と
              <a
                className="text-decoration-none"
                href="https://help.smileboard.jp/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                プライバシーポリシー
              </a>
              に同意したものとします。
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3">
              <Button color="primary" className={styles.button} disabled={disabled} onClick={onSubmit}>
                続行
              </Button>
            </Col>
          </Row>
        </Container>
        <div className={styles.loginLink}>
          スマイルボードアカウントをお持ちの場合は
          <Link className="text-decoration-none" to="/login">
            こちらからログイン
          </Link>
          してください。
        </div>
      </SinglePageCard>
    </>
  )
}

export default Signup
