import { unwrapResult } from '@reduxjs/toolkit'
import axios, { AxiosHeaders } from 'axios'
import { useEffect, useCallback, useState } from 'react'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { asyncValidateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { makeErrorMessage, ERROR_STATUS_CODE } from 'slices/utils'

import type { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import type { ReactElement } from 'react'
import type { AppDispatch } from 'store'

const INVALID_TOKEN_ERROR_MESSAGE = 'Invalid token'

const CLIENT_TYPES = {
  DEFAULT: 'DEFAULT',
  TENANT_ID: 'TENANT_ID',
  USER_ID: 'USER_ID',
} as const

type ClientType = (typeof CLIENT_TYPES)[keyof typeof CLIENT_TYPES]

type Props = {
  useAppDispatch: () => AppDispatch // 循環参照エラーを回避するためにインポートではなく引数で渡す
  children: ReactElement
}
const defaultUrl = `${process.env.REACT_APP_API_SERVER}/api/v2`

// 使用時にパスがわかりにくいので､baseURLでclientを分ける
export const axiosClient = axios.create()
export const axiosClientWithTenantId = axios.create()
export const axiosClientWithTenantIdAndUserId = axios.create()

const shouldExcludeErrorDialog = (config: InternalAxiosRequestConfig) => {
  const getPlanByDate = { pattern: /.*\/workspaces\/\d+\/work-date\/[^/]+\/plan$/, method: 'get' }
  const updateTargetValue = { pattern: /.*\/workspaces\/\d+\/target-value$/, method: 'put' }
  const getWorkspace = { pattern: /.*\/workspaces\/\d+$/, method: 'get' }
  const createUser = { pattern: /.*\/users$/, method: 'post' }
  const updateUser = { pattern: /.*\/users\/[^/]+$/, method: 'patch' }
  const updateUserTenant = { pattern: /.*\/users\/[^/]+\/tenants\/\d+$/, method: 'patch' }
  const createTenantUser = { pattern: /.*\/tenants\/\d+\/users$/, method: 'post' }
  const deleteTenantUser = { pattern: /.*\/tenants\/\d+\/users\/[^/]+$/, method: 'delete' }
  const excludeErrorDialogList = [
    getPlanByDate,
    updateTargetValue,
    createUser,
    updateUser,
    updateUserTenant,
    createTenantUser,
    deleteTenantUser,
    getWorkspace,
  ]

  const { url, method } = config || {}

  if (excludeErrorDialogList.some(e => e.pattern.test(url ?? '') && e.method === method)) {
    return true
  }
  return false
}

export const AxiosClientProvider = ({ useAppDispatch, children }: Props) => {
  const dispatch = useAppDispatch()
  const [isInterceptorSet, setIsInterceptorSet] = useState(false)

  const setInterceptors = useCallback(
    (client: AxiosInstance, clientType: ClientType) => {
      client.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
        // useSelectorから取得したtokenが参照できないためasyncValidateTokenから渡す
        const result = await dispatch(asyncValidateToken())
        const sessionState = unwrapResult(result)

        if (!sessionState) {
          return Promise.reject(new Error(INVALID_TOKEN_ERROR_MESSAGE))
        }

        dispatch(Spinner.start())

        if (clientType === CLIENT_TYPES.DEFAULT) {
          config.baseURL = defaultUrl
        } else {
          const tenantId = sessionState.user.userHasTenants[0]?.id || 0
          if (clientType === CLIENT_TYPES.TENANT_ID) {
            config.baseURL = `${defaultUrl}/tenants/${tenantId}`
          } else {
            config.baseURL = `${defaultUrl}/tenants/${tenantId}/users/${sessionState.user.userId}`
          }
        }

        config.headers = new AxiosHeaders({
          ...config.headers,
          Authorization: sessionState.idToken,
          'X-Access-Authorization': sessionState.accessToken,
        })
        return config
      })

      client.interceptors.response.use(
        (response: AxiosResponse) => {
          dispatch(Spinner.stop())
          return response
        },
        (error: AxiosError) => {
          dispatch(Spinner.stop())
          const errorCode = makeErrorMessage(error)

          if (
            (error.config && shouldExcludeErrorDialog(error.config)) ||
            error.message === INVALID_TOKEN_ERROR_MESSAGE
          ) {
            return Promise.reject(error)
          } else if (errorCode === ERROR_STATUS_CODE.UNAUTHORIZED) {
            dispatch(SessionTimeoutDialog.open())
          } else if (error.config?.method === 'get') {
            dispatch(NetworkErrorDialog.open({ code: errorCode }))
          } else if (errorCode === ERROR_STATUS_CODE.UNREACHABLE) {
            dispatch(NetworkErrorDialog.open({ code: ERROR_STATUS_CODE.UNREACHABLE }))
          }
          return Promise.reject(error)
        }
      )
    },
    [dispatch]
  )

  useEffect(() => {
    setInterceptors(axiosClient, CLIENT_TYPES.DEFAULT)
    setInterceptors(axiosClientWithTenantId, CLIENT_TYPES.TENANT_ID)
    setInterceptors(axiosClientWithTenantIdAndUserId, CLIENT_TYPES.USER_ID)
    setIsInterceptorSet(true)
  }, [setInterceptors])

  // interceptorが設定される前にAPIがリクエストされるのを防ぐ
  return isInterceptorSet && children
}
