import axios from 'axios'

import type { CreateResponse, DeleteResponse, UpdateResponse } from 'api/types'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type {
  CheckExportDataReadyResponse,
  CreateWorkerEditDataType,
  ExportWorkersDataType,
  UpdateWorkerEditDataType,
  WorkerListResponse,
  WorkerResponse,
  WorkersUploadResponse,
  WorkerType,
} from './types'

export const getWorkerList = async (): Promise<WorkerListResponse> => {
  const url = '/workers'

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const getWorker = async (workerId: number): Promise<WorkerResponse> => {
  const url = `/workers/${workerId}`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const createWorker = async (data: CreateWorkerEditDataType): Promise<CreateResponse> => {
  const url = '/workers'

  const response = await axiosClientWithTenantId.post(url, { ...data })
  return response.data
}

export const updateWorker = async (workerId: number, data: UpdateWorkerEditDataType): Promise<UpdateResponse> => {
  const url = `/workers/${workerId}`

  const response = await axiosClientWithTenantId.patch(url, { ...data })
  return response.data
}

export const deleteWorker = async (workerId: number): Promise<DeleteResponse> => {
  const url = `/workers/${workerId}`

  const response = await axiosClientWithTenantId.delete(url)
  return response.data
}

export const workersUploadUrl = async (fileName: string, workerType: WorkerType): Promise<WorkersUploadResponse> => {
  const url = '/workers/upload-url'

  const response = await axiosClientWithTenantId.post(url, { fileName, workerType })
  return response.data
}

export const putUploadUrl = async (url: string, csvContent: string): Promise<void> => {
  const headers = {
    'Content-Type': 'text/csv',
  }
  await axios.put(url, csvContent, { headers })
}

export const exportWorkers = async (data: ExportWorkersDataType): Promise<CheckExportDataReadyResponse> => {
  const url = '/workers/data-export'

  const response = await axiosClientWithTenantId.post(url, data)
  return response.data
}
