import dayjs from 'dayjs'
import durationPlugin from 'dayjs/plugin/duration'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import { compact } from 'es-toolkit'
import { get, isEqual, set } from 'es-toolkit/compat'
import { v4 as uuidv4 } from 'uuid'

import type { TenantApplicationSettingType, TenantData } from 'api/tenants/types'
import { WORKER_TYPES } from 'api/workers/constants'

import { MAGIQANNEAL_APPLICATION_ID, TENTATIVE_SCHEDULE_TYPE_ID, TIME_INTERVAL } from './constants'

import type { EditSchedule, FitDuringShiftTimeData, TimeReducerType, WorkerFilterProps } from './types'
export {
  createLineChartOptions,
  createBarChartOptions,
  createStackedChartOptions,
  getMaxYAxisValue,
} from 'components/common/Chart/Chart'

dayjs.extend(durationPlugin)
dayjs.extend(isBetweenPlugin)

export * as Rules from 'components/common/FormFormat/ValidationRules'

export const getShiftBarWidthByDuration = (duration: number, interval?: number) => {
  const intervalSeconds = interval === TIME_INTERVAL.FIVE ? 300 : 900
  return duration / intervalSeconds
}

export const getUpdateWorkerSchedules = (
  startAt: string,
  duration: number,
  scheduleTypeId: number,
  supportWorkspaceId: number | null,
  supportWorkspaceName: string | null,
  schedules: EditSchedule[]
) => {
  let addSchedule: EditSchedule | undefined = {
    scheduleId: null,
    startAt,
    duration,
    scheduleTypeId,
    supportWorkspaceId,
    supportWorkspaceName,
  }
  const shifts = schedules.filter(s => s.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
  const updatedSchedules = schedules
    .filter(s => s.scheduleTypeId !== TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
    .reduce((acc: EditSchedule[], cur) => {
      if (!addSchedule) {
        cur.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT && acc.push(cur)
        return acc
      }
      const addStart = dayjs(addSchedule.startAt)
      const addEnd = dayjs(addSchedule.startAt).add(addSchedule.duration, 'seconds')
      const curStart = dayjs(cur.startAt)
      const curEnd = dayjs(cur.startAt).add(cur.duration, 'seconds')

      if (addStart.isBetween(curStart, curEnd, 'minute', '()') && addEnd.isBetween(curStart, curEnd, 'minute', '()')) {
        if (cur.scheduleTypeId !== scheduleTypeId || supportWorkspaceId !== cur.supportWorkspaceId) {
          acc.push({ ...cur, duration: cur.duration - (curEnd.unix() - addStart.unix()) })
          acc.push({
            scheduleId: null,
            startAt: addEnd.format(),
            duration: cur.duration - (addEnd.unix() - curStart.unix()),
            scheduleTypeId: cur.scheduleTypeId,
            supportWorkspaceId: cur.supportWorkspaceId,
            supportWorkspaceName: cur.supportWorkspaceName,
          })
        } else {
          acc.push(cur)
          addSchedule = undefined
        }
      } else if (addStart.isBetween(curStart, curEnd, 'minute', '(]')) {
        const diff = curEnd.unix() - addStart.unix()
        if (cur.scheduleTypeId === scheduleTypeId && supportWorkspaceId === cur.supportWorkspaceId) {
          addSchedule = { ...cur, duration: cur.duration + addSchedule.duration - diff }
        } else {
          acc.push({ ...cur, duration: cur.duration - diff })
        }
      } else if (addEnd.isBetween(curStart, curEnd, 'minute', '[)')) {
        const diff = addEnd.unix() - curStart.unix()
        if (scheduleTypeId === cur.scheduleTypeId && supportWorkspaceId === cur.supportWorkspaceId) {
          addSchedule = { ...cur, startAt: addSchedule.startAt, duration: cur.duration + addSchedule.duration - diff }
        } else {
          acc.push({ ...cur, startAt: addEnd.format(), duration: cur.duration - diff })
        }
      } else if (
        curStart.isBetween(addStart, addEnd, 'minute', '[]') &&
        curEnd.isBetween(addStart, addEnd, 'minute', '[]')
      ) {
        return acc
      } else {
        acc.push(cur)
      }
      return acc
    }, [])

  return updatedSchedules.concat(shifts).concat(compact([addSchedule]))
}

export const toFitDuringShiftTime = (
  inputStartAt: string,
  inputDuration: number,
  shifts: EditSchedule[] | FitDuringShiftTimeData[],
  isEndTime: boolean
) => {
  const inputEndAt = dayjs(inputStartAt).add(inputDuration, 'seconds').format()
  const isBetween = shifts.some(shift => {
    const shiftEndAt = dayjs(shift.startAt).add(shift.duration, 'seconds').format()
    return (
      dayjs(inputStartAt).isBetween(shift.startAt, shiftEndAt, 'minutes', '[]') &&
      dayjs(inputEndAt).isBetween(shift.startAt, shiftEndAt, 'minutes', '[]')
    )
  })

  if (!isEndTime && (isBetween || shifts.length === 0)) {
    return { startAt: inputStartAt, duration: inputDuration }
  }

  const baseShift =
    shifts.find(shift => {
      const shiftEndAt = dayjs(shift.startAt).add(shift.duration, 'seconds').format()
      return (
        (dayjs(shift.startAt).isBetween(inputStartAt, inputEndAt, 'minutes', '[]') &&
          dayjs(shiftEndAt).isBetween(inputStartAt, inputEndAt, 'minutes', '[]')) ||
        dayjs(inputStartAt).isBetween(shift.startAt, shiftEndAt, 'minutes') ||
        dayjs(inputEndAt).isBetween(shift.startAt, shiftEndAt, 'minutes')
      )
    }) || shifts[0]
  const baseEndAt = dayjs(baseShift.startAt).add(baseShift.duration, 'seconds').format()
  const startAt = dayjs(inputStartAt).isAfter(baseShift.startAt) || isEndTime ? inputStartAt : baseShift.startAt
  const end = dayjs(inputEndAt).isAfter(baseEndAt) || isEndTime ? baseEndAt : inputEndAt
  const duration = dayjs(end).unix() - dayjs(startAt).unix()

  return { startAt, duration }
}

const initialMagiQannealData: TenantApplicationSettingType = {
  applicationId: MAGIQANNEAL_APPLICATION_ID,
  applicationName: 'magiQanneal連携',
  options: { apiKey: '', tenant: '', relatedWorkspaceData: [] },
}

export const getTenantApplications = (tenant?: TenantData) => {
  if (!tenant) {
    return []
  }
  if (tenant.optionApplications.length === 0) {
    return [initialMagiQannealData]
  }
  const magiQanneal = tenant.optionApplications.find(app => app.applicationId === MAGIQANNEAL_APPLICATION_ID)
  if (magiQanneal && magiQanneal.applicationName !== initialMagiQannealData.applicationName) {
    return tenant.optionApplications.map(app =>
      app.applicationId === MAGIQANNEAL_APPLICATION_ID
        ? { ...magiQanneal, applicationName: initialMagiQannealData.applicationName }
        : app
    )
  }
  return tenant.optionApplications
}

export const timeOverlapped = (
  schedule1StartAt: string,
  schedule1Duration: number,
  schedule2StartAt: string,
  schedule2Duration: number
) => {
  const time1 = dayjs(schedule1StartAt)
  const time2 = dayjs(schedule2StartAt)
  return (
    time1.clone().add(schedule1Duration, 'seconds').isAfter(time2) &&
    time1.isBefore(time2.clone().add(schedule2Duration, 'seconds'))
  )
}
// 作業の時間が重なっていないかチェックする
export const hasOverlappedSchedule = (schedules: EditSchedule[], isShift: boolean) => {
  return schedules.some((controlSchedule, index) => {
    return schedules.slice(index + 1).some(targetSchedule => {
      if (
        !isShift &&
        (controlSchedule.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT ||
          targetSchedule.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
      ) {
        return false
      }
      return timeOverlapped(
        controlSchedule.startAt,
        controlSchedule.duration,
        targetSchedule.startAt,
        targetSchedule.duration
      )
    })
  })
}

const calcRate = (planCount: number | null, recordCount: number | null): number | null => {
  if (planCount === null || planCount === 0 || recordCount === null) {
    return null
  }
  return Math.floor((100 * recordCount) / planCount)
}

export const append = (value1: number | null, value2: number | null): number | null => {
  if (value1 === null && value2 === null) {
    return null
  }
  return (value1 || 0) + (value2 || 0)
}

export const timeDataReducer = (acc: { [key: string]: TimeReducerType }, cur: TimeReducerType) => {
  const before = acc[cur.time]
  if (before) {
    const planCount = append(before.planCount, cur.planCount)
    const recordCount = append(before.recordCount, cur.recordCount)
    return {
      ...acc,
      [before.time]: {
        time: before.time,
        planCount,
        recordCount,
        rate: calcRate(planCount, recordCount),
      },
    }
  }
  return {
    ...acc,
    [cur.time]: {
      ...cur,
      rate: calcRate(cur.planCount, cur.recordCount),
    },
  }
}

// 0以下のランダムな数値を取得する
export const getRandomNumber = () => window.crypto.getRandomValues(new Uint32Array(1))[0] / 10000000000

export const getUUID = () => uuidv4()

export const isSupportedWorkerGroup = (supportedWorkspaceId: number | null, supportedWorkspaceName: string | null) => {
  return !!(supportedWorkspaceId && supportedWorkspaceName)
}

export const isFilteredWorker = (filterWord: string, worker: WorkerFilterProps) =>
  filterWord === '' ||
  worker.name.includes(filterWord) ||
  (worker.workerType === WORKER_TYPES.REGULAR_MEMBER && worker.wmsMemberId?.includes(filterWord))

// 主にpatchでリクエストする際に利用する
// ネストされたオブジェクも含め､変更があるプロパティのみを取得する
export const pickByRecursive = <T extends object, K>(targetObj: T, compareObjA: K, compareObjB: K): Partial<T> =>
  Object.keys(targetObj).reduce((result: Partial<T>, key) => {
    const value = get(targetObj, key)
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      const nestedResult = pickByRecursive<T, K>(value, get(compareObjA, key), get(compareObjB, key))
      if (Object.keys(nestedResult).length > 0) {
        set(result, key, nestedResult)
      }
    } else if (!isEqual(get(compareObjA, key), get(compareObjB, key))) {
      set(result, key, value)
    }
    return result
  }, {})
