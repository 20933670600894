import dayjs from 'dayjs'

type Props = {
  updatedAt?: string
}

export const UpdateLabel = ({ updatedAt }: Props) => {
  const formattedUpdatedAt = updatedAt ? dayjs(updatedAt).format('YYYY/MM/DD HH:mm:ss') : ''

  return (
    formattedUpdatedAt && (
      <div className="d-flex text-muted justify-content-end">
        <i className="icf-updated align-self-center pe-1" />
        <div className="font-x-small">{formattedUpdatedAt}</div>
      </div>
    )
  )
}
