import dayjs from 'dayjs'
import { merge, sortBy, uniqBy, sum, compact, omit } from 'es-toolkit'
import { floor, max, min } from 'es-toolkit/compat'
import { useMemo, useCallback, useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { COLOR_TYPES } from 'api/constants'
import type { DailyWorkRow, DailyWork } from 'api/reports/types'
import { CONNECTION_TYPES } from 'api/schedule_types/constants'
import { compactAverage } from 'api/utils'

import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

import { colorTypeToCode } from 'components/Dashboard/utils'
import { BadgeButton, Chart, Table } from 'components/common'
import type { BadgeItem, Series, TableHeaderType, TableCellType } from 'components/common/types'
import { createLineChartOptions } from 'components/common/utils'

import { createXAxis } from './utils'

import styles from './ProductivityGraph.module.scss'

type ProductivityBadgeItem = BadgeItem & {
  unit: string
}

type DailyWorkArrayRow = {
  date: string
  dailyAvgPlanCount: Array<number | null>
  dailyAvgRecordCount: Array<number | null>
  dailyPlanTime: Array<number | null>
  dailyRecordSum: Array<number | null>
}

type GraphCustomData = {
  date: string
  planTimeData: number | null
  recordCountData: number | null
}

type Props = {
  dailyWorkData: DailyWork[]
  start: Date
  end: Date
}

export const ProductivityGraph = ({ dailyWorkData, start, end }: Props) => {
  const { workspaceId } = useParams<'workspaceId'>()
  const dispatch = useDispatch()
  const [selectedBadgeKeys, setSelectedBadgeKeys] = useState<number[]>([])

  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  useEffect(() => {
    if (!workspaceId) {
      return
    }
    dispatch(getScheduleTypeList(Number(workspaceId)))
  }, [dispatch, workspaceId])

  const badgeItems = useMemo(() => {
    const formattedDailyWorkData = dailyWorkData.map<ProductivityBadgeItem>(dailyWork => ({
      color: dailyWork.scheduleTypeColor,
      key: dailyWork.scheduleTypeId,
      label: dailyWork.scheduleTypeName,
      unit: dailyWork.unit,
    }))
    const dailyWorkDataUniqueByKey = uniqBy(formattedDailyWorkData, data => data.key)

    const scheduleTypeIds = partialScheduleTypes.filter(s => s.connectionType === CONNECTION_TYPES.AUTO).map(s => s.id)

    return sortBy(dailyWorkDataUniqueByKey, ['key']).filter(i => scheduleTypeIds.includes(i.key))
  }, [dailyWorkData, partialScheduleTypes])

  useEffect(() => {
    // badgeItemsが更新されたら全選択
    setSelectedBadgeKeys(badgeItems.map(badgeItem => badgeItem.key))
  }, [badgeItems])

  const createSummaryDailyRows = useCallback(
    (badgeKey: number) => {
      const dailyWorkRows = dailyWorkData
        .filter(dailyWork => dailyWork.scheduleTypeId === badgeKey)
        .flatMap(dailyWork => dailyWork.data)
      const summaryArrayDailyRows = dailyWorkRows.reduce((acc: DailyWorkArrayRow[], cur) => {
        const target = acc.find(row => row.date === cur.date)
        if (!target) {
          return acc.concat([
            {
              date: cur.date,
              dailyAvgPlanCount: [cur.dailyAvgPlanCount],
              dailyAvgRecordCount: [cur.dailyAvgRecordCount],
              dailyPlanTime: [cur.planTime],
              dailyRecordSum: [cur.recordCount],
            },
          ])
        }
        return acc
      }, [])

      // compactAverage で null を除いた平均に置き換え、sum(compact())でnullを除いた合計に置き換える
      return summaryArrayDailyRows.map((row): DailyWorkRow => {
        return {
          date: row.date,
          dailyAvgPlanCount: compactAverage(row.dailyAvgPlanCount),
          dailyAvgRecordCount: compactAverage(row.dailyAvgRecordCount),
          planTime: row.dailyPlanTime.length === 0 ? 0 : sum(compact(row.dailyPlanTime)),
          recordCount: row.dailyRecordSum.length === 0 ? 0 : sum(compact(row.dailyRecordSum)),
        }
      })
    },
    [dailyWorkData]
  )

  const summaryRowData = useMemo(() => {
    return badgeItems.map(item => {
      return {
        ...item,
        data: createSummaryDailyRows(item.key),
      }
    })
  }, [badgeItems, createSummaryDailyRows])

  const scheduleTypeSummaryList = useMemo(() => {
    return summaryRowData.map(row => {
      const dailyAvgRecordCounts = row.data.map(r => r.dailyAvgRecordCount)
      const planTimeSum = sum(compact(row.data.map(r => r.planTime)))
      const recordSum = sum(compact(row.data.map(r => r.recordCount)))

      return {
        key: row.key,
        color: row.color,
        label: row.label,
        unit: row.unit,
        avgProductivity: planTimeSum === 0 ? 0 : recordSum / planTimeSum,
        planTimeSum: planTimeSum,
        recordSum: recordSum,
        maxProductivity: max(compact(dailyAvgRecordCounts)) ?? 0,
        minProductivity: min(compact(dailyAvgRecordCounts)) ?? 0,
      }
    })
  }, [summaryRowData])

  const chartOptions = useMemo(() => {
    const selectedBadges = badgeItems.filter(badge => selectedBadgeKeys.includes(badge.key))
    const xAxisData = createXAxis(start, end)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)
      const summaryRows = createSummaryDailyRows(graphBadge.key)
      // areaの上にlineを表示するためにareaを先にしておく
      const recordGraphData = xAxisData.map(date => {
        // パフォーマンス改善のために日付を直接文字列として比較する
        const target = summaryRows.find(row => row.date === date)

        return {
          date: date,
          recordData: target ? target.dailyAvgRecordCount : null,
          planTimeData: target ? target.planTime : null,
          recordCountData: target ? target.recordCount : null,
        }
      })

      yAxis.push({
        type: 'area',
        color: colorCode,
        data: recordGraphData.map(record => record.recordData),
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
          customData: recordGraphData.map(item => omit(item, ['recordData'])),
        },
      })

      const planData = xAxisData.map(date => {
        // パフォーマンス改善のために日付を直接文字列として比較する
        const target = summaryRows.find(row => row.date === date)
        return target ? target.dailyAvgPlanCount : null
      })
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: planData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })
    })

    const options = createLineChartOptions(
      {
        xAxis: {
          data: xAxisData,
        },
        yAxis,
      },
      'Dash',
      'day'
    )
    merge<Highcharts.Options, Highcharts.Options>(options, {
      tooltip: {
        formatter() {
          const isPlan = this.series.userOptions.type === 'line'
          const planText = isPlan ? '（予測）' : ''
          const tooltipTextData = [
            dayjs(this.x).format('YYYY/MM/DD'),
            this.series.name,
            `${Math.floor(this.y || 0)}${this.series.options.custom!.unit}${planText}`,
          ]
          if (!isPlan) {
            const target = this.series.options.custom!.customData.find((data: GraphCustomData) => data.date === this.x)
            const planTime = target ? target.planTimeData : '-'
            const recordCount = target ? target.recordCountData : '-'

            tooltipTextData.push(`作業実績合計:${planTime}${this.series.options.custom!.unit}`)
            tooltipTextData.push(`投入人時合計:${recordCount}人時`)
          }
          return '<div style="text-align:center">' + tooltipTextData.join('<br>') + '</div>'
        },
      },
      xAxis: {
        tickInterval: Math.ceil(xAxisData.length / 7),
      },
    })
    return options
  }, [badgeItems, selectedBadgeKeys, start, end, createSummaryDailyRows])

  const tableHeaders: TableHeaderType[] = [
    { value: '作業名', width: '25%', className: 'border-end' },
    { value: '平均生産性', width: '15%' },
    { value: '投入人時合計', width: '15%' },
    { value: '作業実績合計', width: '15%' },
    { value: '最高生産性', width: '15%' },
    { value: '最低生産性', width: '15%' },
  ]

  const tableItems: TableCellType[][] = useMemo(
    () =>
      scheduleTypeSummaryList.map(data => [
        {
          value: (
            <div className="d-flex align-items-center px-3">
              <div className={`${styles.square} bg-${data.color} me-1`} />
              <div className="text-truncate">{data.label}</div>
            </div>
          ),
          className: 'border-end',
        },
        { value: `${floor(data.avgProductivity, 1).toFixed(1)} ${data.unit}/1時間` },
        { value: `${data.planTimeSum} 人時` },
        { value: `${data.recordSum} ${data.unit}` },
        { value: `${data.maxProductivity} ${data.unit}/1時間` },
        { value: `${data.minProductivity} ${data.unit}/1時間` },
      ]),
    [scheduleTypeSummaryList]
  )

  return (
    <div>
      <Chart options={chartOptions} />
      <div className="d-flex row mx-2 mt-4">
        <BadgeButton
          items={badgeItems}
          selected={selectedBadgeKeys}
          onChange={selected => setSelectedBadgeKeys(selected)}
        />
      </div>
      <hr color={COLOR_TYPES.SILVER} />
      <div className="mb-3">
        <div className="fw-bold mb-2">選択対象の指定期間のデータ</div>
        <Table header={tableHeaders} body={tableItems} />
      </div>
    </div>
  )
}
