import { isEqual } from 'es-toolkit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Popup from 'reactjs-popup'
import { Input, Label } from 'reactstrap'

import { SPOT_WORKER_SHIFT_STATUS } from 'api/spot_workers/constants'
import type { SpotWorkerColumnDataType } from 'api/spot_workers/types'

import { showSuccess } from 'slices/notificationSlice'
import {
  putSpotWorkersStatusUpdate,
  getAvailableSpotWorkers,
  selectSpotWorkerStatus,
  deleteSpotWorkers,
  putSpotWorkersStatusSickout,
  putSpotWorkerNew,
  assignSpotWorkerExistingId,
} from 'slices/spotWorkerSlice'

import useDateQuery from 'hooks/useDateQuery'
import type { editDataKeyTypes } from 'hooks/useSpotWorker'
import useSpotWorker from 'hooks/useSpotWorker'

import { EditTextForm, DropDownWithSearchBox, SelectDropdown, SelectTimeForm } from './TableInputForms'
import {
  ACTIONS,
  checkIsTimeSelectionValid,
  UNREGISTERED_VALUE_ID,
  UNSELECTED_TIME_LABEL,
  UNREGISTERED_GROUP_LABEL,
  UNREGISTERED_TEMPLATE_LABEL,
  shiftStatusList,
  actionList,
  UNSELECTED_ITEM_LABEL,
  MAX_WORKING_HOURS_COL,
  checkIsColumnNameVerified,
  nameVerificationAction,
} from './utils'

import styles from './SpotWorkerTable.module.scss'

import type { DropdownWithSearchBoxItemProps } from './TableInputForms'
import type { ActionType } from './utils'

type WorkerNameCellProps = {
  textColor: string
  checked: boolean
  name: string
  onChange: () => void
  showAlert: boolean
}
const WorkerNameCell = ({ textColor, checked, onChange, name, showAlert }: WorkerNameCellProps) => (
  <div className="d-flex column-gap-2 align-items-center">
    <Input type="checkbox" checked={checked} onChange={onChange} className="mt-0" />
    <Label check className={textColor}>
      {name}
    </Label>
    {!showAlert && <i className="icf-alert text-danger font-large" />}
  </div>
)

type ShiftStatusCellProps = {
  status: number
  isValid: boolean
  popupLines: string[]
}
const ShiftStatusCell = ({ status, isValid, popupLines }: ShiftStatusCellProps) => (
  <Popup
    trigger={
      <td className={`${styles.stickyCol} ${styles.left2} ${!isValid && styles.tableInvalidColumn}`}>
        <div className={shiftStatusList.find(s => s.id === status)?.color}>
          {shiftStatusList.find(s => s.id === status)?.label || ''}
        </div>
      </td>
    }
    position="bottom center"
    on="hover"
    arrowStyle={{ color: 'black' }}
    offsetX={-30}
    offsetY={-15}
  >
    <div className="text-white bg-black px-3 py-2 rounded font-small">
      {popupLines.map((line, index) => (
        <div key={index}>
          {line}
          {index < popupLines.length - 1 && <br />}
        </div>
      ))}
    </div>
  </Popup>
)

const getWorkerIdFormCellIconClass = (
  workerId: number | null,
  suggestedWorkerId: number | null,
  textColor: string,
  isMemberIdDuplicated: boolean
) => {
  if (workerId) {
    return `icf-check ${textColor || 'text-success'}`
  }
  if (suggestedWorkerId) {
    return isMemberIdDuplicated ? 'icf-error text-danger' : 'icf-hint text-warning'
  }
  return 'icf-new text-warning'
}

type WorkerIdFormCellProps = {
  workerId: number | null
  suggestedWorkerId: number | null
  isMemberIdDuplicated: boolean
  items: { key: number; value: string }[]
  onItemSelect: (item: DropdownWithSearchBoxItemProps) => void
  onPlusIconClick: () => void
  onDropdownItemClick: (isOpen: boolean) => void
  textColor: string
  centerColContainerRef?: HTMLDivElement
  disabled: boolean
}
const WorkerIdFormCell = ({
  workerId,
  suggestedWorkerId,
  isMemberIdDuplicated,
  items,
  onItemSelect,
  onPlusIconClick,
  onDropdownItemClick,
  textColor, // textColorは空文字列の場合がある
  centerColContainerRef,
  disabled,
}: WorkerIdFormCellProps) => (
  <div className={`d-flex align-items-center ${textColor} column-gap-2`}>
    <i
      className={`${getWorkerIdFormCellIconClass(workerId, suggestedWorkerId, textColor, isMemberIdDuplicated)} font-large`}
    />
    {workerId ?? (
      <DropDownWithSearchBox
        items={items}
        placeholder="メンバー名で検索"
        textColor={textColor}
        onClick={onDropdownItemClick}
        selectedItem={suggestedWorkerId?.toString() ?? '新規'}
        icon="icf-searchMember"
        onSelect={onItemSelect}
        containerRef={centerColContainerRef || undefined}
        disabled={disabled}
        invisibleSearchBox
      />
    )}
    {!workerId && (
      <i
        className="icf-plus font-large text-muted"
        onClick={() => {
          !disabled && onPlusIconClick()
        }}
      />
    )}
  </div>
)

type WorkspaceFormCellProps = {
  name: string
  items: { key: number; value: string }[]
  onItemSelect: (item: DropdownWithSearchBoxItemProps) => void
  textColor: string
  centerColContainerRef?: HTMLDivElement
  disabled: boolean
}
const WorkspaceFormCell = ({
  name,
  items,
  onItemSelect,
  textColor,
  centerColContainerRef,
  disabled,
}: WorkspaceFormCellProps) => (
  <DropDownWithSearchBox
    disabled={disabled}
    items={items}
    selectedItem={name}
    icon="icf-carot_down"
    placeholder="ワークスペース名で検索"
    textColor={textColor}
    onSelect={onItemSelect}
    containerRef={centerColContainerRef || undefined}
    invisibleSearchBox
  />
)

type GroupFormCellProps = {
  items: { key: number; value: string }[]
  selectedItem: string
  onItemSelect: (item: DropdownWithSearchBoxItemProps) => void
  textColor: string
  centerColContainerRef?: HTMLDivElement
  disabled: boolean
}
const GroupFormCell = ({
  items,
  selectedItem,
  onItemSelect,
  textColor,
  centerColContainerRef,
  disabled,
}: GroupFormCellProps) => (
  <DropDownWithSearchBox
    items={
      selectedItem === UNREGISTERED_GROUP_LABEL
        ? items
        : [{ key: UNREGISTERED_VALUE_ID, value: UNREGISTERED_GROUP_LABEL }].concat(items)
    }
    placeholder="グループ名で検索"
    selectedItem={selectedItem}
    textColor={textColor}
    icon="icf-carot_down"
    onSelect={onItemSelect}
    containerRef={centerColContainerRef || undefined}
    disabled={disabled}
    invisibleSearchBox
  />
)

type TimeSelectBoxCellProps = {
  time: { startTime: string | null; endTime: string | null }
  onStartTimeChange: (hour: string, minute: string) => void
  onEndTimeChange: (hour: string, minute: string) => void
  textColor: string
  centerColContainerRef?: HTMLDivElement
  disabled: boolean
}
const TimeSelectBoxCell = ({
  time,
  onStartTimeChange,
  onEndTimeChange,
  textColor,
  centerColContainerRef,
  disabled,
}: TimeSelectBoxCellProps) => (
  <td>
    <div className={`d-flex align-items-center ${styles.workTimeSelectBox} ${textColor}`}>
      <SelectTimeForm
        startTime={time.startTime}
        endTime={time.endTime}
        onStartTimeChange={onStartTimeChange}
        onEndTimeChange={onEndTimeChange}
        isPortal
        portalTarget={centerColContainerRef}
        disabled={disabled}
      />
    </div>
  </td>
)

type EditWmsMemberIdFormCellProps = {
  value: string
  onChange: (value: string) => void
  disabled: boolean
}
const EditWmsMemberIdFormCell = ({ value, onChange, disabled }: EditWmsMemberIdFormCellProps) => (
  <div className="d-flex align-items-center">
    <EditTextForm value={value} onChange={onChange} defaultValue="" disabled={disabled} />
  </div>
)

type TemplateFormCellProps = {
  items: { key: number; value: string }[]
  selectedItem: string
  onItemSelect: (item: DropdownWithSearchBoxItemProps) => void
  textColor: string
  centerColContainerRef?: HTMLDivElement
  disabled: boolean
}
const TemplateFormCell = ({
  items,
  selectedItem,
  onItemSelect,
  textColor,
  centerColContainerRef,
  disabled,
}: TemplateFormCellProps) => (
  <DropDownWithSearchBox
    items={
      selectedItem === UNREGISTERED_TEMPLATE_LABEL
        ? items
        : [{ key: UNREGISTERED_VALUE_ID, value: UNREGISTERED_TEMPLATE_LABEL }].concat(items)
    }
    selectedItem={selectedItem}
    textColor={textColor}
    icon="icf-carot_down"
    onSelect={onItemSelect}
    containerRef={centerColContainerRef || undefined}
    disabled={disabled}
  />
)

type SkillCellProps = {
  skillNames: { name: string }[]
  textColor: string
}
const SkillCell = ({ skillNames, textColor }: SkillCellProps) => (
  <div className="d-flex align-items-center">
    {skillNames.map((skill, i) => (
      <div
        key={i}
        className={`${textColor} text-truncate badge rounded-pill text-black bg-light-gray fw-normal font-small me-2`}
      >
        {skill.name}
      </div>
    ))}
  </div>
)

type ActionItemCellProps = {
  items: { key: ActionType; value: string }[]
  onActionClick: (actionId: ActionType) => void
  centerColContainerRef?: HTMLDivElement
  disabled: boolean
}
const ActionItemCell = ({ items, onActionClick, centerColContainerRef, disabled }: ActionItemCellProps) => (
  <SelectDropdown
    content={<i className="icf-others font-large" role="button" />}
    items={items}
    onSelect={item => onActionClick(item.key as ActionType)}
    isContainer
    containerRef={centerColContainerRef || undefined}
    disabled={disabled}
  />
)

type SpotWorkerTableColumnProps = {
  centerColContainerRef?: HTMLDivElement
  initDataColumn?: SpotWorkerColumnDataType
  editDataColumn: SpotWorkerColumnDataType
  addWorkTimeRow2: boolean
  addWorkTimeRow3: boolean
  onColumnChange: (
    lineNumber: number,
    key: editDataKeyTypes,
    value: { id: number; name: string } | string | null
  ) => void
  onSelectedUserCheck: (index: number) => void
  selectedUser: number[]
}
export const SpotWorkerTableColumn = ({
  centerColContainerRef,
  initDataColumn,
  editDataColumn,
  addWorkTimeRow2,
  addWorkTimeRow3,
  selectedUser,
  onSelectedUserCheck,
  onColumnChange,
}: SpotWorkerTableColumnProps) => {
  const dispatch = useDispatch()
  const { checkValidColumn, checkIsDateInEditableRange, targetWorkspaces, targetGroups, targetTemplates } =
    useSpotWorker()
  const workDate = useDateQuery()
  const { availableSpotWorkers, isRequesting, errorMessage, failedColumnNames } = useSelector(
    selectSpotWorkerStatus,
    shallowEqual
  )

  const [submitted, setSubmitted] = useState(false)
  const isSickout = useMemo(() => editDataColumn.sickout, [editDataColumn.sickout])
  const isEditable = useMemo(() => !checkIsDateInEditableRange(workDate), [checkIsDateInEditableRange, workDate])
  const isEdited = useMemo(() => !isEqual(initDataColumn, editDataColumn), [initDataColumn, editDataColumn])
  const isValid = useMemo(() => checkValidColumn(editDataColumn), [checkValidColumn, editDataColumn])
  const isWorkspaceSelected = useMemo(() => editDataColumn.workspace !== null, [editDataColumn.workspace])
  const isUserSelected = useMemo(() => selectedUser.includes(editDataColumn.lineNumber), [selectedUser, editDataColumn])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '' && failedColumnNames.length === 0) {
      dispatch(showSuccess())
    }
    setSubmitted(false)
  }, [dispatch, errorMessage, failedColumnNames, isRequesting, submitted])

  const getDisabledColor = useCallback((disabled?: boolean) => (disabled ? 'text-muted opacity-50' : ''), [])
  const getPopupContent = useCallback(
    (shiftStatus: number) => {
      switch (shiftStatus) {
        case SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED:
          return ['最新の情報がシフトに反映されています']
        case SPOT_WORKER_SHIFT_STATUS.SHIFT_UNREGISTERED:
          return ['シフト未登録です', '作業計画の編集ができません']
        case SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT:
        case SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT_WITHOUT_ID:
          return [
            '必須情報が不足しています',
            !editDataColumn.workerId && 'メンバーID',
            !editDataColumn.workspace && '配属先ワークスペース',
            (!checkIsTimeSelectionValid(editDataColumn.workStart1, editDataColumn.workEnd1) ||
              !editDataColumn.workStart1 ||
              !editDataColumn.workEnd1) &&
              '勤務時間',
          ].filter(Boolean) as string[]
        case SPOT_WORKER_SHIFT_STATUS.NON_UPDATED:
          return ['編集内容がシフトに反映されていません']
        case SPOT_WORKER_SHIFT_STATUS.SICK_OUT:
          return ['欠勤として登録済み', '作業計画の編集ができません']
        default:
          return []
      }
    },
    [editDataColumn]
  )

  // 変更がある場合のみ保存データを作成
  const saveData = useMemo(
    () =>
      isEdited
        ? [
            {
              revision: editDataColumn.revision,
              workerId: editDataColumn.workerId,
              lineNumber: editDataColumn.lineNumber,
              workspaceId: editDataColumn.workspace?.id || null,
              groupId: editDataColumn.group?.id || null,
              wmsMemberId: editDataColumn.wmsMemberId,
              workTemplateId: editDataColumn.template?.id || null,
              workStart1: editDataColumn.workStart1,
              workEnd1: editDataColumn.workEnd1,
              workStart2: editDataColumn.workStart2,
              workEnd2: editDataColumn.workEnd2,
              workStart3: editDataColumn.workStart3,
              workEnd3: editDataColumn.workEnd3,
            },
          ]
        : [],
    [editDataColumn, isEdited]
  )

  const statusChangeData = useMemo(
    () => [
      {
        lineNumber: editDataColumn.lineNumber,
        revision: editDataColumn.revision,
      },
    ],
    [editDataColumn]
  )

  const targetNameVerificationData = useMemo(() => {
    if (!editDataColumn.suggestedWorkerId) {
      return []
    }
    return [
      {
        lineNumber: editDataColumn.lineNumber,
        revision: editDataColumn.revision,
        workerId: editDataColumn.suggestedWorkerId,
      },
    ]
  }, [editDataColumn])

  const actionItems = useMemo(() => {
    if (checkIsColumnNameVerified(editDataColumn)) {
      return [...actionList[editDataColumn.status], nameVerificationAction]
    }
    return actionList[editDataColumn.status]
  }, [editDataColumn])

  const handleActionClick = useCallback(
    (actionId: ActionType) => {
      switch (actionId) {
        case ACTIONS.Update:
        case ACTIONS.Register:
          dispatch(putSpotWorkersStatusUpdate(workDate, statusChangeData, saveData))
          break
        case ACTIONS.Delete:
          dispatch(deleteSpotWorkers(workDate, statusChangeData, saveData))
          break
        case ACTIONS.Sickout:
          dispatch(putSpotWorkersStatusSickout(workDate, statusChangeData, saveData))
          break
        case ACTIONS.NameVerification:
          dispatch(assignSpotWorkerExistingId(workDate, targetNameVerificationData, saveData))
          break
        default:
          break
      }
      setSubmitted(true)
    },
    [dispatch, statusChangeData, saveData, workDate, targetNameVerificationData]
  )

  const isRegistered = useMemo(
    () =>
      editDataColumn.status === SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED ||
      editDataColumn.status === SPOT_WORKER_SHIFT_STATUS.NON_UPDATED,
    [editDataColumn]
  )

  const isCellDisabled = useMemo(() => isSickout || isEditable, [isSickout, isEditable])

  const isGroupFormCellDisabled = useMemo(
    () => isCellDisabled || !isWorkspaceSelected,
    [isCellDisabled, isWorkspaceSelected]
  )

  const isTimeSelectBoxDisabled = useMemo(
    () =>
      isCellDisabled ||
      editDataColumn.status === SPOT_WORKER_SHIFT_STATUS.NON_UPDATED ||
      editDataColumn.status === SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED,
    [isCellDisabled, editDataColumn]
  )

  const isTemplateFormCellDisabled = useMemo(
    () =>
      isCellDisabled ||
      !isWorkspaceSelected ||
      (isRegistered && editDataColumn.workspace?.id === initDataColumn?.workspace?.id),
    [isCellDisabled, isWorkspaceSelected, isRegistered, editDataColumn.workspace?.id, initDataColumn?.workspace?.id]
  )

  const timeData = useMemo(
    () =>
      [{ startTime: editDataColumn.workStart1, endTime: editDataColumn.workEnd1 }]
        .concat(addWorkTimeRow2 ? [{ startTime: editDataColumn.workStart2, endTime: editDataColumn.workEnd2 }] : [])
        .concat(addWorkTimeRow3 ? [{ startTime: editDataColumn.workStart3, endTime: editDataColumn.workEnd3 }] : []),
    [editDataColumn, addWorkTimeRow2, addWorkTimeRow3]
  )

  return (
    <tr className={isValid ? 'bg-white' : styles.tableInvalidColumn}>
      <td className={`${styles.stickyCol} ${styles.left1} ${!isValid ? styles.tableInvalidColumn : ''}`}>
        <WorkerNameCell
          name={editDataColumn.workerName}
          onChange={() => onSelectedUserCheck(editDataColumn.lineNumber)}
          checked={isUserSelected}
          textColor={`${getDisabledColor(isCellDisabled)} text-truncate`}
          showAlert={isValid}
        />
      </td>
      <ShiftStatusCell
        popupLines={getPopupContent(editDataColumn.status).filter(line => line !== '')}
        status={editDataColumn.status}
        isValid={isValid}
      />
      <td>
        <WorkerIdFormCell
          workerId={editDataColumn.workerId}
          suggestedWorkerId={editDataColumn.suggestedWorkerId}
          isMemberIdDuplicated={editDataColumn.isWorkerIdDuplicated}
          onDropdownItemClick={isOpen => !isOpen && dispatch(getAvailableSpotWorkers(workDate))}
          items={availableSpotWorkers.map(w => ({ key: w.id, value: w.name }))}
          disabled={isCellDisabled}
          textColor={getDisabledColor(isCellDisabled)}
          onItemSelect={item => {
            dispatch(
              assignSpotWorkerExistingId(
                workDate,
                statusChangeData.map(data => ({ ...data, workerId: Number(item.key) })),
                saveData
              )
            )
            setSubmitted(true)
          }}
          onPlusIconClick={() => dispatch(putSpotWorkerNew(workDate, statusChangeData, saveData))}
          centerColContainerRef={centerColContainerRef}
        />
      </td>
      <td>
        <WorkspaceFormCell
          onItemSelect={item => {
            onColumnChange(editDataColumn.lineNumber, 'workspace', { id: Number(item.key), name: item.value })
            onColumnChange(editDataColumn.lineNumber, 'group', null)
            if (isRegistered && Number(item.key) === initDataColumn?.workspace?.id) {
              onColumnChange(editDataColumn.lineNumber, 'template', initDataColumn?.template || null)
              return
            }
            onColumnChange(editDataColumn.lineNumber, 'template', null)
          }}
          items={targetWorkspaces
            .filter(w => w.id !== editDataColumn.workspace?.id)
            .map(w => ({ key: w.id, value: w.name }))}
          textColor={getDisabledColor(isCellDisabled)}
          disabled={isCellDisabled}
          centerColContainerRef={centerColContainerRef}
          name={editDataColumn.workspace ? editDataColumn.workspace.name : UNSELECTED_ITEM_LABEL}
        />
      </td>
      <td>
        <GroupFormCell
          selectedItem={
            isWorkspaceSelected ? editDataColumn.group?.name ?? UNREGISTERED_GROUP_LABEL : UNSELECTED_ITEM_LABEL
          }
          disabled={isGroupFormCellDisabled}
          onItemSelect={item => {
            onColumnChange(
              editDataColumn.lineNumber,
              'group',
              item.key === UNREGISTERED_VALUE_ID ? null : { id: Number(item.key), name: item.value }
            )
          }}
          items={targetGroups
            .filter(g => g.id !== editDataColumn.group?.id && g.workspaceId === editDataColumn.workspace?.id)
            .map(g => ({ key: g.id, value: g.name }))}
          textColor={getDisabledColor(isGroupFormCellDisabled)}
          centerColContainerRef={centerColContainerRef}
        />
      </td>
      {timeData.map((time, index) => {
        if (index >= MAX_WORKING_HOURS_COL) {
          return <></>
        }
        const workStartField = `workStart${index + 1}` as editDataKeyTypes
        const workEndField = `workEnd${index + 1}` as editDataKeyTypes
        return (
          <TimeSelectBoxCell
            key={index}
            textColor={getDisabledColor(isTimeSelectBoxDisabled)}
            disabled={isTimeSelectBoxDisabled}
            time={time}
            onStartTimeChange={(hour, minute) =>
              onColumnChange(
                editDataColumn.lineNumber,
                workStartField,
                `${hour}:${minute}` === UNSELECTED_TIME_LABEL ? null : `${hour}:${minute}`
              )
            }
            onEndTimeChange={(hour, minute) =>
              onColumnChange(
                editDataColumn.lineNumber,
                workEndField,
                `${hour}:${minute}` === UNSELECTED_TIME_LABEL ? null : `${hour}:${minute}`
              )
            }
            centerColContainerRef={centerColContainerRef}
          />
        )
      })}
      <td>
        <EditWmsMemberIdFormCell
          value={editDataColumn.wmsMemberId || ''}
          disabled={isCellDisabled}
          onChange={value => onColumnChange(editDataColumn.lineNumber, 'wmsMemberId', value === '' ? null : value)}
        />
      </td>
      <td>
        <TemplateFormCell
          items={targetTemplates
            .filter(t => t.id !== editDataColumn.template?.id && t.workspaceId === editDataColumn.workspace?.id)
            .map(t => ({ key: t.id, value: t.name }))}
          onItemSelect={item => {
            onColumnChange(
              editDataColumn.lineNumber,
              'template',
              item.key === UNREGISTERED_VALUE_ID ? null : { id: Number(item.key), name: item.value }
            )
          }}
          selectedItem={
            isWorkspaceSelected ? editDataColumn.template?.name || UNREGISTERED_TEMPLATE_LABEL : UNSELECTED_ITEM_LABEL
          }
          textColor={getDisabledColor(isTemplateFormCellDisabled)}
          disabled={isTemplateFormCellDisabled}
          centerColContainerRef={centerColContainerRef}
        />
      </td>
      <td>
        <SkillCell skillNames={editDataColumn.skills} textColor={getDisabledColor(isCellDisabled)} />
      </td>
      <td className={`${styles.stickyCol} ${styles.right}`}>
        <ActionItemCell
          disabled={isEditable}
          items={actionItems}
          onActionClick={handleActionClick}
          centerColContainerRef={centerColContainerRef}
        />
      </td>
    </tr>
  )
}
