import { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Button, ModalBody, ModalFooter } from 'reactstrap'

import { selectSpotWorkerStatus, clearFailedColumnNames } from 'slices/spotWorkerSlice'

import { Modal } from 'components/common/Modal/Modal'

import styles from './SpotWorkerRequestFailDialog.module.scss'

export const SpotWorkerRequestFailDialog = () => {
  const { failedColumnNames } = useSelector(selectSpotWorkerStatus, shallowEqual)
  const [isRequestFailDialogOpen, setIsRequestFailDialogOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (failedColumnNames.length > 0) {
      setIsRequestFailDialogOpen(true)
    }
  }, [dispatch, failedColumnNames])

  const handleClick = () => {
    dispatch(clearFailedColumnNames())
    setIsRequestFailDialogOpen(false)
  }

  return (
    <Modal isOpen={isRequestFailDialogOpen}>
      <ModalBody className="font-large fw-bold">{'全ての変更を反映できませんでした'}</ModalBody>
      <ModalBody>
        <div className="mb-4">
          あなたの変更内容は反映できませんでした。あなたの変更内容は破棄され、最新状態に更新されています。再度メンバー情報をご確認ください。
        </div>
        <div>変更内容を反映できなかったメンバー : </div>
        <div className={styles.failedColumnContainer}>{failedColumnNames.join(', ')}</div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-right">
        <Button onClick={handleClick} color="primary">
          {'閉じる'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
