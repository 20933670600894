import { sortBy } from 'es-toolkit'
import { useCallback } from 'react'

import type { BopReportsWorkspaceArray } from 'api/bop_reports/types'

import styles from 'components/common/BopReports/BopProfitAndLoss.module.scss'

export type ProcessedWorkspaceData = {
  sales: number
  otherSales: number
  totalSales: number
  variableDirectLaborCosts: number
  variableIndirectLaborCosts: number
  materialCosts: number
  totalCosts: number
  marginalProfits: number
  indirectLaborCosts: number
  managementCosts: number
  totalFixedCosts: number
  contributionProfits: number
  workspaceCosts: number
  tenantCostsWithWorkspace: number
  totalOtherCosts: number
  operatingProfits: number
}

const initProcessedWorkspaceData = {
  sales: 0,
  otherSales: 0,
  totalSales: 0,
  variableDirectLaborCosts: 0,
  variableIndirectLaborCosts: 0,
  materialCosts: 0,
  totalCosts: 0,
  marginalProfits: 0,
  indirectLaborCosts: 0,
  managementCosts: 0,
  totalFixedCosts: 0,
  contributionProfits: 0,
  workspaceCosts: 0,
  tenantCostsWithWorkspace: 0,
  totalOtherCosts: 0,
  operatingProfits: 0,
}

export type WorkspaceDataItem = {
  actualValue: number // 実績値、または、見込みを含んだ実績値を代入
  estimateValue: number // 見込み値を代入
  className?: string
}

export type MergedWorkspaceData = {
  title: string
  data: WorkspaceDataItem[]
}

type Keys = {
  key: keyof ProcessedWorkspaceData
  className: string
}

// マージする際に使用するキーとクラス名の定義
const keys: Keys[] = [
  { key: 'sales', className: '' },
  { key: 'otherSales', className: '' },
  { key: 'totalSales', className: styles.bgColorTotal },
  { key: 'variableDirectLaborCosts', className: '' },
  { key: 'variableIndirectLaborCosts', className: '' },
  { key: 'materialCosts', className: '' },
  { key: 'totalCosts', className: styles.bgColorTotal },
  { key: 'marginalProfits', className: styles.bgColorProfit },
  { key: 'indirectLaborCosts', className: '' },
  { key: 'managementCosts', className: '' },
  { key: 'totalFixedCosts', className: styles.bgColorTotal },
  { key: 'contributionProfits', className: styles.bgColorProfit },
  { key: 'workspaceCosts', className: '' },
  { key: 'tenantCostsWithWorkspace', className: '' },
  { key: 'totalOtherCosts', className: styles.bgColorTotal },
  { key: 'operatingProfits', className: styles.bgColorProfit },
]

// 各種小計の計算
const calculateProfitAndLoss = (workspaceData: BopReportsWorkspaceArray[]) =>
  workspaceData.map(workspace => {
    const {
      sales,
      otherSales,
      variableDirectLaborCosts,
      variableIndirectLaborCosts,
      materialCosts,
      indirectLaborCosts,
      managementCosts,
      workspaceCosts,
      tenantCostsWithWorkspace,
    } = workspace.data

    const totalSales = sales + otherSales
    const totalCosts = variableDirectLaborCosts + variableIndirectLaborCosts + materialCosts
    const marginalProfits = totalSales - totalCosts
    const totalFixedCosts = indirectLaborCosts + managementCosts
    const contributionProfits = marginalProfits - totalFixedCosts
    const totalOtherCosts = workspaceCosts + tenantCostsWithWorkspace
    const operatingProfits = contributionProfits - totalOtherCosts

    return {
      ...workspace.data,
      totalSales,
      totalCosts,
      marginalProfits,
      totalFixedCosts,
      contributionProfits,
      totalOtherCosts,
      operatingProfits,
    }
  })

// 1つのパラメータに変換するため、newActualとnewEstimateとタイトルをマージする
const mergeData = (newActual: ProcessedWorkspaceData[], newEstimate: ProcessedWorkspaceData[], titles: string[]) =>
  newActual.map((actualData, index) => {
    const estimateData = newEstimate[index] || initProcessedWorkspaceData
    return {
      title: titles[index] || '',
      data: keys.map(({ key, className }) => ({
        actualValue: actualData[key],
        estimateValue: estimateData[key],
        className,
      })),
    }
  })

const useBopProfitAndLoss = () => {
  const processProfitAndLossData = useCallback(
    (actuals: BopReportsWorkspaceArray[], estimates: BopReportsWorkspaceArray[]) => {
      const sortedActual = sortBy(actuals, ['workspaceName'])
      const sortedEstimate = sortBy(estimates, ['workspaceName'])

      const newActual = calculateProfitAndLoss(sortedActual)
      const newEstimate = calculateProfitAndLoss(sortedEstimate)
      const titles = sortedActual.map(workspace => workspace.workspaceName)
      return mergeData(newActual, newEstimate, titles)
    },
    []
  )

  return { processProfitAndLossData }
}

export default useBopProfitAndLoss
