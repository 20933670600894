import { useState, useEffect } from 'react'

import PivotOuterIndex from './PivotOuterIndex'

import type { ReactElement } from 'react'

type TypedProps = {
  defaultIndex?: number
  onChange?: (index: number) => void
  children: ReactElement[]
}
const Pivot = (props: TypedProps) => {
  const [selectedIndex, setIndex] = useState(props.defaultIndex || 0)
  useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedIndex)
    }
  }, [selectedIndex, props])
  return (
    <PivotOuterIndex selectedIndex={selectedIndex} onChange={index => setIndex(index)}>
      {props.children}
    </PivotOuterIndex>
  )
}

export default Pivot
