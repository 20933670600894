import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardText } from 'reactstrap'

import { setTeamWorkspace } from 'slices/sessionSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import useLogout from 'hooks/useLogout'

import styles from './styles.module.scss'

export const TeamSelectWorkspaces = () => {
  const dispatch = useDispatch()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { logout } = useLogout()

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const navigate = useNavigate()
  const handleClick = (workspaceId: number, workspaceName: string) => {
    dispatch(setTeamWorkspace({ workspaceId, workspaceName }))
    navigate('/team-select-groups')
  }

  return (
    <>
      <div className={styles.header}>
        <div className="font-x-large fw-bold mx-auto">ワークスペースを選択</div>
        <div className="text-end" onClick={() => logout()}>
          ログアウト
        </div>
      </div>
      <div className={`position-sticky ${styles.list}`}>
        {partialWorkspaces.map(workspace => (
          <Card
            className={`${styles.singleTextCard} p-3 mb-2`}
            key={workspace.id}
            onClick={() => handleClick(workspace.id, workspace.name)}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="overflow-hidden">
                <CardText className="m-0 text-truncate">{workspace.name}</CardText>
              </div>
              <i className="icf-chevron_right font-large" />
            </div>
          </Card>
        ))}
      </div>
    </>
  )
}
