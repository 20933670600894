import { last } from 'es-toolkit'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Card, CardTitle, CardBody, Col, Row, Button } from 'reactstrap'

import { showError, showSuccess } from 'slices/notificationSlice'
import {
  getOfficialDutiesList,
  selectOfficialDutiesStatus,
  updateOfficialDuties,
  createOfficialDuties,
  getOfficialDuties,
} from 'slices/officialDutiesSlice'
import { ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import {
  NavMenu,
  List,
  InputFormat,
  InputGroupFormatWithCostsBadge,
  CustomButton,
  CardSubmitFooter,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'
import type { ListItem } from 'components/common/types'

import placeholder from 'images/allEmpty.svg'

import HourlyWageDelete from './HourlyWageDelete'

import styles from './HourlyWage.module.scss'

const NEW_OFFICIAL_DUTY_ID = 0
const MAX_ITEMS = 20

const HourlyWage = () => {
  const [officialDutiesName, setOfficialDutiesName] = useState<string | undefined>(undefined)
  const [officialDutiesNameValidity, setOfficialDutiesNameValidity] = useState(false)
  const [averageHourlyWage, setAverageHourlyWage] = useState<number>(0)
  const [averageHourlyWageValidity, setAverageHourlyWageValidity] = useState(false)
  const [selectedOfficialDutyId, setSelectedOfficialDutyId] = useState<number | undefined>(undefined)
  const [officialDutiesItems, setOfficialDutiesItems] = useState<ListItem[]>([])
  const { partialOfficialDutiesList, isRequesting, errorMessage, officialDuties } = useSelector(
    selectOfficialDutiesStatus,
    shallowEqual
  )
  const hasOfficialDutiesItems = useMemo(() => !(officialDutiesItems.length === 0), [officialDutiesItems])
  const [openDelete, setOpenDelete] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatch()

  const isNewItem = useMemo(() => selectedOfficialDutyId === NEW_OFFICIAL_DUTY_ID, [selectedOfficialDutyId])

  useEffect(() => {
    dispatch(getOfficialDutiesList())
  }, [dispatch])

  useEffect(() => {
    if (selectedOfficialDutyId === NEW_OFFICIAL_DUTY_ID) {
      setOfficialDutiesName('')
      setAverageHourlyWage(0)
      return
    }
    if (selectedOfficialDutyId === undefined) {
      return
    }
    // selectedOfficialDutyIdが変化した時、個別データを取得する
    dispatch(getOfficialDuties(selectedOfficialDutyId))
  }, [selectedOfficialDutyId, dispatch])

  // officialDutiesから、フォームに値を設定する
  useEffect(() => {
    if (!officialDuties) {
      return
    }
    setSelectedOfficialDutyId(officialDuties.id)
    setOfficialDutiesName(officialDuties.name)
    setAverageHourlyWage(officialDuties.averageHourlyWage)
  }, [officialDuties])

  const initOfficialDutiesItems = useCallback(() => {
    setOfficialDutiesItems(
      partialOfficialDutiesList.map(duties => ({
        id: duties.id,
        title: duties.name || '',
      }))
    )
  }, [partialOfficialDutiesList])

  // officialDutiesのレスポンス更新時にofficialDutiesItemsを初期化する
  useEffect(() => {
    initOfficialDutiesItems()
  }, [initOfficialDutiesItems])

  useEffect(() => {
    setSelectedOfficialDutyId(prev => {
      // 0件の場合、undefinedで未登録画面を表示させる
      if (officialDutiesItems.length === 0) {
        return
      }
      // 追加アイテムを保存した時
      if (prev === NEW_OFFICIAL_DUTY_ID && !officialDutiesItems.some(item => item.id === NEW_OFFICIAL_DUTY_ID)) {
        return Number(last(officialDutiesItems)!.id)
      }
      // 初期化時とアイテム削除時
      if (prev === undefined || !officialDutiesItems.some(item => item.id === prev)) {
        return Number(officialDutiesItems[0].id)
      }
      return prev
    })
  }, [officialDutiesItems])

  const handleListItemChange = useCallback((id: number) => {
    setSelectedOfficialDutyId(prevId => {
      if (prevId === id) {
        return prevId
      }
      if (prevId === NEW_OFFICIAL_DUTY_ID) {
        setOfficialDutiesItems(prev => prev.filter(item => item.id !== NEW_OFFICIAL_DUTY_ID))
      }
      return id
    })
  }, [])

  const onAddNewItem = useCallback(() => {
    setOfficialDutiesItems(prev =>
      prev.concat({
        title: '',
        id: NEW_OFFICIAL_DUTY_ID,
      })
    )
    // selectedOfficialDutyIdを初期化する
    setSelectedOfficialDutyId(NEW_OFFICIAL_DUTY_ID)
  }, [])

  const disabled = useMemo(
    () => !(officialDutiesName && officialDutiesNameValidity && averageHourlyWage && averageHourlyWageValidity),
    [officialDutiesName, officialDutiesNameValidity, averageHourlyWage, averageHourlyWageValidity]
  )

  const unchanged = useMemo(
    () => officialDutiesName === officialDuties?.name && averageHourlyWage === officialDuties?.averageHourlyWage,
    [officialDuties, officialDutiesName, averageHourlyWage]
  )

  const onCancel = useCallback(() => {
    initOfficialDutiesItems()
    setSelectedOfficialDutyId(officialDuties?.id)
    if (officialDuties) {
      setOfficialDutiesName(officialDuties.name)
      setAverageHourlyWage(officialDuties.averageHourlyWage)
    }
  }, [officialDuties, initOfficialDutiesItems])

  const onSubmit = useCallback(() => {
    if (!officialDutiesName || !averageHourlyWage) {
      return
    }
    if (selectedOfficialDutyId === NEW_OFFICIAL_DUTY_ID) {
      // 作成
      dispatch(createOfficialDuties({ name: officialDutiesName, averageHourlyWage }))
      setSubmitted(true)
      return
    }
    if (selectedOfficialDutyId) {
      // 更新
      dispatch(updateOfficialDuties(selectedOfficialDutyId, { name: officialDutiesName, averageHourlyWage }))
      setSubmitted(true)
    }
  }, [selectedOfficialDutyId, officialDutiesName, averageHourlyWage, dispatch])

  const handleHourlyWageDeleteSuccess = useCallback(() => {
    dispatch(showSuccess())
    setOpenDelete(false)
  }, [dispatch])

  // 平均時給のonChangeハンドラー
  const handleAverageHourlyWageChange = (value: string) => {
    const numericValue = Number(value)
    if (!isNaN(numericValue)) {
      setAverageHourlyWage(numericValue)
    }
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      if (errorMessage === ERROR_STATUS_CODE.CONFLICT) {
        dispatch(showError({ errorMessage: '登録上限に達しているため、保存できませんでした。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, selectedOfficialDutyId])

  const onDetailClick = () => window.open('https://help.smileboard.jp/hourly-wage', '_blank')

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="mb-3">
          <div className="d-flex justify-content-between align-self-center">
            <div className="font-x-large fw-bold">職掌別時給管理</div>
            <div className="d-flex align-items-center">
              <div className="me-2 text-gray">
                {officialDutiesItems.length} / {MAX_ITEMS} 利用中
              </div>
              <CustomButton
                icon="plus"
                className="ms-2"
                onClick={onAddNewItem}
                disabled={
                  officialDutiesItems.some(item => item.id === NEW_OFFICIAL_DUTY_ID) ||
                  officialDutiesItems.length >= MAX_ITEMS
                }
              >
                職掌を追加
              </CustomButton>
            </div>
          </div>
        </div>

        <Row className={styles.row}>
          <Col md={4} className="h-100">
            <Card className={`h-100 ${styles.list}`}>
              {hasOfficialDutiesItems ? (
                <List items={officialDutiesItems} selectedId={selectedOfficialDutyId} onAction={handleListItemChange} />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">職掌がまだ登録されていません</div>
                    <div>まずは最初の職掌を登録してみましょう。</div>
                    <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                      職掌についてもっと詳しく
                    </Button>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100">
              {hasOfficialDutiesItems ? (
                <>
                  <div className="h-100 overflow-auto">
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <CardTitle className="font-large fw-bold">職掌設定</CardTitle>
                        <span className="font-small">※必須項目</span>
                      </div>
                      <InputFormat
                        label="名称※"
                        placeholder="名称を入力"
                        value={officialDutiesName}
                        validations={[Rules.Required]}
                        maxLength={30}
                        size={COLUMN_SIZES.MIDDLE}
                        className="mb-3"
                        onChange={value => setOfficialDutiesName(value)}
                        onValidate={setOfficialDutiesNameValidity}
                      />
                      <InputGroupFormatWithCostsBadge
                        label="平均時給※"
                        addonText="円 / 時間"
                        size={COLUMN_SIZES.MIDDLE}
                        maxLength={10}
                        value={averageHourlyWage.toString()}
                        onChange={handleAverageHourlyWageChange}
                        validations={[Rules.PositiveInteger]}
                        onValidate={setAverageHourlyWageValidity}
                      />
                    </CardBody>

                    <CardBody>
                      <CardTitle className="font-large fw-bold">職掌の削除</CardTitle>
                      職掌を削除すると、その職掌が設定されているメンバーは原価計算に反映されません。
                      <Button
                        outline
                        color="danger"
                        className="my-3 d-block"
                        onClick={() => setOpenDelete(true)}
                        disabled={selectedOfficialDutyId === NEW_OFFICIAL_DUTY_ID}
                      >
                        職掌を削除
                      </Button>
                    </CardBody>
                  </div>
                  <CardSubmitFooter
                    onCancel={() => onCancel()}
                    onSubmit={() => onSubmit()}
                    submitDisabled={disabled || unchanged}
                    cancelDisabled={unchanged}
                    updatedBy={isNewItem ? undefined : officialDuties?.updatedByName}
                    updatedAt={isNewItem ? undefined : officialDuties?.updatedAt}
                  />
                </>
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">職掌が選択されていません</div>
                    <div>職掌を選択して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <HourlyWageDelete
          isOpen={openDelete}
          officialDutyId={selectedOfficialDutyId}
          onSuccess={handleHourlyWageDeleteSuccess}
          onCancel={() => setOpenDelete(false)}
        />
      </div>
    </NavMenu>
  )
}

export default HourlyWage
