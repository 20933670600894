import { useState, useEffect, useMemo, Fragment } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { DropdownItem, Nav, Navbar, NavbarText, NavbarToggler } from 'reactstrap'

import { selectTenantsStatus } from 'slices/tenantsSlice'

import { DropdownList } from 'components/common'
import logo from 'components/common/SinglePageCard/logo.svg'

import useAuthority from 'hooks/useAuthority'

import styles from './HeaderNavbar.module.scss'

import type { CSSProperties } from 'react'

type Menu = {
  label: string
  action: () => void
}
export type DropdownMenu = {
  name: string
  menu: Menu[]
}
type Props = {
  label: string | undefined
  dropdownMenu: DropdownMenu
  onNavbarTogglerClick: () => void
}

const HeaderNavbar = (props: Props) => {
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { isTenantAdmin } = useAuthority()

  const {
    label,
    onNavbarTogglerClick,
    dropdownMenu: { name, menu },
  } = props
  const [open, setOpen] = useState(false)
  const [nameStyles, setNameStyles] = useState<CSSProperties>({
    minWidth: 220,
    maxWidth: window.innerWidth / 3,
  })

  useEffect(
    () =>
      window.addEventListener('resize', () =>
        setNameStyles({
          minWidth: 220,
          maxWidth: window.innerWidth / 3,
        })
      ),
    []
  )
  const toggleContent = (
    <div className="text-end text-truncate" style={nameStyles} role="button">
      {name}
    </div>
  )
  const isStoppedTenant = useMemo(() => {
    if (isTenantAdmin) {
      return false
    }

    if (!tenant) {
      return true
    }

    return !tenant.isActive
  }, [isTenantAdmin, tenant])

  return (
    <Navbar color="white" light className="sticky-top shadow-sm">
      <NavbarToggler className={styles.navbarToggler} hidden={isStoppedTenant} onClick={onNavbarTogglerClick} />
      <Nav className="me-auto" navbar>
        {label ? (
          <NavbarText tag="h6" className="ps-3 pb-0">
            {label}
          </NavbarText>
        ) : null}
      </Nav>

      <img className={styles.logo} src={logo} alt="Smile Board Connect" />

      <NavbarText tag="h6" className="pb-0">
        <DropdownList open={open} setOpen={() => setOpen(!open)} content={toggleContent}>
          {menu.map((m, index) => (
            <Fragment key={m.label}>
              <DropdownItem onClick={m.action}>{m.label}</DropdownItem>
              {index < menu.length - 1 && <DropdownItem divider />}
            </Fragment>
          ))}
        </DropdownList>
      </NavbarText>
    </Navbar>
  )
}
export default HeaderNavbar
