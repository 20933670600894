import { useState, useMemo, useCallback } from 'react'
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Input } from 'reactstrap'

import styles from './Suggestion.module.scss'

import type { ChangeEvent, KeyboardEvent } from 'react'

export type SuggestionItem = {
  id: string | number
  value: string
}

type Props = {
  suggestionList: SuggestionItem[]
  onSelect: (item: SuggestionItem) => void
  placeholder?: string
  className?: string
  disabled?: boolean
}

export const SuggestionFormat = ({ suggestionList = [], onSelect, placeholder = '', className, disabled }: Props) => {
  const [value, setValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [activeItem, setActiveItem] = useState<number | null>(null)
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.value)
  }
  const handleDropdownItemClick = (item: SuggestionItem) => {
    onSelect(item)
    clean()
  }
  const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    switch (ev.key) {
      case 'ArrowDown':
        if (activeItem === null) {
          return setActiveItem(0)
        }
        return setActiveItem(Math.min(activeItem + 1, wordList.length - 1))
      case 'ArrowUp':
        if (activeItem === null) {
          return
        }
        return setActiveItem(Math.max(activeItem - 1, 0))
      case 'Enter':
        if (activeItem === null) {
          return
        }
        onSelect(wordList[activeItem])
        ;(ev.target as HTMLInputElement).blur()
        return clean()
    }
  }
  const clean = useCallback(() => {
    setValue('')
    setActiveItem(null)
    setIsOpen(false)
  }, [])
  const wordList = useMemo(
    () => suggestionList.filter(suggestion => suggestion.value.includes(value)),
    [suggestionList, value]
  )

  return (
    <div className={className}>
      <Dropdown isOpen={isOpen} direction="down" toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggle tag="div" data-toggle="dropdown" disabled={disabled}>
          <Input
            value={value}
            type="search"
            placeholder={placeholder}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
        </DropdownToggle>
        <DropdownMenu style={{ maxHeight: '200px', width: '100%', overflow: 'auto' }}>
          {wordList.map((suggestion, index) => {
            return (
              <DropdownItem
                key={suggestion.id}
                className={`${styles.item} ${index === activeItem && styles.activeItem} text-truncate`}
                onClick={() => handleDropdownItemClick(suggestion)}
                onMouseOver={() => setActiveItem(index)}
              >
                {suggestion.value}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}
export default SuggestionFormat
