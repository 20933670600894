import { createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'es-toolkit'

import * as API from 'api/allocations/allocations'
import type {
  AllocationData,
  AllocationListResponse,
  AllocationResponse,
  PartialAllocationData,
  UpdateAllocationProps,
} from 'api/allocations/types'

import { handleApiError } from './utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type AllocationState = {
  isRequesting: boolean
  errorMessage: string
  allocation?: AllocationData
  partialAllocations: PartialAllocationData[]
  workspaceId: number | null
}

const initialState: AllocationState = {
  isRequesting: false,
  errorMessage: '',
  allocation: undefined,
  partialAllocations: [],
  workspaceId: null,
}

export const allocationSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },

    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getAllocationListSuccess: (state, action: PayloadAction<AllocationListResponse>) => {
      state.isRequesting = false
      state.partialAllocations = sortBy(action.payload.partialAllocations, ['name'])
    },
    getAllocationSuccess: (state, action: PayloadAction<AllocationResponse>) => {
      state.isRequesting = false
      state.allocation = action.payload.allocation
    },
    createAllocationSuccess: state => {
      state.isRequesting = false
    },
    updateAllocationSuccess: state => {
      state.isRequesting = false
    },
    deleteAllocationSuccess: state => {
      state.isRequesting = false
    },
    storeWorkspaceId: (state, action: PayloadAction<number>) => {
      state.workspaceId = action.payload
    },
  },
})

export const {
  startRequest,
  apiFailure,
  getAllocationListSuccess,
  getAllocationSuccess,
  createAllocationSuccess,
  updateAllocationSuccess,
  deleteAllocationSuccess,
  storeWorkspaceId,
} = allocationSlice.actions

export const getAllocationList =
  (workspaceId: number, callback?: (allocationList: PartialAllocationData[]) => void): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      const res = await API.getAllocationList(workspaceId)
      callback?.(res.partialAllocations)
      dispatch(getAllocationListSuccess(res))
      dispatch(storeWorkspaceId(workspaceId))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const getAllocation =
  (workspaceId: number, allocationId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      const res = await API.getAllocation(workspaceId, allocationId)
      dispatch(getAllocationSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const createAllocation =
  (workspaceId: number, data: UpdateAllocationProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      await API.createAllocation(workspaceId, data)
      dispatch(createAllocationSuccess())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const updateAllocation =
  (workspaceId: number, allocationId: number, data: UpdateAllocationProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      await API.updateAllocation(workspaceId, allocationId, data)
      dispatch(updateAllocationSuccess())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const deleteAllocation =
  (workspaceId: number, allocationId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      await API.deleteAllocation(workspaceId, allocationId)
      dispatch(deleteAllocationSuccess())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const selectAllocationStatus = (state: RootState) => ({ ...state.allocations })

export default allocationSlice.reducer
