import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

type IPaginationProps = {
  totalPages: number
  currentPage: number
  onClick: (page: number) => void
}

type NumberItemType = {
  content: JSX.Element
  active: boolean
}

const PaginationAtoms = (props: IPaginationProps) => {
  // PaginationItem構成の簡略図
  // - [<][                      Window                     ][>]
  // - [<][(First) Left Window][Current][Right Window (Last)][>]
  //
  // - 省略しないケース
  //   - [<][(First)    Viewable Left Page      ][Current][      Viewable Right Page    (Last)][>]
  // - 省略するケース
  //   - [<][First][Ellipsis][Viewable Left Page][Current][Viewable Right Page][Ellipsis][Last][>]

  const NUMBER_ITEMS_MAX_SIZE = 7
  const WINDOW_HALF_POINT = Math.round(NUMBER_ITEMS_MAX_SIZE / 2)
  const ELLIPSIS_POS = 2 // 両端からの[Ellipsis]の位置
  const FIRST = 1
  const last = props.totalPages
  const prevIndex = props.currentPage - 1
  const nextIndex = props.currentPage + 1

  const leftPageCount = props.currentPage - FIRST
  const rightPageCount = last - props.currentPage

  // [Window]内の位置
  const posInWindow = () => {
    if (leftPageCount < WINDOW_HALF_POINT) {
      // [Current]がWINDOW_HALF_POINT以内の位置であれば、[Current]
      return props.currentPage
    }
    if (rightPageCount < WINDOW_HALF_POINT) {
      // [Last]から遡って、WINDOW_HALF_POINT以内の位置であれば、[Last]までの距離
      return NUMBER_ITEMS_MAX_SIZE - (last - props.currentPage)
    }

    // それ以外は、WINDOW_HALF_POINT
    return WINDOW_HALF_POINT
  }

  const leftWindowSize = posInWindow() - 1
  const rightWindowSize = NUMBER_ITEMS_MAX_SIZE - leftWindowSize - 1
  const exceededLeftWindowSize = leftWindowSize < leftPageCount
  const exceededRightWindowSize = rightWindowSize < rightPageCount

  const isViewableLeftPage = (index: number) => {
    if (exceededLeftWindowSize) {
      return props.currentPage - (leftWindowSize - ELLIPSIS_POS) <= index && index < props.currentPage
    }
    return index < props.currentPage
  }

  const isViewableRightPage = (index: number) => {
    if (exceededRightWindowSize) {
      return props.currentPage < index && index <= props.currentPage + (rightWindowSize - ELLIPSIS_POS)
    }
    return props.currentPage < index
  }

  const numberItems: NumberItemType[] = [...Array(props.totalPages)].reduce((acc: NumberItemType[], _, i) => {
    const index = i + 1
    const isFirst = index === FIRST
    const isLast = index === last
    const isCurrent = index === props.currentPage

    if (isFirst && exceededLeftWindowSize) {
      return [
        ...acc,
        {
          content: <PaginationLink onClick={() => props.onClick(index)}>{index}</PaginationLink>,
          active: isCurrent,
        },
        {
          content: <i className="icf-others" />,
          active: false,
        },
      ]
    }
    if (isLast && exceededRightWindowSize) {
      return [
        ...acc,
        {
          content: <i className="icf-others" />,
          active: false,
        },
        {
          content: <PaginationLink onClick={() => props.onClick(index)}>{index}</PaginationLink>,
          active: isCurrent,
        },
      ]
    }
    if (isFirst || isViewableLeftPage(index) || isCurrent || isViewableRightPage(index) || isLast) {
      return [
        ...acc,
        {
          content: <PaginationLink onClick={() => props.onClick(index)}>{index}</PaginationLink>,
          active: isCurrent,
        },
      ]
    }
    return acc
  }, [])

  return (
    <Pagination className="d-flex">
      <PaginationItem key="item-prev-arrow" className="m-1" disabled={prevIndex < FIRST}>
        <PaginationLink key="link-prev-arrow" onClick={() => props.onClick(prevIndex)}>
          <i className="icf-chevron_left" />
        </PaginationLink>
      </PaginationItem>

      {numberItems.map((item, index) => (
        <PaginationItem key={index} className="m-1 align-self-center" active={item.active}>
          {item.content}
        </PaginationItem>
      ))}

      <PaginationItem key="item-next-arrow" className="m-1" disabled={nextIndex > last}>
        <PaginationLink key="link-next-arrow" onClick={() => props.onClick(nextIndex)}>
          <i className="icf-chevron_right" />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  )
}

export default PaginationAtoms
