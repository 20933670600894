import { sortBy } from 'es-toolkit'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'

import { getTenantSummary, selectDashboardStatus } from 'slices/dashboardSlice'

import { SingleBar } from 'components/common'

import useDateQuery from 'hooks/useDateQuery'

import styles from './WorkspaceProgressCards.module.scss'

type ScheduleTypeProgress = {
  scheduleTypeName: string
  targetCount: number | null
  planCount: number | null
  recordCount: number | null
}

type WorkspaceProgress = {
  workspaceId: number
  workspaceName: string
  lastImportedAt: string | undefined
  scheduleTypeProgresses: ScheduleTypeProgress[]
}

export type Props = {
  workspaceProgresses: WorkspaceProgress[]
}

const WorkspaceProgressCards = (props: Props) => {
  const dispatch = useDispatch()
  const date = useDateQuery()
  const { workspaceSummary } = useSelector(selectDashboardStatus, shallowEqual)

  // ワークスペース名でソート
  const workspaceProgresses = useMemo(
    () => sortBy(props.workspaceProgresses, ['workspaceName']),
    [props.workspaceProgresses]
  )

  useEffect(() => {
    workspaceSummary?.relatedWorkspaceData.forEach(({ workspaceId }) => {
      dispatch(getTenantSummary(date, { workspaceId }))
    })
  }, [date, dispatch, workspaceSummary?.relatedWorkspaceData])

  return (
    <Row md={3} className="mb-3 ps-3">
      {workspaceProgresses.map((workspaceProgress, workspaceIndex) => {
        const lastImportedAt = moment.utc(workspaceProgress.lastImportedAt).format('YYYY/MM/DD HH:mm:ss')
        return (
          <Col key={`workspace-item-col-${workspaceIndex}`} className="p-0">
            <Card className="mt-3 me-3">
              <CardBody>
                <Link
                  to={`/dashboard/${workspaceProgress.workspaceId}?date=${date}`}
                  className="text-black text-decoration-none"
                >
                  <u>{workspaceProgress.workspaceName}</u>
                </Link>
              </CardBody>
              <CardBody className={`p-0 overflow-auto ${styles.singleBars}`}>
                {workspaceProgress.scheduleTypeProgresses.map((scheduleTypeProgress, scheduleTypeIndex) => {
                  const { scheduleTypeName, targetCount, planCount, recordCount } = scheduleTypeProgress

                  let rate = 0
                  if (recordCount && targetCount && targetCount > 0) {
                    rate = Math.floor((100 * recordCount) / targetCount)
                  }
                  let borderRate = 0
                  if (planCount && targetCount && targetCount > 0) {
                    borderRate = Math.floor((100 * planCount) / targetCount)
                  }
                  let tooltipDiff = 0
                  let tooltipWarning = false
                  if (recordCount && planCount) {
                    tooltipDiff = Math.floor(recordCount - planCount)
                    // 計画していた値から15%以上ズレがある場合はtooltipで警告表示
                    tooltipWarning = Math.abs(1 - recordCount / planCount) >= 0.15
                  }

                  return (
                    <SingleBar
                      key={`workspace-item-bar-${scheduleTypeIndex}`}
                      label={scheduleTypeName}
                      rate={rate}
                      borderRate={borderRate}
                      tooltipDiff={tooltipDiff}
                      tooltipWarning={tooltipWarning}
                    />
                  )
                })}
              </CardBody>
              <CardBody className="d-flex text-muted align-self-end">
                {lastImportedAt && <i className="icf-updated align-self-center pe-1" />}
                <small className={styles.date}>{lastImportedAt}</small>
              </CardBody>
            </Card>
          </Col>
        )
      })}
    </Row>
  )
}

export default WorkspaceProgressCards
