import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { Input } from 'reactstrap'

import { showError, showSuccess } from 'slices/notificationSlice'
import { updateTargetValue, selectPlansStatus } from 'slices/plansSlice'

import styles from './PlanItem.module.scss'
import './plan-item.css'

type Props = {
  workDate: string
  dailyTarget?: number
  workspaceId: number
  scheduleTypeId: number
  disabled?: boolean
  onSuccess: () => void
}

const PlanItem = (props: Props) => {
  const { workDate, dailyTarget, workspaceId, scheduleTypeId, onSuccess } = props

  const [edit, setEdit] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [inputValue, setInputValue] = useState<number | '-'>('-')
  const [submitted, setSubmitted] = useState(false)

  const { isRequesting, errorMessage } = useSelector(selectPlansStatus, shallowEqual)
  const dispatch = useDispatch()

  const targetValue = useMemo(() => {
    return dailyTarget ?? '-'
  }, [dailyTarget])
  const disabled = useMemo(() => props.disabled || targetValue === '-', [props.disabled, targetValue])

  useEffect(() => setInputValue(targetValue), [targetValue])

  useEffect(() => {
    if (!edit || isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
      setEdit(!edit)
      onSuccess()
    } else {
      dispatch(showError())
    }
    setSubmitted(false)
  }, [edit, submitted, errorMessage, isRequesting, dispatch, onSuccess])

  const saveInputValue = () => {
    if (inputValue === targetValue || invalid || inputValue === '-') {
      setEdit(!edit)
      setInvalid(false)
    } else {
      setSubmitted(true)
      const data = {
        dailyTargets: [
          {
            workDate: workDate,
            scheduleTypeId: scheduleTypeId,
            value: inputValue,
          },
        ],
      }
      dispatch(updateTargetValue(workspaceId, data))
    }
  }

  const trigger = (
    <div className="ms-2 align-self-center">
      <i className={`${edit ? 'icf-save' : 'icf-edit'} font-large text-gray`} role="button" onClick={saveInputValue} />
    </div>
  )

  const onInputChange = (value: string) => {
    if (/^(\+|-)/.test(value) || !Number.isSafeInteger(Number(value)) || value === '') {
      setInvalid(true)
    } else {
      setInputValue(Number(value))
      setInvalid(false)
    }
  }

  const onKeyPress = (key: string) => {
    if (key === 'Enter' && !invalid) {
      saveInputValue()
    }
  }

  return (
    <div className={`d-flex pt-1 ps-3 ${edit ? '' : 'mt-2'}`}>
      {edit ? (
        <Input
          className={styles.input}
          maxLength={7}
          defaultValue={inputValue}
          onChange={e => onInputChange(e.target.value)}
          onKeyPress={e => onKeyPress(e.key)}
          invalid={invalid}
          valid={inputValue !== targetValue}
        />
      ) : (
        inputValue.toLocaleString()
      )}
      {!disabled && (
        <Popup trigger={trigger} position="bottom center" on="hover" className="plan-item">
          <div className={styles.toolTip}>作業目標の設定</div>
        </Popup>
      )}
    </div>
  )
}

export default PlanItem
