import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { createSkill, selectSkillsStatus } from 'slices/skillsSlice'
import { ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import { InputFormat, CustomModal } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const SkillCreate = ({ isOpen, onSuccess, onCancel }: Props) => {
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = useState(false)
  const [skillName, setSkillName] = useState<string | undefined>(undefined)
  const [skillNameValidity, setSkillNameValidity] = useState(false)
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectSkillsStatus, shallowEqual)

  const disabled = useMemo(() => !(skillName && skillNameValidity), [skillName, skillNameValidity])

  const handleSaveClick = () => {
    if (skillName) {
      setSubmitted(true)
      dispatch(createSkill(skillName))
    }
  }
  const handleCancelClick = () => {
    setSkillName(undefined)
    setSkillNameValidity(false)
    setModalErrorMessage(undefined)
    onCancel()
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setSkillName(undefined)
      setSkillNameValidity(false)
      onSuccess()
    } else {
      if (errorMessage === ERROR_STATUS_CODE.CONFLICT) {
        setModalErrorMessage('登録上限に達しているため、保存できませんでした。')
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
        setModalErrorMessage('保存できませんでした。')
      }
    }
    setSubmitted(false)
  }, [submitted, errorMessage, isRequesting, onSuccess, dispatch])

  return (
    <CustomModal
      isOpen={isOpen}
      title="スキルの追加"
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-2">
        スキルを追加する事で、作業の必須スキルを作業者が持っていない場合、作業をスケジュールする際にアラートメッセージを表示し、計画ミスを防ぐことができます。
        <div className="mt-4">
          <InputFormat
            label="スキル名"
            placeholder="スキル名"
            value={skillName}
            size={COLUMN_SIZES.MIDDLE}
            maxLength={100}
            onChange={value => setSkillName(value)}
            validations={[Rules.Required]}
            onValidate={setSkillNameValidity}
          />
        </div>
      </div>
    </CustomModal>
  )
}

export default SkillCreate
