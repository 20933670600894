import dayjs from 'dayjs'
import { sortBy, round } from 'es-toolkit'
import moment from 'moment'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'

import { showSuccess } from 'slices/notificationSlice'
import type { DailyPlan } from 'slices/plansSlice'
import { getPlanList, selectPlansStatus } from 'slices/plansSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'
import { getWorkspace } from 'slices/workspacesSlice'

import { formatPositiveNumber, getStyledColorClass } from 'components/Dashboard/utils'
import { NavMenu, BadgeLabel, BadgeButton, Table, MonthSelect, CustomButton } from 'components/common'
import type { BadgeItem, TableHeaderType, TableCellType } from 'components/common/types'

import useAuthority from 'hooks/useAuthority'
import useBusinessTime from 'hooks/useBusinessTime'
import { useTargetsCsv } from 'hooks/useTargetsCsv'
import useWorkspaceSideBar from 'hooks/useWorkspaceSideBar'

import ImportTargetsDialog from './ImportTargetsDialog'
import PlanItem from './PlanItem'

import styles from './Schedules.module.scss'

import 'dayjs/locale/ja'

const Schedules = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = useMemo(() => Number(params.workspaceId), [params])

  const [isPast, setIsPast] = useState(false)
  const [selectedBadges, setSelectedBadges] = useState<number[]>([])
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'))
  const [isMoreLoadButtonClicked, setIsMoreLoadButtonClicked] = useState(false)
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false)

  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { planList } = useSelector(selectPlansStatus, shallowEqual)
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isReadOnlyWorkspace } = useAuthority(workspaceId)
  const { workspaceName } = useWorkspaceSideBar('/schedules')
  const { exportTargets } = useTargetsCsv()
  const { getWorkDate } = useBusinessTime()

  useEffect(() => {
    if (!workspaceId) {
      return
    }
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, workspaceId])

  useEffect(() => {
    setIsMoreLoadButtonClicked(false)
  }, [setIsMoreLoadButtonClicked, workspaceId])

  useEffect(() => {
    setSelectedBadges(partialScheduleTypes.filter(type => type.dataConnection).map(type => type.id))
  }, [partialScheduleTypes])

  const { fromDate, toDate } = useMemo(() => {
    const from = isPast
      ? moment(selectedMonth).startOf('month').format('YYYY-MM-DD') // 選択した月の初日
      : moment().format('YYYY-MM-DD') // 当日
    // 初回ロードは2週間分、それ以降は45日分
    const loadDays = isMoreLoadButtonClicked ? 44 : 13
    const to = isPast
      ? moment(selectedMonth).endOf('month').format('YYYY-MM-DD') // 選択した月の末日
      : moment().add(loadDays, 'day').format('YYYY-MM-DD') // 最大30日分

    return { fromDate: getWorkDate(from), toDate: getWorkDate(to) }
  }, [isMoreLoadButtonClicked, isPast, selectedMonth, getWorkDate])

  useEffect(() => {
    if (workspaceId && tenant) {
      // getWorkDateでbusinessStartTimeを使用するためtenant取得後にAPIを実行する
      dispatch(getPlanList(workspaceId, fromDate, toDate))
    }
  }, [dispatch, workspaceId, fromDate, toDate, tenant])

  // 作業計画では利用開始日をステータスがactiveの時ではなく、
  // テナントが作成された時とするため、startedAtではなくcreatedAtを使用する
  const tenantStartMonth = useMemo(() => moment(tenant?.createdAt).format('YYYY-MM'), [tenant])

  const badges: BadgeItem[] = useMemo(
    () =>
      partialScheduleTypes
        .filter(type => type.dataConnection)
        .map(type => ({
          color: type.color,
          key: type.id,
          label: type.name,
        })),
    [partialScheduleTypes]
  )

  const plansWithManHours = useMemo(
    () =>
      planList?.dailyPlans.map(daily => {
        const totalManHour = daily.data.reduce((total, dailyPlan) => {
          const difference = Math.floor(dailyPlan.plan - dailyPlan.target)
          const scheduleType = partialScheduleTypes.find(type => type.id === dailyPlan.scheduleTypeId)
          const manHour = round(difference / (scheduleType?.defaultProductivity || 1), 1)
          return round(total + manHour, 1)
        }, 0)
        return { ...daily, totalManHour }
      }) || [],
    [partialScheduleTypes, planList?.dailyPlans]
  )

  const getScheduleTableCells = useCallback(
    (dailyPlanData: DailyPlan) => {
      return selectedBadges.reduce((acc: TableCellType[], badgeId) => {
        const selected = dailyPlanData.data.find(item => item.scheduleTypeId === badgeId)
        if (!selected) {
          return acc
        }
        const planValue = Math.floor(selected.plan)
        const targetValue = Math.floor(selected.target)
        const difference = Math.floor(planValue - targetValue)
        const scheduleType = partialScheduleTypes.find(type => type.id === selected.scheduleTypeId)
        const manHour = round(difference / (scheduleType?.defaultProductivity || 1), 1)

        acc.push({
          value: (
            <PlanItem
              workDate={dailyPlanData.workDate}
              dailyTarget={targetValue}
              disabled={isReadOnlyWorkspace}
              workspaceId={workspaceId}
              scheduleTypeId={selected.scheduleTypeId}
              onSuccess={() => workspaceId && dispatch(getPlanList(workspaceId, fromDate, toDate))}
            />
          ),
          className: 'p-0',
        })
        acc.push({ value: planValue.toLocaleString() })
        acc.push({
          value: difference === 0 ? '-' : `${formatPositiveNumber(difference)} (${formatPositiveNumber(manHour)}人時)`,
          className: getStyledColorClass(difference),
        })
        return acc
      }, [])
    },
    [selectedBadges, partialScheduleTypes, isReadOnlyWorkspace, workspaceId, dispatch, fromDate, toDate]
  )

  const tableData: TableCellType[][] = useMemo(() => {
    const table = workspaceId
      ? [...Array(45)].reduce((acc: TableCellType[][], _cur, index: number) => {
          const date = dayjs(getWorkDate(dayjs().format('YYYY-MM-DD'))).add(index, 'day')
          const plan = plansWithManHours.find(p => p.workDate === date.format('YYYY-MM-DD'))

          if (!plan) {
            return acc
          }

          const data: TableCellType[] = [
            { value: date.locale('ja').format('YYYY/MM/DD(ddd)') },
            { value: plan.workerCount },
            {
              value: `${formatPositiveNumber(plan.totalManHour)}人時`,
              className: `${getStyledColorClass(plan.totalManHour)} border-end`,
            },
          ]

          acc.push(data.concat(getScheduleTableCells(plan)))

          return acc
        }, [])
      : []

    const pastTableData = sortBy(plansWithManHours, ['workDate'])
      .reverse()
      .reduce((acc: TableCellType[][], cur) => {
        const past = dayjs(getWorkDate(dayjs().format('YYYY-MM-DD'))).isAfter(cur.workDate, 'day')
        if (!past) {
          return acc
        }
        const totalManHour = cur.totalManHour
        const data: TableCellType[] = [
          { value: dayjs(cur.workDate).locale('ja').format('YYYY/MM/DD(ddd)') },
          { value: cur.workerCount },
          {
            value: `${formatPositiveNumber(totalManHour)}人時`,
            className: `${getStyledColorClass(totalManHour)} border-end`,
          },
        ]

        acc.push(data.concat(getScheduleTableCells(cur) || []))

        return acc
      }, [])
    return isPast ? pastTableData : table
  }, [workspaceId, plansWithManHours, isPast, getScheduleTableCells, getWorkDate])

  const tableHeader: TableHeaderType[] = useMemo(() => {
    const header: TableHeaderType[] = [
      { value: '日付', width: '40%' },
      { value: 'メンバー数', width: '30%' },
      { value: '過不足人時', width: '30%', className: 'border-end' },
    ]

    return selectedBadges.reduce((acc: TableHeaderType[], key) => {
      const badge = badges.find(b => b.key === key)
      if (badge) {
        return acc.concat([
          { value: `目標 - ${badge.label}`, width: '180px' },
          { value: `計画 - ${badge.label}`, width: '180px' },
          { value: `差分 - ${badge.label}`, width: '180px' },
        ])
      }
      return acc
    }, header)
  }, [badges, selectedBadges])

  const onBadgesChange = useCallback(
    (list: number[]) => {
      // 名前順にするためにソート済みのbadgesでフィルターをかける
      setSelectedBadges(badges.filter(badge => list.includes(badge.key)).map(badge => badge.key))
    },
    [badges]
  )

  const onMonthChange = (month: string) => setSelectedMonth(month)

  const onRowClick = (index: number) => {
    const date = tableData[index][0].value as string
    const formattedDate = date.replace(/(\d{4})\/(\d{2})\/(\d{2}).*/g, '$1-$2-$3')
    navigate(`/schedules/${workspaceId}/${formattedDate}`)
  }

  const handleLoadMoreClick = () => {
    setIsMoreLoadButtonClicked(true)
  }

  const onSchedulesButtonClick = () => {
    setIsMoreLoadButtonClicked(false)
    setIsPast(!isPast)
  }

  const handleImportTargetsSuccess = useCallback(() => {
    setIsImportDialogOpen(false)
    dispatch(getPlanList(workspaceId, fromDate, toDate))
    dispatch(showSuccess())
  }, [dispatch, fromDate, toDate, workspaceId])

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex">
            <div className="font-x-large fw-bold">{isPast ? '過去の' : ''}作業計画一覧</div>
            <div className="px-2 align-self-center">
              <BadgeLabel label={workspaceName} />
            </div>
          </div>
          <div className="d-flex">
            {isPast ? (
              <div className="bg-white rounded me-2">
                <MonthSelect
                  start={tenantStartMonth}
                  end={moment().format('YYYY-MM')}
                  selectedMonth={selectedMonth}
                  onChange={onMonthChange}
                />
              </div>
            ) : (
              <div className="d-flex">
                <CustomButton outline className="me-2" onClick={() => setIsImportDialogOpen(true)}>
                  作業目標インポート
                </CustomButton>
                <CustomButton
                  outline
                  className="me-2"
                  onClick={() => {
                    exportTargets()
                    dispatch(showSuccess())
                  }}
                >
                  作業目標エクスポート
                </CustomButton>
              </div>
            )}
            <CustomButton outline onClick={onSchedulesButtonClick}>
              {isPast ? '作業計画一覧' : '過去の計画'}
            </CustomButton>
          </div>
        </div>

        <div className="font-x-small text-muted my-2">リストの表示項目の編集</div>
        <div className="d-flex row my-2">
          <BadgeButton items={badges} selected={selectedBadges} onChange={onBadgesChange} name="work-plan-list-badge" />
        </div>

        <div className="d-flex bg-white mt-3 mw-100">
          <div className={styles.dateTable}>
            <Table
              header={tableHeader.slice(0, 3)}
              body={tableData.map(data => data.slice(0, 3))}
              onRowClick={onRowClick}
            />
          </div>
          <div className={styles.scTable}>
            <Table
              header={tableHeader.slice(3)}
              body={tableData.map(data => data.slice(3))}
              name="work-plan-list-table"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center my-2">
          {!isMoreLoadButtonClicked && !isPast && (
            <Button outline onClick={handleLoadMoreClick}>
              さらに読み込む
            </Button>
          )}
        </div>
        <ImportTargetsDialog
          key={isImportDialogOpen.toString()}
          isOpen={isImportDialogOpen}
          onSuccess={handleImportTargetsSuccess}
          onCancel={() => setIsImportDialogOpen(false)}
        ></ImportTargetsDialog>
      </div>
    </NavMenu>
  )
}

export default Schedules
