import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormGroup, Label, Col } from 'reactstrap'

import { getPostcodeList } from 'slices/postcodeSlice'
import { createTenant, selectTenantsStatus } from 'slices/tenantsSlice'
import { getUserList } from 'slices/usersSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import {
  InputFormat,
  PostalCodeInputForm,
  Notification,
  SelectBoxFormat,
  TimeSelect,
  SubmitFooter,
} from 'components/common'
import { Z_INDEX_MORE_THAN_SUBMIT_FOOTER } from 'components/common/Footer/SubmitFooter'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { PREFECTURE_ITEMS, COLUMN_SIZES } from 'components/common/constants'

import useTenant from 'hooks/useTenant'

import styles from './TenantCreate.module.scss'

const TenantCreate = () => {
  const [submitted, setSubmitted] = useState(false)
  const [showError, setShowError] = useState(false)
  const { isRequesting, errorMessage } = useSelector(selectTenantsStatus, shallowEqual)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    editData,
    targetPostalCode,
    businessTimeValidity,
    personInChargeItems,
    unchanged,
    disabled,
    calculateTimeRange24Hours,
    timeRange24Hours,
    setEditData,
    setMunicipalityValidity,
    setNameValidity,
    setOtherAddressValidity,
    setPhoneNumberValidity,
    setPostalCodeValidity,
    setPrefectureValidity,
    setPersonInChargeValidity,
  } = useTenant()

  useEffect(() => {
    dispatch(getUserList())
    dispatch(getPostcodeList())
  }, [dispatch])

  const onSubmit = () => {
    setSubmitted(true)

    // 営業終了時間が24:00を超えている場合、時の値を24減らしてリクエストデータにする
    const createData = {
      ...editData,
      businessEndHour:
        Number(editData.businessEndHour) >= 24
          ? (Number(editData.businessEndHour) - 24).toString().padStart(2, '0')
          : editData.businessEndHour,
    }
    dispatch(createTenant(createData))
  }

  const onAddressAutoInput = () => {
    setEditData({
      ...editData,
      prefecture: targetPostalCode?.address1 || '',
      municipality: targetPostalCode?.address2 || '',
      otherAddress: targetPostalCode?.address3 || '',
    })
  }

  useEffect(() => {
    // 送信前やリクエスト中の場合には抜ける
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      navigate('/tenants')
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
      setShowError(true)
    }
    setSubmitted(false)
  }, [isRequesting, errorMessage, submitted, navigate])

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large fw-bold text-center border-bottom py-3 bg-white">テナント新規登録</div>
          <Notification errorMessage="保存できませんでした。" error={showError} hide={() => setShowError(false)} />
        </div>
        <div className="w-50 mx-auto py-3">
          <div className="d-flex justify-content-between py-1">
            <div className="font-middle fw-bold">テナントの詳細</div>
            <small>※必須項目</small>
          </div>
          <InputFormat
            label="企業名※"
            placeholder="企業名を入力"
            value={editData.name}
            maxLength={200}
            onChange={value => setEditData({ ...editData, name: value })}
            validations={[Rules.Required]}
            onValidate={setNameValidity}
            className="mb-3"
          />
          <InputFormat
            label="事業所名"
            placeholder="事業所名を入力"
            value={editData.salesOfficeName ?? ''}
            maxLength={100}
            onChange={value => setEditData({ ...editData, salesOfficeName: value === '' ? null : value })}
            className="mb-3"
          />
          <InputFormat
            label="電話番号※"
            placeholder="電話番号を入力"
            value={editData.phoneNumber}
            size={COLUMN_SIZES.SHORT}
            maxLength={20}
            onChange={value => setEditData({ ...editData, phoneNumber: value })}
            validations={[Rules.Required, Rules.PhoneNumber]}
            onValidate={setPhoneNumberValidity}
          />

          <div className="font-middle fw-bold py-1">事業所住所</div>
          <PostalCodeInputForm
            label="郵便番号※"
            value={editData.postalCode}
            onChange={value => setEditData({ ...editData, postalCode: value })}
            validations={[Rules.Required, Rules.PostalCode]}
            onValidate={setPostalCodeValidity}
            onAddressAutoInput={onAddressAutoInput}
            disabledAddressAutoInput={!targetPostalCode}
          />
          <SelectBoxFormat
            label="都道府県※"
            placeholder="都道府県を選択"
            value={editData.prefecture}
            size={COLUMN_SIZES.SHORT}
            items={PREFECTURE_ITEMS}
            onChange={e => setEditData({ ...editData, prefecture: e.value })}
            validations={[Rules.Required]}
            onValidate={setPrefectureValidity}
            className="mb-3"
          />
          <InputFormat
            label="市区町村※"
            placeholder="市区町村を入力"
            value={editData.municipality}
            maxLength={100}
            onChange={value => setEditData({ ...editData, municipality: value })}
            validations={[Rules.Required]}
            onValidate={setMunicipalityValidity}
            className="mb-3"
          />
          <InputFormat
            label="それ以降の住所※"
            value={editData.otherAddress}
            maxLength={100}
            onChange={value => setEditData({ ...editData, otherAddress: value })}
            validations={[Rules.Required]}
            onValidate={setOtherAddressValidity}
          />

          <div className="font-middle fw-bold pt-3 pb-1">営業時間</div>
          <p>
            事業所の営業時間を設定してください。設定した営業時間内で予定を設定する事ができます。設定できるのは営業時間開始時間から最大24時間です。
          </p>
          <FormGroup row>
            <Label md={4}>営業開始時間※</Label>
            <Col md={8}>
              <TimeSelect
                hour={editData.businessStartHour}
                minute={editData.businessStartMinute}
                label="から"
                onChange={(hour, minute) => {
                  const { fromHour, fromMin } = calculateTimeRange24Hours(hour, minute)
                  setEditData({
                    ...editData,
                    businessStartHour: hour,
                    businessStartMinute: minute,
                    businessEndHour: fromHour,
                    businessEndMinute: fromMin,
                  })
                }}
                range={{ fromHour: '00', fromMin: '00', toHour: '23', toMin: '45' }}
                menuZIndex={Z_INDEX_MORE_THAN_SUBMIT_FOOTER}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>営業終了時間※</Label>
            <Col md={8}>
              <TimeSelect
                hour={editData.businessEndHour}
                minute={editData.businessEndMinute}
                label="まで"
                onChange={(hour, minute) =>
                  setEditData({ ...editData, businessEndHour: hour, businessEndMinute: minute })
                }
                range={timeRange24Hours}
                menuZIndex={Z_INDEX_MORE_THAN_SUBMIT_FOOTER}
              />
              <div className={`invalid-feedback ${!businessTimeValidity && 'd-block'}`}>
                営業終了時間は営業開始時間より後の時間を設定してください
              </div>
            </Col>
          </FormGroup>
          <div className="font-middle fw-bold py-1">担当者情報</div>
          <p>担当者は後で変更することもできます。</p>
          <SelectBoxFormat
            label="担当者※"
            placeholder="担当者を選択"
            value={editData.personInChargeId}
            size={COLUMN_SIZES.SHORT}
            items={personInChargeItems}
            onChange={e => setEditData({ ...editData, personInChargeId: e.key?.toString() || e.value })}
            validations={[Rules.Required]}
            onValidate={setPersonInChargeValidity}
            className="mb-3"
          />
        </div>
      </div>
      <SubmitFooter
        onCancel={() => navigate(-1)}
        onSubmit={onSubmit}
        submitDisabled={disabled || unchanged}
        submitLabel="登録"
      />
    </>
  )
}

export default TenantCreate
