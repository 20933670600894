import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Col, Row } from 'reactstrap'

import type { TenantApplicationSettingType } from 'api/tenants/types'

import { showError, showSuccess } from 'slices/notificationSlice'
import { updateApplications, selectTenantsStatus } from 'slices/tenantsSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { List } from 'components/common'
import { MAGIQANNEAL_APPLICATION_ID } from 'components/common/constants'
import { getTenantApplications } from 'components/common/utils'

import placeholder from 'images/allEmpty.svg'

import OptEngineSetting from './OptEngineSetting'

import styles from './styles.module.scss'

const TenantServiceCooperation = () => {
  const [submitted, setSubmitted] = useState(false)
  const [applicationId, setApplicationId] = useState<number>()
  const { tenant, isRequesting, errorMessage } = useSelector(selectTenantsStatus, shallowEqual)

  const dispatch = useDispatch()
  useEffect(() => {
    if (tenant?.id) {
      dispatch(getWorkspaceList({ tenantId: tenant.id }))
    }
  }, [dispatch, tenant?.id])
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const applications = useMemo(() => getTenantApplications(tenant), [tenant])

  const listItems = useMemo(
    () =>
      applications.map(application => ({
        title: application.applicationName,
        id: application.applicationId,
      })),
    [applications]
  )
  const isServices = useMemo(() => listItems.length > 0, [listItems])
  useEffect(() => setApplicationId(isServices ? listItems[0].id : undefined), [isServices, listItems])
  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage) {
      dispatch(showError())
    } else {
      dispatch(showSuccess())
    }
    setSubmitted(false)
  }, [dispatch, errorMessage, isRequesting, submitted])

  const handleUpdateTenant = useCallback(
    (update: TenantApplicationSettingType) => {
      if (!tenant?.id || !tenant) {
        return
      }

      const applicationExists = applications.some(app => app.applicationId === update.applicationId)
      const optionApplications = applicationExists
        ? applications.map(app =>
            app.applicationId === update.applicationId ? { ...app, options: { ...update.options } } : app
          )
        : [update].concat(applications)
      setSubmitted(true)
      dispatch(updateApplications(tenant.id, optionApplications))
    },
    [dispatch, tenant, applications]
  )

  const handleDeleteApplication = useCallback(
    (deleteApplicationId: number) => {
      if (!tenant?.id || !tenant) {
        return
      }

      const filteredApplications = applications.filter(app => app.applicationId !== deleteApplicationId)
      setSubmitted(true)
      dispatch(updateApplications(tenant.id, filteredApplications))
    },
    [dispatch, tenant, applications]
  )

  const selectedComponent = useMemo(() => {
    const application = applications.find(app => app.applicationId === applicationId)

    switch (application?.applicationId) {
      case MAGIQANNEAL_APPLICATION_ID:
        return (
          <OptEngineSetting
            application={application}
            partialWorkspaces={partialWorkspaces}
            updateTenant={handleUpdateTenant}
            deleteApplication={handleDeleteApplication}
          />
        )

      default:
        return (
          <CardBody className="text-center">
            <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
            <div className="font-middle fw-bold py-4">サービスが選択されていません</div>
            <div>サービスを選択して、編集しましょう。</div>
          </CardBody>
        )
    }
  }, [applicationId, applications, handleUpdateTenant, partialWorkspaces, handleDeleteApplication])

  return (
    <Row className="g-0">
      <Col md={4}>
        <Card className={`position-sticky ${styles.cardContainer}`}>
          {isServices ? (
            <List items={listItems} selectedId={applicationId} onAction={setApplicationId} />
          ) : (
            <CardBody className="text-center">
              <img
                className={`mx-auto my-auto d-block h-75 w-100 ${styles.placeholderImage}`}
                src={placeholder}
                alt=""
              />
              <div className="font-middle fw-bold py-4">連携可能なサービスはありません</div>
            </CardBody>
          )}
        </Card>
      </Col>
      <Col md={8}>
        <Card>{selectedComponent}</Card>
      </Col>
    </Row>
  )
}

export default TenantServiceCooperation
