import { useCallback } from 'react'
import { Row, Col } from 'reactstrap'

import type { ColorType } from 'api/types'

import { DividedTimeSelect } from 'components/common'
import { TIME_INTERVAL } from 'components/common/constants'

import useBusinessTime from 'hooks/useBusinessTime'

import styles from './TimeRangeSelector.module.scss'

export type SelectedScheduleType = {
  id: number
  name: string
  color?: ColorType
}

type Props = {
  open: boolean
  startHour: string
  startMinute: string
  endHour: string
  endMinute: string
  onClose: () => void
  onTimeChange: (startHour: string, startMinute: string, endHour: string, endMinute: string) => void
}

export const TimeRangeSelector = (props: Props) => {
  const { open, startHour, startMinute, endHour, endMinute, onClose, onTimeChange } = props
  const { timeRange } = useBusinessTime({
    interval: TIME_INTERVAL.FIVE,
  })

  const handleStartTimeChange = useCallback(
    (hour: string, minute: string) => {
      onTimeChange(hour, minute, endHour, endMinute)
    },
    [endHour, endMinute, onTimeChange]
  )

  const handleEndTimeChange = useCallback(
    (hour: string, minute: string) => {
      onTimeChange(startHour, startMinute, hour, minute)
    },
    [onTimeChange, startHour, startMinute]
  )

  return (
    <>
      <div className={open ? styles.backdrop : ''} />
      <div className={`px-4 pt-4 ${open ? styles.sidebarOpen : styles.sidebar}`}>
        <Row>
          <Col>時間帯を入力してください</Col>
          <Col sm={{ size: 'auto', offset: 1 }}>
            <i className="icf-close" onClick={onClose} />
          </Col>
        </Row>

        <Row className="pt-4 pb-2">
          <Col>開始時間</Col>
        </Row>
        <Row>
          <Col>
            <DividedTimeSelect
              hour={startHour}
              minute={startMinute}
              label="から"
              onChange={handleStartTimeChange}
              range={timeRange.start}
              roundingInterval={TIME_INTERVAL.FIVE}
            />
          </Col>
        </Row>
        <Row className="pt-4 pb-2">
          <Col>終了時間</Col>
        </Row>
        <Row>
          <Col>
            <DividedTimeSelect
              hour={endHour}
              minute={endMinute}
              label="まで"
              onChange={handleEndTimeChange}
              range={timeRange.end}
              roundingInterval={TIME_INTERVAL.FIVE}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}
