import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useBusinessTime from './useBusinessTime'
import { useQuery } from './useQuery'

export const DEFAULT_PAGE = 1

export const useImportHistoryQuery = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [dateRange] = useQuery<string>('dateRange', '')
  const [queryPage, setQueryPage] = useQuery<number>('page', DEFAULT_PAGE)
  const { getWorkDate } = useBusinessTime()

  // 営業時間前を加味して日付算出する。
  const [queryStart, queryEnd] = useMemo(() => {
    const baseDate = dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')))
    if (dateRange) {
      return dateRange.split(':')
    }

    const formattedDate = baseDate.format('YYYY-MM-DD')
    return [formattedDate, formattedDate]
  }, [getWorkDate, dateRange])

  const setFunc = useCallback(
    (start: string, end: string) => {
      const params = new URLSearchParams(search)
      params.delete('page')
      params.set('dateRange', `${dayjs(start).format('YYYY-MM-DD')}:${dayjs(end).format('YYYY-MM-DD')}`)
      navigate(`?${params.toString()}`, { replace: true })
    },
    [navigate, search]
  )

  return {
    queryPage,
    setQueryPage,
    queryStart,
    queryEnd,
    setDateRange: setFunc,
  }
}
