import styles from './styles.module.scss'

import type { ReactElement } from 'react'

export type Props = {
  label: string
  labelColor?: string
  color?: string
  className?: string
  isFill?: boolean
  isDashBorder?: boolean
  dataSourceLabel?: string | ReactElement
  dataSourceColor?: string
}

export const BadgeLabel = ({
  label,
  labelColor,
  color,
  className,
  isFill = true,
  isDashBorder = false,
  dataSourceLabel,
  dataSourceColor,
}: Props) => {
  const bgLabelColor = isFill ? `bg-${labelColor}` : ''
  const bgSquareColor = isFill ? `bg-${color}` : ''
  const square = isDashBorder ? `${styles.dashedSquare} border-${bgSquareColor}` : styles.square
  const dataSourceBGColor = dataSourceColor ? `bg-${dataSourceColor}` : ''

  return (
    <div className="d-flex mw-100">
      <div
        className={`d-flex mw-100 user-select-none ${styles.toggleButtonDisabled} ${dataSourceLabel ? styles.leftRounded : ''} ${className} ${bgLabelColor}`}
      >
        {color && <div className={`${square} ${bgSquareColor} me-1 align-self-center`}></div>}
        <div title={label} className="text-truncate">
          {label}
        </div>
      </div>
      {dataSourceLabel && (
        <div
          className={`text-truncate text-white ${dataSourceBGColor} ${styles.toggleButtonDisabled} ${styles.rightRounded}`}
        >
          {dataSourceLabel}
        </div>
      )}
    </div>
  )
}
