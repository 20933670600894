import type { CreateResponse, DeleteResponse, UpdateResponse } from 'api/types'

import { axiosClient, axiosClientWithTenantId } from 'AxiosClientProvider'

import type { EditWorkspaceProps, WorkspaceListResponse, WorkspaceResponse, UploadTargetsResponse } from './types'

export const getWorkspacesList = async (
  tenantId: number | undefined,
  workDate?: string
): Promise<WorkspaceListResponse> => {
  const url = `/tenants/${tenantId}/workspaces`

  const paramData = workDate ? { 'work-date': workDate } : {}
  const response = await axiosClient.get(url, { params: paramData })
  return response.data
}

export const getWorkspace = async (workspaceId: number, workDate?: string): Promise<WorkspaceResponse> => {
  const url = `/workspaces/${workspaceId}`

  const paramData = workDate ? { workDate } : {}
  const response = await axiosClientWithTenantId.get(url, { params: paramData })
  return response.data
}

export const createWorkspace = async (data: EditWorkspaceProps): Promise<CreateResponse> => {
  const url = '/workspaces'

  const response = await axiosClientWithTenantId.post(url, data)
  return response.data
}

export const updateWorkspace = async (workspaceId: number, data: EditWorkspaceProps): Promise<UpdateResponse> => {
  const url = `/workspaces/${workspaceId}`

  const response = await axiosClientWithTenantId.patch(url, data)
  return response.data
}

export const deleteWorkspace = async (workspaceId: number): Promise<DeleteResponse> => {
  const url = `/workspaces/${workspaceId}`

  const response = await axiosClientWithTenantId.delete(url)
  return response.data
}

export const uploadTargets = async (workspaceId: string, fileData: FormData): Promise<UploadTargetsResponse> => {
  const url = `/workspaces/${workspaceId}/target-value-with-file`

  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  const response = await axiosClientWithTenantId.post(url, fileData, { headers })
  return response.data
}
