import { useCallback } from 'react'

import type { DailyReportsScheduleType, DailyReportsWorkspaceType } from 'api/daily_reports/types'

import { ScheduleTypeEditor } from './ScheduleTypeEditor'

import styles from './ScheduleTypeMapping.module.scss'

import type { allHeadersType } from './types'

type ScheduleTypeMappingProps = {
  workspace: DailyReportsWorkspaceType
  onChange: (value: DailyReportsScheduleType[]) => void
  onHeaderValidate: (allHeaders: allHeadersType) => void
  allHeaders: allHeadersType
}

export type WorkspaceCheckBoxItemTypes = {
  id: number
  label: string
  checked: boolean
  disabled: boolean
}

export const ScheduleTypeMapping = ({
  workspace,
  onChange,
  onHeaderValidate,
  allHeaders,
}: ScheduleTypeMappingProps) => {
  const handleValidChange = (
    workspaceId: number,
    scheduleTypeId: number,
    headerIndex: number,
    isValid: boolean,
    value: string
  ) => {
    const targetWs = allHeaders.find(ws => ws.workspaceId === workspaceId)
    if (!targetWs) {
      return
    }

    const targetSchedule = targetWs.schedules.find(s => s.scheduleTypeId === scheduleTypeId && s.index === headerIndex)
    if (!targetSchedule || (targetSchedule.isValid === isValid && targetSchedule.value === value)) {
      return
    }

    const updatedHeaders: allHeadersType = allHeaders.map(ws => ({
      ...ws,
      schedules: ws.schedules.map(s =>
        s.scheduleTypeId === scheduleTypeId && s.index === headerIndex && ws.workspaceId === workspaceId
          ? { ...s, value }
          : s
      ),
    }))

    // すべてのvalueを取得し、重複カウントした後、カウント2以上は重複値としてマーク
    const allValues = updatedHeaders.flatMap(ws => ws.schedules.map(s => s.value))

    const valueCountMap = allValues.reduce<Record<string, number>>((acc, val) => {
      if (val === '') {
        return acc
      }
      acc[val] = (acc[val] || 0) + 1
      return acc
    }, {})

    const duplicatedValues = new Set(Object.keys(valueCountMap).filter(key => valueCountMap[key] >= 2))

    const finalHeaders = updatedHeaders.map(ws => ({
      ...ws,
      schedules: ws.schedules.map(s => ({
        ...s,
        isValid: s.value !== '' && !duplicatedValues.has(s.value),
      })),
    }))

    onHeaderValidate(finalHeaders)
  }

  // プログレスバーの計算
  const calculateProgress = useCallback(() => {
    const currentWorkspace = allHeaders.find(ws => ws.workspaceId === workspace.id)

    if (!currentWorkspace || currentWorkspace.schedules.length === 0) {
      return 0
    }

    const validCount = currentWorkspace.schedules.filter(s => s.value !== '' && s.isValid).length
    const totalCount = currentWorkspace.schedules.length

    return totalCount > 0 ? (validCount / totalCount) * 100 : 0
  }, [allHeaders, workspace.id])

  // 編集処理
  const handleHeaderChange = useCallback(
    (scheduleTypeId: number, headerIndex: number, newValue: string) => {
      const scheduleTypes = workspace.scheduleTypes.map(type => {
        if (type.id !== scheduleTypeId) {
          return type
        }

        // headerが空配列の場合は新規追加
        if (type.header.length === 0) {
          return {
            ...type,
            header: [newValue],
          }
        }

        return {
          ...type,
          header: type.header.map((header, index) => (index === headerIndex ? newValue : header)),
        }
      })

      onChange(scheduleTypes)
    },
    [onChange, workspace]
  )

  // 追加処理
  const handleHeaderAdd = useCallback(
    (scheduleTypeId: number) => {
      const scheduleTypes = workspace.scheduleTypes.map(type => {
        if (type.id !== scheduleTypeId) {
          return type
        }

        return {
          ...type,
          header: type.header.concat(''),
        }
      })

      onChange(scheduleTypes)
    },
    [onChange, workspace]
  )

  // 削除処理
  const handleHeaderRemove = useCallback(
    (scheduleTypeId: number, headerIndex: number) => {
      const scheduleTypes = workspace.scheduleTypes.map(type => {
        if (type.id !== scheduleTypeId) {
          return type
        }

        return {
          ...type,
          header: type.header.filter((_, index) => index !== headerIndex),
        }
      })

      onChange(scheduleTypes)
    },
    [onChange, workspace]
  )

  return (
    <>
      <div className="mb-3">作業マッピング設定</div>
      <div className="mb-2">このワークスペースのマッピング達成度</div>
      <div className={`${styles.progressContainer} mb-4`}>
        <div
          className={styles.progressBar}
          style={{
            width: `${calculateProgress()}%`,
            backgroundColor: calculateProgress() === 100 ? 'rgb(var(--bs-success-rgb))' : 'rgb(var(--bs-danger-rgb))',
          }}
        />
      </div>
      <div className={styles.scheduleTypeContainer}>
        {workspace.scheduleTypes.map((type, index) => (
          <div className={index === workspace.scheduleTypes.length - 1 ? '' : 'mb-3'} key={`${type.id}-${index}`}>
            <ScheduleTypeEditor
              workspaceId={workspace.id}
              scheduleType={type}
              allHeaders={allHeaders}
              onChange={(headerIndex, newValue) => handleHeaderChange(type.id, headerIndex, newValue)}
              onAddButtonClick={() => handleHeaderAdd(type.id)}
              onRemoveButtonClick={headerIndex => handleHeaderRemove(type.id, headerIndex)}
              onValidChange={handleValidChange}
            />
          </div>
        ))}
      </div>
    </>
  )
}
