import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'

import { login, selectSessionStatus, clearErrorMessage } from 'slices/sessionSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'
import { ERROR_STATUS_CODE } from 'slices/utils'

import { InputFormat, SinglePageCard } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'

import useAuthority from 'hooks/useAuthority'

import AutoLogin from './AutoLogin'

import styles from './styles.module.scss'

import type { MouseEvent } from 'react'

const Login = () => {
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [emailValidity, setEmailValidity] = useState(false)
  const [passwordValidity, setPasswordValidity] = useState(false)

  const dispatch = useDispatch()
  const { loggedIn, activated, errorMessage } = useSelector(selectSessionStatus, shallowEqual)
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  const { isTenantAdmin, isAdmin, isProcessAdmin } = useAuthority()
  const navigate = useNavigate()
  const onSubmit = (e: MouseEvent) => {
    e.preventDefault()
    if (email && password) {
      dispatch(login({ email, password }))
    }
  }

  useEffect(() => {
    if (!loggedIn) {
      return
    }

    // メールで渡すサインアップURLはPC版であるため、タブレット版ではアクティベートしない
    if (!activated && process.env.REACT_APP_TEAM_MODE !== 'true') {
      navigate('/activate')
      return
    }

    // タブレット版から全体管理者画面にはログインできない
    if (isTenantAdmin) {
      process.env.REACT_APP_TEAM_MODE === 'true'
        ? setModalErrorMessage('メールアドレスかパスワードが間違っています。')
        : navigate('/tenants')
      return
    }

    if (!tenant || !(isAdmin || isProcessAdmin)) {
      return
    }

    if (!tenant.isActive) {
      navigate('/suspension')
      return
    }

    if (process.env.REACT_APP_TEAM_MODE === 'true') {
      // グループ管理画面向け遷移
      navigate('/team-select-workspaces')
      return
    }
    navigate('/dashboard')
  }, [navigate, loggedIn, activated, tenant, isTenantAdmin, isProcessAdmin, isAdmin])

  useEffect(() => {
    // UNREACHABLEのときにはエラーダイアログが出るのでNotificationは出さない
    if (errorMessage === ERROR_STATUS_CODE.UNREACHABLE || errorMessage === '') {
      setModalErrorMessage(undefined)
    } else {
      // ログイン不要ページでUNAUTHORIZEDのときはNotificationを出す
      setModalErrorMessage('メールアドレスかパスワードが間違っています。')
    }
  }, [errorMessage])

  const disabled = useMemo(
    () => !(email && emailValidity && password && passwordValidity),
    [email, emailValidity, password, passwordValidity]
  )

  return (
    <SinglePageCard
      errorMessage={modalErrorMessage}
      onHideNotification={() => {
        setModalErrorMessage(undefined)
        dispatch(clearErrorMessage())
      }}
    >
      <Container>
        <InputFormat
          label="メールアドレス"
          type="email"
          placeholder="メールアドレス"
          value={email}
          size={COLUMN_SIZES.LARGE}
          maxLength={100}
          onChange={value => setEmail(value)}
          validations={[Rules.Required]}
          onValidate={setEmailValidity}
          className="mb-3"
        />

        <InputFormat
          label="パスワード"
          type="password"
          placeholder="パスワード"
          value={password}
          size={COLUMN_SIZES.LARGE}
          maxLength={100}
          onChange={value => setPassword(value)}
          validations={[Rules.Required]}
          onValidate={setPasswordValidity}
        />

        <Row>
          <Col className="text-center mt-3">
            <Link className="text-decoration-none" to="/password-reset">
              パスワードを忘れた方
            </Link>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <Button disabled={disabled} color="primary" className={styles.button} onClick={onSubmit}>
              ログイン
            </Button>
          </Col>
        </Row>
      </Container>
      {process.env.NODE_ENV === 'development' && <AutoLogin setEmail={setEmail} setPassword={setPassword} />}
    </SinglePageCard>
  )
}

export default Login
