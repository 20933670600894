import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { selectSpinnerStatus } from 'slices/spinnerSlice'

import styles from './styles.module.scss'

const Spinner = () => {
  const { show } = useSelector(selectSpinnerStatus, shallowEqual)
  const spinnerMemo = useMemo(
    () => (
      <div className={styles.backdrop}>
        <div className={`spinner-border text-primary ${styles.spinner}`} role="status" />
      </div>
    ),
    []
  )
  return show ? spinnerMemo : null
}

export default Spinner
