import { useState, useEffect, useMemo, useCallback } from 'react'
import { Col, FormGroup, FormText, Input, InputGroup, Label, FormFeedback } from 'reactstrap'

import { COLUMN_SIZES } from 'components/common/constants'
import type { ColumnSize } from 'components/common/types'

import styles from './BalanceOfPaymentsFormat.module.scss'

import type { ChangeEvent, FocusEvent, CSSProperties } from 'react'

type InputGroupFormatWithCostsBadgeProps = {
  label: string | JSX.Element
  labelSize?: ColumnSize
  size?: ColumnSize
  placeholder?: string
  formText?: string | JSX.Element
  className?: string
  value?: string
  addonText: string
  maxLength?: number
  disabled?: boolean
  onChange: (value: string) => void
  validations?: Array<(s: string | undefined) => string>
  onValidate?: (b: boolean) => void
  backgroundColor?: string
  onFocus?: () => void
  onBlur?: () => void
}

type InputGroupFormatWithSalesBadgeProps = InputGroupFormatWithCostsBadgeProps

export const InputGroupFormatWithCostsBadge = ({
  label,
  placeholder,
  value,
  addonText,
  labelSize = COLUMN_SIZES.SHORT,
  size = COLUMN_SIZES.LARGE,
  className,
  maxLength,
  formText,
  disabled,
  onChange,
  validations,
  onValidate,
  onFocus,
  onBlur,
}: InputGroupFormatWithCostsBadgeProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')
  const invalid = useCallback(
    (inputValue: string | undefined) => validations?.find(validation => validation(inputValue))?.(inputValue),
    [validations]
  )

  useEffect(() => {
    setErrorMessage(invalid(value))
  }, [invalid, value])

  const handleChange = (e: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const id = useMemo(() => `id-${Math.random()}`, [])
  const style: CSSProperties = {
    textAlign: 'right',
  }

  // 直接onValidateを実行すると親のrender中に子コンポーネントから状態を変えることになりwarningが出る
  useEffect(() => onValidate?.(!errorMessage), [onValidate, value, errorMessage])
  return (
    <>
      <FormGroup row className="mb-0 position-relative">
        <Label for={id} md={labelSize}>
          {label}
        </Label>
        <Col md={size} className={className ? styles[className] : ''}>
          <InputGroup className="flex-nowrap">
            <Input
              id={id}
              style={style}
              value={value || ''}
              placeholder={placeholder}
              invalid={!!errorMessage}
              maxLength={maxLength}
              className={`form-control ${styles.inputPaddingLeft}`}
              disabled={disabled}
              onChange={e => onChange(e.target.value)}
              onBlur={onBlur ? () => onBlur() : handleChange}
              onFocus={() => onFocus?.()}
            />
            <span
              className={`position-absolute badge rounded-pill fw-normal font-small py-2 px-3 my-1 mx-2 ${styles.innerBadgeLabel} ${styles.badgeColorCosts}`}
            >
              費用
            </span>
            <span className="input-group-text rounded-end">{addonText}</span>
          </InputGroup>
          {errorMessage && (
            <FormFeedback className="d-block" hidden={!errorMessage}>
              {errorMessage}
            </FormFeedback>
          )}
          <FormText color="muted" hidden={!formText}>
            {formText}
          </FormText>
        </Col>
      </FormGroup>
    </>
  )
}

export const InputGroupFormatWithSalesBadge = ({
  label,
  placeholder,
  value,
  addonText,
  labelSize = COLUMN_SIZES.SHORT,
  size = COLUMN_SIZES.LARGE,
  className,
  maxLength,
  formText,
  disabled,
  onChange,
  validations,
  onValidate,
  onFocus,
  onBlur,
}: InputGroupFormatWithSalesBadgeProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')
  const invalid = useCallback(
    (inputValue: string | undefined) => validations?.find(validation => validation(inputValue))?.(inputValue),
    [validations]
  )

  useEffect(() => {
    setErrorMessage(invalid(value))
  }, [invalid, value])

  const handleChange = (e: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const id = useMemo(() => `id-${Math.random()}`, [])
  const style: CSSProperties = {
    textAlign: 'right',
  }

  // 直接onValidateを実行すると親のrender中に子コンポーネントから状態を変えることになりwarningが出る
  useEffect(() => onValidate?.(!errorMessage), [onValidate, value, errorMessage])
  return (
    <>
      <FormGroup row className="mb-0 position-relative">
        <Label for={id} md={labelSize}>
          {label}
        </Label>
        <Col md={size} className={className ? styles[className] : ''}>
          <InputGroup className="flex-nowrap">
            <Input
              id={id}
              style={style}
              value={value || ''}
              placeholder={placeholder}
              invalid={!!errorMessage}
              maxLength={maxLength}
              className={`form-control ${styles.inputPaddingLeft}`}
              disabled={disabled}
              onChange={e => onChange(e.target.value)}
              onBlur={onBlur ? () => onBlur() : handleChange}
              onFocus={() => onFocus?.()}
            />
            <span
              className={`position-absolute badge rounded-pill fw-normal font-small py-2 px-3 my-1 mx-2 ${styles.innerBadgeLabel} ${styles.badgeColorSales}`}
            >
              売上
            </span>
            <span className="input-group-text rounded-end">{addonText}</span>
          </InputGroup>
          {errorMessage && (
            <FormFeedback className="d-block" hidden={!errorMessage}>
              {errorMessage}
            </FormFeedback>
          )}
          <FormText color="muted" hidden={!formText}>
            {formText}
          </FormText>
        </Col>
      </FormGroup>
    </>
  )
}
