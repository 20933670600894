import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { clearErrorMessage, selectUsersStatus } from 'slices/usersSlice'

import { CustomModal } from 'components/common'

import useDeleteUser from 'hooks/useDeleteUser'

type Props = {
  isOpen: boolean
  tenantId: number
  userId: string
  onSuccess: () => void
  onCancel: () => void
}

const UserDelete = ({ isOpen, tenantId, userId, onSuccess, onCancel }: Props) => {
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = useState(false)
  const { isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const { deleteUser } = useDeleteUser(tenantId)

  const dispatch = useDispatch()

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    setSubmitted(false)
    onCancel()
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else {
      setModalErrorMessage('削除できませんでした。')
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  const handleDeleteClick = () => {
    setSubmitted(true)
    deleteUser(userId)
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="ユーザーアカウント削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleDeleteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">このユーザーを本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}

export default UserDelete
