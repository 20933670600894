import type { CreateResponse, UpdateResponse, DeleteResponse } from 'api/types'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type {
  DailyReportsImportSettingsListResponse,
  DailyReportsImportSettingsResponse,
  DailyReportsImportSettingsRequestBody,
  DailyReportsImportUploadUrlResponse,
  DailyReportsImportUploadUrlRequestBody,
} from './types'

export const getDailyReportsImportSettingsList = async (
  workDate?: string
): Promise<DailyReportsImportSettingsListResponse> => {
  const url = '/daily-report'

  const params = workDate ? { workDate: workDate } : {}
  const response = await axiosClientWithTenantId.get(url, { params })
  return response.data
}

export const getDailyReportsImportSettings = async (
  dailyReportId: number,
  workDate?: string
): Promise<DailyReportsImportSettingsResponse> => {
  const url = `/daily-report/${dailyReportId}`

  const params = workDate ? { workDate: workDate } : {}
  const response = await axiosClientWithTenantId.get(url, { params })
  return response.data
}

export const createDailyReportsImportSettings = async (
  requestBody: DailyReportsImportSettingsRequestBody
): Promise<CreateResponse> => {
  const url = '/daily-report'

  const response = await axiosClientWithTenantId.post(url, requestBody)
  return response.data
}

export const updateDailyReportsImportSettings = async (
  dailyReportId: number,
  requestBody: DailyReportsImportSettingsRequestBody
): Promise<UpdateResponse> => {
  const url = `/daily-report/${dailyReportId}`

  const response = await axiosClientWithTenantId.patch(url, requestBody)
  return response.data
}

export const deleteDailyReportsImportSettings = async (
  dailyReportId: number,
  workDate?: string
): Promise<DeleteResponse> => {
  const url = `/daily-report/${dailyReportId}`

  const params = workDate ? { workDate: workDate } : {}
  const response = await axiosClientWithTenantId.delete(url, { params })
  return response.data
}

export const dailyReportUploadUrl = async (
  data: DailyReportsImportUploadUrlRequestBody
): Promise<DailyReportsImportUploadUrlResponse> => {
  const url = '/daily-report-upload-url'

  const response = await axiosClientWithTenantId.post(url, data)
  return response.data
}
