import { useCallback } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'

import { selectSessionStatus } from 'slices/sessionSlice'
import { deleteTenantUser, deleteUser as deleteTenantAdminUser, selectUsersStatus } from 'slices/usersSlice'

import useLogout from './useLogout'

const useDeleteUser = (tenantId: number) => {
  const dispatch = useDispatch()
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { allTenantUsers, selectedTenantUsers } = useSelector(selectUsersStatus, shallowEqual)
  const { logout } = useLogout()

  // ログイン中のユーザーを削除する
  const deleteLoginUser = useCallback(() => {
    const { id } = user.userHasTenants[0]

    if (id === 0) {
      dispatch(deleteTenantAdminUser(user.userId))
      logout()
      return
    }

    if (id >= 1) {
      dispatch(deleteTenantUser(user.userId, tenantId))
      logout()
    }
  }, [dispatch, user, logout, tenantId])

  // ユーザー一覧から特定のユーザーを削除する
  const deleteSpecificUser = useCallback(
    (userId: string) => {
      if (allTenantUsers.some(u => u.userId === userId)) {
        dispatch(deleteTenantAdminUser(userId))
        return
      }
      if (selectedTenantUsers.some(u => u.userId === userId)) {
        dispatch(deleteTenantUser(userId, tenantId))
      }
    },
    [allTenantUsers, selectedTenantUsers, dispatch, tenantId]
  )

  const deleteUser = useCallback(
    (userId?: string) => (userId ? deleteSpecificUser(userId) : deleteLoginUser()),
    [deleteSpecificUser, deleteLoginUser]
  )

  return { deleteUser }
}

export default useDeleteUser
