import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { getBopProfitAndLoss } from 'slices/bopSlice'
import { getBopMonitoringSummary, getTenantSummary } from 'slices/dashboardSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkspaceList } from 'slices/workspacesSlice'

import { Pivot, PivotItem } from 'components/common'

import useBusinessTime from 'hooks/useBusinessTime'
import useDateChange from 'hooks/useDateChange'
import useDateQuery from 'hooks/useDateQuery'

import BopMonitoringProfitAndLoss from './BopMonitoringProfitAndLoss'
import { BopMonitoringSummary } from './BopMonitoringSummary'
import Dashboard from './Dashboard'

export const BopMonitoring = () => {
  const [selectedWorkspaceIds, setSelectedWorkspaceIds] = useState<number[]>([])
  const [selectedKeyScheduleTypeIds, setSelectedKeyScheduleTypeIds] = useState<number[]>([])

  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const dispatch = useDispatch()
  const { getWorkDate } = useBusinessTime()

  const date = getWorkDate(useDateQuery())
  const { checkTenantCreated } = useDateChange()

  // ダッシュボード収支モニタリング取得
  useEffect(() => {
    dispatch(getBopProfitAndLoss(date))
    dispatch(getTenantSummary(date, { dashboardFilter: true }))
  }, [dispatch, date])

  useEffect(() => {
    dispatch(getWorkspaceList({ workDate: date, tenantId: user.userHasTenants[0].id }))
  }, [dispatch, user, date])

  const handleInterval = useCallback(() => {
    const workspacesParam = selectedWorkspaceIds.length === 0 ? {} : { workspaceIds: selectedWorkspaceIds.join() }
    const keyScheduleTypesParam =
      selectedKeyScheduleTypeIds.length === 0 ? {} : { scheduleTypeIds: selectedKeyScheduleTypeIds.join() }
    dispatch(getBopProfitAndLoss(date))
    dispatch(getBopMonitoringSummary(date, { ...workspacesParam, ...keyScheduleTypesParam }))
  }, [dispatch, date, selectedWorkspaceIds, selectedKeyScheduleTypeIds])

  const handleDateChange = useCallback((newDate: string) => checkTenantCreated(newDate), [checkTenantCreated])

  return (
    <Dashboard onInterval={handleInterval} onDateChange={handleDateChange}>
      <Pivot>
        {[
          { header: 'サマリー', key: 'summary' },
          { header: '損益詳細', key: 'profit_and_loss_detail' },
        ].map(({ header, key }) => (
          <PivotItem headerText={header} key={key}>
            {key === 'summary' ? (
              <BopMonitoringSummary
                onSelectedWorkspacesChange={setSelectedWorkspaceIds}
                onSelectedKeyScheduleTypesChange={setSelectedKeyScheduleTypeIds}
              />
            ) : key === 'profit_and_loss_detail' ? (
              <BopMonitoringProfitAndLoss />
            ) : (
              <></>
            )}
          </PivotItem>
        ))}
      </Pivot>
    </Dashboard>
  )
}
