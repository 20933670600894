import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { isEqual, maxBy, sumBy } from 'es-toolkit'
import { concat, floor } from 'es-toolkit/compat'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Card, CardBody, CardTitle, Input, Label } from 'reactstrap'

import type { BopDateData } from 'api/bop_reports/types'

import { getBopReports, selectBopReportsStatus } from 'slices/bopReportsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getDisplayFilter, selectUsersStatus, updateDisplayFilter } from 'slices/usersSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { BOP_TYPE } from 'components/Dashboard/utils'
import {
  BadgeLabel,
  Chart,
  GroupRadioButton,
  GraphSelectButton,
  AmountCard,
  NotSelectedPlaceholder,
} from 'components/common'
import { PLACE_HOLDER_TYPES } from 'components/common/NotSelectedPlaceholder/NotSelectedPlaceholder'
import { UpdateLabel } from 'components/common/UpdateLabel/UpdateLabel'
import { createStackedChartOptions } from 'components/common/utils'

import { useBopReportsQuery } from 'hooks/useBopReportsQuery'
import useBusinessTime from 'hooks/useBusinessTime'

import { BopReportsCommon, toggleButtonItemList } from './BopReportsCommon'
import { DATA_TYPE_ITEMS } from './constants'

import type { XAxisOptions } from 'highcharts'

dayjs.extend(isSameOrAfter)

type BopGraphDataProps = {
  data: BopDateData[]
}

const BOP_GRAPH_GROUP_TYPE = {
  SALES: 'sales',
  COSTS: 'costs',
} as const

const POINT_PLACEMENT = 0.09
const BOP_POINT_PADDING = 0.3

const BopReportsBop = () => {
  const [submitted, setSubmitted] = useState(false)
  const [selectedBopType, setSelectedBopType] = useState(BOP_TYPE.ACTUAL)
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<number[]>([])
  const [isDailyReportChecked, setIsDailyReportChecked] = useState(true)
  const [isEstimateChecked, setIsEstimateChecked] = useState(false)
  const [isAverageChecked, setIsAverageChecked] = useState(false)
  const [isDataSourceOpen, setIsDataSourceOpen] = useState(false)

  const isInit = useRef(false)

  const { queryStart, queryEnd } = useBopReportsQuery()
  const { getWorkDate } = useBusinessTime()

  const dispatch = useDispatch()

  const { displayFilter, isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { bopReportsBop } = useSelector(selectBopReportsStatus, shallowEqual)

  useEffect(() => {
    dispatch(getDisplayFilter())
  }, [dispatch])

  useEffect(() => {
    // 初回表示は、displayFilter: true, isDailyReport: trueの固定値で取得する
    if (isInit.current) {
      return
    }

    isInit.current = true

    const queryParams = {
      from: queryStart,
      to: queryEnd,
      displayFilter: true,
      isDailyReport: true,
    }

    dispatch(getBopReports(queryParams))
  }, [dispatch, queryStart, queryEnd])

  useEffect(() => {
    setSelectedWorkspaces(
      displayFilter?.bopReport.workspaceData
        .filter(workspace => workspace.isFilteredInSummary)
        .map(workspace => workspace.id) || []
    )
  }, [displayFilter])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  // データソースリストの作成
  const dataSourceItems = useMemo(
    () =>
      bopReportsBop?.workspaces?.map(({ id, dataType }) => {
        const name = partialWorkspaces.find(pw => pw.id === id)?.name || ''
        const dataTypeKey = selectedBopType === BOP_TYPE.ESTIMATE ? BOP_TYPE.ESTIMATE : dataType
        return { id, name, ...DATA_TYPE_ITEMS[dataTypeKey] }
      }) || [],
    [bopReportsBop?.workspaces, partialWorkspaces, selectedBopType]
  )

  const todayWorkDate = useMemo(() => dayjs(getWorkDate(dayjs().format('YYYY-MM-DD'))).startOf('day'), [getWorkDate])

  const isTodayAfter = useCallback(
    (date: string) => dayjs(date).startOf('day').isSameOrAfter(todayWorkDate, 'days'),
    [todayWorkDate]
  )

  const isActualType = useMemo(() => selectedBopType === BOP_TYPE.ACTUAL, [selectedBopType])

  const periodDays = useMemo(() => {
    const period = dayjs(queryEnd).diff(queryStart, 'days') + 1
    if (!isActualType || (isActualType && isEstimateChecked) || !isTodayAfter(queryEnd)) {
      return period
    }

    // 今日以降の日数
    const afterTodayPeriod = dayjs(queryEnd).startOf('day').diff(todayWorkDate, 'days') + 1

    // 期間から今日以降の日数を引いて、実績の日数を計算
    return period - afterTodayPeriod
  }, [queryEnd, queryStart, isActualType, isEstimateChecked, isTodayAfter, todayWorkDate])

  const averageOfDays = useCallback(
    (value: number) => {
      return periodDays > 0 ? Number(floor(value / periodDays, 0).toFixed(0)) : value
    },
    [periodDays]
  )

  const displayData = useMemo(() => {
    if (!bopReportsBop) {
      return
    }

    if (!isActualType) {
      return isAverageChecked
        ? {
            totalPeriodData: {
              totalSales: averageOfDays(bopReportsBop.estimate.totalPeriodData.totalSales),
              sales: averageOfDays(bopReportsBop.estimate.totalPeriodData.sales),
              otherSales: averageOfDays(bopReportsBop.estimate.totalPeriodData.otherSales),
              totalCosts: averageOfDays(bopReportsBop.estimate.totalPeriodData.totalCosts),
              costOfGoodsSold: averageOfDays(bopReportsBop.estimate.totalPeriodData.costOfGoodsSold),
              fixedCosts: averageOfDays(bopReportsBop.estimate.totalPeriodData.fixedCosts),
              otherCosts: averageOfDays(bopReportsBop.estimate.totalPeriodData.otherCosts),
              profit: averageOfDays(bopReportsBop.estimate.totalPeriodData.profit),
              profitRatio: bopReportsBop.estimate.totalPeriodData.profitRatio,
            },
            data: bopReportsBop.estimate.data,
          }
        : bopReportsBop.estimate
    }

    const getIncludesEstimateData = () => {
      // 見込のデータを含める場合
      const estimateDateData = bopReportsBop.estimate.data.filter(item => isTodayAfter(item.workDate))

      const estimateSales = sumBy(estimateDateData, d => d.sales)
      const estimateOtherSales = sumBy(estimateDateData, d => d.otherSales)
      const estimateCostOfGoodsSold = sumBy(estimateDateData, d => d.costOfGoodsSold)
      const estimateFixedCosts = sumBy(estimateDateData, d => d.fixedCosts)
      const estimateOtherCosts = sumBy(estimateDateData, d => d.otherCosts)
      const estimateTotalSales = estimateSales + estimateOtherSales
      const estimateTotalCosts = estimateCostOfGoodsSold + estimateFixedCosts + estimateOtherCosts
      const estimateProfit = estimateTotalSales - estimateTotalCosts
      const totalSales = bopReportsBop.actual.totalPeriodData.totalSales + estimateTotalSales
      const estimateProfitRatio =
        totalSales === 0 ? 0 : ((bopReportsBop.actual.totalPeriodData.profit + estimateProfit) / totalSales) * 100
      const includeEstimateData = {
        totalPeriodData: {
          totalSales,
          sales: bopReportsBop.actual.totalPeriodData.sales + estimateSales,
          otherSales: bopReportsBop.actual.totalPeriodData.otherSales + estimateOtherSales,
          totalCosts: bopReportsBop.actual.totalPeriodData.totalCosts + estimateTotalCosts,
          costOfGoodsSold: bopReportsBop.actual.totalPeriodData.costOfGoodsSold + estimateCostOfGoodsSold,
          fixedCosts: bopReportsBop.actual.totalPeriodData.fixedCosts + estimateFixedCosts,
          otherCosts: bopReportsBop.actual.totalPeriodData.otherCosts + estimateOtherCosts,
          profit: bopReportsBop.actual.totalPeriodData.profit + estimateProfit,
          profitRatio: estimateProfitRatio,
        },
        data: bopReportsBop.actual.data.concat(estimateDateData),
      }

      return includeEstimateData
    }

    const emptyData = bopReportsBop.estimate.data
      .filter(item => isTodayAfter(item.workDate))
      .map(item => ({
        workDate: item.workDate,
        sales: 0,
        otherSales: 0,
        costOfGoodsSold: 0,
        fixedCosts: 0,
        otherCosts: 0,
        profitRatio: 0,
      }))

    const actualData = isEstimateChecked && isTodayAfter(queryEnd) ? getIncludesEstimateData() : bopReportsBop.actual

    return isAverageChecked
      ? {
          totalPeriodData: {
            totalSales: averageOfDays(actualData.totalPeriodData.totalSales),
            sales: averageOfDays(actualData.totalPeriodData.sales),
            otherSales: averageOfDays(actualData.totalPeriodData.otherSales),
            totalCosts: averageOfDays(actualData.totalPeriodData.totalCosts),
            costOfGoodsSold: averageOfDays(actualData.totalPeriodData.costOfGoodsSold),
            fixedCosts: averageOfDays(actualData.totalPeriodData.fixedCosts),
            otherCosts: averageOfDays(actualData.totalPeriodData.otherCosts),
            profit: averageOfDays(actualData.totalPeriodData.profit),
            profitRatio: actualData.totalPeriodData.profitRatio,
          },
          data: isEstimateChecked ? actualData.data : actualData.data.concat(emptyData),
        }
      : {
          totalPeriodData: actualData.totalPeriodData,
          data: isEstimateChecked ? actualData.data : actualData.data.concat(emptyData),
        }
  }, [bopReportsBop, isActualType, isEstimateChecked, isTodayAfter, queryEnd, isAverageChecked, averageOfDays])

  const getGraphSeriesData = useCallback(
    (graphData: BopGraphDataProps): Highcharts.SeriesOptionsType[] => {
      const salesData = graphData.data.map(item => item.sales)
      const otherSalesData = graphData.data.map(item => item.otherSales)
      const costOfGoodsSoldData = graphData.data.map(item => item.costOfGoodsSold)
      const fixedCostsData = graphData.data.map(item => item.fixedCosts)
      const otherCostsData = graphData.data.map(item => item.otherCosts)
      const profitRatioData = graphData.data.map(item => item.profitRatio)
      const dateData = graphData.data.map(item => item.workDate)

      // 実績表示時に見込み（本日以降）のデータはopacityを設定する
      const salesSeriesData = salesData.map((item, index) => ({
        y: item,
        color: 'var(--bs-primary)',
        className: isActualType && isTodayAfter(dateData[index]) ? 'opacity-50' : '',
      }))
      const otherSalesSeriesData = otherSalesData.map((item, index) => ({
        y: item,
        color: 'var(--bs-primary-middle)',
        className: isActualType && isTodayAfter(dateData[index]) ? 'opacity-50' : '',
      }))
      const costOfGoodsSoldSeriesData = costOfGoodsSoldData.map((item, index) => ({
        y: item,
        color: 'var(--bs-danger-stronger-middle)',
        className: isActualType && isTodayAfter(dateData[index]) ? 'opacity-50' : '',
      }))
      const fixedCostsSeriesData = fixedCostsData.map((item, index) => ({
        y: item,
        color: 'var(--bs-danger-middle)',
        className: isActualType && isTodayAfter(dateData[index]) ? 'opacity-50' : '',
      }))
      const otherCostsSeriesData = otherCostsData.map((item, index) => ({
        y: item,
        color: 'var(--bs-danger-pale)',
        className: isActualType && isTodayAfter(dateData[index]) ? 'opacity-50' : '',
      }))
      const profitRatioSeriesData = profitRatioData.map((item, index) => {
        // 実績表示かつ本日以降のデータはnullにする
        if (isActualType && isTodayAfter(dateData[index])) {
          return null
        }

        return {
          y: item,
          color: 'var(--bs-success)',
        }
      })

      const series: Highcharts.SeriesOptionsType[] = [
        {
          type: 'column',
          name: '売上',
          data: salesSeriesData,
          stack: BOP_GRAPH_GROUP_TYPE.SALES,
          pointPlacement: POINT_PLACEMENT,
          zIndex: 0,
        },
        {
          type: 'column',
          name: 'その他売上',
          data: otherSalesSeriesData,
          stack: BOP_GRAPH_GROUP_TYPE.SALES,
          pointPlacement: POINT_PLACEMENT,
          zIndex: 0,
        },
        {
          type: 'column',
          name: '売上原価',
          data: costOfGoodsSoldSeriesData,
          stack: BOP_GRAPH_GROUP_TYPE.COSTS,
          pointPlacement: -1 * POINT_PLACEMENT,
          zIndex: 0,
        },
        {
          type: 'column',
          name: '固定費',
          data: fixedCostsSeriesData,
          stack: BOP_GRAPH_GROUP_TYPE.COSTS,
          pointPlacement: -1 * POINT_PLACEMENT,
          zIndex: 0,
        },
        {
          type: 'column',
          name: 'その他費用',
          data: otherCostsSeriesData,
          stack: BOP_GRAPH_GROUP_TYPE.COSTS,
          pointPlacement: -1 * POINT_PLACEMENT,
          zIndex: 0,
        },
        {
          type: 'line',
          name: '利益率',
          color: 'var(--bs-success)',
          data: profitRatioSeriesData,
          yAxis: 1,
          zIndex: 1,
          dashStyle: 'Dash',
        },
      ]

      // 実績かつ見込を含む表示
      if (isActualType && isEstimateChecked) {
        // 見込の利益率のグラフのデータを作成し、グラフに追加する
        // 前日から期間最後までのデータを作成（前日以前はnullになる）
        const estimateProfitRatioSeriesData = profitRatioData.map((item, index) => {
          if (dayjs(dateData[index]).startOf('day').isBefore(todayWorkDate.subtract(1, 'days'), 'days')) {
            return null
          }

          return {
            y: item,
            color: 'rgba(var(--bs-success-rgb), 0.5)',
          }
        })

        series.push({
          type: 'line',
          name: '利益率(見込)',
          color: 'rgba(var(--bs-success-rgb), 0.5)',
          data: estimateProfitRatioSeriesData,
          yAxis: 1,
          zIndex: 0,
          dashStyle: 'Dash',
        })
      }

      return series
    },
    [isActualType, isEstimateChecked, isTodayAfter, todayWorkDate]
  )

  const chartOptions = useMemo(() => {
    if (!displayData) {
      return
    }

    const graphData = {
      data: displayData.data,
    }
    const categories = graphData.data.map(item => item.workDate)
    const maxY = maxBy(graphData.data, data => data.profitRatio)?.profitRatio
    const optionProps = {
      seriesData: getGraphSeriesData(graphData),
      categories: categories,
      pointPadding: BOP_POINT_PADDING,
    }
    const options = createStackedChartOptions(optionProps)
    ;(options.xAxis as XAxisOptions).labels!.formatter = function () {
      return dayjs(this.value).format('MM/DD')
    }
    options.yAxis = concat(options.yAxis!, [
      {
        title: {
          text: undefined,
        },
        max: maxY || 100,
        min: 0,
        opposite: true,
      },
    ])
    options.tooltip!.shared = true
    options.tooltip!.formatter = function () {
      // this.points[5]に利益率のデータが入ってくる
      if (!this.points || !this.points[5]) {
        // this.points[5]がない場合はツールチップは非表示にする
        // 実績表示で見込を含まない場合に見込部分(本日以降)のデータでthis.points[5]は存在しない
        return false
      }

      const date = dayjs(this.points[0].x).format('YYYY/MM/DD')
      const totalSales = this.points[0].y! + this.points[1].y!
      const totalCosts = this.points[2].y! + this.points[3].y! + this.points[4].y!
      const profitRatio = this.points[5].y!

      const tooltipText = `
      <div style="text-align:right">${date}<br>
      売上：${totalSales.toLocaleString()}円<br>
      費用：${totalCosts.toLocaleString()}円<br>
      利益率：${profitRatio}%
      </div>`

      return tooltipText
    }

    return options
  }, [displayData, getGraphSeriesData])

  const filterItems = useMemo(
    () =>
      partialWorkspaces?.map(workspace => ({
        key: workspace.id,
        label: workspace.name,
        checked: selectedWorkspaces.includes(workspace.id),
      })) || [],
    [partialWorkspaces, selectedWorkspaces]
  )

  const handleToggleChange = useCallback((id: string) => {
    setSelectedBopType(id)
  }, [])

  const handleWorkspaceSelect = useCallback(
    (items: number[]) => {
      if (isEqual(items, selectedWorkspaces)) {
        return
      }

      setSelectedWorkspaces(items)

      const queryParams = {
        from: queryStart,
        to: queryEnd,
        workspaceIds: items.join(),
        ...(isDailyReportChecked && { isDailyReport: true }),
      }

      dispatch(getBopReports(queryParams))
    },
    [dispatch, isDailyReportChecked, queryEnd, queryStart, selectedWorkspaces]
  )

  const handleWorkspaceFilterSaveButtonClick = useCallback(() => {
    if (!displayFilter) {
      return
    }

    setSubmitted(true)
    const updateWorkspaces = displayFilter.bopReport.workspaceData.map(df => {
      return { ...df, isFilteredInSummary: selectedWorkspaces.includes(df.id) }
    })

    dispatch(updateDisplayFilter({ bopReport: { workspaceData: updateWorkspaces } }))
  }, [dispatch, displayFilter, selectedWorkspaces])

  const getAmountCardLabel = useCallback(
    (text: string) => {
      const label = isActualType ? text : `${text}見込`
      return isAverageChecked ? `平均${label}` : label
    },
    [isAverageChecked, isActualType]
  )

  const handleIsEstimateChecked = useCallback((checked: boolean) => {
    setIsEstimateChecked(checked)
  }, [])

  const handleIsAverageChecked = useCallback((checked: boolean) => {
    setIsAverageChecked(checked)
  }, [])

  const handleDataSourceOpen = useCallback(() => {
    setIsDataSourceOpen(prevState => !prevState)
  }, [])

  const handleIsDailyReportChecked = useCallback(
    (checked: boolean) => {
      setIsDailyReportChecked(checked)

      const queryParams = {
        from: queryStart,
        to: queryEnd,
        workspaceIds: selectedWorkspaces.join(),
        ...(checked && { isDailyReport: true }),
      }
      dispatch(getBopReports(queryParams))
    },
    [dispatch, queryEnd, queryStart, selectedWorkspaces]
  )

  const handleDateChange = useCallback(
    (start: string, end: string) => {
      const queryParams = {
        from: start,
        to: end,
        displayFilter: true,
        ...(isDailyReportChecked && { isDailyReport: true }),
      }
      dispatch(getBopReports(queryParams))

      setSelectedWorkspaces(
        displayFilter?.bopReport.workspaceData
          .filter(workspace => workspace.isFilteredInSummary)
          .map(workspace => workspace.id) || []
      )
    },
    [dispatch, displayFilter?.bopReport.workspaceData, isDailyReportChecked]
  )

  return (
    <BopReportsCommon selectedWorkspaceIds={selectedWorkspaces} onDateChange={handleDateChange}>
      <Card className="mt-2">
        <CardBody className="p-4">
          <div className="d-flex align-items-baseline">
            <CardTitle className="fw-bold font-large text-nowrap">収支</CardTitle>
            <GraphSelectButton
              items={filterItems}
              selectedGraphs={selectedWorkspaces}
              onChange={handleWorkspaceSelect}
              text="表示ワークスペース"
              onSaveButtonClick={handleWorkspaceFilterSaveButtonClick}
            />
          </div>

          {selectedWorkspaces.length === 0 ? (
            <NotSelectedPlaceholder type={PLACE_HOLDER_TYPES.WORKSPACE} />
          ) : (
            <>
              <div className="d-flex align-items-baseline mt-2">
                <GroupRadioButton
                  items={toggleButtonItemList}
                  initSelectedId={toggleButtonItemList[1].id}
                  onChange={handleToggleChange}
                />
                {isActualType && (
                  <>
                    <div className="form-check ms-3">
                      <Input
                        className="form-check-input"
                        id="daily-report"
                        checked={isDailyReportChecked}
                        type="checkbox"
                        onChange={e => handleIsDailyReportChecked(e.target.checked)}
                      />
                      <Label className="form-check-label mb-0" for="daily-report">
                        日報実績を利用
                      </Label>
                    </div>
                    <div className="form-check ms-3">
                      <Input
                        className="form-check-input"
                        id="expectation"
                        checked={isEstimateChecked}
                        type="checkbox"
                        onChange={e => handleIsEstimateChecked(e.target.checked)}
                      />
                      <Label className="form-check-label mb-0" for="expectation">
                        見込を含んだ予測表示
                      </Label>
                    </div>
                  </>
                )}
                <div className="ms-auto">
                  <button
                    className="btn p-0 border-0 bg-transparent text-gray font-x-small"
                    onClick={handleDataSourceOpen}
                  >
                    <i className={`icf-carot_${isDataSourceOpen ? 'down' : 'right'}`} />
                    データソースを表示
                  </button>
                </div>
              </div>

              {isDataSourceOpen && (
                <div className="d-flex flex-wrap my-3 gap-2">
                  {dataSourceItems.map(dataSource => (
                    <BadgeLabel
                      key={dataSource.id}
                      label={dataSource.name}
                      dataSourceLabel={dataSource.dataTypeLabel}
                      dataSourceColor={dataSource.dataTypeColor}
                    />
                  ))}
                </div>
              )}

              <div className="d-flex mt-2 gap-2">
                <AmountCard
                  amount={displayData?.totalPeriodData.totalSales.toLocaleString() || '0'}
                  badgeLabel={getAmountCardLabel('売上')}
                  badgeColor="primary"
                  className="h-100 w-100"
                  unit="円"
                />
                <AmountCard
                  amount={displayData?.totalPeriodData.totalCosts.toLocaleString() || '0'}
                  badgeLabel={getAmountCardLabel('費用')}
                  badgeColor="danger-stronger-middle"
                  className="h-100 w-100"
                  unit="円"
                />
                <AmountCard
                  amount={displayData?.totalPeriodData.profit.toLocaleString() || '0'}
                  ratio={displayData?.totalPeriodData.profitRatio.toString() || '0'}
                  badgeLabel={getAmountCardLabel('利益')}
                  badgeColor="success"
                  className="h-100 w-100"
                  unit="円"
                />
              </div>
              <div className="mt-2 d-flex gap-2">
                <AmountCard
                  amount={displayData?.totalPeriodData.costOfGoodsSold.toLocaleString() || '0'}
                  badgeLabel={getAmountCardLabel('売上原価')}
                  badgeColor="danger-stronger-middle"
                  className="h-100"
                  unit="円"
                />
                <AmountCard
                  amount={displayData?.totalPeriodData.fixedCosts.toLocaleString() || '0'}
                  badgeLabel={getAmountCardLabel('固定費')}
                  badgeColor="danger-middle"
                  className="h-100"
                  unit="円"
                />
                <AmountCard
                  amount={displayData?.totalPeriodData.otherCosts.toLocaleString() || '0'}
                  badgeLabel={getAmountCardLabel('その他費用')}
                  badgeColor="danger-pale"
                  className="h-100"
                  unit="円"
                />
                <div className="form-check ms-3 align-self-center">
                  <Input
                    className="form-check-input"
                    id="average"
                    checked={isAverageChecked}
                    type="checkbox"
                    onChange={e => handleIsAverageChecked(e.target.checked)}
                  />
                  <Label className="form-check-label" for="average">
                    平均表示
                  </Label>
                </div>
              </div>

              <div className="mt-4">
                <Chart options={chartOptions!} />
              </div>

              <div className="d-flex gap-2">
                <BadgeLabel label="売上" color="primary" />
                <BadgeLabel label="その他売上" color="primary-middle" />
                <BadgeLabel label="売上原価" color="danger-stronger-middle" />
                <BadgeLabel label="固定費" color="danger-middle" />
                <BadgeLabel label="その他費用" color="danger-pale" />
                <BadgeLabel label="利益率" color="success" isFill={false} isDashBorder={true} />
              </div>

              <UpdateLabel updatedAt={bopReportsBop?.updatedAt} />
            </>
          )}
        </CardBody>
      </Card>
    </BopReportsCommon>
  )
}

export default BopReportsBop
