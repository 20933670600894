import { Input } from 'reactstrap'

import styles from './FilteringInputField.module.scss'

type Props = {
  placeholder?: string
  value: string
  onChange: (value: string) => void
}
const FilteringInputField = ({ placeholder = 'メンバー名もしくは識別子で検索', onChange, value }: Props) => {
  return (
    <div className="position-relative">
      <i className={`icf-search font-large text-muted ${styles.searchIcon}`} />
      <Input
        value={value}
        placeholder={placeholder}
        className={`${styles.paddingInput} border-secondary`}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  )
}
export default FilteringInputField
