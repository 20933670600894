import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'

import { BOP_EXPORT_DATA_TYPES } from 'api/bop_reports/constants'
import type { BopExportDataType } from 'api/bop_reports/types'

import { getWorkspaceList } from 'slices/workspacesSlice'

import { BOP_TYPE } from 'components/Dashboard/utils'
import { NavMenu, BadgeLabel, CustomButton } from 'components/common'
import { PeriodSelect } from 'components/common/PeriodSelect/PeriodSelect'
import { WEEKDAY_SUNDAY } from 'components/common/constants'

import { useBopReportsQuery } from 'hooks/useBopReportsQuery'
import useBusinessTime from 'hooks/useBusinessTime'

import { CsvExportDialog } from './CsvExportDialog'

import type { ReactElement } from 'react'

export const toggleButtonItemList = [
  { id: BOP_TYPE.ESTIMATE, label: '見込' },
  { id: BOP_TYPE.ACTUAL, label: '実績' },
]

type Props = {
  selectedWorkspaceIds: number[]
  onDateChange?: (start: string, end: string) => void
  children: ReactElement
}

export type BopPathItemType = {
  PATH: string
  LABEL: string
  EXPORT_DATA_TYPE: BopExportDataType
}

const BOP_PATH_ITEMS: BopPathItemType[] = [
  {
    PATH: '/bop-reports/bop',
    LABEL: '収支',
    EXPORT_DATA_TYPE: BOP_EXPORT_DATA_TYPES.SUMMARY,
  },
  {
    PATH: '/bop-reports/profit-and-loss',
    LABEL: '損益詳細',
    EXPORT_DATA_TYPE: BOP_EXPORT_DATA_TYPES.DETAIL,
  },
  {
    PATH: '/bop-reports/labor-costs',
    LABEL: '労務費バランス',
    EXPORT_DATA_TYPE: BOP_EXPORT_DATA_TYPES.LABOR_COSTS,
  },
  {
    PATH: '/bop-reports/unit-costs',
    LABEL: '単位原価',
    EXPORT_DATA_TYPE: BOP_EXPORT_DATA_TYPES.UNIT_COSTS,
  },
]

export const BopReportsCommon = ({ selectedWorkspaceIds, onDateChange, children }: Props) => {
  const { queryStart, queryEnd, setDateRange } = useBopReportsQuery()
  const [openCsvExportDialog, setOpenCsvExportDialog] = useState(false)
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const { getWorkDate } = useBusinessTime()

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const selectedBopPathItem = useMemo(() => BOP_PATH_ITEMS.find(item => matchPath(item.PATH, pathname)), [pathname])

  const bopPeriodList = useMemo(() => {
    const baseDate = dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')))
    return [
      {
        label: '今週',
        periodStart:
          baseDate.day() === WEEKDAY_SUNDAY ? baseDate.subtract(1, 'day').day(1).toDate() : baseDate.day(1).toDate(),
        periodEnd: baseDate.day() === WEEKDAY_SUNDAY ? baseDate.toDate() : baseDate.day(1).add(6, 'day').toDate(),
        id: 'thisWeek',
      },
      {
        label: '先週',
        periodStart:
          baseDate.day() === WEEKDAY_SUNDAY
            ? baseDate.subtract(8, 'day').day(1).toDate()
            : baseDate.subtract(7, 'day').day(1).toDate(),
        periodEnd:
          baseDate.day() === WEEKDAY_SUNDAY
            ? baseDate.subtract(7, 'day').toDate()
            : baseDate.subtract(7, 'day').day(1).add(6, 'day').toDate(),
        id: 'lastWeek',
      },
      {
        label: '今月',
        periodStart: baseDate.startOf('month').toDate(),
        periodEnd: baseDate.endOf('month').toDate(),
        id: 'thisMonth',
      },
      {
        label: '先月',
        periodStart: baseDate.subtract(1, 'month').startOf('month').toDate(),
        periodEnd: baseDate.subtract(1, 'month').endOf('month').toDate(),
        id: 'lastMonth',
      },
      {
        label: '開始日と終了日を設定',
        periodStart: baseDate.toDate(),
        periodEnd: baseDate.toDate(),
        id: 'user_setting',
      },
    ]
  }, [getWorkDate])

  const handlePeriodChange = useCallback(
    (start: Date, end: Date) => {
      const formattedStart = dayjs(start).format('YYYY-MM-DD')
      const formattedEnd = dayjs(end).format('YYYY-MM-DD')
      onDateChange?.(formattedStart, formattedEnd)
      setDateRange(formattedStart, formattedEnd)
    },
    [onDateChange, setDateRange]
  )

  return (
    <NavMenu>
      <div className="m-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <div className="font-x-large fw-bold">収支レポート</div>
            <div className="px-2 align-self-center">
              <BadgeLabel label={selectedBopPathItem?.LABEL || ''} />
            </div>
          </div>
          <div className="d-flex  ms-auto">
            <CustomButton className="me-2" outline onClick={() => setOpenCsvExportDialog(true)}>
              CSVエクスポート
            </CustomButton>
            <PeriodSelect
              period={{ start: dayjs(queryStart).toDate(), end: dayjs(queryEnd).toDate() }}
              periodListData={bopPeriodList}
              maxDate={dayjs().add(45, 'days').toDate()}
              onChange={handlePeriodChange}
            />
          </div>
        </div>
        <div>{children}</div>

        <CsvExportDialog
          open={openCsvExportDialog}
          setOpen={setOpenCsvExportDialog}
          selectedWorkspaceIds={selectedWorkspaceIds}
          bopPathItem={selectedBopPathItem || BOP_PATH_ITEMS[0]}
          key={`csvExportDialog-${openCsvExportDialog}`}
        />
      </div>
    </NavMenu>
  )
}
