import type { ColorType } from 'api/types'
import './icons/icons.scss'

type Props = {
  name: string
  groupColor: ColorType
}

const ICON_TYPE_SIZE = 3
const WorkerIcon = ({ name, groupColor }: Props) => {
  const randomIconNumber = Math.ceil(Math.random() * ICON_TYPE_SIZE)
  return <div className={`${groupColor}-icon-0${randomIconNumber}`}>{name.slice(0, 1)}</div>
}

export default WorkerIcon
