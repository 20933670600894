import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/import_history/import_history'
import type {
  ImportHistoryParams,
  ImportHistoryResponse,
  ImportHistoryDownLoadRequestBody,
} from 'api/import_history/types'
import { handleApiError } from 'api/utils'

import { showError } from './notificationSlice'
import { downloadByURL } from './utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type ImportHistoryState = {
  importHistory?: ImportHistoryResponse
  isRequesting: boolean
  errorMessage: string
}

const initialState: ImportHistoryState = {
  importHistory: undefined,
  isRequesting: false,
  errorMessage: '',
}

const importHistorySlice = createSlice({
  name: 'importHistory',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getImportHistorySuccess: (state, action: PayloadAction<ImportHistoryResponse>) => {
      state.isRequesting = false
      state.importHistory = action.payload
    },
    requestSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const { startRequest, clearErrorMessage, apiFailure, getImportHistorySuccess, requestSuccess } =
  importHistorySlice.actions

export const getImportHistory =
  (params: ImportHistoryParams): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const response = await API.getImportHistory(params)
      dispatch(getImportHistorySuccess(response))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const importTargetTypeCsvFromHistory =
  (data: ImportHistoryDownLoadRequestBody, fileInfoId: number, fileName: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const response = await API.importHistoryUrl(data, fileInfoId)
      if (!response.downloadUrl) {
        dispatch(showError())
        dispatch(requestSuccess())
        return
      }
      try {
        await downloadByURL(response.downloadUrl, fileName)
      } catch {
        dispatch(showError())
      }
      dispatch(requestSuccess())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const selectImportHistoryStatus = (state: RootState) => ({ ...state.importHistory })

export default importHistorySlice.reducer
