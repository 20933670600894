import { floor } from 'es-toolkit/compat'
import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, CardBody } from 'reactstrap'

import { getReportAverage, selectReportsStatus } from 'slices/reportsSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import { BadgeLabel } from 'components/common'

import useBusinessTime from 'hooks/useBusinessTime'
import { useReportsQuery } from 'hooks/useReportsQuery'

import { TooltipHintIcon } from './TooltipHintIcon'

const formatProductReportValue = (value: number | null): string => {
  return value !== null ? floor(value, 1).toFixed(1).toLocaleString() : '-'
}

const calculateAveragePlanAccuracy = (
  productivity: number | null,
  accuracy: number | null,
  hours: number,
  defaultProductivity: number | null
): string => {
  if (!productivity || !accuracy || !defaultProductivity) {
    return '-'
  }
  return floor((productivity * accuracy * hours) / 100 / defaultProductivity, 1)
    .toFixed(1)
    .toLocaleString()
}

type Props = {
  workspaceId?: number
  isDailyReportUse?: boolean
  type?: string
}

export const ReportAveragePlanAccuracy = ({ workspaceId, isDailyReportUse = false }: Props) => {
  const { businessDuration } = useBusinessTime()
  const { queryStart, queryEnd } = useReportsQuery()

  const dispatch = useDispatch()
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  const { reportAverages, reportAveragesDailyReport } = useSelector(selectReportsStatus, shallowEqual)

  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const selectedData = useMemo(() => {
    return isDailyReportUse ? reportAveragesDailyReport : reportAverages
  }, [isDailyReportUse, reportAverages, reportAveragesDailyReport])

  useEffect(() => {
    // getWorkDateでbusinessStartTimeを使用するためtenant取得後にAPIを実行する
    if (!workspaceId || !tenant) {
      return
    }
    // データが未取得、または取得済みのデータと選択期間が異なる場合にAPIを実行する
    if (
      !selectedData ||
      selectedData.workspaceId !== workspaceId ||
      selectedData.from !== queryStart ||
      selectedData.to !== queryEnd
    ) {
      dispatch(getReportAverage(workspaceId, queryStart, queryEnd, isDailyReportUse))
      dispatch(getScheduleTypeList(Number(workspaceId)))
    }
  }, [
    dispatch,
    workspaceId,
    tenant,
    isDailyReportUse,
    reportAveragesDailyReport,
    reportAverages,
    selectedData,
    queryStart,
    queryEnd,
  ])

  const reportAverageData = useMemo(() => selectedData?.data ?? [], [selectedData])

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="fw-bold">指定期間のサマリー</div>
        </div>

        <Row md={3} className="g-3">
          {reportAverageData.map(reportAverage => (
            <Col key={`report-card-col-${reportAverage.scheduleTypeId}`}>
              <Card>
                <CardBody>
                  <div className="d-flex mb-3">
                    <BadgeLabel label={reportAverage.scheduleTypeName} color={reportAverage.scheduleTypeColor} />
                  </div>

                  <div className="d-flex font-x-small text-muted">
                    <div className="me-2">平均計画変更率</div>
                    <TooltipHintIcon text="アーカイブされた作業計画と最終的な作業計画の変更割合（=計画変更率）の指定された期間の平均" />
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="font-large fw-bold me-2">
                      &#177;{formatProductReportValue(reportAverage.dailyAvgPlanAccuracy)}%
                    </span>
                    /1日
                  </div>
                  <div className="font-x-small text-muted d-flex">
                    <div className="me-1">
                      &#177;
                      {calculateAveragePlanAccuracy(
                        reportAverage.hourlyAvgProductivity,
                        reportAverage.dailyAvgPlanAccuracy,
                        businessDuration / 4,
                        partialScheduleTypes.find(s => s.id === reportAverage.scheduleTypeId)?.defaultProductivity ??
                          null
                      )}
                    </div>
                    人時
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}
