import { IMPORT_HISTORY_STATUS } from 'api/import_history/constants'

export const LIMIT_PER_PAGE = 20

export const BASE_STYLE = 'd-flex align-items-center'

export const STATUS_LABELS = {
  [IMPORT_HISTORY_STATUS.SUCCESS]: {
    label: '成功',
    bgColor: 'success',
    textColor: 'white',
  },
  [IMPORT_HISTORY_STATUS.FAILED]: {
    label: '失敗',
    bgColor: 'danger',
    textColor: 'white',
  },
  [IMPORT_HISTORY_STATUS.RUNNING]: { label: '処理中', bgColor: 'gray', textColor: 'white' },
  [IMPORT_HISTORY_STATUS.SKIP]: { label: '一部成功', bgColor: 'warning', textColor: 'black' },
}
