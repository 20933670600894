import dayjs from 'dayjs'
import { useMemo, useState, useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Input, Label } from 'reactstrap'

import { selectReportsStatus } from 'slices/reportsSlice'

import { NavMenu, BadgeLabel, CustomButton, PivotItem, PivotOuterIndex } from 'components/common'
import { PeriodSelect } from 'components/common/PeriodSelect/PeriodSelect'

import { useQuery } from 'hooks/useQuery'
import { useReportsQuery } from 'hooks/useReportsQuery'
import useWorkspaceSideBar from 'hooks/useWorkspaceSideBar'

import { BadgeLabelForReports } from './BadgeLabelForReports'
import CsvExportDialog from './CsvExportDialog'
import { ReportAveragePlanAccuracy } from './ReportAveragePlanAccuracy'
import { ReportAverageProductivity } from './ReportAverageProductivity'
import { ReportPlanAccuracy } from './ReportPlanAccuracy'
import ReportProductivity from './ReportProductivity'
import { TAB_TYPES } from './utils'

import type { TabType } from './utils'

const Reports = () => {
  const [openCsvExportDialog, setOpenCsvExportDialog] = useState(false)

  const navigate = useNavigate()
  const { search } = useLocation()

  const { reportAverages, reportAveragesDailyReport } = useSelector(selectReportsStatus, shallowEqual)

  const { workspaceId, workspaceName } = useWorkspaceSideBar('/reports')
  const { queryStart, queryEnd } = useReportsQuery()
  const [queryTab] = useQuery('tab', TAB_TYPES.PRODUCTIVITY)
  const [isDataSourceOpen, setIsDataSourceOpen] = useQuery<boolean>('data-source', false)
  const [isDailyReportUse, setIsDailyReportUse] = useQuery<boolean>('daily-report', false)

  const selectedData = useMemo(() => {
    return isDailyReportUse ? reportAveragesDailyReport : reportAverages
  }, [isDailyReportUse, reportAverages, reportAveragesDailyReport])

  const tabs = useMemo(
    () => [
      {
        header: '生産性',
        key: TAB_TYPES.PRODUCTIVITY,
        component: (
          <>
            <ReportAverageProductivity workspaceId={workspaceId} isDailyReportUse={isDailyReportUse} />
            <ReportProductivity workspaceId={workspaceId} isDailyReportUse={isDailyReportUse} />
          </>
        ),
      },
      {
        header: '作業計画変更率',
        key: TAB_TYPES.PLAN_ACCURACY,
        component: (
          <>
            <ReportAveragePlanAccuracy workspaceId={workspaceId} isDailyReportUse={isDailyReportUse} />
            <ReportPlanAccuracy workspaceId={workspaceId} isDailyReportUse={isDailyReportUse} />
          </>
        ),
      },
    ],
    [workspaceId, isDailyReportUse]
  )

  const handleDataSourceChange = useCallback(
    (dataSource: boolean) => {
      setIsDataSourceOpen(dataSource)
    },
    [setIsDataSourceOpen]
  )

  const handleDailyReportChange = useCallback(
    (dailyReport: boolean) => {
      setIsDailyReportUse(dailyReport)
    },
    [setIsDailyReportUse]
  )

  const handleTabChange = useCallback(
    (key: TabType) => {
      const params = new URLSearchParams(search)
      params.delete('page')
      params.set('tab', key)
      navigate(`?${params.toString()}`, { replace: true })
    },
    [search, navigate]
  )

  const handlePeriodChange = useCallback(
    (start: Date, end: Date) => {
      const params = new URLSearchParams(search)
      params.delete('page')
      params.set('dateRange', `${dayjs(start).format('YYYY-MM-DD')}:${dayjs(end).format('YYYY-MM-DD')}`)
      navigate(`?${params.toString()}`, { replace: true })
    },
    [navigate, search]
  )

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <div className="font-x-large fw-bold">業務レポート</div>
            <div className="px-2 align-self-center">
              <BadgeLabel label={workspaceName} />
            </div>
          </div>
          <div className="d-flex ms-auto">
            <CustomButton className="me-2" outline onClick={() => setOpenCsvExportDialog(true)}>
              CSVエクスポート
            </CustomButton>
            <PeriodSelect
              period={{ start: dayjs(queryStart).toDate(), end: dayjs(queryEnd).toDate() }}
              onChange={(start, end) => handlePeriodChange(start, end)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <Input
              className="form-check-input"
              id="use-daily-report"
              checked={isDailyReportUse}
              type="checkbox"
              onChange={e => handleDailyReportChange(e.target.checked)}
            />
            <Label className="form-check-label mb-0" for="use-daily-report">
              日報実績を利用
            </Label>
          </div>

          <i
            className={`icf-carot_${isDataSourceOpen ? 'down' : 'right'} text-gray`}
            role="button"
            onClick={() => handleDataSourceChange(!isDataSourceOpen)}
          >
            データソースを表示
          </i>
        </div>

        {isDataSourceOpen && (
          <div className="d-flex flex-wrap my-3 gap-2">
            {selectedData?.data.map(reportAverage => (
              <BadgeLabelForReports
                key={`scheduleType-badges-${reportAverage.scheduleTypeId}`}
                label={reportAverage.scheduleTypeName}
                isDailyReportUse={isDailyReportUse ?? false}
              />
            ))}
          </div>
        )}

        <PivotOuterIndex
          selectedIndex={tabs.findIndex(tab => tab.key === queryTab)}
          onChange={index => handleTabChange(tabs[index].key)}
        >
          {tabs.map(({ header, key, component }) => (
            <PivotItem headerText={header} key={key}>
              {component}
            </PivotItem>
          ))}
        </PivotOuterIndex>
        <CsvExportDialog
          key={openCsvExportDialog.toString()}
          open={openCsvExportDialog}
          setOpen={setOpenCsvExportDialog}
          currentWorkspaceId={workspaceId}
        />
      </div>
    </NavMenu>
  )
}

export default Reports
