import moment from 'moment'
import { useCallback, useMemo } from 'react'

import { DateChangeButton } from 'components/common'

import useDateChange from 'hooks/useDateChange'
import useDateQuery from 'hooks/useDateQuery'

type Props = {
  onChange: (date: string) => void
}
const DashboardDateChangeButton = ({ onChange }: Props) => {
  const date = useDateQuery()
  const { createDateFilter } = useDateChange()

  const filterDate = useMemo(() => createDateFilter({ fromDays: -425 }), [createDateFilter])

  const onChangeDate = useCallback((newDate: Date) => onChange(moment(newDate).format('YYYY-MM-DD')), [onChange])

  return (
    <div>
      <DateChangeButton date={date} popupPosition="bottom right" filterDate={filterDate} onChange={onChangeDate} />
    </div>
  )
}
export default DashboardDateChangeButton
