import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Input, Button, Label } from 'reactstrap'

import { EXPORT_DATA_TYPES } from 'api/reports/constants'
import type { ExportDataType } from 'api/reports/types'

import { exportReport } from 'slices/reportsSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { CustomModal, SingleDateRangePicker, ItemEdit } from 'components/common'
import SelectBox from 'components/common/FormFormat/SelectBox'
import type { Item } from 'components/common/FormFormat/SelectBox'
import type { SuggestionItem } from 'components/common/types'

import useBusinessTime from 'hooks/useBusinessTime'

type Props = {
  open: boolean
  setOpen: (prop: boolean) => void
  currentWorkspaceId: number | undefined
}

type ExportDataItemSelectType = Item<ExportDataType>

type ExportDataItemType = ExportDataItemSelectType & {
  showProductivityCheckbox: boolean | undefined
  showRecordCheckbox: boolean | undefined
}

const DATE_PICKER_MAX_DAYS = 31

const exportDataItem: ExportDataItemType[] = [
  {
    key: EXPORT_DATA_TYPES.PLAN_AND_RECORD,
    value: '作業計画 + 作業実績',
    showProductivityCheckbox: true,
    showRecordCheckbox: undefined,
  },
  {
    key: EXPORT_DATA_TYPES.DAILY_REPORT_AND_RECORD,
    value: '日報実績 + 作業実績',
    showProductivityCheckbox: true,
    showRecordCheckbox: undefined,
  },
  {
    key: EXPORT_DATA_TYPES.PLAN_DETAIL,
    value: '作業計画 (詳細)',
    showProductivityCheckbox: true,
    showRecordCheckbox: true,
  },
  {
    key: EXPORT_DATA_TYPES.DAILY_REPORT_DETAIL,
    value: '日報実績 (詳細)',
    showProductivityCheckbox: true,
    showRecordCheckbox: true,
  },
  {
    key: EXPORT_DATA_TYPES.PLAN_WORKER_COUNTS,
    value: '時間別配置人数 (作業計画)',
    showProductivityCheckbox: undefined,
    showRecordCheckbox: undefined,
  },
  {
    key: EXPORT_DATA_TYPES.DAILY_REPORT_WORKER_COUNTS,
    value: '時間別配置人数 (日報実績)',
    showProductivityCheckbox: undefined,
    showRecordCheckbox: undefined,
  },
]

const onDetailClick = () => window.open('https://help.smileboard.jp/export-to-csv', '_blank')

const CsvExportDialog = ({ open, setOpen, currentWorkspaceId }: Props) => {
  const [includeProductivityValue, setIncludeProductivityValue] = useState<boolean | undefined>(true)
  const [includeRecord, setIncludeRecord] = useState<boolean | undefined>(true)
  const [openRangeDatePicker, setOpenRangeDatePicker] = useState(false)
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<SuggestionItem[]>([])
  const [period, setPeriod] = useState<{ start: Date; end: Date }>()
  const [selectedExportDataType, setSelectedExportDataType] = useState(exportDataItem[0])
  const dispatch = useDispatch()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { getWorkDate } = useBusinessTime()

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  // ダイアログを開くたびに、設定を初期化する
  useEffect(() => {
    if (!currentWorkspaceId) {
      return
    }
    const target = partialWorkspaces.find(workspace => workspace.id === currentWorkspaceId)
    if (target) {
      setSelectedWorkspaces([{ id: currentWorkspaceId, value: target.name }])
    }
  }, [currentWorkspaceId, partialWorkspaces])

  useEffect(() => {
    setIncludeProductivityValue(selectedExportDataType.showProductivityCheckbox)
    setIncludeRecord(selectedExportDataType.showRecordCheckbox)
  }, [selectedExportDataType])

  const handleApprove = useCallback(() => {
    if (!period || selectedWorkspaces.length === 0) {
      return
    }

    if (selectedExportDataType.key === undefined) {
      return
    }

    const targetWorkspaces: number[] = selectedWorkspaces.map(w => (typeof w.id === 'number' ? w.id : Number(w.id)))
    const filename = `業務レポート（作業計画${selectedExportDataType.key === exportDataItem[0].key ? '＋実績' : ''}）_${dayjs().format('YYYY-MM-DD')}.csv`
    const data = {
      startDate: dayjs(period.start).format('YYYY-MM-DD'),
      endDate: dayjs(period.end).format('YYYY-MM-DD'),
      targetWorkspaces,
      includeProductivityValue,
      includeRecord,
      exportDataType: selectedExportDataType.key,
    }
    dispatch(exportReport(data, filename))
    setOpen(false)
  }, [
    dispatch,
    includeProductivityValue,
    includeRecord,
    period,
    selectedExportDataType.key,
    selectedWorkspaces,
    setOpen,
  ])

  const disabled = useMemo(() => !period || selectedWorkspaces.length === 0, [period, selectedWorkspaces])

  const items = useMemo(() => partialWorkspaces.map(w => ({ id: w.id, value: w.name })), [partialWorkspaces])

  const handleExportDataTypeSelect = useCallback((item: ExportDataItemSelectType) => {
    const matchedItem = exportDataItem.find(data => data.key === item.key)
    if (!matchedItem) {
      return
    }
    setSelectedExportDataType(matchedItem)
  }, [])

  return (
    <div>
      <CustomModal
        isOpen={open}
        title="CSVエクスポート"
        onCancel={() => setOpen(false)}
        onHideNotification={() => {}}
        approveLabel="CSVエクスポート"
        onApprove={handleApprove}
        approveDisabled={disabled}
        submitName="csv-export-dialog-submit"
      >
        <div className="ms-2">
          <div className="mb-4">
            <div className="my-2 d-flex justify-content-between align-items-center">
              <div className="fw-bold">エクスポートデータ選択</div>
              <Button color="link" className="text-decoration-none" onClick={onDetailClick}>
                エクスポートデータについて
              </Button>
            </div>
            <SelectBox
              id="export-data-type"
              value={selectedExportDataType.value}
              items={exportDataItem}
              onSelect={handleExportDataTypeSelect}
              className="w-50 mb-4"
            />
            <div className="my-2 fw-bold">ワークスペース選択</div>
            <div className="mb-2">他ワークスペースもまとめてエクスポートできます｡ワークスペースを選択してください｡</div>
            <ItemEdit
              items={items}
              selectedItems={selectedWorkspaces}
              label="ワークスペースを追加"
              itemName="ワークスペース"
              onChange={setSelectedWorkspaces}
              showAddAllItemsButton
            />
          </div>
          <div className="mb-4">
            <div className="my-2 fw-bold">エクスポート期間設定</div>
            <div className="mb-2">最大31日分エクスポートが可能です｡</div>
            <div className="d-flex">
              <Button outline onClick={() => setOpenRangeDatePicker(true)}>
                {period
                  ? `${dayjs(period.start).format('YYYY/MM/DD')} - ${dayjs(period.end).format('YYYY/MM/DD')}`
                  : '開始日と終了日を決定'}
              </Button>
              <SingleDateRangePicker
                isOpen={openRangeDatePicker}
                from={period && period.start}
                to={period && period.end}
                maxRange={DATE_PICKER_MAX_DAYS}
                maxDate={dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')))
                  .subtract(1, 'days')
                  .toDate()}
                onCancel={() => setOpenRangeDatePicker(false)}
                onChange={(start, end) => {
                  setPeriod({ start, end })
                  setOpenRangeDatePicker(false)
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            {selectedExportDataType.showProductivityCheckbox && (
              <>
                <div className="my-2 fw-bold">その他の設定</div>
                <div className="form-check">
                  <Input
                    id="include-productivity-value"
                    className="form-check-input"
                    checked={includeProductivityValue}
                    type="checkbox"
                    onChange={e => setIncludeProductivityValue(e.target.checked)}
                  />
                  <Label className="form-check-label" for="include-productivity-value">
                    予測に生産性調整を含む
                  </Label>
                </div>
              </>
            )}

            {selectedExportDataType.showRecordCheckbox && (
              <div className="form-check">
                <Input
                  id="include-record"
                  className="form-check-input"
                  checked={includeRecord}
                  type="checkbox"
                  onChange={e => setIncludeRecord(e.target.checked)}
                />
                <Label className="check-form-label" for="include-record">
                  作業予定時間内の実績例を含む
                </Label>
              </div>
            )}
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default CsvExportDialog
