import { isEqual } from 'es-toolkit'
import { useState, useCallback, useMemo, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import type { DailyReportsImportSettingsListData, EditDailyReportsImportSettingsProps } from 'api/daily_reports/types'

import {
  getDailyReportsImportSettingsList,
  getDailyReportsImportSettings,
  createDailyReportsImportSettings,
  updateDailyReportsImportSettings,
  selectDailyReportsStatus,
} from 'slices/dailyReportsSlice'

import { CHARACTER_CODE_TYPES } from 'components/common/CharacterCodeSelect/CharacterCodeSelect'
import ListDetailView from 'components/common/ListDetailView/ListDetailVIew'
import { pickByRecursive } from 'components/common/utils'

import { DailyReportSettingsDelete } from './Settings/DailyReportSettingsDelete'
import { DailyReportSettingsDetail } from './Settings/DailyReportSettingsDetail'

const handleDetailClick = () => window.open('https://help.smileboard.jp/import-work-reports', '_blank')

const NEW_DAILY_REPORT_ID = 0
const MAX_ITEMS = 10

const initDailyReportsImportSettings = {
  name: '',
  encoding: CHARACTER_CODE_TYPES.SHIFT_JIS,
  settings: {
    workDate: '',
    wmsMemberId: '',
    startTime: '',
    endTime: '',
    scheduleType: '',
  },
  workspaces: [],
}

export const DailyReportSettings = () => {
  const [disabled, setDisabled] = useState(true)
  const [openDelete, setOpenDelete] = useState(false)
  const [editData, setEditData] = useState<EditDailyReportsImportSettingsProps>(initDailyReportsImportSettings)
  const [initEditData, setInitEditData] = useState<EditDailyReportsImportSettingsProps>(initDailyReportsImportSettings)
  const [selectedDailyReportId, setSelectedDailyReportId] = useState<number>()

  const { dailyReportsImportSettingsList, dailyReportsImportSettings, isRequesting, errorMessage } = useSelector(
    selectDailyReportsStatus,
    shallowEqual
  )

  const dispatch = useDispatch()

  const listItems = useMemo(
    () =>
      dailyReportsImportSettingsList.map(({ id, name }) => ({
        id,
        title: name,
      })),
    [dailyReportsImportSettingsList]
  )

  useEffect(() => {
    if (!dailyReportsImportSettings) {
      return
    }

    // workspacesにchecked=trueを追加して、editDataにセット
    const updatedData = {
      ...dailyReportsImportSettings,
      workspaces: dailyReportsImportSettings.workspaces.map(ws => ({
        ...ws,
        checked: true,
      })),
    }

    setInitEditData(updatedData)
    setEditData(updatedData)
  }, [dailyReportsImportSettings])

  const handleNewItemAdd = useCallback(() => {
    setEditData(initDailyReportsImportSettings)
  }, [])

  const handleItemSelect = useCallback(
    (id: number) => {
      dispatch(getDailyReportsImportSettings(id))
    },
    [dispatch]
  )

  const handleCancel = useCallback(() => {
    setEditData(initEditData)
  }, [initEditData])

  const handleSubmit = useCallback(() => {
    // selectedDailyReportId === 0は新規作成のため、ゼロを許容する。
    if (selectedDailyReportId === undefined) {
      return
    }

    const workspaces = editData.workspaces.map(workspace => ({
      id: workspace.id,
      scheduleTypes: workspace.scheduleTypes.map(scheduleType => ({
        id: scheduleType.id,
        header: scheduleType.header,
      })),
    }))

    const requestBody = { ...editData, workspaces }

    if (selectedDailyReportId === NEW_DAILY_REPORT_ID) {
      dispatch(createDailyReportsImportSettings(requestBody))
      return
    }

    const updateRequestBody = pickByRecursive(requestBody, initEditData, editData)
    dispatch(updateDailyReportsImportSettings(selectedDailyReportId, updateRequestBody))
  }, [selectedDailyReportId, editData, initEditData, dispatch])

  const listFunc = useCallback(
    (callback: (newList: DailyReportsImportSettingsListData[]) => void) => getDailyReportsImportSettingsList(callback),
    []
  )

  const detailFunc = useCallback((id: number) => getDailyReportsImportSettings(id), [])

  const deleteDialog = useCallback(
    (onSuccess: () => void) => {
      if (!selectedDailyReportId) {
        return
      }
      return (
        <DailyReportSettingsDelete
          isOpen={openDelete}
          dailyReportId={selectedDailyReportId}
          onSuccess={() => {
            onSuccess()
            setOpenDelete(false)
          }}
          onCancel={() => setOpenDelete(false)}
        />
      )
    },
    [openDelete, selectedDailyReportId]
  )

  const newListItem = useMemo(() => ({ title: '未保存の項目', id: NEW_DAILY_REPORT_ID }), [])

  const handleValidate = useCallback((isDisabled: boolean) => {
    setDisabled(isDisabled)
  }, [])

  const unchanged = useMemo(() => isEqual(initEditData, editData), [initEditData, editData])

  const handleChange = useCallback(
    <K extends keyof EditDailyReportsImportSettingsProps>(key: K, value: EditDailyReportsImportSettingsProps[K]) => {
      setEditData(prev => ({ ...prev, [key]: value }))
    },
    []
  )

  const handleDeleteButtonClick = useCallback(() => {
    setOpenDelete(true)
  }, [])

  return (
    <ListDetailView
      addButton={{ onClick: handleNewItemAdd, label: '日報実績インポート設定' }}
      maxItems={MAX_ITEMS}
      listItems={listItems}
      onSelectItem={handleItemSelect}
      newListItem={newListItem}
      emptyList={{
        title: '日報実績インポート設定がまだ登録されていません',
        text: 'まずは最初の日報実績インポート設定を登録してみましょう。',
        buttonLabel: '日報実績インポート設定についてもっと詳しく',
        onClick: handleDetailClick,
      }}
      emptyDetail={{
        title: '日報実績インポート設定が選択されていません',
        text: '日報実績インポート設定を選択して、詳細情報を編集しましょう。',
      }}
      footer={{
        onCancel: handleCancel,
        onSubmit: handleSubmit,
        updatedAt: dailyReportsImportSettings?.updatedAt,
        updatedBy: dailyReportsImportSettings?.updatedByName,
        cancelDisabled: unchanged,
        submitDisabled: disabled || unchanged,
      }}
      isRequesting={isRequesting}
      errorMessage={errorMessage}
      selectedId={selectedDailyReportId}
      setSelectedId={setSelectedDailyReportId}
      newItemId={NEW_DAILY_REPORT_ID}
      getListFunc={listFunc}
      getDetailFunc={detailFunc}
      deleteDialog={deleteDialog}
      storeId={dailyReportsImportSettings?.id}
    >
      <DailyReportSettingsDetail
        editData={editData}
        onChange={handleChange}
        onDeleteButtonClick={handleDeleteButtonClick}
        disabledDeleteButton={selectedDailyReportId === NEW_DAILY_REPORT_ID}
        onValidate={handleValidate}
      />
    </ListDetailView>
  )
}
