import { floor } from 'es-toolkit/compat'
import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, CardBody, Table } from 'reactstrap'

import { getReportAverage, selectReportsStatus } from 'slices/reportsSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import { BadgeLabel } from 'components/common'

import { useReportsQuery } from 'hooks/useReportsQuery'

import { TooltipHintIcon } from './TooltipHintIcon'

type Props = {
  workspaceId?: number
  isDailyReportUse?: boolean
}

const ProductivityRow = ({ label, value, unit }: { label: string; value: number | null; unit: string }) => {
  return (
    <tr>
      <td className="text-muted pb-1">{label}</td>
      <td className="pb-1" align="right">
        {value !== null ? Math.floor(value).toLocaleString() : '-'}
      </td>
      <td className="text-muted font-x-small align-bottom ps-0 pb-1" align="left">
        {unit}
      </td>
    </tr>
  )
}

export const ReportAverageProductivity = ({ workspaceId, isDailyReportUse = false }: Props) => {
  const dispatch = useDispatch()
  const { queryStart, queryEnd } = useReportsQuery()

  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { reportAverages, reportAveragesDailyReport } = useSelector(selectReportsStatus, shallowEqual)

  const selectedData = useMemo(() => {
    return isDailyReportUse ? reportAveragesDailyReport : reportAverages
  }, [isDailyReportUse, reportAverages, reportAveragesDailyReport])

  useEffect(() => {
    if (!workspaceId || !tenant) {
      return
    }
    // getWorkDateでbusinessStartTimeを使用するためtenant取得後にAPIを実行する
    if (
      !selectedData ||
      selectedData.workspaceId !== workspaceId ||
      selectedData.from !== queryStart ||
      selectedData.to !== queryEnd
    ) {
      // データが未取得、または取得済みのデータと選択期間が異なる場合にAPIを実行する
      dispatch(getReportAverage(workspaceId, queryStart, queryEnd, isDailyReportUse))
    }
  }, [
    dispatch,
    workspaceId,
    tenant,
    isDailyReportUse,
    reportAveragesDailyReport,
    reportAverages,
    selectedData,
    queryStart,
    queryEnd,
  ])

  const reportAverageData = useMemo(() => selectedData?.data ?? [], [selectedData])

  const getArrow = (ratio: number) => {
    const arrow = ratio > 0 ? '↗︎' : '↘︎'
    return <div className="fw-bold">{arrow}</div>
  }

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="fw-bold">指定期間のサマリー</div>
        </div>

        <Row md={3} className="g-3">
          {reportAverageData.map(reportAverage => (
            <Col key={`report-card-col-${reportAverage.scheduleTypeId}`}>
              <Card>
                <CardBody className="d-flex">
                  <BadgeLabel label={reportAverage.scheduleTypeName} color={reportAverage.scheduleTypeColor} />
                </CardBody>
                <CardBody className="pt-0">
                  <div className="font-x-small text-muted d-flex">
                    <div className="me-1">生産性</div>
                    <TooltipHintIcon text="指定された期間の実績の合計を作業計画で入力された予定の合計で割った数値" />
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="font-large fw-bold me-2">
                      {reportAverage.hourlyAvgProductivity !== null
                        ? floor(reportAverage.hourlyAvgProductivity, 1).toLocaleString()
                        : '-'}
                      {reportAverage.unit}
                    </span>
                    /1時間
                    <div
                      className={`${reportAverage.avgProductivityRatio >= 0 ? 'text-success' : 'text-danger'} d-flex`}
                    >
                      <div className="mx-2">
                        {reportAverage.avgProductivityRatio !== 0 && getArrow(reportAverage.avgProductivityRatio)}
                      </div>
                      {floor(reportAverage.avgProductivityRatio, 1).toFixed(1)}%
                    </div>
                  </div>

                  <Table className="mb-0">
                    <tbody>
                      <ProductivityRow
                        label="指定期間の作業実績平均"
                        value={reportAverage.estimateHourlyAvgProductivity}
                        unit={`${reportAverage.unit} / 1時間`}
                      />
                      <ProductivityRow
                        label="指定期間の作業実績合計"
                        value={reportAverage.totalRecordCount}
                        unit={reportAverage.unit}
                      />

                      <ProductivityRow
                        label="指定期間の投入人時合計"
                        value={reportAverage.totalPlanCount}
                        unit="人時"
                      />
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}
