import dayjs from 'dayjs'
import { sortBy } from 'es-toolkit'
import { floor } from 'es-toolkit/compat'
import { useCallback, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import type { BopWorkspaceData } from 'api/dashboard/types'

import { selectBopReportsStatus } from 'slices/bopReportsSlice'
import { selectDashboardStatus } from 'slices/dashboardSlice'

import { BOP_TYPE, ESTIMATE_POINT_PADDING } from 'components/Dashboard/utils'
import { DEFAULT_POINT_PADDING } from 'components/common/Chart/Chart'
import { createStackedChartOptions } from 'components/common/utils'

import type { Options, SeriesOptionsType } from 'highcharts'

const BOP_LABOR_COSTS_GRAPH_TYPE = {
  VARIABLE_DIRECT: 'variableDirectLaborCosts',
  VARIABLE_INDIRECT: 'variableIndirectLaborCosts',
  INDIRECT: 'indirectLaborCosts',
  VARIABLE_DIRECT_ESTIMATE: 'variableDirectLaborCostsEstimate',
  VARIABLE_INDIRECT_ESTIMATE: 'variableIndirectLaborCostsEstimate',
  INDIRECT_ESTIMATE: 'indirectLaborCostsEstimate',
  LABOR_COSTS_PERCENTAGE: 'laborCostsPercentage',
  LABOR_COSTS_PERCENTAGE_ESTIMATE: 'laborCostsPercentageEstimate',
} as const

type LaborCostsDataUnit = {
  variableDirectLaborCosts: number
  variableIndirectLaborCosts: number
  indirectLaborCosts: number
}

type BopLaborCostsDataProps = {
  data: LaborCostsDataUnit[]
  estimate?: LaborCostsDataUnit[]
}

type graphSeriesData = {
  series: SeriesOptionsType[]
  seriesPercentage: SeriesOptionsType[]
}

const LABOR_COSTS_PERCENTAGE_GRAPH_Y_AXIS_MAX = 100
const ESTIMATE_OPACITY = 0.4
const SERIES_Z_INDEX = {
  ACTUAL: 1,
  ESTIMATE: 0,
} as const
const ACTUAL_LABOR_COSTS_INDEX = {
  VARIABLE_DIRECT_INDEX: 1,
  VARIABLE_INDIRECT_INDEX: 3,
  INDIRECT_INDEX: 5,
} as const
const POINT_PLACEMENT = {
  PERCENTAGE: 0.15,
  YEN: 0.05,
} as const
const ROUNDING_PRECISION = {
  PERCENTAGE: 2,
  YEN: 0,
} as const
const TOOLTIP_TEXT_UNIT = {
  PERCENTAGE: '%',
  YEN: '円',
} as const

const calLaborCostsPercentage = (value: number, costs: LaborCostsDataUnit) => {
  const totalLaborCosts = costs.variableDirectLaborCosts + costs.variableIndirectLaborCosts + costs.indirectLaborCosts
  return totalLaborCosts ? (value / totalLaborCosts) * 100 : 0
}

export const useBopLaborCosts = (selectedBopType: string, isPercentage: boolean) => {
  const { bopMonitoring } = useSelector(selectDashboardStatus, shallowEqual)
  const { bopReportsLaborCosts } = useSelector(selectBopReportsStatus, shallowEqual)

  const formatTooltipLaborCostsText = useCallback(
    (laborCosts: number) => {
      const tooltipTextUnit = isPercentage ? TOOLTIP_TEXT_UNIT.PERCENTAGE : TOOLTIP_TEXT_UNIT.YEN
      const tooltipRoundingPrecision = isPercentage ? ROUNDING_PRECISION.PERCENTAGE : ROUNDING_PRECISION.YEN
      return `${Number(floor(laborCosts, tooltipRoundingPrecision)).toLocaleString()}${tooltipTextUnit}`
    },
    [isPercentage]
  )

  const getGraphSeriesData = useCallback((graphData: BopLaborCostsDataProps): graphSeriesData => {
    // 実数表示
    const variableDirectData = graphData.data.map(item => item.variableDirectLaborCosts)
    const variableIndirectData = graphData.data.map(item => item.variableIndirectLaborCosts)
    const indirectData = graphData.data.map(item => item.indirectLaborCosts)
    // 割合表示
    const variableDirectPercentageData = graphData.data.map(item =>
      calLaborCostsPercentage(item.variableDirectLaborCosts, item)
    )
    const variableIndirectPercentageData = graphData.data.map(item =>
      calLaborCostsPercentage(item.variableIndirectLaborCosts, item)
    )
    const indirectPercentageData = graphData.data.map(item => calLaborCostsPercentage(item.indirectLaborCosts, item))

    const variableDirectSeriesData = variableDirectData.map((item, index) => ({
      y: item,
      custom: {
        type: BOP_LABOR_COSTS_GRAPH_TYPE.VARIABLE_DIRECT,
        variableDirectLaborCosts: item,
        variableIndirectLaborCosts: variableIndirectData[index],
        indirectLaborCosts: indirectData[index],
      },
    }))
    const variableIndirectSeriesData = variableIndirectData.map((item, index) => ({
      y: item,
      custom: {
        type: BOP_LABOR_COSTS_GRAPH_TYPE.VARIABLE_INDIRECT,
        variableDirectLaborCosts: variableDirectData[index],
        variableIndirectLaborCosts: item,
        indirectLaborCosts: indirectData[index],
      },
    }))
    const indirectSeriesData = indirectData.map((item, index) => ({
      y: item,
      custom: {
        type: BOP_LABOR_COSTS_GRAPH_TYPE.INDIRECT,
        variableDirectLaborCosts: variableDirectData[index],
        variableIndirectLaborCosts: variableIndirectData[index],
        indirectLaborCosts: item,
      },
    }))

    const variableDirectPercentageSeriesData = variableDirectPercentageData.map((item, index) => {
      return {
        y: item,
        custom: {
          type: BOP_LABOR_COSTS_GRAPH_TYPE.LABOR_COSTS_PERCENTAGE,
          variableDirectLaborCosts: item,
          variableIndirectLaborCosts: variableIndirectPercentageData[index],
          indirectLaborCosts: indirectPercentageData[index],
        },
      }
    })

    const variableIndirectPercentageSeriesData = variableIndirectPercentageData.map((item, index) => {
      return {
        y: item,
        custom: {
          type: BOP_LABOR_COSTS_GRAPH_TYPE.LABOR_COSTS_PERCENTAGE,
          variableDirectLaborCosts: variableDirectPercentageData[index],
          variableIndirectLaborCosts: item,
          indirectLaborCosts: indirectPercentageData[index],
        },
      }
    })

    const indirectPercentageSeriesData = indirectPercentageData.map((item, index) => {
      return {
        y: item,
        custom: {
          type: BOP_LABOR_COSTS_GRAPH_TYPE.LABOR_COSTS_PERCENTAGE,
          variableDirectLaborCosts: variableDirectPercentageData[index],
          variableIndirectLaborCosts: variableIndirectPercentageData[index],
          indirectLaborCosts: item,
        },
      }
    })

    const pointPlacement = graphData.estimate ? POINT_PLACEMENT.YEN : undefined
    const pointPlacementPercentage = graphData.estimate ? POINT_PLACEMENT.PERCENTAGE : undefined

    const series: SeriesOptionsType[] = [
      {
        type: 'column',
        name: '変動直接労務費',
        color: 'var(--bs-danger-stronger-middle)',
        data: variableDirectSeriesData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.VARIABLE_DIRECT,
        pointPlacement: pointPlacement,
        zIndex: SERIES_Z_INDEX.ACTUAL,
      },
      {
        type: 'column',
        name: '変動間接労務費',
        color: 'var(--bs-danger-middle)',
        data: variableIndirectSeriesData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.VARIABLE_INDIRECT,
        pointPlacement: pointPlacement,
        zIndex: SERIES_Z_INDEX.ACTUAL,
      },
      {
        type: 'column',
        name: '間接労務費',
        color: 'var(--bs-danger-pale)',
        data: indirectSeriesData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.INDIRECT,
        pointPlacement: pointPlacement,
        zIndex: SERIES_Z_INDEX.ACTUAL,
      },
    ]

    const seriesPercentage: SeriesOptionsType[] = [
      {
        type: 'column',
        name: '変動直接労務費',
        color: 'var(--bs-danger-stronger-middle)',
        data: variableDirectPercentageSeriesData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.LABOR_COSTS_PERCENTAGE,
        pointPlacement: pointPlacementPercentage,
        zIndex: SERIES_Z_INDEX.ACTUAL,
      },
      {
        type: 'column',
        name: '変動間接労務費',
        color: 'var(--bs-danger-middle)',
        data: variableIndirectPercentageSeriesData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.LABOR_COSTS_PERCENTAGE,
        pointPlacement: pointPlacementPercentage,
        zIndex: SERIES_Z_INDEX.ACTUAL,
      },
      {
        type: 'column',
        name: '変動労務費',
        color: 'var(--bs-danger-pale)',
        data: indirectPercentageSeriesData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.LABOR_COSTS_PERCENTAGE,
        pointPlacement: pointPlacementPercentage,
        zIndex: SERIES_Z_INDEX.ACTUAL,
      },
    ]

    if (graphData.estimate) {
      const estimateVariableDirectData = graphData.estimate.map(item => item.variableDirectLaborCosts)
      const estimateVariableIndirectData = graphData.estimate.map(item => item.variableIndirectLaborCosts)
      const estimateIndirectData = graphData.estimate.map(item => item.indirectLaborCosts)
      const estimateVariableDirectPercentageData = graphData.estimate.map(item =>
        calLaborCostsPercentage(item.variableDirectLaborCosts, item)
      )
      const estimateVariableIndirectPercentageData = graphData.estimate.map(item =>
        calLaborCostsPercentage(item.variableIndirectLaborCosts, item)
      )
      const estimateIndirectPercentageData = graphData.estimate.map(item =>
        calLaborCostsPercentage(item.indirectLaborCosts, item)
      )

      series.splice(ACTUAL_LABOR_COSTS_INDEX.VARIABLE_DIRECT_INDEX, 0, {
        type: 'column',
        name: '変動直接労務費(見込み)',
        color: 'var(--bs-danger-stronger-middle)',
        data: estimateVariableDirectData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.VARIABLE_DIRECT_ESTIMATE,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacement!,
        zIndex: SERIES_Z_INDEX.ESTIMATE,
      })

      series.splice(ACTUAL_LABOR_COSTS_INDEX.INDIRECT_INDEX, 0, {
        type: 'column',
        name: '変動間接労務費(見込み)',
        color: 'var(--bs-danger-middle)',
        data: estimateVariableIndirectData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.VARIABLE_INDIRECT_ESTIMATE,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacement!,
        zIndex: SERIES_Z_INDEX.ESTIMATE,
      })

      series.splice(ACTUAL_LABOR_COSTS_INDEX.VARIABLE_INDIRECT_INDEX, 0, {
        type: 'column',
        name: '間接労務費(見込み)',
        color: 'var(--bs-danger-pale)',
        data: estimateIndirectData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.INDIRECT_ESTIMATE,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacement!,
        zIndex: SERIES_Z_INDEX.ESTIMATE,
      })

      seriesPercentage.splice(ACTUAL_LABOR_COSTS_INDEX.VARIABLE_DIRECT_INDEX, 0, {
        type: 'column',
        name: '変動直接労務費(見込み)',
        color: 'var(--bs-danger-stronger-middle)',
        data: estimateVariableDirectPercentageData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.LABOR_COSTS_PERCENTAGE_ESTIMATE,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacementPercentage!,
        zIndex: SERIES_Z_INDEX.ESTIMATE,
      })
      seriesPercentage.splice(ACTUAL_LABOR_COSTS_INDEX.VARIABLE_INDIRECT_INDEX, 0, {
        type: 'column',
        name: '変動間接労務費(見込み)',
        color: 'var(--bs-danger-middle)',
        data: estimateVariableIndirectPercentageData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.LABOR_COSTS_PERCENTAGE_ESTIMATE,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacementPercentage!,
        zIndex: SERIES_Z_INDEX.ESTIMATE,
      })
      seriesPercentage.splice(ACTUAL_LABOR_COSTS_INDEX.INDIRECT_INDEX, 0, {
        type: 'column',
        name: '間接労務費(見込み)',
        color: 'var(--bs-danger-pale)',
        data: estimateIndirectPercentageData,
        stack: BOP_LABOR_COSTS_GRAPH_TYPE.LABOR_COSTS_PERCENTAGE_ESTIMATE,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacementPercentage!,
        zIndex: SERIES_Z_INDEX.ESTIMATE,
      })
    }

    const seriesData = {
      series: series,
      seriesPercentage: seriesPercentage,
    }
    return seriesData
  }, [])

  const defineCommonOptions = useCallback(
    (options: Options) => {
      options.tooltip!.shared = true

      options.tooltip!.positioner = function (labelWidth, labelHeight, point) {
        const tooltipX = point.plotX
        const tooltipY = this.chart.plotHeight + 20
        return {
          x: tooltipX,
          y: tooltipY,
        }
      }

      if (isPercentage) {
        options.yAxis = { ...options.yAxis, max: LABOR_COSTS_PERCENTAGE_GRAPH_Y_AXIS_MAX, title: { text: '' } }
      }
    },
    [isPercentage]
  )

  // 収支モニタリングの労務費バランスタブ用のグラフデータを作成
  const summaryLaborCostsGraphOptions = useMemo(() => {
    if (!bopMonitoring) {
      return {}
    }

    const displayData =
      selectedBopType === BOP_TYPE.ESTIMATE ? bopMonitoring.estimate.workspaces : bopMonitoring.actual.workspaces

    const sortedWorkspaces = sortBy(displayData, [
      workspace =>
        workspace.data.variableDirectLaborCosts +
        workspace.data.variableIndirectLaborCosts +
        workspace.data.indirectLaborCosts,
    ]).reverse()

    const sortedEstimateData = (
      sortedWorkspacesData: BopWorkspaceData[],
      estimateWorkspacesData: BopWorkspaceData[]
    ) => {
      return sortedWorkspacesData.map(workspace => estimateWorkspacesData.find(ew => ew.id === workspace.id))
    }

    const graphData =
      selectedBopType === BOP_TYPE.ACTUAL && bopMonitoring.estimate.workspaces
        ? {
            data: sortedWorkspaces.map(workspace => ({
              variableDirectLaborCosts: workspace.data.variableDirectLaborCosts,
              variableIndirectLaborCosts: workspace.data.variableIndirectLaborCosts,
              indirectLaborCosts: workspace.data.indirectLaborCosts,
            })),
            estimate: sortedEstimateData(sortedWorkspaces, bopMonitoring.estimate.workspaces).map(workspace => ({
              variableDirectLaborCosts: workspace!.data.variableDirectLaborCosts, // workspaceがundefinedであることはないが、sortedEstimateData関数の返り値はundefinedを許容するため、workspace!としている
              variableIndirectLaborCosts: workspace!.data.variableIndirectLaborCosts,
              indirectLaborCosts: workspace!.data.indirectLaborCosts,
            })),
          }
        : {
            data: sortedWorkspaces.map(workspace => ({
              variableDirectLaborCosts: workspace.data.variableDirectLaborCosts,
              variableIndirectLaborCosts: workspace.data.variableIndirectLaborCosts,
              indirectLaborCosts: workspace.data.indirectLaborCosts,
            })),
          }

    const seriesData = getGraphSeriesData(graphData)
    const optionProps = {
      seriesData: isPercentage ? seriesData.seriesPercentage : seriesData.series,
      categories: sortedWorkspaces.map(workspace => workspace.name),
      pointPadding: graphData.estimate ? ESTIMATE_POINT_PADDING : DEFAULT_POINT_PADDING,
    }

    const options = createStackedChartOptions(optionProps)
    defineCommonOptions(options)

    options.tooltip!.formatter = function () {
      if (!this.point.options.custom) {
        return false
      }
      const { variableDirectLaborCosts, variableIndirectLaborCosts, indirectLaborCosts } = this.point.options.custom
      return `
          <div style="text-align:right">
          変動直接労務費：${formatTooltipLaborCostsText(variableDirectLaborCosts)}<br>
          変動間接労務費：${formatTooltipLaborCostsText(variableIndirectLaborCosts)}<br>
          間接労務費：${formatTooltipLaborCostsText(indirectLaborCosts)}
          </div>
        `
    }

    return options
  }, [
    bopMonitoring,
    selectedBopType,
    getGraphSeriesData,
    isPercentage,
    defineCommonOptions,
    formatTooltipLaborCostsText,
  ])

  // 収支レポートの労務費バランスタブ用のグラフデータを作成
  const reportLaborCostsGraphOptions = useMemo(() => {
    if (!bopReportsLaborCosts) {
      return {}
    }

    const graphData =
      selectedBopType === BOP_TYPE.ACTUAL
        ? {
            data: bopReportsLaborCosts.actuals,
            estimate: bopReportsLaborCosts.estimates,
          }
        : {
            data: bopReportsLaborCosts.estimates,
          }
    const seriesData = getGraphSeriesData(graphData)

    // x軸用・ツールチップ用日付のフォーマット: actualsには当日を含む未来日付のデータが含まれないため、estimatesから取得する
    const formattedCategories = bopReportsLaborCosts.estimates.map(data => ({
      xAxis: dayjs(data.workDate).format('MM/DD'),
      dateInTooltip: dayjs(data.workDate).format('YYYY/MM/DD'),
    }))

    const optionProps = {
      seriesData: isPercentage ? seriesData.seriesPercentage : seriesData.series,
      categories: formattedCategories.map(category => category.xAxis), // x軸用の日付
      pointPadding: graphData.estimate ? ESTIMATE_POINT_PADDING : DEFAULT_POINT_PADDING,
    }
    const options = createStackedChartOptions(optionProps)
    defineCommonOptions(options)

    options.tooltip!.formatter = function () {
      if (!this.points || !this.point.options.custom) {
        return false
      }

      const pointIndex = this.points[0].point.index
      const date = formattedCategories[pointIndex].dateInTooltip // 同じindexの日付を取得

      const { variableDirectLaborCosts, variableIndirectLaborCosts, indirectLaborCosts } = this.point.options.custom

      return `
          <div style="text-align:right">
          ${date}<br>
          変動直接労務費：${formatTooltipLaborCostsText(variableDirectLaborCosts)}<br>
          変動間接労務費：${formatTooltipLaborCostsText(variableIndirectLaborCosts)}<br>
          間接労務費：${formatTooltipLaborCostsText(indirectLaborCosts)}
          </div>
        `
    }

    return options
  }, [
    bopReportsLaborCosts,
    selectedBopType,
    getGraphSeriesData,
    isPercentage,
    defineCommonOptions,
    formatTooltipLaborCostsText,
  ])

  return {
    summaryLaborCostsGraphOptions,
    reportLaborCostsGraphOptions,
  }
}
