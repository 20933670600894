import { InputGroupFormatWithCostsBadge, InputGroupFormatWithSalesBadge } from 'components/common'

export type CostArrayToString = {
  name: string
  cost: string
}

export type UnitCostsArrayToString = {
  scheduleTypeId: number
  scheduleTypeName: string
  unit: string
  unitCost: string
  materialCosts: CostArrayToString[]
}

export type ValidationType = {
  nameValidations?: ValidationFunction[]
  costValidations?: ValidationFunction[]
}

export const FIELD_NAMES = {
  NAME: 'name',
  COST: 'cost',
  UNIT_COST: 'unitCost',
  MATERIAL_COSTS: 'materialCosts',
} as const

type ValidationFunction = (value?: string) => string

export const BADGE_LABEL_TYPES = {
  SALES: InputGroupFormatWithSalesBadge,
  COSTS: InputGroupFormatWithCostsBadge,
} as const

export type ValidationListType = {
  first: boolean
  second: boolean
}
