import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { orderBy } from 'es-toolkit'
import { useEffect, useMemo, useState, useCallback } from 'react'
import { ModalBody, ModalFooter, Button, Card, CardBody, CardText, FormGroup, Label, Row, Col, Input } from 'reactstrap'

import SupportConfirm from 'components/SupportConfirm/SupportConfirm'
import { BadgeLabel, Modal, TimeSelect } from 'components/common'
import { TIME_INTERVAL } from 'components/common/constants'
import { toFitDuringShiftTime } from 'components/common/utils'

import useAssignment from 'hooks/useAssignment'
import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'

import WorkerCard from './WorkerCard'

import styles from './WorkerReassign.module.scss'

import type { Worker, DropdownScheduleType } from './WorkerCard'

dayjs.extend(isSameOrAfter)

type Props = {
  isOpen: boolean
  workers: Worker[]
  selected: DropdownScheduleType
  onReassignWorkers: (workers: Worker[]) => void
  onClose: () => void
}

const WorkerReassign = (props: Props) => {
  const { isOpen, workers, selected, onReassignWorkers, onClose } = props
  const [invalid, setInvalid] = useState(false)
  const [openSupportConfirm, setOpenSupportConfirm] = useState(false)
  const [onApprove, setOnApprove] = useState<() => void>(() => {})
  const [noSkillWorkers, setNoSkillWorkers] = useState<Worker[]>([])
  // 直前の予定開始時刻を設定
  const [startHour, setStartHour] = useState('00')
  const [startMinute, setStartMinute] = useState('00')
  // 直前の予定開始時刻の１時間後を設定
  const [endHour, setEndHour] = useState('00')
  const [endMinute, setEndMinute] = useState('00')
  const [isEndTime, setEndTime] = useState(false)

  const { startTime } = useAssignment()
  const { businessStartTime, businessEndTime, timeRange, flooredCurrentTime } = useBusinessTime({
    interval: TIME_INTERVAL.FIVE,
  })
  const { planWorkDate, getFitDuringShiftTimeData } = usePlans()

  const getHourOver24h = useCallback(
    (time: string) => {
      const [hour, minute] = time.split(':').map(Number)
      const [businessStartHour, businessStartMinute] = businessStartTime.split(':').map(Number)

      return hour < businessStartHour || (hour === businessStartHour && minute < businessStartMinute)
        ? `${hour + 24}`
        : hour.toString().padStart(2, '0')
    },
    [businessStartTime]
  )

  const shifts = useMemo(() => getFitDuringShiftTimeData(), [getFitDuringShiftTimeData])

  useEffect(() => {
    setInvalid(false)
    setNoSkillWorkers([])
    const date = dayjs(`${planWorkDate} ${startTime}`)
    const calcStartHour = getHourOver24h(date.format('HH:mm'))
    const calcStartMinute = date.minute()
    setStartHour(calcStartHour.toString().padStart(2, '0'))
    setStartMinute(calcStartMinute.toString().padStart(2, '0'))

    // endHourは、24時間以降を考慮したstartHourに、数値計算上+1した値とする。
    const calcEndHour = (Number(calcStartHour) + 1).toString().padStart(2, '0')
    const calcEndMinute = calcStartMinute.toString().padStart(2, '0')

    const [businessEndHour, businessEndMinute] = businessEndTime.split(':').map(Number)

    // 営業終了時間とcalcEndHour・calcEndMinuteを比較し、短い方をendHour・endMinuteに代入する
    if (
      businessEndHour < Number(calcEndHour) ||
      (businessEndHour === Number(calcEndHour) && businessEndMinute <= Number(calcEndMinute))
    ) {
      setEndHour(businessEndHour.toString().padStart(2, '0'))
      setEndMinute(businessEndMinute.toString().padStart(2, '0'))
    } else {
      setEndHour(calcEndHour)
      setEndMinute(calcEndMinute)
    }
    setEndTime(false)
  }, [getHourOver24h, isOpen, planWorkDate, flooredCurrentTime, businessEndTime, startTime])

  // 選択したワークスペース情報
  const requiredSkills = useMemo(() => selected?.skillIds, [selected?.skillIds])

  const getUpdateTimes = useCallback(
    (worker: Worker) => {
      const inputStartAt = dayjs(`${planWorkDate} ${startHour}:${startMinute}`).utc().format()
      const inputDuration = dayjs(`${planWorkDate} ${endHour}:${endMinute}`).utc().unix() - dayjs(inputStartAt).unix()
      const shiftsFilteredWorkerId = shifts.filter(shift => shift.workerId === Number(worker.workerId))
      const shiftsOrderByStartAt = orderBy(shiftsFilteredWorkerId, ['startAt'], ['asc'])
      return toFitDuringShiftTime(inputStartAt, inputDuration, shiftsOrderByStartAt, isEndTime)
    },
    [planWorkDate, startHour, startMinute, endHour, endMinute, shifts, isEndTime]
  )

  const memberSave = (func: () => void) => {
    if (!selected?.color) {
      setOnApprove(() => func)
      setOpenSupportConfirm(true)
    } else {
      func()
    }
  }

  const allWorkersSave = () => {
    // 全メンバーを移動して人員配置画面に戻る
    const updateWorkers: Worker[] = workers
      .map(w => {
        const { startAt, duration } = getUpdateTimes(w)
        return { ...w, startAt, duration, saved: true }
      })
      .filter(w => w.duration > 0)
    onReassignWorkers(updateWorkers)
  }

  const skillWorkersSave = () => {
    // 必須スキルもちのメンバーのみ移動して人員配置画面に戻る
    const skillWorkers: Worker[] = workers
      .filter(worker => requiredSkills?.some(skill => worker.skillIds?.includes(skill)))
      .map(w => {
        const { startAt, duration } = getUpdateTimes(w)
        return { ...w, startAt, duration, saved: true }
      })
      .filter(w => w.duration > 0)
    onReassignWorkers(skillWorkers)
  }

  const onSaveClick = () => {
    // 保存ボタンを押した時の処理
    const flag =
      !isEndTime &&
      dayjs(`${planWorkDate} ${startHour}:${startMinute}`).isSameOrAfter(
        dayjs(`${planWorkDate} ${endHour}:${endMinute}`)
      )
    setInvalid(flag)

    if (!flag) {
      // 時刻判定がvalidだった時必須スキルチェックをする
      const noSkill =
        requiredSkills && requiredSkills?.length > 0
          ? workers.filter(worker => requiredSkills?.every(skill => !worker.skillIds?.includes(skill)))
          : []
      setNoSkillWorkers(noSkill)
      if (noSkill.length === 0) {
        memberSave(allWorkersSave)
      }
    }
  }

  const isAlert = useMemo(() => noSkillWorkers.length > 0, [noSkillWorkers])
  const title = useMemo(() => (isAlert ? '必須スキルを持っていません' : '配置変更時間の入力'), [isAlert])

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalBody className="font-large fw-bold">{title}</ModalBody>

        <ModalBody className="font-small">
          {isAlert ? (
            <>
              <CardText className="mx-3">
                以下の作業者は作業に設定されている必須スキルを持っていません。そのまま配置変更しますか？
              </CardText>
              <CardBody className="p-0">
                <Card className="p-2 mx-3">
                  <Row md={3} className={`g-0 ${styles.workerCards}`}>
                    {noSkillWorkers.map(worker => (
                      <Col key={`${worker.workerId}`} className="p-1">
                        <WorkerCard worker={worker} disabled={true} selected={[]} />
                      </Col>
                    ))}
                  </Row>
                </Card>
              </CardBody>
            </>
          ) : (
            <>
              <CardBody className="p-0">
                <Card className="p-2 mx-3">
                  <Row md={3} className={`g-0 ${styles.workerCards}`}>
                    {workers.map(worker => (
                      <Col key={`${worker.workerId}`} className="p-1">
                        <WorkerCard worker={worker} disabled={true} selected={[]} />
                      </Col>
                    ))}
                  </Row>
                </Card>
              </CardBody>
              <CardBody className="py-3">
                <Row className="pb-3">
                  <Col>配置変更先:</Col>
                  <Col md={9} className="d-flex">
                    <BadgeLabel label={selected.label} color={selected?.color} />
                  </Col>
                </Row>
                <CardText>
                  上記のメンバーの配置変更を行います。配置変更の開始する時間と終了する時間を設定してください。
                </CardText>
              </CardBody>
              <CardBody className="py-0">
                <FormGroup row>
                  <Label md={3}>開始時間</Label>
                  <Col md={8}>
                    <TimeSelect
                      hour={startHour}
                      minute={startMinute}
                      label="から"
                      onChange={(hour, minute) => {
                        setStartHour(hour)
                        setStartMinute(minute)
                      }}
                      range={timeRange.start}
                      roundingInterval={TIME_INTERVAL.FIVE}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3}>終了時間</Label>
                  <Col md={4}>
                    <TimeSelect
                      hour={endHour}
                      minute={endMinute}
                      label="まで"
                      onChange={(hour, minute) => {
                        setEndHour(hour)
                        setEndMinute(minute)
                      }}
                      invalid={isEndTime}
                      range={timeRange.end}
                      roundingInterval={TIME_INTERVAL.FIVE}
                    />
                  </Col>
                  <Label md={4} className="form-check">
                    <Input
                      className="form-check-input"
                      id="end-time"
                      checked={isEndTime}
                      type="checkbox"
                      onChange={e => setEndTime(e.target.checked)}
                    />
                    <Label className="form-check-label" for="end-time">
                      終業時間まで
                    </Label>
                  </Label>
                </FormGroup>
                {invalid && (
                  <CardText className="text-danger">終了時刻は開始時刻より後の時刻を設定してください</CardText>
                )}
              </CardBody>
            </>
          )}
        </ModalBody>

        {isAlert ? (
          <ModalFooter className="d-flex justify-content-between">
            <div className="flex-grow-1">
              <Button outline onClick={onClose}>
                キャンセル
              </Button>
            </div>
            <Button outline onClick={() => memberSave(skillWorkersSave)}>
              スキルを持たない人のみキャンセル
            </Button>
            <Button color="primary" className="ms-2" onClick={() => memberSave(allWorkersSave)}>
              そのまま配置変更
            </Button>
          </ModalFooter>
        ) : (
          <ModalFooter className="d-flex justify-content-between">
            <Button outline onClick={onClose}>
              キャンセル
            </Button>
            <Button color="primary" className="px-4" onClick={onSaveClick} name="worker-reassign-submit">
              保存
            </Button>
          </ModalFooter>
        )}
      </Modal>
      <SupportConfirm
        isOpen={openSupportConfirm}
        start={`${startHour}:${startMinute}`}
        end={isEndTime ? `${businessEndTime}` : `${endHour}:${endMinute}`}
        name={selected.label}
        onCancel={() => {
          onClose()
          setOpenSupportConfirm(false)
        }}
        onApprove={() => {
          onApprove?.()
          setOpenSupportConfirm(false)
        }}
      />
    </>
  )
}

export default WorkerReassign
