import { isEqual, sortBy } from 'es-toolkit'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CardBody, CardText, Card } from 'reactstrap'

import { updateTargetValue, selectPlansStatus } from 'slices/plansSlice'

import { CustomModal, InputGroupFormat } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'

import useAuthority from 'hooks/useAuthority'
import usePlans from 'hooks/usePlans'

import styles from './TargetValuesUpdate.module.scss'

type TargetValuesType = {
  scheduleTypeId: number
  targetValue: string | undefined
  name: string
  unit: string
}

type Props = {
  isOpen: boolean
  workspaceId: number
  workspaceName: string
  onSuccess: () => void
  onCancel: () => void
}

const TargetValuesUpdate = ({ isOpen, workspaceId, workspaceName, onSuccess, onCancel }: Props) => {
  const [updateValues, setUpdateValues] = useState<TargetValuesType[]>([])
  const [initUpdateValues, setInitUpdateValues] = useState<TargetValuesType[]>([])
  const [validities, setValidities] = useState<boolean[]>([])
  const [disabled, setDisabled] = useState(true)
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = useState(false)

  const { isRequesting, errorMessage } = useSelector(selectPlansStatus, shallowEqual)
  const dispatch = useDispatch()

  const { planWorkDate, planScheduleTypes, dailyTarget } = usePlans()
  const { isReadOnlyWorkspace } = useAuthority(workspaceId)

  useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else {
      setModalErrorMessage('保存できませんでした。')
    }
    setSubmitted(false)
  }, [errorMessage, isRequesting, onSuccess, submitted])

  useEffect(() => {
    const nextDisabled = !(updateValues.every(t => t.targetValue) && validities.every(validity => validity))
    setDisabled(nextDisabled)
  }, [updateValues, validities])

  useEffect(() => {
    if (!dailyTarget?.data) {
      return
    }
    const newTargetValues = dailyTarget.data.reduce((acc: TargetValuesType[], cur) => {
      const scheduleType = planScheduleTypes.filter(s => s.dataConnection).find(s => s.id === cur.scheduleTypeId)
      if (scheduleType) {
        acc.push({
          scheduleTypeId: cur.scheduleTypeId,
          targetValue: cur.target?.toString() || '',
          name: scheduleType.name,
          unit: scheduleType.unit!,
        })
      }
      return acc
    }, [])
    const sortedTargetValues = sortBy(newTargetValues, ['name'])
    setUpdateValues(sortedTargetValues)
    setInitUpdateValues(sortedTargetValues)
    setValidities(sortedTargetValues.map(() => true))
    setModalErrorMessage(undefined)
  }, [isOpen, planScheduleTypes, dailyTarget?.data])

  const handleUpdateValue = (index: number, targetValue: string) => {
    const newTargetValues = updateValues.map((target, i) => (i === index ? { ...target, targetValue } : target))
    setUpdateValues(newTargetValues)
  }

  const handleValidate = (index: number, validity: boolean) => {
    if (validities[index] !== validity) {
      setValidities(validities.map((v, i) => (i === index ? validity : v)))
    }
  }

  const handleSaveClick = () => {
    setSubmitted(true)
    const dailyTargets = updateValues
      .filter((value, index) => value.targetValue !== initUpdateValues[index].targetValue)
      .map(t => ({
        workDate: planWorkDate,
        scheduleTypeId: t.scheduleTypeId,
        value: Number(t.targetValue),
      }))
    dispatch(updateTargetValue(workspaceId, { dailyTargets }))
  }

  const unchanged = useMemo(() => isEqual(initUpdateValues, updateValues), [initUpdateValues, updateValues])

  return (
    <CustomModal
      isOpen={isOpen}
      title="目標設定"
      approveDisabled={disabled || unchanged || isReadOnlyWorkspace}
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <p>この日の各作業の目標を設定します。</p>
      <Card className={styles.cardContainer}>
        <CardBody>
          <CardText>{workspaceName}</CardText>
          {updateValues.map((target, index) => (
            <InputGroupFormat
              key={target.scheduleTypeId}
              label={target.name}
              value={target.targetValue}
              addonText={target.unit}
              maxLength={7}
              onChange={value => handleUpdateValue(index, value)}
              validations={[Rules.Required, Rules.PositiveInteger]}
              onValidate={validity => handleValidate(index, validity)}
              className={index === updateValues.length - 1 ? '' : 'mb-3'}
            />
          ))}
        </CardBody>
      </Card>
    </CustomModal>
  )
}

export default TargetValuesUpdate
