import { useMemo } from 'react'
import { Button, Card } from 'reactstrap'

import type { ScheduleTypeFilter, ScheduleTypeFilterRow } from 'api/schedule_types/types'
import type { FilterColumn } from 'api/tenants/types'

import { BadgeLabel } from 'components/common'
import { getUUID } from 'components/common/utils'

import FilterCard from './FilterCard'

import type { Filters } from './FilterCard'

type Props = {
  filterColumns: FilterColumn[]
  filter: ScheduleTypeFilter[]
  disabled?: boolean
  onChange: (filter: ScheduleTypeFilter[]) => void
}

const FilterEdit = (props: Props) => {
  const { filterColumns, filter, disabled, onChange } = props

  const databaseFilters: Filters[] = useMemo(() => {
    const filters = filterColumns.map(filterColumn => ({
      key: filterColumn.columnName,
      label: filterColumn.displayName,
      values: filterColumn.values,
    }))
    return [{ key: '-', label: '-', values: ['-'] }, ...filters]
  }, [filterColumns])

  const onFilterCardChange = (key: string, value: string, pIndex: number, cIndex: number) => {
    const newFilter = filter.reduce((acc: ScheduleTypeFilter[], cur: ScheduleTypeFilter, index) => {
      if (index === pIndex) {
        const newData = [...cur.data]
        newData[cIndex] = { ...newData[cIndex], key, value }
        acc.push({ ...cur, data: newData })
      } else {
        acc.push(cur)
      }
      return acc
    }, [])
    onChange(newFilter)
  }

  const onAddInner = (pIndex: number) => {
    const newFilter = filter.reduce((acc: ScheduleTypeFilter[], cur: ScheduleTypeFilter, index) => {
      if (index === pIndex) {
        const row: ScheduleTypeFilterRow = {
          id: getUUID(),
          key: '-',
          value: '-',
        }
        const newData = [...cur.data, row]
        acc.push({ ...cur, data: newData })
      } else {
        acc.push(cur)
      }
      return acc
    }, [])
    onChange(newFilter)
  }

  const onDeleteInner = (pIndex: number, cIndex: number) => {
    const newFilter = filter
      .reduce((acc: ScheduleTypeFilter[], cur: ScheduleTypeFilter, index) => {
        if (index === pIndex) {
          const newData = cur.data.filter((_, i) => i !== cIndex)
          acc.push({ ...cur, data: newData })
        } else {
          acc.push(cur)
        }
        return acc
      }, [])
      .filter(row => row.data.length > 0)
    onChange(newFilter)
  }

  const onAddOuter = () => {
    const row: ScheduleTypeFilterRow = {
      id: getUUID(),
      key: '-',
      value: '-',
    }
    const newFilter: ScheduleTypeFilter = {
      id: getUUID(),
      data: [row],
    }
    onChange([...filter, newFilter])
  }

  return (
    <>
      {filter.map((parent, pIndex) => (
        <div key={parent.id}>
          <Card>
            {parent.data.map((row, cIndex, map) => (
              <div key={row.id}>
                <FilterCard
                  index={cIndex}
                  last={map.length - 1 === cIndex}
                  filters={databaseFilters}
                  filterKey={row.key}
                  filterValue={row.value}
                  disabled={disabled}
                  onFilterKeyChange={key => onFilterCardChange(key, '-', pIndex, cIndex)}
                  onFilterValueChange={value => onFilterCardChange(row.key, value, pIndex, cIndex)}
                  onAddClick={() => onAddInner(pIndex)}
                  onDeleteClick={() => onDeleteInner(pIndex, cIndex)}
                />
                {map.length - 1 !== cIndex && (
                  <>
                    <div className="d-flex justify-content-center position-relative" style={{ zIndex: 1 }}>
                      <BadgeLabel label="OR" className="position-absolute translate-middle-y" />
                    </div>
                    <hr className="m-0"></hr>
                  </>
                )}
              </div>
            ))}
          </Card>
          {filter.length - 1 !== pIndex && (
            <div className="d-flex  justify-content-center py-2">
              <BadgeLabel label="AND" />
            </div>
          )}
        </div>
      ))}
      <Button outline size="sm" color="primary" className="px-4 mt-3" onClick={onAddOuter} disabled={disabled}>
        フィルターを追加
      </Button>
    </>
  )
}

export default FilterEdit
