import { createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'es-toolkit'

import * as API from 'api/daily_reports/daily_reports'
import type {
  DailyReportsImportSettingsListResponse,
  DailyReportsImportSettingsResponse,
  DailyReportsImportSettingsListData,
  DailyReportsImportSettingsDetail,
  DailyReportsImportSettingsRequestBody,
  DailyReportsImportUploadUrlRequestBody,
} from 'api/daily_reports/types'
import { handleApiError, putUploadUrl } from 'api/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type DailyReportsState = {
  isRequesting: boolean
  errorMessage: string
  dailyReportsImportSettingsList: DailyReportsImportSettingsListData[]
  dailyReportsImportSettings: DailyReportsImportSettingsDetail | undefined
}

const initialState: DailyReportsState = {
  isRequesting: false,
  errorMessage: '',
  dailyReportsImportSettingsList: [],
  dailyReportsImportSettings: undefined,
}

export const dailyReportsSlice = createSlice({
  name: 'dailyReports',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getDailyReportsImportSettingsListSuccess: (
      state,
      action: PayloadAction<DailyReportsImportSettingsListResponse>
    ) => {
      state.isRequesting = false
      state.dailyReportsImportSettingsList = sortBy(action.payload.partialDailyReportList, ['name'])
    },
    getDailyReportsImportSettingsSuccess: (state, action: PayloadAction<DailyReportsImportSettingsResponse>) => {
      state.isRequesting = false
      state.dailyReportsImportSettings = action.payload.dailyReport
    },
    requestFinished: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  apiFailure,
  getDailyReportsImportSettingsListSuccess,
  getDailyReportsImportSettingsSuccess,
  requestFinished,
} = dailyReportsSlice.actions

export const getDailyReportsImportSettingsList =
  (
    callback?: (dailyReportsImportSettingsList: DailyReportsImportSettingsListData[]) => void,
    workDate?: string
  ): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      const res = await API.getDailyReportsImportSettingsList(workDate)
      dispatch(getDailyReportsImportSettingsListSuccess(res))
      callback?.(res.partialDailyReportList)
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const getDailyReportsImportSettings =
  (dailyReportId: number, workDate?: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      const res = await API.getDailyReportsImportSettings(dailyReportId, workDate)
      dispatch(getDailyReportsImportSettingsSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const createDailyReportsImportSettings =
  (requestBody: DailyReportsImportSettingsRequestBody): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      await API.createDailyReportsImportSettings(requestBody)
      dispatch(requestFinished())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const updateDailyReportsImportSettings =
  (dailyReportId: number, requestBody: DailyReportsImportSettingsRequestBody): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      await API.updateDailyReportsImportSettings(dailyReportId, requestBody)
      dispatch(requestFinished())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const deleteDailyReportsImportSettings =
  (dailyReportId: number, workDate?: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      await API.deleteDailyReportsImportSettings(dailyReportId, workDate)
      dispatch(requestFinished())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const importDailyReport =
  (data: DailyReportsImportUploadUrlRequestBody, csvContent: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      const res = await API.dailyReportUploadUrl(data)
      await putUploadUrl(res.uploadUrl, csvContent)
      dispatch(requestFinished())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const selectDailyReportsStatus = (state: RootState) => ({ ...state.dailyReports })

export default dailyReportsSlice.reducer
