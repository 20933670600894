import { sortBy, isEqual, omitBy, maxBy, last } from 'es-toolkit'
import { floor } from 'es-toolkit/compat'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText, CardColumns } from 'reactstrap'

import { ALLOCATION_TYPES } from 'api/allocations/constants'
import type {
  PartialAllocationData,
  EditAllocationProps,
  AllocationData,
  EditKeyScheduleType,
} from 'api/allocations/types'

import {
  getAllocationList,
  getAllocation,
  selectAllocationStatus,
  createAllocation,
  updateAllocation,
} from 'slices/allocationsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'
import { getWorkspace, selectWorkspacesStatus } from 'slices/workspacesSlice'

import {
  NavMenu,
  CustomButton,
  BadgeLabel,
  InputFormat,
  List,
  SelectBoxFormat,
  CardSubmitFooter,
  InputGroupFormat,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'
import type { ListItem } from 'components/common/types'

import useAuthority from 'hooks/useAuthority'
import useWorkspaceAllocation from 'hooks/useWorkspaceAllocation'

import placeholder from 'images/allEmpty.svg'

import WorkspaceAllocationDelete from './WorkspaceAllocationDelete'

import styles from './WorkspaceAllocation.module.scss'

const onDetailClick = () => window.open('https://help.smileboard.jp/allocation-of-costs', '_blank')

const MAX_ALLOCATION_SETTING_NUM = 30
const MAX_KEY_SCHEDULE_SETTING_NUM = 20

const WorkspaceAllocation = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)

  const [selectedAllocationId, setSelectedAllocationId] = useState<number | undefined>(undefined)
  const [initEditData, setInitEditData] = useState<EditAllocationProps | undefined>()

  const [submitted, setSubmitted] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)

  const { workspace } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const {
    partialAllocations,
    allocation,
    isRequesting,
    errorMessage,
    workspaceId: stateWorkspaceId,
  } = useSelector(selectAllocationStatus, shallowEqual)

  const dispatch = useDispatch()

  const {
    NEW_ALLOCATION_ID,
    NEW_KEY_SCHEDULE_ID,
    allocationTypeData,
    disabled,
    editData,
    setEditData,
    keyScheduleTypes,
    targetKeyScheduleTypes,
    remainKeyScheduleTypes,
    allocationTypeText,
    setAllocationNameValidity,
    setTargetScheduleTypeValidity,
    setAllocationTypeValidity,
    setAllocationRatioTypeValidity,
    setRemainderScheduleTypeValidity,
    distributionRatioValidation,
    deletedKeyScheduleTypeValidation,
  } = useWorkspaceAllocation()

  const { isReadOnlyWorkspace } = useAuthority(workspaceId)

  const initAllocationData = useMemo(() => {
    return {
      name: '',
      scheduleTypeId: null,
      type: ALLOCATION_TYPES.MANUAL,
      keys: [{ scheduleTypeId: NEW_KEY_SCHEDULE_ID, ratio: '0.00' }],
      remainderScheduleTypeId: null,
    }
  }, [NEW_KEY_SCHEDULE_ID])

  // LISTを取得した後の詳細取得は同期的に行う、ロジックはここで実装する
  const fetchAllocationDetail = useCallback(
    (allocationList: PartialAllocationData[]) => {
      if (allocationList.length === 0) {
        return
      }
      // allocationIdがundefinedの場合は最初のallocationを取得する
      const targetId = selectedAllocationId ?? sortBy(allocationList, ['name'])[0].id
      // allocationIdが0の場合は最後のallocationを取得する
      const lastId = targetId === NEW_ALLOCATION_ID ? last(allocationList)!.id : targetId

      dispatch(getAllocation(workspaceId, lastId))
      setSelectedAllocationId(lastId)
    },
    [selectedAllocationId, NEW_ALLOCATION_ID, dispatch, workspaceId]
  )

  const handleDeleteDialogSuccess = () => {
    const callback = (allocationList: PartialAllocationData[]) => {
      if (allocationList.length === 0) {
        return
      }
      const targetId = sortBy(allocationList, ['name'])[0].id
      dispatch(getAllocation(workspaceId, targetId))
      setSelectedAllocationId(targetId)
    }
    setOpenDelete(false)
    dispatch(getAllocationList(workspaceId, callback))
    dispatch(showSuccess())
  }

  const isNewItem = useMemo(() => selectedAllocationId === NEW_ALLOCATION_ID, [NEW_ALLOCATION_ID, selectedAllocationId])

  useEffect(() => {
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, workspaceId])

  useEffect(() => {
    // 画面表示時のみ実行
    if (selectedAllocationId || isNewItem) {
      return
    }
    dispatch(getAllocationList(workspaceId, fetchAllocationDetail))
  }, [dispatch, workspaceId, isNewItem, fetchAllocationDetail, selectedAllocationId])

  const allocationListItems = useMemo(() => {
    if (workspaceId !== stateWorkspaceId) {
      return []
    }

    const allocationItems: ListItem[] = partialAllocations.map(a => {
      const cardTextColor = a.id === selectedAllocationId ? 'text-white' : 'text-black-50'

      return {
        id: a.id,
        title: a.name,
        data: (
          <CardText className={`small mt-2 text-truncate ${cardTextColor}`}>
            <i className="icf-relation pr-2" />
            {a.keys.map(key => key.scheduleTypeName).join(',')}
          </CardText>
        ),
        option: a.isError ? <i className={`icf-alert font-large ${cardTextColor}`} /> : undefined,
      }
    })
    if (selectedAllocationId === NEW_ALLOCATION_ID) {
      allocationItems.push({
        id: NEW_ALLOCATION_ID,
        title: '未保存の項目',
        data: (
          <CardText className={'small mt-2 text-truncate text-white'}>
            <i className="icf-relation pr-2" />
          </CardText>
        ),
      })
    }
    return allocationItems
  }, [NEW_ALLOCATION_ID, partialAllocations, selectedAllocationId, stateWorkspaceId, workspaceId])

  const getAllocationKeys = (type: string, keys: EditKeyScheduleType[]) =>
    type === ALLOCATION_TYPES.MANUAL ? keys : keys.map(item => ({ scheduleTypeId: item.scheduleTypeId, ratio: '0.00' }))

  const updateEditData = useCallback(
    (allocationData: AllocationData | undefined) => {
      const newEditData = allocationData && {
        name: allocationData.name,
        scheduleTypeId: allocationData.scheduleTypeId,
        type: allocationData.type,
        keys: getAllocationKeys(
          allocationData.type,
          allocationData.keys.length > 0
            ? allocationData.keys.map(key => {
                return {
                  scheduleTypeId: key.scheduleTypeId,
                  ratio: key.ratio ? floor(key.ratio, 2).toFixed(2) : '0.00',
                }
              })
            : [{ scheduleTypeId: NEW_KEY_SCHEDULE_ID, ratio: '0.00' }]
        ),
        remainderScheduleTypeId: allocationData.remainderScheduleTypeId,
      }

      setEditData(newEditData)
      setInitEditData(newEditData)
    },
    [setEditData, setInitEditData, NEW_KEY_SCHEDULE_ID]
  )

  useEffect(() => {
    if (isNewItem) {
      setEditData(initAllocationData)
      return
    }

    if (!allocation || allocation.id !== selectedAllocationId) {
      return
    }

    updateEditData(allocation)
  }, [allocation, selectedAllocationId, setEditData, updateEditData, initAllocationData, isNewItem])

  useEffect(() => {
    if (allocationListItems.length === 0) {
      return
    }

    setSelectedAllocationId(prev => {
      // アイテムの追加に失敗した時
      if (prev === NEW_ALLOCATION_ID && errorMessage !== '') {
        return prev
      }
      // 追加アイテムを保存した時
      if (prev === NEW_ALLOCATION_ID && !allocationListItems.some(item => item.id === NEW_ALLOCATION_ID)) {
        return Number(maxBy(allocationListItems, item => item.id)!.id)
      }
      // 初期化時とアイテム削除時
      if (prev === undefined || (prev !== NEW_ALLOCATION_ID && !allocationListItems.some(item => item.id === prev))) {
        return Number(allocationListItems[0].id)
      }
      return prev
    })
  }, [allocationListItems, NEW_ALLOCATION_ID, errorMessage])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(getAllocationList(workspaceId, fetchAllocationDetail))
      dispatch(showSuccess())
    } else {
      if (errorMessage === ERROR_STATUS_CODE.CONFLICT) {
        dispatch(showError({ errorMessage: '登録上限に達しているため、保存できませんでした。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        dispatch(showError())
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, workspaceId, fetchAllocationDetail])

  const targetScheduleTypes = useMemo(() => {
    const allocatedScheduleTypeIds = isRequesting
      ? []
      : partialAllocations.filter(a => a.id !== selectedAllocationId).map(a => a.scheduleTypeId)
    const filteredScheduleTypes = partialScheduleTypes.filter(
      item => !allocatedScheduleTypeIds.includes(item.id) && !item.isKey && !item.isCostless
    )

    return filteredScheduleTypes.map(s => ({
      key: s.id,
      value: s.name,
    }))
  }, [partialAllocations, partialScheduleTypes, selectedAllocationId, isRequesting])

  const unchanged = useMemo(() => isEqual(initEditData, editData), [initEditData, editData])

  const handleItemSelect = useCallback(
    (id: number) => {
      setSelectedAllocationId(prevAllocationId => {
        if (prevAllocationId === id) {
          return prevAllocationId
        }
        dispatch(getAllocation(workspaceId, id))

        return id
      })
    },
    [dispatch, workspaceId]
  )

  const onSubmit = useCallback(() => {
    if (!editData || unchanged) {
      return
    }

    const newKeys =
      editData.type === ALLOCATION_TYPES.MANUAL
        ? editData.keys.map(key => ({ scheduleTypeId: key.scheduleTypeId, ratio: Number(key.ratio) }))
        : editData.keys.map(key => ({ scheduleTypeId: key.scheduleTypeId }))

    if (selectedAllocationId === NEW_ALLOCATION_ID) {
      setSubmitted(true)
      dispatch(
        createAllocation(workspaceId, {
          ...editData,
          keys: newKeys,
        })
      )
      return
    }

    if (!selectedAllocationId || !initEditData) {
      return
    }

    const omitParams = omitBy(editData, (value, key) => isEqual(value, initEditData[key as keyof EditAllocationProps]))
    const updateData = {
      ...omitParams,
      type: editData.type,
      remainderScheduleTypeId: editData.remainderScheduleTypeId,
      keys: newKeys,
    }

    setSubmitted(true)
    dispatch(updateAllocation(workspaceId, selectedAllocationId, updateData))
  }, [dispatch, editData, initEditData, selectedAllocationId, workspaceId, NEW_ALLOCATION_ID, unchanged])

  const onCancel = useCallback(() => {
    if (selectedAllocationId === NEW_ALLOCATION_ID) {
      const newId = partialAllocations.length > 0 && partialAllocations[0].id
      setSelectedAllocationId(newId || undefined)
      if (newId) {
        dispatch(getAllocation(workspaceId, newId))
      }
      return
    }

    allocation && updateEditData(allocation)
  }, [partialAllocations, allocation, selectedAllocationId, updateEditData, NEW_ALLOCATION_ID, workspaceId, dispatch])

  const onAddNewItem = useCallback(() => setSelectedAllocationId(NEW_ALLOCATION_ID), [NEW_ALLOCATION_ID])

  const onAddNewKeySchedule = useCallback(() => {
    if (!editData) {
      return
    }

    setEditData({
      ...editData,
      keys: editData.keys.concat({ scheduleTypeId: NEW_KEY_SCHEDULE_ID, ratio: '0.00' }),
    })
  }, [editData, setEditData, NEW_KEY_SCHEDULE_ID])

  const onDeleteNewKeySchedule = useCallback(
    (KeyScheduleTypeId: number) => {
      if (!editData) {
        return
      }

      const newKeys = editData.keys.filter(item => item.scheduleTypeId !== KeyScheduleTypeId)
      setEditData({
        ...editData,
        keys: newKeys,
        remainderScheduleTypeId: newKeys.length === 1 ? newKeys[0].scheduleTypeId : editData.remainderScheduleTypeId,
      })

      // 入力欄の削除時に、allocationRatioTypeValidityが再計算されないため、ここで再計算する
      const newKeysValidRatios = newKeys.every(key => Number(key.ratio) > 0 && Number(key.ratio) <= 100)
      setAllocationRatioTypeValidity(newKeysValidRatios)
    },
    [editData, setEditData, setAllocationRatioTypeValidity]
  )

  const onChangeAllocationType = useCallback(
    (type: string) => {
      if (!editData) {
        return
      }

      setEditData({
        ...editData,
        type: type,
        keys: getAllocationKeys(type, editData.keys),
      })
    },
    [editData, setEditData]
  )

  const onChangeKeySchedule = useCallback(
    (changeItem: { key?: string | number; value: string }, index: number) => {
      if (!editData) {
        return
      }

      const newKeys =
        editData.keys.length === 0
          ? [{ scheduleTypeId: Number(changeItem.key), ratio: '0.00' }]
          : editData.keys.map((item, itemIndex) => {
              if (index !== itemIndex) {
                return item
              }

              return {
                scheduleTypeId: Number(changeItem.key),
                ratio: item.ratio,
              }
            })
      setEditData({
        ...editData,
        keys: newKeys,
        remainderScheduleTypeId: newKeys.length === 1 ? newKeys[0].scheduleTypeId : editData.remainderScheduleTypeId,
      })
    },
    [editData, setEditData]
  )

  const onChangeKeyScheduleRatio = useCallback(
    (value: string, index: number) => {
      if (!editData) {
        return
      }

      // 数値以外の値の場合
      if (isNaN(Number(value))) {
        return
      }

      setEditData({
        ...editData,
        keys: editData.keys.map((item, itemIndex) => {
          if (index !== itemIndex) {
            return item
          }

          return {
            scheduleTypeId: item.scheduleTypeId,
            ratio: value,
          }
        }),
      })
    },
    [editData, setEditData]
  )

  const onBlurKeyScheduleRatio = useCallback(
    (index: number) => {
      if (!editData) {
        return
      }

      setEditData({
        ...editData,
        keys: editData.keys.map((item, itemIndex) => {
          if (index !== itemIndex) {
            return item
          }

          return {
            scheduleTypeId: item.scheduleTypeId,
            ratio: floor(Number(item.ratio), 2).toFixed(2),
          }
        }),
      })
    },
    [editData, setEditData]
  )

  const onChangeRemainKeySchedule = useCallback(
    (changeItem: { key?: string | number; value: string }) => {
      if (!editData) {
        return
      }

      setEditData({
        ...editData,
        remainderScheduleTypeId: Number(changeItem.key),
      })
    },
    [editData, setEditData]
  )

  return (
    <>
      <NavMenu>
        <div className="mt-3 mx-3">
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="font-x-large fw-bold">作業間配賦管理</div>
                <div className="px-2">{workspace && <BadgeLabel label={workspace?.name || ''} />}</div>
              </div>
              <div className="d-flex align-items-center">
                <div className="me-2 text-gray">
                  {allocationListItems.length} / {MAX_ALLOCATION_SETTING_NUM} 利用中
                </div>
                <CustomButton
                  icon="plus"
                  disabled={
                    allocationListItems.length >= MAX_ALLOCATION_SETTING_NUM ||
                    allocationListItems.some(item => item.id === NEW_ALLOCATION_ID)
                  }
                  onClick={onAddNewItem}
                >
                  作業間配賦設定を追加
                </CustomButton>
              </div>
            </div>
          </div>

          <Row className={styles.row}>
            <Col md={4} className="h-100">
              <Card className={styles.list}>
                {allocationListItems.length > 0 ? (
                  <List items={allocationListItems} selectedId={selectedAllocationId} onAction={handleItemSelect} />
                ) : (
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                      <div className="font-middle fw-bold py-4">作業間配賦設定がまだ登録されていません</div>
                      <div>まずは最初の作業間配賦設定を登録してみましょう。</div>
                      <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                        作業間配賦設定についてもっと詳しく
                      </Button>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
            <Col md={8} className="h-100">
              <Card className="h-100">
                {allocationListItems.length > 0 && editData ? (
                  <>
                    <div className="h-100 overflow-auto">
                      <CardBody>
                        <CardColumns className="d-flex justify-content-between">
                          <CardTitle className="font-large fw-bold">作業間配賦設定</CardTitle>
                          <span className="font-x-small text-muted">※必須項目</span>
                        </CardColumns>
                        <InputFormat
                          label="名称※"
                          placeholder="作業間配賦設定を入力"
                          value={editData.name}
                          size={COLUMN_SIZES.MIDDLE}
                          maxLength={100}
                          onChange={name => setEditData({ ...editData, name })}
                          validations={[Rules.StringLengthRange(1, 30)]}
                          onValidate={setAllocationNameValidity}
                          className="mb-3"
                        />
                        <SelectBoxFormat
                          label="作業を選択※"
                          placeholder="作業を選択"
                          value={editData.scheduleTypeId?.toString()}
                          size={COLUMN_SIZES.MIDDLE}
                          items={targetScheduleTypes}
                          disabled={false}
                          onChange={e =>
                            setEditData({
                              ...editData,
                              scheduleTypeId: Number(e.key),
                            })
                          }
                          validations={[Rules.Required]}
                          onValidate={setTargetScheduleTypeValidity}
                          className="mb-3"
                        />
                        <SelectBoxFormat
                          label="按分方法"
                          placeholder="按分方法を選択"
                          formText={allocationTypeText}
                          value={editData.type}
                          size={COLUMN_SIZES.MIDDLE}
                          items={allocationTypeData}
                          disabled={false}
                          onChange={e => onChangeAllocationType(e.key!.toString())}
                          validations={[Rules.Required]}
                          onValidate={setAllocationTypeValidity}
                          className="mb-3"
                        />
                        <CardText>
                          登録済みのキー作業への配賦を設定できます。設定に応じて、選択した作業の費用がキー作業の費用に含める事ができます。配賦設定の詳細に関しては
                          <a href="https://help.smileboard.jp/allocation-of-costs" target="_blank" rel="noreferrer">
                            ヘルプ
                          </a>
                          をご確認ください。
                        </CardText>
                        <CardText className="text-end text-gray">
                          {editData.keys.length} / {MAX_KEY_SCHEDULE_SETTING_NUM} 利用中
                        </CardText>
                        <Card>
                          {editData.keys.map((item, index) => {
                            return (
                              <div key={item.scheduleTypeId || `deletedScheduleType${index}`}>
                                {index > 0 && <hr className="m-0" />}
                                <div className="d-flex justify-content-between m-3">
                                  <div className="w-75 d-flex flex-column gap-3">
                                    <SelectBoxFormat
                                      label={`キー作業${index + 1}※`}
                                      placeholder={item.scheduleTypeId === null ? '-' : 'キー作業を選択'}
                                      value={item.scheduleTypeId === null ? '-' : item.scheduleTypeId.toString()}
                                      size={COLUMN_SIZES.MIDDLE}
                                      items={targetKeyScheduleTypes(item.scheduleTypeId)}
                                      disabled={false}
                                      onChange={e => onChangeKeySchedule(e, index)}
                                      validations={
                                        item.scheduleTypeId === null
                                          ? [deletedKeyScheduleTypeValidation]
                                          : [Rules.Required]
                                      }
                                    />

                                    {editData.type === ALLOCATION_TYPES.MANUAL && (
                                      <InputGroupFormat
                                        label="按分割合※"
                                        placeholder=""
                                        addonText="%"
                                        value={editData.keys[index].ratio || undefined}
                                        size={COLUMN_SIZES.MIDDLE}
                                        maxLength={10}
                                        onChange={value => onChangeKeyScheduleRatio(value, index)}
                                        onBlur={() => onBlurKeyScheduleRatio(index)}
                                        validations={[Rules.Required, distributionRatioValidation]}
                                        onValidate={setAllocationRatioTypeValidity}
                                      />
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center">
                                    {index + 1 === editData.keys.length && (
                                      <CustomButton
                                        outline
                                        icon="plus"
                                        disabled={
                                          item.scheduleTypeId === NEW_KEY_SCHEDULE_ID ||
                                          editData.keys.length === keyScheduleTypes.length ||
                                          editData.keys.length === MAX_KEY_SCHEDULE_SETTING_NUM
                                        }
                                        onClick={onAddNewKeySchedule}
                                      >
                                        追加
                                      </CustomButton>
                                    )}
                                    {editData.keys.length > 1 && (
                                      <Button
                                        className="ms-2"
                                        outline
                                        color="danger"
                                        disabled={false}
                                        onClick={() => onDeleteNewKeySchedule(item.scheduleTypeId!)}
                                      >
                                        削除
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          {editData.keys.length > 1 && (
                            <>
                              <hr className="m-0" />
                              <CardBody className="d-flex">
                                <div className="w-75">
                                  <SelectBoxFormat
                                    className="mb-0"
                                    label="余りの配賦先※"
                                    placeholder={editData.remainderScheduleTypeId === null ? '-' : 'キー作業を選択'}
                                    value={
                                      editData.remainderScheduleTypeId === null
                                        ? '-'
                                        : editData.remainderScheduleTypeId.toString()
                                    }
                                    size={COLUMN_SIZES.MIDDLE}
                                    items={remainKeyScheduleTypes}
                                    disabled={false}
                                    onChange={e => onChangeRemainKeySchedule(e)}
                                    validations={
                                      editData.remainderScheduleTypeId === null
                                        ? [deletedKeyScheduleTypeValidation]
                                        : [Rules.Required]
                                    }
                                    onValidate={setRemainderScheduleTypeValidity}
                                  />
                                </div>
                              </CardBody>
                            </>
                          )}
                        </Card>
                      </CardBody>

                      <CardBody>
                        <CardTitle className="font-large fw-bold">作業間配賦設定の削除</CardTitle>
                        <CardText className="py-2">
                          作業間配賦設定を削除すると情報はすべて失われ、復旧できません。
                        </CardText>
                        <Button
                          outline
                          color="danger"
                          disabled={selectedAllocationId === NEW_ALLOCATION_ID || isReadOnlyWorkspace}
                          onClick={() => setOpenDelete(true)}
                        >
                          作業間配賦設定を削除
                        </Button>
                      </CardBody>
                    </div>

                    <CardSubmitFooter
                      onCancel={onCancel}
                      onSubmit={onSubmit}
                      submitDisabled={unchanged || disabled || isReadOnlyWorkspace}
                      cancelDisabled={unchanged}
                      updatedBy={isNewItem ? undefined : allocation?.updatedByName}
                      updatedAt={isNewItem ? undefined : allocation?.updatedAt}
                    />
                  </>
                ) : (
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                      <div className="font-middle fw-bold py-4">作業間配賦設定が選択されていません</div>
                      <div>作業間配賦設定を選択して、詳細情報を編集しましょう。</div>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>

          <WorkspaceAllocationDelete
            isOpen={openDelete}
            workspaceId={workspaceId}
            allocationId={selectedAllocationId}
            onSuccess={handleDeleteDialogSuccess}
            onCancel={() => setOpenDelete(false)}
          />
        </div>
      </NavMenu>
    </>
  )
}

export default WorkspaceAllocation
