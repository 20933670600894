import { useNavigate } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'

import { SinglePageCard } from 'components/common'

import styles from './styles.module.scss'

const PasswordResetComplete = () => {
  const navigate = useNavigate()

  return (
    <SinglePageCard subtitle="パスワードの再設定に成功しました">
      <Container>
        <Row>
          <Col className="text-center">スマイルボードアカウントのパスワードが正常に再設定されました。</Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <Button color="primary" className={styles.button} onClick={() => navigate('/login')}>
              ログインページへ
            </Button>
          </Col>
        </Row>
      </Container>
    </SinglePageCard>
  )
}

export default PasswordResetComplete
