import dayjs from 'dayjs'
import { useMemo, useEffect, useCallback, useReducer } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import { WORKER_TYPES } from 'api/workers/constants'
import type { WorkerType } from 'api/workers/types'

import { getOfficialDutiesList, selectOfficialDutiesStatus } from 'slices/officialDutiesSlice'
import { getReportProductivity, selectReportsStatus } from 'slices/reportsSlice'
import { getSkillList, selectSkillsStatus } from 'slices/skillsSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import { NULL_GROUP_ID, NULL_GROUP_NAME, SUPPORT_WORKER_GROUP_PREFIX } from 'components/Dashboard/utils'
import { FilteringButton } from 'components/common'
import type { FilterItem } from 'components/common/types'
import { isSupportedWorkerGroup } from 'components/common/utils'

import { useReportsQuery } from 'hooks/useReportsQuery'

import { ProductivityGraph } from './ProductivityGraph'

const BLANK_SKILL = {
  ID: -2,
  LABEL: '未設定',
}
const BLANK_OFFICIAL_DUTIES = {
  ID: -3,
  LABEL: '未設定',
}
const FILTER_TEXT = {
  ALL: 'すべて',
  NO_SELECT: '-',
}

type ReducerState = {
  groups: (number | string)[]
  workerTypes: WorkerType[]
  skills: number[]
  officialDuties: number[]
  members: number[]
}

type ReducerStateWithoutMembers = Omit<ReducerState, 'members'>

const ACTION_TYPE = {
  GROUPS: 'groups',
  WORKER_TYPES: 'workerTypes',
  SKILLS: 'skills',
  OFFICIAL_DUTIES: 'officialDuties',
  MEMBERS: 'members',
  INIT: 'init',
} as const

type ReducerAction =
  | { type: typeof ACTION_TYPE.GROUPS; payload: (number | string)[] }
  | { type: typeof ACTION_TYPE.WORKER_TYPES; payload: WorkerType[] }
  | { type: typeof ACTION_TYPE.SKILLS; payload: number[] }
  | { type: typeof ACTION_TYPE.OFFICIAL_DUTIES; payload: number[] }
  | { type: typeof ACTION_TYPE.MEMBERS; payload: number[] }
  | { type: typeof ACTION_TYPE.INIT; payload: ReducerStateWithoutMembers }

const initialReducerState: ReducerState = {
  groups: [],
  workerTypes: [],
  skills: [],
  officialDuties: [],
  members: [],
}

type Props = {
  workspaceId?: number
  isDailyReportUse?: boolean
}

const ReportProductivity = ({ workspaceId, isDailyReportUse = false }: Props) => {
  const dispatch = useDispatch()

  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { productivity, productivityDailyReport } = useSelector(selectReportsStatus, shallowEqual)
  const { partialOfficialDutiesList } = useSelector(selectOfficialDutiesStatus, shallowEqual)
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)

  const { queryStart, queryEnd } = useReportsQuery()

  const [startDate, endDate] = useMemo(
    () => [dayjs(queryStart).toDate(), dayjs(queryEnd).toDate()],
    [queryStart, queryEnd]
  )

  const selectedData = useMemo(
    () => (isDailyReportUse ? productivityDailyReport : productivity),
    [isDailyReportUse, productivity, productivityDailyReport]
  )

  const reducer = useCallback(
    (state: ReducerState, action: ReducerAction) => {
      if (!selectedData?.data) {
        return state
      }

      // 各フィルターの値からメンバーフィルターを作成する
      const getFilterMembers = (newState: ReducerStateWithoutMembers) => {
        return selectedData
          .data!.groups.filter(group => newState.groups.includes(group.groupId ?? NULL_GROUP_ID))
          .flatMap(group =>
            group.workers.filter(
              worker =>
                newState.officialDuties.includes(worker.officialDutyId ?? BLANK_OFFICIAL_DUTIES.ID) &&
                newState.workerTypes.includes(worker.workerType) &&
                newState.skills.some(
                  s => worker.skillIds.includes(s) || (s === BLANK_SKILL.ID && worker.skillIds.length === 0)
                )
            )
          )
          .map(worker => worker.workerId)
      }

      switch (action.type) {
        // グループフィルターの設定更新
        case ACTION_TYPE.GROUPS:
          return { ...state, groups: action.payload, members: getFilterMembers({ ...state, groups: action.payload }) }
        // メンバー属性フィルターの設定更新
        case ACTION_TYPE.WORKER_TYPES:
          return {
            ...state,
            workerTypes: action.payload,
            members: getFilterMembers({ ...state, workerTypes: action.payload }),
          }
        // スキルフィルターの設定更新
        case ACTION_TYPE.SKILLS:
          return { ...state, skills: action.payload, members: getFilterMembers({ ...state, skills: action.payload }) }
        // 職掌フィルターの設定更新
        case ACTION_TYPE.OFFICIAL_DUTIES:
          return {
            ...state,
            officialDuties: action.payload,
            members: getFilterMembers({ ...state, officialDuties: action.payload }),
          }
        // メンバーフィルターの設定更新
        case ACTION_TYPE.MEMBERS:
          return { ...state, members: action.payload }
        // フィルターの初期化
        // action.payloadにはメンバー以外のデータを設定する
        case ACTION_TYPE.INIT:
          return { ...action.payload, members: getFilterMembers(action.payload) }
        default:
          throw Error('Unknown action.')
      }
    },
    [selectedData?.data]
  )
  const [selectedFilter, dispatchState] = useReducer(reducer, initialReducerState)

  useEffect(() => {
    dispatch(getOfficialDutiesList())
    dispatch(getSkillList())
  }, [dispatch])

  useEffect(() => {
    if (!workspaceId || !tenant) {
      return
    }

    // getWorkDateでbusinessStartTimeを使用するためtenant取得後にAPIを実行する
    if (
      !selectedData ||
      selectedData.workspaceId !== workspaceId ||
      selectedData.from !== queryStart ||
      selectedData.to !== queryEnd
    ) {
      // データが未取得、または取得済みのデータと選択期間が異なる場合にAPIを実行する
      dispatch(getReportProductivity(workspaceId, queryStart, queryEnd, isDailyReportUse))
    }
  }, [
    dispatch,
    workspaceId,
    tenant,
    isDailyReportUse,
    productivityDailyReport,
    productivity,
    selectedData,
    queryStart,
    queryEnd,
  ])

  const initFilters = useCallback(() => {
    const getGroupFilter = () => {
      if (!selectedData?.data) {
        return []
      }

      const workspaceGroups: (number | string)[] = selectedData.data.groups
        .filter(group => !isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName))
        .map(group => group.groupId ?? NULL_GROUP_ID)

      const supportGroups: string[] = selectedData.data.groups
        .filter(group => isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName))
        .map(group => `${SUPPORT_WORKER_GROUP_PREFIX}${group.supportedWorkspaceId ?? ''}`)

      return workspaceGroups.concat(supportGroups)
    }

    const groupFilter = getGroupFilter()
    const workTypeFilter = [WORKER_TYPES.REGULAR_MEMBER, WORKER_TYPES.SPOT_MEMBER]
    const skillFilter = skills.map(s => s.id).concat(BLANK_SKILL.ID)
    const officialDutiesFilter = partialOfficialDutiesList.map(s => s.id).concat(BLANK_OFFICIAL_DUTIES.ID)

    dispatchState({
      type: ACTION_TYPE.INIT,
      payload: {
        groups: groupFilter,
        workerTypes: workTypeFilter,
        skills: skillFilter,
        officialDuties: officialDutiesFilter,
      },
    })
  }, [partialOfficialDutiesList, selectedData?.data, skills])

  const handleFilterClear = useCallback(() => {
    initFilters()
  }, [initFilters])

  useEffect(() => {
    initFilters()
  }, [initFilters])

  const productivityDailyWork = useMemo(() => {
    if (!selectedData?.data) {
      return []
    }

    return selectedData.data.groups
      .flatMap(group => group.workers)
      .filter(worker => selectedFilter.members.includes(worker.workerId))
      .flatMap(worker => worker.dailyWorkData)
  }, [selectedData?.data, selectedFilter.members])

  const filterGroupItems = useMemo<FilterItem<number | string>[]>(() => {
    if (!selectedData?.data) {
      return []
    }

    const workspaceGroups: FilterItem<number | string>[] = selectedData.data.groups
      .filter(group => !isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName))
      .map(group => ({
        key: group.groupId ?? NULL_GROUP_ID,
        label: group.groupName ?? NULL_GROUP_NAME,
        checked: selectedFilter.groups.includes(group.groupId ?? NULL_GROUP_ID),
      }))

    const supportGroups: FilterItem<number | string>[] = selectedData.data.groups
      .filter(group => isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName))
      .map(group => ({
        key: `${SUPPORT_WORKER_GROUP_PREFIX}${group.supportedWorkspaceId ?? ''}`,
        label: group.supportedWorkspaceName ?? '',
        checked: selectedFilter.groups.includes(`${SUPPORT_WORKER_GROUP_PREFIX}${group.supportedWorkspaceId ?? ''}`),
      }))

    return workspaceGroups.concat(supportGroups)
  }, [selectedData?.data, selectedFilter.groups])

  const filterWorkTypeItems = useMemo<FilterItem<WorkerType>[]>(
    () => [
      {
        key: WORKER_TYPES.REGULAR_MEMBER,
        label: 'レギュラー',
        checked: selectedFilter.workerTypes.includes(WORKER_TYPES.REGULAR_MEMBER),
      },
      {
        key: WORKER_TYPES.SPOT_MEMBER,
        label: 'スポット',
        checked: selectedFilter.workerTypes.includes(WORKER_TYPES.SPOT_MEMBER),
      },
    ],
    [selectedFilter.workerTypes]
  )

  const filterSkillItems = useMemo<FilterItem[]>(
    () =>
      skills
        .map(skill => ({ key: skill.id, label: skill.name, checked: selectedFilter.skills.includes(skill.id) }))
        .concat({
          key: BLANK_SKILL.ID,
          label: BLANK_SKILL.LABEL,
          checked: selectedFilter.skills.includes(BLANK_SKILL.ID),
        }),
    [selectedFilter.skills, skills]
  )

  const filterOfficialDutiesItems = useMemo<FilterItem[]>(
    () =>
      partialOfficialDutiesList
        .map(duties => ({
          key: duties.id,
          label: duties.name,
          checked: selectedFilter.officialDuties.includes(duties.id),
        }))
        .concat({
          key: BLANK_OFFICIAL_DUTIES.ID,
          label: BLANK_OFFICIAL_DUTIES.LABEL,
          checked: selectedFilter.officialDuties.includes(BLANK_OFFICIAL_DUTIES.ID),
        }),
    [partialOfficialDutiesList, selectedFilter.officialDuties]
  )

  const filterMemberItems = useMemo<FilterItem[]>(
    () =>
      selectedData?.data?.groups
        .filter(group => selectedFilter.groups.includes(group.groupId ?? NULL_GROUP_ID))
        .flatMap(group => group.workers)
        .filter(
          worker =>
            selectedFilter.officialDuties.includes(worker.officialDutyId ?? BLANK_OFFICIAL_DUTIES.ID) &&
            selectedFilter.workerTypes.includes(worker.workerType) &&
            selectedFilter.skills.some(
              s => worker.skillIds.includes(s) || (s === BLANK_SKILL.ID && worker.skillIds.length === 0)
            )
        )
        .map(worker => ({
          key: worker.workerId,
          label: worker.workerName,
          checked: selectedFilter.members.includes(worker.workerId),
        })) ?? [],
    [
      selectedData?.data?.groups,
      selectedFilter.groups,
      selectedFilter.members,
      selectedFilter.officialDuties,
      selectedFilter.skills,
      selectedFilter.workerTypes,
    ]
  )

  const filterGroupText = useMemo(() => {
    if (selectedFilter.groups.length === 0) {
      return FILTER_TEXT.NO_SELECT
    }

    if (selectedFilter.groups.length === filterGroupItems.length) {
      return FILTER_TEXT.ALL
    }

    const selectedLabels = filterGroupItems
      .filter(item => selectedFilter.groups.includes(item.key))
      .map(item => item.label)

    return selectedLabels.join()
  }, [filterGroupItems, selectedFilter.groups])

  const filterWorkTypeText = useMemo(() => {
    if (selectedFilter.workerTypes.length === 0) {
      return FILTER_TEXT.NO_SELECT
    }

    if (selectedFilter.workerTypes.length === filterWorkTypeItems.length) {
      return FILTER_TEXT.ALL
    }

    const selectedLabels = filterWorkTypeItems
      .filter(item => selectedFilter.workerTypes.includes(item.key))
      .map(item => item.label)

    return selectedLabels.join()
  }, [filterWorkTypeItems, selectedFilter.workerTypes])

  const filterSkillText = useMemo(() => {
    if (selectedFilter.skills.length === 0) {
      return FILTER_TEXT.NO_SELECT
    }

    if (selectedFilter.skills.length === filterSkillItems.length) {
      return FILTER_TEXT.ALL
    }

    const selectedLabels = filterSkillItems
      .filter(item => selectedFilter.skills.includes(item.key))
      .map(item => item.label)

    return selectedLabels.join()
  }, [filterSkillItems, selectedFilter.skills])

  const filterOfficialDutiesText = useMemo(() => {
    if (selectedFilter.officialDuties.length === 0) {
      return FILTER_TEXT.NO_SELECT
    }

    if (selectedFilter.officialDuties.length === filterOfficialDutiesItems.length) {
      return FILTER_TEXT.ALL
    }

    const selectedLabels = filterOfficialDutiesItems
      .filter(item => selectedFilter.officialDuties.includes(item.key))
      .map(item => item.label)

    return selectedLabels.join()
  }, [filterOfficialDutiesItems, selectedFilter.officialDuties])

  const filterMemberText = useMemo(() => {
    if (selectedFilter.members.length === 0) {
      return FILTER_TEXT.NO_SELECT
    }

    if (selectedFilter.members.length === filterMemberItems.length) {
      return FILTER_TEXT.ALL
    }

    const selectedLabels = filterMemberItems
      .filter(item => selectedFilter.members.includes(item.key))
      .map(item => item.label)

    return selectedLabels.join()
  }, [filterMemberItems, selectedFilter.members])

  const handleChangeFilterGroup = useCallback((filterItems: (string | number)[]) => {
    dispatchState({ type: ACTION_TYPE.GROUPS, payload: filterItems })
  }, [])

  const handleChangeFilterWorkerTypes = useCallback((filterItems: WorkerType[]) => {
    dispatchState({ type: ACTION_TYPE.WORKER_TYPES, payload: filterItems })
  }, [])

  const handleChangeFilterSkills = useCallback((filterItems: number[]) => {
    dispatchState({ type: ACTION_TYPE.SKILLS, payload: filterItems })
  }, [])

  const handleChangeFilterOfficialDuties = useCallback((filterItems: number[]) => {
    dispatchState({ type: ACTION_TYPE.OFFICIAL_DUTIES, payload: filterItems })
  }, [])

  const handleChangeFilterMembers = useCallback((filterItems: number[]) => {
    dispatchState({ type: ACTION_TYPE.MEMBERS, payload: filterItems })
  }, [])

  return (
    <div>
      <Card className="mb-3">
        <CardBody>
          <div className="fw-bold">生産性の推移（個人実績あり）</div>
          <div className="mt-2 text-muted">絞り込み</div>
          <div className="d-flex justify-content-between align-items-center me-2">
            <div className="d-flex mt-1 align-items-center gap-2">
              <FilteringButton
                items={filterGroupItems}
                onChange={handleChangeFilterGroup}
                value={selectedFilter.groups}
                label="グループ"
                disabled={!selectedData?.data || selectedData?.data.groups.length === 0}
                showIcon={false}
              />
              <FilteringButton
                items={filterWorkTypeItems}
                onChange={handleChangeFilterWorkerTypes}
                value={selectedFilter.workerTypes}
                label="メンバー属性"
                disabled={!selectedData?.data || selectedData?.data.groups.length === 0}
                showIcon={false}
              />
              <FilteringButton
                items={filterSkillItems}
                onChange={handleChangeFilterSkills}
                value={selectedFilter.skills}
                label="スキル"
                disabled={skills.length === 0}
                showIcon={false}
              />
              <FilteringButton
                items={filterOfficialDutiesItems}
                onChange={handleChangeFilterOfficialDuties}
                value={selectedFilter.officialDuties}
                label="職掌"
                disabled={partialOfficialDutiesList.length === 0}
                showIcon={false}
              />
              <FilteringButton
                items={filterMemberItems}
                onChange={handleChangeFilterMembers}
                value={selectedFilter.members}
                label="メンバー"
                disabled={filterMemberItems.length === 0}
                showIcon={false}
              />
            </div>
            <a className="text-muted" role="button" onClick={handleFilterClear}>
              絞り込みをクリア
            </a>
          </div>
          <div className="mt-2 mb-3 text-muted">
            <div className="d-flex gap-3">
              <div>グループ:{filterGroupText}</div>
              <div>メンバー属性:{filterWorkTypeText}</div>
              <div>スキル:{filterSkillText}</div>
              <div>職掌:{filterOfficialDutiesText}</div>
              <div>メンバー:{filterMemberText}</div>
            </div>
          </div>
          <ProductivityGraph dailyWorkData={productivityDailyWork} start={startDate} end={endDate} />
        </CardBody>
      </Card>
      <Card className="mb-2">
        <CardBody>
          <div className="fw-bold mb-2">生産性の推移（個人実績なし）</div>
          <ProductivityGraph dailyWorkData={selectedData?.data?.dailyWorkData ?? []} start={startDate} end={endDate} />
        </CardBody>
      </Card>
    </div>
  )
}

export default ReportProductivity
