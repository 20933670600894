import { Link, useLocation } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'

import DrawerIcon from './DrawerIcon'

import styles from './styles.module.scss'

export type LinkItem = {
  to: string
  label: string
  match: RegExp
}

export type Item = LinkItem & {
  page: string
  icon: string
}

type IDrawerProps = {
  open: boolean
  items: Item[]
  columnSize: number
  links?: LinkItem[]
  onBackdropClick: () => void
}

const Drawer = ({ open, items, columnSize, links, onBackdropClick }: IDrawerProps) => {
  const { pathname } = useLocation()

  return (
    <>
      <div className={open ? `${styles.backdrop}` : ``} onClick={onBackdropClick}></div>
      <div className={open ? `${styles.sidebarOpen}` : `${styles.sidebar}`}>
        <Row xs={columnSize} className="text-center g-0">
          {items.map((item: Item) => {
            const type = item.match.test(pathname) ? `${item.icon}Active` : item.icon
            return (
              <Col className="pt-3" key={item.page}>
                <Link to={item.to} onClick={onBackdropClick}>
                  <Card className={`p-2 mx-auto ${styles.cardBody}`}>
                    <div className="d-flex align-self-center">
                      <DrawerIcon name={`${type}`} height={55} width={55} fill="currentColor" />
                    </div>
                  </Card>
                </Link>
                <div className="mt-2">{item.label}</div>
              </Col>
            )
          })}
        </Row>
        {links && (
          <>
            <hr className="mt-3 mb-2"></hr>
            {links.map((link: LinkItem, index: number) => (
              <Row key={`link-row-${index}`}>
                <Col className="font-middle my-2 mx-3" key={`link-col-${index}`}>
                  <Link className="text-decoration-none" to={link.to} onClick={onBackdropClick}>
                    {link.label}
                  </Link>
                </Col>
              </Row>
            ))}
            <hr className="m-2"></hr>
          </>
        )}
      </div>
    </>
  )
}
export default Drawer
