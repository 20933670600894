import { useState, useRef, useCallback } from 'react'
import Popup from 'reactjs-popup'
import { Card, CardBody, Input } from 'reactstrap'

import { TimeRangeSelect } from 'components/common'
import { TIME_INTERVAL } from 'components/common/constants'

import type { PopupActions } from 'reactjs-popup/dist/types'

type Props = {
  performanceRateValue: string
  startTime: string
  duration: number
  disabled?: boolean
  onChange: (value: number) => void
  onDelete: () => void
  onTimeChange: (startHour: string, startMinute: string, endHour: string, endMinute: string) => void
}

export const PerformanceRatePopover = (props: Props) => {
  const { performanceRateValue, startTime, duration, disabled = false, onChange, onDelete, onTimeChange } = props
  const [invalid, setInvalid] = useState(false)

  const onInputChange = (value: string) => {
    if (/^(\+|-)|\.$|^\d*\.\d{3,}$/.test(value) || isNaN(Number(value)) || value === '') {
      setInvalid(true)
    } else {
      setInvalid(false)
      value !== performanceRateValue && onChange(Number(value))
    }
  }

  const ref = useRef<PopupActions>(null)
  const trigger = <div className="h-100">{performanceRateValue}</div>

  const onDeleteHandler = () => {
    onDelete()
    ref.current?.close()
  }

  const handleTimeChange = useCallback(
    (changeStartHour: string, changeStartMinute: string, changeEndHour: string, changeEndMinute: string) => {
      onTimeChange(changeStartHour, changeStartMinute, changeEndHour, changeEndMinute)
    },
    [onTimeChange]
  )

  return (
    <Popup
      ref={ref}
      trigger={trigger}
      onOpen={() => setInvalid(false)}
      position="bottom center"
      contentStyle={{ width: '14rem' }}
    >
      <Card>
        <CardBody className="p-2 font-x-small">
          <div className="d-flex justify-content-end font-large">
            {!disabled && <i className="icf-delete" onClick={() => onDeleteHandler()} />}
            <i className="icf-close ps-1" onClick={() => ref.current?.close()} />
          </div>
          <div className="my-2">
            <Input
              defaultValue={performanceRateValue}
              invalid={invalid}
              placeholder="1.00"
              maxLength={10}
              disabled={disabled}
              onChange={e => onInputChange(e.target.value)}
            />
          </div>
          <div className="d-flex mt-2 align-items-center justify-content-between">
            <TimeRangeSelect
              startTime={startTime}
              duration={duration}
              interval={TIME_INTERVAL.FIVE}
              onTimeChange={handleTimeChange}
            />
          </div>
        </CardBody>
      </Card>
    </Popup>
  )
}
