import { useMemo, useCallback } from 'react'
import Select from 'react-select'

import { TIME_INTERVAL } from '../constants'

type Props = {
  hour: string
  minute: string
  label: string
  invalid?: boolean
  onChange: (hour: string, minute: string) => void
  onBlur?: () => void
  menuIsOpen?: boolean
  menuZIndex?: number
  menuPosition?: 'auto' | 'bottom' | 'top'
  range?: { fromHour: string; fromMin: string; toHour: string; toMin: string }
  portalTarget?: HTMLElement
  roundingInterval?: number
  isError?: boolean
}

export const DividedTimeSelect = (props: Props) => {
  const {
    hour,
    minute,
    label,
    invalid,
    onChange,
    onBlur,
    menuIsOpen,
    menuZIndex,
    menuPosition = 'auto',
    portalTarget,
    range = { fromHour: '00', fromMin: '00', toHour: '00', toMin: '00' },
    roundingInterval = TIME_INTERVAL.FIFTEEN,
    isError = false,
  } = props
  const hourOptions = useMemo(() => {
    const toHour = Number(range.toHour)
    const fromHour = Number(range.fromHour)
    return [...Array(toHour - fromHour + 1)].map((_, index) => {
      const hourValue = (index + fromHour).toString().padStart(2, '0')

      return {
        label: hourValue,
        value: hourValue,
      }
    })
  }, [range.fromHour, range.toHour])

  const minuteOptions = useMemo(
    () =>
      [...Array(60 / roundingInterval)].map((_, index) => {
        const minuteValue = (index * roundingInterval).toString().padStart(2, '0')

        return {
          label: minuteValue,
          value: minuteValue,
        }
      }),
    [roundingInterval]
  )

  const defaultHourValue = useMemo(() => {
    const target = hourOptions.find(val => val.value === hour)
    return target || hourOptions[0]
  }, [hourOptions, hour])

  const defaultMinuteValue = useMemo(() => {
    const target = minuteOptions.find(val => val.value === minute)

    return target || minuteOptions[0]
  }, [minuteOptions, minute])

  const handleHourChange = useCallback(
    (changeHour: string) => {
      onChange(changeHour, minute)
    },
    [minute, onChange]
  )

  const handleMinuteChange = useCallback(
    (changeMinute: string) => {
      onChange(hour, changeMinute)
    },
    [hour, onChange]
  )

  return (
    <div className="d-flex align-items-center gap-2">
      <Select
        onBlur={onBlur}
        autoFocus={menuIsOpen}
        menuIsOpen={menuIsOpen}
        options={hourOptions}
        defaultValue={hourOptions[0]}
        value={defaultHourValue}
        isDisabled={invalid}
        onChange={val => val && handleHourChange(val.value)}
        maxMenuHeight={200}
        required
        styles={{
          menu: base => (menuZIndex ? { ...base, zIndex: menuZIndex } : base),
          control: base => (isError ? { ...base, border: '2px solid var(--bs-danger)' } : base),
        }}
        menuPlacement={menuPosition}
        noOptionsMessage={() => '選択肢がありません'}
        menuPortalTarget={portalTarget}
        menuPosition={portalTarget && 'fixed'}
      />
      :
      <Select
        onBlur={onBlur}
        autoFocus={menuIsOpen}
        menuIsOpen={menuIsOpen}
        options={minuteOptions}
        defaultValue={minuteOptions[0]}
        value={defaultMinuteValue}
        isDisabled={invalid}
        onChange={val => val && handleMinuteChange(val.value)}
        maxMenuHeight={200}
        required
        styles={{
          menu: base => (menuZIndex ? { ...base, zIndex: menuZIndex } : base),
          control: base => (isError ? { ...base, border: '2px solid var(--bs-danger)' } : base),
        }}
        menuPlacement={menuPosition}
        noOptionsMessage={() => '選択肢がありません'}
        menuPortalTarget={portalTarget}
        menuPosition={portalTarget && 'fixed'}
      />
      {label && <span className="ms-2">{label}</span>}
    </div>
  )
}
