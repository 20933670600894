import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type {
  BopProfitAndLossResponse,
  ScheduleTypeSummaryResponse,
  TenantBopMonitoringParam,
  TenantBopMonitoringResponse,
  TenantSummaryFilteringData,
  TenantSummaryResponse,
  WorkerCountsResponse,
  WorkspaceSummaryResponse,
} from './types'

export const getTenantSummary = async (
  date: string,
  data: TenantSummaryFilteringData
): Promise<TenantSummaryResponse> => {
  const url = `/work-date/${date}/summary`

  const response = await axiosClientWithTenantId.get(url, { params: data })
  return response.data
}

export const getWorkspaceSummary = async (workspaceId: number, date: string): Promise<WorkspaceSummaryResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${date}/workspace-summary`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const getBopMonitoring = async (
  date: string,
  data: TenantBopMonitoringParam
): Promise<TenantBopMonitoringResponse> => {
  const url = `/work-date/${date}/bop`

  const response = await axiosClientWithTenantId.get(url, { params: data })
  return response.data
}

export const getBopProfitAndLoss = async (date: string): Promise<BopProfitAndLossResponse> => {
  const url = `/work-date/${date}/bop?detail=true`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const getWorkerCounts = async (date: string, workspaceId?: number): Promise<WorkerCountsResponse> => {
  const url = `/work-date/${date}/worker-counts`

  const params = { workspaceId }

  const response = await axiosClientWithTenantId.get(url, { params })
  return response.data
}

export const getScheduleTypeSummary = async (
  workspaceId: number,
  date: string,
  scheduleTypeId: number
): Promise<ScheduleTypeSummaryResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${date}/schedule-types/${scheduleTypeId}/summary`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}
