import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/official_duties/official_duties'
import type {
  OfficialDutiesData,
  OfficialDutiesDetailResponse,
  OfficialDutiesEditData,
  OfficialDutiesListResponse,
  PartialOfficialDutiesListData,
} from 'api/official_duties/types'

import { handleApiError } from './utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type OfficialDutiesState = {
  partialOfficialDutiesList: PartialOfficialDutiesListData[]
  officialDuties?: OfficialDutiesData
  isRequesting: boolean
  errorMessage: string
}

const initialState: OfficialDutiesState = {
  isRequesting: false,
  errorMessage: '',
  officialDuties: undefined,
  partialOfficialDutiesList: [],
}

export const officialDutiesSlice = createSlice({
  name: 'duties',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getOfficialDutiesListSuccess: (state, action: PayloadAction<OfficialDutiesListResponse>) => {
      state.isRequesting = false
      state.partialOfficialDutiesList = action.payload.partialOfficialDutiesList
    },
    getOfficialDutiesSuccess: (state, action: PayloadAction<OfficialDutiesDetailResponse>) => {
      state.isRequesting = false
      state.officialDuties = action.payload.officialDuties
    },
    createOfficialDutiesSuccess: state => {
      state.isRequesting = false
    },
    updateOfficialDutiesSuccess: state => {
      state.isRequesting = false
    },
    deleteOfficialDutiesSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  apiFailure,
  getOfficialDutiesListSuccess,
  getOfficialDutiesSuccess,
  createOfficialDutiesSuccess,
  updateOfficialDutiesSuccess,
  deleteOfficialDutiesSuccess,
} = officialDutiesSlice.actions

export const getOfficialDutiesList = (): AppThunk => async dispatch => {
  dispatch(startRequest())

  try {
    const res = await API.getOfficialDutiesList()
    dispatch(getOfficialDutiesListSuccess(res))
  } catch (res) {
    handleApiError(res as AxiosError, dispatch, apiFailure)
  }
}

export const getOfficialDuties =
  (id: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getOfficialDuties(id)
      dispatch(getOfficialDutiesSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const createOfficialDuties =
  (data: OfficialDutiesEditData): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.createOfficialDuties(data)
      dispatch(createOfficialDutiesSuccess())
      dispatch(getOfficialDutiesList())
      dispatch(getOfficialDuties(res.id))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const updateOfficialDuties =
  (id: number, data: OfficialDutiesEditData): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.updateOfficialDuties(id, data)
      dispatch(updateOfficialDutiesSuccess())
      dispatch(getOfficialDutiesList())
      dispatch(getOfficialDuties(id))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const deleteOfficialDuties =
  (id: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.deleteOfficialDuties(id)
      dispatch(deleteOfficialDutiesSuccess())
      dispatch(getOfficialDutiesList())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const selectOfficialDutiesStatus = (state: RootState) => ({ ...state.officialDuties })

export default officialDutiesSlice.reducer
