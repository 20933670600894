import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import moment from 'moment'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import App from 'components/App/App'

import { AxiosClientProvider } from 'AxiosClientProvider'
import * as serviceWorker from 'serviceWorker'
import { store, useAppDispatch } from 'store'

import 'bootstrap.scss'
import 'fonts/sbc-icon.scss'
import 'index.scss'

moment.locale('ja', { weekdays: ['日', '月', '火', '水', '木', '金', '土'] })

dayjs.extend(customParseFormat)

const startApp = async () => {
  if (process.env.REACT_APP_ENABLE_MOCKS === 'true') {
    const { worker } = await import('./__mocks__/browser')
    await worker.start({
      onUnhandledRequest: 'bypass', // モックデータが存在しない場合はAPIコールする
      quiet: false, // ログ出力を有効にする
    })
  }

  const container = document.getElementById('root')
  const root = createRoot(container!)

  root.render(
    <StrictMode>
      <Provider store={store}>
        <AxiosClientProvider useAppDispatch={useAppDispatch}>
          <App />
        </AxiosClientProvider>
      </Provider>
    </StrictMode>
  )
}

startApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
