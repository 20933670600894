import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap'

import { showError, showSuccess } from 'slices/notificationSlice'
import { selectSessionStatus, setUserNickname } from 'slices/sessionSlice'
import { updateTenantUser, selectUsersStatus, updateUser } from 'slices/usersSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import EditChangesDiscardDialog from 'components/EditChangesDiscardDialog/EditChangesDiscardDialog'
import { InputFormat, NavMenu, CardSubmitFooter } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'

import useAuthority from 'hooks/useAuthority'
import useLogout from 'hooks/useLogout'

import AccountDelete from './AccountDelete'
import PasswordChange from './PasswordChange'

const AccountInformationEdit = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const [nickname, setNickname] = useState(user.userHasTenants[0].nickname ?? '')
  const [email, setEmail] = useState(user.email)
  const [nameValidity, setNameValidity] = useState(false)
  const [emailValidity, setEmailValidity] = useState(false)
  const [isEmailChange, setIsEmailChange] = useState(false)
  const [openPasswordChange, setOpenPasswordChange] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openEditChangesDiscardDialog, setOpenEditChangesDiscardDialog] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const { logout } = useLogout()
  const { isTenantAdmin } = useAuthority()

  const onSubmit = () => {
    setSubmitted(true)
    setIsEmailChange(user.email !== email)
    if (!isTenantAdmin) {
      dispatch(updateTenantUser({ nickname, email }))
      return
    }
    dispatch(updateUser({ nickname, email }))
  }
  const handlePasswordChange = () => {
    setOpenPasswordChange(false)
    dispatch(showSuccess())
  }
  const handleDelete = () => {
    setOpenDelete(false)
  }
  const handleEditChangesDiscard = () => {
    setOpenEditChangesDiscardDialog(false)
    setNickname(user.userHasTenants[0].nickname ?? '')
    setEmail(user.email)
  }

  const unchanged = useMemo(
    () => user.userHasTenants[0].nickname === nickname && user.email === email,
    [user, nickname, email]
  )

  const disabled = useMemo(
    () => !(nickname && nameValidity && email && emailValidity),
    [nickname, nameValidity, email, emailValidity]
  )

  useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }

    if (errorMessage === '') {
      dispatch(showSuccess())
      if (isEmailChange) {
        logout()
      }
      // ユーザー情報の変更がセッションではなくユーザーAPIで実行されるため、セッション情報を更新する
      dispatch(setUserNickname(nickname))
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      dispatch(showError())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, isEmailChange, dispatch, logout, nickname])

  return (
    <>
      <NavMenu>
        <div className="m-3">
          <Row className="mb-3 g-0">
            <Col className="font-x-large fw-bold">アカウント情報</Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col md={8}>
                  <InputFormat
                    label="お名前"
                    placeholder="お名前"
                    className="mt-4"
                    value={nickname}
                    size={COLUMN_SIZES.MIDDLE}
                    onChange={setNickname}
                    validations={[Rules.Required]}
                    onValidate={setNameValidity}
                    maxLength={100}
                  />

                  <InputFormat
                    label="メールアドレス"
                    placeholder="メールアドレス"
                    className="mt-4"
                    value={email}
                    size={COLUMN_SIZES.MIDDLE}
                    formText="メールアドレス変更保存後、自動的にログアウトします。新しいメールアドレスに確認メールが送信されますので、再度ログインしてください。"
                    onChange={value => setEmail(value)}
                    validations={[Rules.Required, Rules.Email]}
                    onValidate={setEmailValidity}
                    maxLength={100}
                  />

                  <FormGroup row className="mt-4">
                    <Label md={4}>パスワード</Label>
                    <Col md={8} className="align-self-center">
                      強固なパスワードにするには、数字、文字、記号を組み合わせることをお勧めします。
                      また、推測が困難で実際に使われていない言葉を使ったり、このアカウントで独自に設定することをお勧めします。
                      <Row className="mt-3">
                        <Col md={5}>
                          <Button outline color="primary" size="sm" block onClick={() => setOpenPasswordChange(true)}>
                            パスワードを変更
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>

                  <FormGroup row className="mt-4">
                    <Label md={4}>アカウントの削除</Label>
                    <Col md={8} className="align-self-center">
                      アカウントを削除すると、アカウント情報などはすべて失われ、復旧できません。
                      <Row className="mt-3">
                        <Col md={5}>
                          <Button outline color="danger" size="sm" block onClick={() => setOpenDelete(true)}>
                            アカウントを削除
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>

            <CardSubmitFooter
              onCancel={() => setOpenEditChangesDiscardDialog(true)}
              onSubmit={onSubmit}
              cancelDisabled={unchanged}
              submitDisabled={unchanged || disabled}
            />
          </Card>
        </div>
      </NavMenu>

      <PasswordChange
        isOpen={openPasswordChange}
        onSuccess={handlePasswordChange}
        onCancel={() => setOpenPasswordChange(false)}
      />

      <AccountDelete
        isOpen={openDelete}
        tenantId={user.userHasTenants[0].id}
        onSuccess={handleDelete}
        onCancel={() => setOpenDelete(false)}
        userId={user.userId}
      />

      <EditChangesDiscardDialog
        isOpen={openEditChangesDiscardDialog}
        onCancel={() => setOpenEditChangesDiscardDialog(false)}
        onDiscard={handleEditChangesDiscard}
      />
    </>
  )
}
export default AccountInformationEdit
