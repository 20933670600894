import { encode } from 'iconv-lite'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button, FormGroup, Input, Label } from 'reactstrap'

import { WORKER_TYPES } from 'api/workers/constants'
import type { WorkerType } from 'api/workers/types'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES, exportData } from 'slices/utils'
import { selectWorkersStatus, importWorkers, exportWorkers } from 'slices/workersSlice'

import { CustomModal, FileInput } from 'components/common'
import type { CharacterCodeType } from 'components/common/CharacterCodeSelect/CharacterCodeSelect'
import { CHARACTER_CODE_TYPES, CharacterCodeSelect } from 'components/common/CharacterCodeSelect/CharacterCodeSelect'
import { WORKER_TYPES_LABEL } from 'components/common/constants'

import type { LoadFileProps } from 'hooks/useImportCsv'
import { useImportCsv } from 'hooks/useImportCsv'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES = [
  '名前',
  'メンバーID',
  '職掌',
  'スキル設定',
  'メンバー削除',
]
const MAX_MEMBER_IMPORT_COLUMNS = 2000

const WorkersImportDialog = ({ isOpen, onSuccess, onCancel }: Props) => {
  const [submitted, setSubmitted] = useState(false)
  const [characterCode, setCharacterCode] = useState<CharacterCodeType>(CHARACTER_CODE_TYPES.SHIFT_JIS)
  const [selectedWorkerType, setSelectedWorkerType] = useState<WorkerType>(WORKER_TYPES.REGULAR_MEMBER)
  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const dispatch = useDispatch()
  const { importDisabled, setFile, importCsvFile, modalErrorMessage, setModalErrorMessage } = useImportCsv()

  const isRegularMemberSelected = useMemo(
    () => selectedWorkerType === WORKER_TYPES.REGULAR_MEMBER,
    [selectedWorkerType]
  )

  const handleSaveClick = () => {
    const handleFileLoad = (data: LoadFileProps) => {
      dispatch(importWorkers(data.fileName, selectedWorkerType, data.content))
      setSubmitted(true)
    }
    importCsvFile(handleFileLoad, characterCode)
    setSelectedWorkerType(WORKER_TYPES.REGULAR_MEMBER)
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      onSuccess()
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      setModalErrorMessage('エラーが発生しました。')
    }

    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, setModalErrorMessage])

  const handleDownloadFormat = () => {
    if (isRegularMemberSelected) {
      const data = {
        workerType: selectedWorkerType,
        replaceAverageProductivity: false,
        includeUnaffiliatedWorker: false,
        targetWorkspaceIds: [],
      }
      dispatch(exportWorkers(data, 'レギュラーメンバー登録シート.csv'))
      return
    }

    const csvAllScheduleTypesCsvHeader = allScheduleTypes.map(s => `人時生産性_${s?.name}_${s?.workspaceName}[${s.id}]`)

    MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES.splice(
      MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES.length - 1,
      0,
      ...csvAllScheduleTypesCsvHeader
    )
    const formatHeader = [MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES]
      .map(row => row.join(','))
      .join('\n')
    const csvData = encode(formatHeader, characterCode)
    const blob = new Blob([csvData], { type: 'text/csv' })
    exportData(blob, 'スポットメンバー登録シート.csv')
  }

  const WorkerTypeText = useMemo(
    () => (isRegularMemberSelected ? 'レギュラーメンバー' : 'スポットメンバー'),
    [isRegularMemberSelected]
  )

  const handleCharacterCodeChange = (value: CharacterCodeType) => {
    setCharacterCode(value)
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="メンバーインポート"
      approveLabel="CSVインポート"
      approveDisabled={importDisabled}
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
      submitName="member-import-submit"
    >
      <div className="pb-4">
        <div>
          <div className="my-2 fw-bold">メンバー属性の選択</div>
          <div className="mb-2">メンバーエクスポートするメンバー属性を選択してください</div>
          <FormGroup check className="d-flex flex-column row-gap-2">
            <Label check>
              <Input
                type="radio"
                name="radio1"
                className="me-2"
                checked={isRegularMemberSelected}
                onChange={() => setSelectedWorkerType(WORKER_TYPES.REGULAR_MEMBER)}
              />
              {WORKER_TYPES_LABEL.find(w => w.key === WORKER_TYPES.REGULAR_MEMBER)?.value}
            </Label>
            <Label check>
              <Input
                type="radio"
                name="radio1"
                className="me-2"
                checked={!isRegularMemberSelected}
                onChange={() => setSelectedWorkerType(WORKER_TYPES.SPOT_MEMBER)}
              />
              {WORKER_TYPES_LABEL.find(w => w.key === WORKER_TYPES.SPOT_MEMBER)?.value}
            </Label>
          </FormGroup>
        </div>
        <div className="my-3">
          {`下記${WorkerTypeText}登録用シートをダウンロードして、同じフォーマットでCSVアップロードをしてください。`}
        </div>
        <div className="w-60 mb-3">
          <CharacterCodeSelect characterCode={characterCode} onChange={handleCharacterCodeChange} />
        </div>
        <ul className="mb-3">
          <li>
            メンバーインポートについて詳しくは
            <a
              className="text-decoration-none"
              target="_blank"
              rel="noreferrer"
              href="https://help.smileboard.jp/member-import"
            >
              こちら
            </a>
            をご確認ください。
          </li>
          <li>データを更新する場合は現在のメンバーマスタをエクスポートし、編集してインポートしてください。</li>
          <li>
            {`一度にインポートできるのはヘッダーを除いて${MAX_MEMBER_IMPORT_COLUMNS}
            行までです。適宜分割してインポートしてください。`}
            <span className="text-danger">
              {`アップロードされたCSVファイルがヘッダーを除いて${MAX_MEMBER_IMPORT_COLUMNS}行より多い場合は処理は実施されません。`}
            </span>
          </li>
        </ul>
        <Button color="link" className="mb-3 ps-0 shadow-none text-decoration-none" onClick={handleDownloadFormat}>
          {`${WorkerTypeText}登録用シートをダウンロード`}
        </Button>
        <FileInput id="memberFile" accept=".csv" onChange={f => setFile(f)} />
      </div>
    </CustomModal>
  )
}

export default WorkersImportDialog
