import styles from './styles.module.scss'

import type { ReactElement, ReactNode } from 'react'

type TypedProps = {
  selectedIndex: number
  onChange: (index: number) => void
  children: ReactElement[]
}
const PivotOuterIndex = ({ selectedIndex, onChange, children }: TypedProps) => {
  return (
    <>
      <div className={`${styles.pivotMenu} overflow-x-auto d-flex`}>
        {children.map((child, i) => (
          <div
            key={`tab-${i}`}
            className={`d-flex ${styles.label}`}
            onClick={() => onChange(i)}
            data-is-active={i === selectedIndex}
          >
            {child.props.icon && <i className={`icf-${child.props.icon} me-2 font-large ${child.props.iconColor}`} />}
            {child.props.headerText}
          </div>
        ))}
      </div>
      <div className="flex-grow-1 position-relative py-3 overflow-auto">
        {children
          .filter((_, i: number) => i === selectedIndex)
          .map((child, i) => {
            return (
              <div key={`content-${i}`} className="d-block h-100 overflow-hidden">
                {child}
              </div>
            )
          })}
      </div>
    </>
  )
}

export default PivotOuterIndex
export const PivotItem = ({
  children,
}: {
  headerText: string
  icon?: string
  iconColor?: string
  children?: ReactNode
}) => <>{children}</>
