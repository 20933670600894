import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type {
  GetAggregateStatusResponse,
  GetManualInputResponse,
  PutManualInputType,
  UpdateManualInputResponse,
} from './types'

export const getWorkResults = async (workDate: string): Promise<GetManualInputResponse> => {
  const url = `/work-date/${workDate}/work-results`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const postWorkResults = async (
  workDate: string,
  workResults: PutManualInputType
): Promise<UpdateManualInputResponse> => {
  const url = `/work-date/${workDate}/work-results`

  const response = await axiosClientWithTenantId.post(url, workResults)
  return response.data
}

export const getAggregateStatus = async (workDate: string, revision: number): Promise<GetAggregateStatusResponse> => {
  const url = `/work-date/${workDate}/work-results/revision/${revision}/aggregate-status`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const importWorkResults = async (
  workDate: string,
  workResults: FormData
): Promise<UpdateManualInputResponse> => {
  const url = `/work-date/${workDate}/work-results-with-file`

  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  const response = await axiosClientWithTenantId.post(url, workResults, { headers })
  return response.data
}
