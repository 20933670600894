import { createSlice } from '@reduxjs/toolkit'
import { sortBy, partition } from 'es-toolkit'

import { WORKER_TYPES } from 'api/workers/constants'
import type {
  CreateWorkerEditDataType,
  ExportWorkersDataType,
  PartialWorkerData,
  UpdateWorkerEditDataType,
  WorkerData,
  WorkerListResponse,
  WorkerResponse,
  WorkerScheduleUpdateType,
  WorkerType,
} from 'api/workers/types'
import * as API from 'api/workers/workers'

import { downloadByURL, handleApiError } from 'slices/utils'

import { showError } from './notificationSlice'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type CreateUpdateParam = {
  createData: WorkerScheduleUpdateType[]
  updateData: Array<{ data: WorkerScheduleUpdateType; scheduleId: number }>
}

type WorkersState = {
  isRequesting: boolean
  errorMessage: string
  partialWorkers: PartialWorkerData[]
  worker?: WorkerData
}

const initialState: WorkersState = {
  isRequesting: false,
  errorMessage: '',
  partialWorkers: [],
  worker: undefined,
}

export const workersSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkerListSuccess: (state, action: PayloadAction<WorkerListResponse>) => {
      state.isRequesting = false
      const [regularWorkers, spotWorkers] = partition(
        action.payload.partialWorkers,
        worker => worker.workerType === WORKER_TYPES.REGULAR_MEMBER
      )

      const sortedRegularWorkers = sortBy(regularWorkers, ['wmsMemberId'])
      const sortedSpotWorkers = sortBy(spotWorkers, ['id'])
      state.partialWorkers = sortedRegularWorkers.concat(sortedSpotWorkers)
    },
    getWorkerSuccess: (state, action: PayloadAction<WorkerResponse>) => {
      state.isRequesting = false
      state.worker = action.payload.worker
    },

    requestSuccess: state => {
      state.isRequesting = false
    },
    getExportDataUrlSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  apiFailure,
  getWorkerListSuccess,
  getWorkerSuccess,
  requestSuccess,
  getExportDataUrlSuccess,
} = workersSlice.actions

export const getWorkerList = (): AppThunk => async dispatch => {
  dispatch(startRequest())

  try {
    const res = await API.getWorkerList()
    dispatch(getWorkerListSuccess(res))
  } catch (res) {
    handleApiError(res as AxiosError, dispatch, apiFailure)
  }
}

export const getWorker =
  (workerId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getWorker(workerId)
      dispatch(getWorkerSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const createWorker =
  (data: CreateWorkerEditDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.createWorker(data)
      dispatch(requestSuccess())
      dispatch(getWorkerList())
      dispatch(getWorker(res.id))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const updateWorker =
  (workerId: number, data: UpdateWorkerEditDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.updateWorker(workerId, data)
      dispatch(requestSuccess())
      dispatch(getWorkerList())
      dispatch(getWorker(workerId))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const deleteWorker =
  (workerId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.deleteWorker(workerId)
      dispatch(requestSuccess())
      dispatch(getWorkerList())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const importWorkers =
  (fileName: string, workerType: WorkerType, csvContent: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const response = await API.workersUploadUrl(fileName, workerType)
      await API.putUploadUrl(response.uploadUrl, csvContent)
      dispatch(requestSuccess())
    } catch (error) {
      handleApiError(error as AxiosError, dispatch, apiFailure)
    }
  }

export const exportWorkers =
  (data: ExportWorkersDataType, fileName: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const exportDataResponse = await API.exportWorkers(data)
      if (!exportDataResponse.downloadUrl) {
        dispatch(showError())
        dispatch(getExportDataUrlSuccess())
        return
      }
      try {
        await downloadByURL(exportDataResponse.downloadUrl, fileName)
      } catch {
        dispatch(showError())
      }
      dispatch(getExportDataUrlSuccess())
    } catch (err) {
      handleApiError(err as AxiosError, dispatch, apiFailure)
    }
  }

export const selectWorkersStatus = (state: RootState) => ({ ...state.workers })

export default workersSlice.reducer
