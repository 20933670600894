import { Table } from 'reactstrap'

import styles from './Table.module.scss'

import type { MouseEvent } from 'react'

/*  valueにJSX.Elementを指定できるので
 * [
 *   { value: 'SBT00002' },
 *   { value: 3 },
 *   { value: <div className="text-primary text-truncate" title="長い名前">xx</div }
 * ]
 * のような指定も可能
 * ただしJSX.Elementを使う場合には title 属性と text-truncate は
 * 自分で入れる必要がある。
 *
 * className に指定したクラス名はtd要素に適用される
 */

export type TableValue = JSX.Element | string | number
export type TableHeaderType = {
  value: TableValue
  width?: string
  className?: string
}
export type TableCellType = {
  value: TableValue
  className?: string
  onClick?: () => void
}
export type TableProps = {
  header: TableHeaderType[]
  body: TableCellType[][]
  onRowClick?: (index: number, event: MouseEvent) => void
  name?: string // APMを判別するための文字列
}

type HeaderProps = {
  items: TableHeaderType[]
}
const HeaderCell = ({ items }: HeaderProps) => {
  return (
    <tr>
      {items.map((col, index) => {
        return (
          <td
            key={`table-header-${index}-${col.value}`}
            className={`overflow-hidden bg-light ${col.className} ${styles.cell}`}
            style={{ width: col.width }}
          >
            <div className="text-truncate">{col.value}</div>
          </td>
        )
      })}
    </tr>
  )
}

const convertTitle = (value: TableValue): string => {
  if (typeof value === 'string') {
    return value
  }
  if (typeof value === 'number') {
    return value.toString()
  }
  return ''
}

type RowProps = {
  items: TableCellType[]
  rowIndex: number
}
const Row = ({ items, rowIndex }: RowProps) => {
  return (
    <>
      {items.map((cell, cellIndex) => (
        <td key={`table-cell-${rowIndex}-${cellIndex}-${cell.value}`} className={`${cell.className} ${styles.cell}`}>
          {typeof cell.value === 'string' || typeof cell.value === 'number' ? (
            <div className="text-truncate" title={convertTitle(cell.value)}>
              {cell.value}
            </div>
          ) : (
            cell.value
          )}
        </td>
      ))}
    </>
  )
}

export const CustomTable = ({ header, body, onRowClick, name }: TableProps) => {
  const pointerClass = onRowClick ? styles.pointer : ''
  const handleClick = (rowIndex: number, event: MouseEvent) => {
    onRowClick && onRowClick(rowIndex, event)
  }
  return (
    <Table className={`mb-0 ${styles.table}`} hover>
      <thead className={styles.header}>
        <HeaderCell items={header} />
      </thead>
      <tbody>
        {body.map((row, rowIndex) => (
          <tr
            key={`table-row-${rowIndex}`}
            className={pointerClass}
            onClick={e => handleClick(rowIndex, e)}
            data-transaction-name={name}
          >
            <Row items={row} rowIndex={rowIndex} />
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
export default CustomTable
