import { Modal as ReactModal } from 'reactstrap'

import styles from './Modal.module.scss'

import type { ReactNode } from 'react'

type Props = {
  isOpen: boolean
  children: ReactNode
  zIndex?: number
  overflow?: string
}

export const Modal = ({ isOpen, children, zIndex = 2000, overflow }: Props) => {
  return (
    <ReactModal className={`position-absolute ${styles.modal}`} isOpen={isOpen} style={{ zIndex, overflow }}>
      <div className="mx-3 mt-3">{children}</div>
    </ReactModal>
  )
}

export default Modal
