import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardTitle, Input, Label } from 'reactstrap'

import { getBopReportsProfitAndLoss, selectBopReportsStatus } from 'slices/bopReportsSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { BadgeLabel } from 'components/common'
import BopTable from 'components/common/BopReports/BopTable'
import { UpdateLabel } from 'components/common/UpdateLabel/UpdateLabel'

import useBopProfitAndLoss from 'hooks/useBopProfitAndLoss'
import { useBopReportsQuery } from 'hooks/useBopReportsQuery'
import useBusinessTime from 'hooks/useBusinessTime'

import { BopReportsCommon } from './BopReportsCommon'
import { DATA_TYPE_ITEMS } from './constants'

import type { ChangeEvent } from 'react'

export const BopReportsProfitAndLoss = () => {
  const [dataType, setDataType] = useState<'conjecturedEstimates' | 'actuals'>('conjecturedEstimates')
  const [isEstimateChecked, setIsEstimateChecked] = useState(true)
  const [isDailyReportChecked, setIsDailyReportChecked] = useState(true)
  const [isDataSourceOpen, setIsDataSourceOpen] = useState(false)

  const { queryStart, queryEnd } = useBopReportsQuery()
  const { processProfitAndLossData } = useBopProfitAndLoss()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { bopReports } = useSelector(selectBopReportsStatus, shallowEqual)
  const workspaceIds = useMemo(() => partialWorkspaces.map(w => w.id), [partialWorkspaces])
  const dispatch = useDispatch()
  const { getWorkDate } = useBusinessTime()

  useEffect(() => {
    const queryParams = {
      from: getWorkDate(queryStart),
      to: getWorkDate(queryEnd),
      ...(isDailyReportChecked && { isDailyReport: true }),
    }

    dispatch(getBopReportsProfitAndLoss(queryParams))
  }, [dispatch, queryStart, queryEnd, getWorkDate, isDailyReportChecked])

  const bopCols = useMemo(() => {
    if (!bopReports) {
      return []
    }
    return processProfitAndLossData(bopReports[dataType], bopReports.estimates)
  }, [bopReports, dataType, processProfitAndLossData])

  // データソースリストの作成
  const dataSourceItems = useMemo(
    () =>
      bopReports?.workspaces?.map(({ id, dataType: workspaceDataType }) => {
        const name = partialWorkspaces.find(pw => pw.id === id)?.name || ''
        return { id, name, ...DATA_TYPE_ITEMS[workspaceDataType] }
      }) || [],
    [bopReports?.workspaces, partialWorkspaces]
  )

  const handleConjecturedEstimatesChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDataType(e.target.checked ? 'conjecturedEstimates' : 'actuals')
  }, [])

  const handleDataSourceOpen = useCallback(() => {
    setIsDataSourceOpen(prevState => !prevState)
  }, [])

  const handleIsEstimateChecked = useCallback((checked: boolean) => {
    setIsEstimateChecked(checked)
  }, [])

  const handleIsDailyReportChecked = useCallback((checked: boolean) => {
    setIsDailyReportChecked(checked)
  }, [])

  return (
    <BopReportsCommon selectedWorkspaceIds={workspaceIds}>
      <Card className="mt-2">
        <CardBody className="p-4">
          <CardTitle className="mb-3 fw-bold font-large">損益詳細</CardTitle>

          <div className="d-flex mb-3">
            <div className="form-check me-3">
              <Input
                className="form-check-input"
                id="estimate"
                checked={isEstimateChecked}
                type="checkbox"
                onChange={e => handleIsEstimateChecked(e.target.checked)}
              />
              <Label className="form-check-label" for="estimate">
                見込表示
              </Label>
            </div>
            <div className="form-check me-3">
              <Input
                className="form-check-input"
                id="daily-report"
                checked={isDailyReportChecked}
                type="checkbox"
                onChange={e => handleIsDailyReportChecked(e.target.checked)}
              />
              <Label className="form-check-label" for="daily-report">
                日報実績を利用
              </Label>
            </div>
            <div className="form-check">
              <Input
                className="form-check-input"
                id="conjectured-estimates"
                checked={dataType === 'conjecturedEstimates'}
                type="checkbox"
                onChange={handleConjecturedEstimatesChange}
              />
              <Label className="form-check-label" for="conjectured-estimates">
                見込を含んだ予測表示
              </Label>
            </div>
            <div className="ms-auto">
              <button className="btn p-0 border-0 bg-transparent text-gray font-x-small" onClick={handleDataSourceOpen}>
                <i className={`icf-carot_${isDataSourceOpen ? 'down' : 'right'}`} />
                データソースを表示
              </button>
            </div>
          </div>

          {isDataSourceOpen && (
            <div className="d-flex flex-wrap my-3 gap-2">
              {dataSourceItems.map(dataSource => (
                <BadgeLabel
                  key={dataSource.id}
                  label={dataSource.name}
                  dataSourceLabel={dataSource.dataTypeLabel}
                  dataSourceColor={dataSource.dataTypeColor}
                />
              ))}
            </div>
          )}

          <BopTable bopCols={bopCols} isCheckedEstimate={isEstimateChecked} />

          <UpdateLabel updatedAt={bopReports?.updatedAt} />
        </CardBody>
      </Card>
    </BopReportsCommon>
  )
}
