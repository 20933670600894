import { useMemo } from 'react'

import placeholderImage from './notSelectedWorkspace.svg'

import styles from './styles.module.scss'

export const PLACE_HOLDER_TYPES = {
  WORKSPACE: 'workspace',
  DASHBOARD: 'dashboard',
  UNIT_COSTS: 'unitCosts',
} as const
export type PlaceholderType = (typeof PLACE_HOLDER_TYPES)[keyof typeof PLACE_HOLDER_TYPES]

type Props = {
  type: PlaceholderType
}

const placeholderList = [
  {
    type: PLACE_HOLDER_TYPES.WORKSPACE,
    title: '表示するワークスペースを選択しましょう。',
    text:
      '上の「表示ワークスペースの選択」から、表示したいワークスペースを選択してください。\n' +
      '「表示ワークスペースの保存」をすることで、次回アクセス時にも同じワークスペースを表示します。',
  },
  {
    type: PLACE_HOLDER_TYPES.DASHBOARD,
    title: '表示する作業を選択してあなたの\nダッシュボードを完成させましょう｡',
    text:
      '上の「表示作業の選択」から、ダッシュボードに表示する作業を選択してください。\n' +
      '「表示作業の保存」を押すことで、あなたに最適なダッシュボードが完成します。\n' +
      '表示作業はいつでも変更・保存が可能です。',
  },
  {
    type: PLACE_HOLDER_TYPES.UNIT_COSTS,
    title: '表示するキー作業を選択しましょう。',
    text:
      '上の「表示キー作業の選択」から、表示したい作業を選択してください。\n' +
      '「表示キー作業の保存」をすることで、次回アクセス時にも同じ作業を表示します。',
  },
]

export const NotSelectedPlaceholder = ({ type }: Props) => {
  const placeholderData = useMemo(() => {
    return placeholderList.find(item => item.type === type)
  }, [type])

  return (
    <div className={`d-flex ${styles.placeholderContainer}`}>
      <div className={styles.placeholderImageContainer}>
        <img className={styles.placeholderImage} src={placeholderImage} alt="notSelectedWorkspace" />
      </div>
      <div className={`my-auto ${styles.placeholderTextContainer}`}>
        <div className={`${styles.placeholderBoldText} mb-2`}>{placeholderData?.title}</div>
        {placeholderData?.text}
      </div>
    </div>
  )
}
export default NotSelectedPlaceholder
