import { isEqual } from 'es-toolkit'
import moment from 'moment'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Button, Col, Card, CardBody, CardTitle } from 'reactstrap'

import { ROLE } from 'api/users/constants'
import type { EditWorkspaceProps } from 'api/workspaces/types'

import { getTenantSummary } from 'slices/dashboardSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { selectUsersStatus } from 'slices/usersSlice'
import { ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'
import { selectWorkspacesStatus, updateWorkspace, getWorkspace, getWorkspaceList } from 'slices/workspacesSlice'

import { NavMenu, InputFormat, SelectBoxFormat, CardSubmitFooter, BadgeLabel } from 'components/common'
import type { SuggestionItem } from 'components/common/FormFormat/Suggestion'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'

import useAuthority from 'hooks/useAuthority'

import RelatedWorkspaceEdit from './RelatedWorkspaceEdit'
import WorkspaceAdminEdit from './WorkspaceAdminEdit'
import WorkspaceDelete from './WorkspaceDelete'

import styles from './WorkspaceEdit.module.scss'

type WorkspaceUpdateType = {
  name: string
  admins: SuggestionItem[]
  members: SuggestionItem[]
  relatedWorkspaces: { id: number; value: string }[]
  autoArchiveTime: string
}

const WorkspaceEdit = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)

  const [editData, setEditData] = useState<WorkspaceUpdateType>({
    name: '',
    admins: [],
    members: [],
    relatedWorkspaces: [],
    autoArchiveTime: '08:00',
  })
  const [initData, setInitData] = useState<WorkspaceUpdateType>({
    name: '',
    admins: [],
    members: [],
    relatedWorkspaces: [],
    autoArchiveTime: '08:00',
  })
  const [nameValidity, setNameValidity] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const navigate = useNavigate()
  const { selectedTenantUsers } = useSelector(selectUsersStatus, shallowEqual)
  const { partialWorkspaces, workspace, isRequesting, errorMessage } = useSelector(selectWorkspacesStatus, shallowEqual)

  const dispatch = useDispatch()

  const { isReadOnlyWorkspace, isProcessAdmin } = useAuthority(workspaceId)

  useEffect(() => {
    dispatch(getWorkspaceList())
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, workspaceId])

  useEffect(() => {
    if (!workspace) {
      return
    }

    const admins = workspace.memberIds
      .map(memberId => selectedTenantUsers.find(u => u.userId === memberId))
      .flatMap(u => u ?? [])
      .filter(u => {
        const userHasTenant = u.partialUserHasTenants[0]
        userHasTenant?.role === ROLE.ADMIN
      })
      .map(u => ({
        id: u.userId,
        value: u.partialUserHasTenants[0].nickname ?? '',
      }))
    const members = workspace.memberIds
      .map(memberId => selectedTenantUsers.find(u => u.userId === memberId))
      .flatMap(u => u ?? [])
      .filter(u => u.partialUserHasTenants[0].role === ROLE.PROCESS_ADMIN)
      .map(u => ({
        id: u.userId,
        value: u.partialUserHasTenants[0].nickname ?? '',
      }))
    const relatedWorkspaces = workspace.relatedWorkspaceIds
      .map(relatedWorkspaceId => partialWorkspaces.find(w => w.id === relatedWorkspaceId))
      .flatMap(w => w ?? [])
      .map(w => ({ id: w.id, value: w.name }))

    setEditData({
      name: workspace.name,
      admins,
      members,
      relatedWorkspaces: relatedWorkspaces,
      autoArchiveTime: workspace.autoArchiveTime ? workspace.autoArchiveTime : '',
    })
    setInitData({
      name: workspace.name,
      admins,
      members,
      relatedWorkspaces: relatedWorkspaces,
      autoArchiveTime: workspace.autoArchiveTime ? workspace.autoArchiveTime : '',
    })
  }, [workspace, selectedTenantUsers, partialWorkspaces])

  const disabled = useMemo(() => !nameValidity, [nameValidity])

  const unchanged = useMemo(() => {
    const edit = {
      name: editData.name,
      memberIds: editData.members.map(member => member.id).sort(),
      relatedWorkspaceIds: editData.relatedWorkspaces.map(({ id }) => id).sort(),
      autoArchiveTime: editData.autoArchiveTime,
    }
    const initial = {
      name: initData.name,
      memberIds: initData.members.map(member => member.id).sort(),
      relatedWorkspaceIds: initData.relatedWorkspaces.map(({ id }) => id).sort(),
      autoArchiveTime: initData.autoArchiveTime,
    }
    return isEqual(edit, initial)
  }, [editData, initData])

  const archiveTimeItems = [...Array(48)].map((_value, i) => {
    const h = moment()
      .startOf('day')
      .add(i * 30, 'minutes')
      .format('HH:mm')
    return { value: h }
  })

  const onSubmit = () => {
    if (!workspace) {
      return
    }

    setSubmitted(true)

    const data: EditWorkspaceProps = {
      name: editData.name,
      memberIds: editData.admins.concat(editData.members).map(member => member.id.toString()),
      relatedWorkspaceIds: editData.relatedWorkspaces.map(({ id }) => id),
      autoArchiveTime: editData.autoArchiveTime,
    }
    dispatch(updateWorkspace(workspaceId, data))
  }

  const onCancel = () => {
    setEditData(initData)
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      if (errorMessage === ERROR_STATUS_CODE.CONFLICT) {
        dispatch(showError({ errorMessage: 'すでにこの名前の作業は存在しています。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, navigate])

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <div className="font-x-large flex-grow-1 fw-bold">設定</div>
              <div className="px-2 align-self-center">{workspace && <BadgeLabel label={workspace?.name || ''} />}</div>
            </div>
          </div>
        </div>
        <Row className={styles.row}>
          <Col className="h-100">
            <Card className="h-100">
              <>
                <div className={`h-100 overflow-auto ${styles.pe25}`}>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <CardTitle className="font-middle fw-bold">ワークスペースの詳細</CardTitle>
                      <span className="font-x-small text-muted">※必須項目</span>
                    </div>

                    <InputFormat
                      className="mb-0"
                      label="名称※"
                      placeholder="ワークスペース名を入力"
                      value={editData.name}
                      maxLength={100}
                      onChange={value => setEditData({ ...editData, name: value })}
                      validations={[Rules.Required]}
                      onValidate={setNameValidity}
                    />
                  </CardBody>
                  <CardBody>
                    <CardTitle className="font-middle fw-bold">ワークスペース管理者の登録</CardTitle>
                    <div className="my-2">
                      ワークスペースの管理者を登録します。オーナーは初期状態で管理者として登録されています。オーナーを管理者から除外することはできません。
                    </div>
                    <WorkspaceAdminEdit
                      admins={editData.admins}
                      members={editData.members}
                      onChange={(admins, members) => setEditData({ ...editData, admins, members })}
                    />
                  </CardBody>
                  <CardBody>
                    <CardTitle className="font-middle fw-bold">連携ワークスペースの設定</CardTitle>
                    <div className="mt-2 mb-1">
                      このワークスペースの前工程となるワークスペース、後工程となるワークスペースを設定しておくことで、ダッシュボードから設定したワークスペースの進捗を確認することができます。
                    </div>
                    <div className="mt-3">連携するワークスペース</div>
                    <RelatedWorkspaceEdit
                      workspaceId={workspaceId}
                      relatedWorkspaces={editData.relatedWorkspaces}
                      onChange={relatedWorkspaces => setEditData({ ...editData, relatedWorkspaces })}
                    />
                  </CardBody>
                  <CardBody className="pb-0">
                    <CardTitle className="font-middle fw-bold">作業計画の自動アーカイブ</CardTitle>
                    <div className="my-2">
                      作業計画を自動アーカイブすることで、アーカイブされた作業計画と最終的な作業計画の変更割合を計画変更率として算出します。計画変更率は「レポート」で確認できます。デフォルトでは8:00が設定されています。
                    </div>
                    <div className="mt-3">
                      <SelectBoxFormat
                        className="mb-0"
                        label="自動アーカイブ時刻"
                        value={editData.autoArchiveTime}
                        size={COLUMN_SIZES.SHORT}
                        items={archiveTimeItems}
                        onChange={e => setEditData({ ...editData, autoArchiveTime: e.value })}
                      />
                    </div>
                  </CardBody>
                  <CardBody>
                    <CardTitle className="font-middle fw-bold py-1">ワークスペースの削除</CardTitle>
                    <div className="my-2">
                      ワークスペースを削除すると、登録してある作業情報などはすべて失われ、復旧できません。
                    </div>
                    <Button
                      outline
                      color="danger"
                      className="mt-2"
                      disabled={isProcessAdmin}
                      onClick={() => setOpenDelete(true)}
                    >
                      ワークスペースを削除
                    </Button>
                  </CardBody>
                </div>
                <CardSubmitFooter
                  onCancel={() => onCancel()}
                  onSubmit={onSubmit}
                  submitDisabled={disabled || unchanged || isReadOnlyWorkspace}
                  cancelDisabled={unchanged}
                  updatedBy={workspace?.updatedByName}
                  updatedAt={workspace?.updatedAt}
                />
              </>
            </Card>
          </Col>
        </Row>

        <WorkspaceDelete
          isOpen={openDelete}
          workspaceId={workspaceId!}
          onSuccess={() => {
            navigate('/workspaces')
            // ワークスペース削除後にダッシュボードへ遷移すると
            // tenantSummary.workspaceDataに含まれるワークスペースのworker_countsを取得する
            // ただし、削除されたワークスペースも含まれるため、エラーが発生
            // これを防ぐために、削除後にtenantSummaryを更新する
            dispatch(getTenantSummary(moment().format('YYYY-MM-DD'), { dashboardFilter: true }))
          }}
          onCancel={() => setOpenDelete(false)}
        />
      </div>
    </NavMenu>
  )
}

export default WorkspaceEdit
