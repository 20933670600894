import { useMemo } from 'react'
import { Card, CardBody } from 'reactstrap'

import type { ColorType } from 'api/types'

import { BadgeLabel } from 'components/common'

import styles from './TeamWorkPlanCard.module.scss'

export type TeamWorkPlanCardItem = {
  label: string
  color: ColorType
  targetValue: number
  planValue: number
}
const TeamWorkPlanCard = (props: TeamWorkPlanCardItem) => {
  const { label, color, targetValue, planValue } = props
  const rate = useMemo(
    () => (targetValue ? Math.floor((planValue / targetValue) * 100) : planValue ? 100 : 0),
    [planValue, targetValue]
  )
  const barStyle = useMemo(
    () => ({
      width: `${rate}%`,
      backgroundColor: rate < 100 ? '#e33840' : '#97c5e9',
    }),
    [rate]
  )

  return (
    <Card className={styles.cardContainer}>
      <CardBody className="d-flex pb-0">
        <BadgeLabel label={label} color={color} />
      </CardBody>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <small className="text-muted">予定からの予測</small>
            <div className="font-middle fw-bold">{Math.floor(planValue).toLocaleString()}</div>
          </div>
          <div>
            <small className="text-muted">目標の</small>
            <div className="text-danger">{rate}%</div>
          </div>
          <div>
            <small className="text-muted">目標</small>
            <div>{targetValue.toLocaleString()}</div>
          </div>
        </div>
        <div className={styles.barContainer}>
          <div className="h-100" style={barStyle}></div>
        </div>
      </CardBody>
    </Card>
  )
}
export default TeamWorkPlanCard
