import dayjs from 'dayjs'
import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardText } from 'reactstrap'

import { COLOR_TYPES } from 'api/constants'
import type { ColorType } from 'api/types'
import { WORKER_TYPES } from 'api/workers/constants'

import { getGroupList, selectGroupsStatus } from 'slices/groupsSlice'
import { selectSessionStatus, setTeamGroup } from 'slices/sessionSlice'
import { getWorkerList, selectWorkersStatus } from 'slices/workersSlice'

import { NO_GROUP_ID } from 'components/TeamAssignment/utils'

import useLogout from 'hooks/useLogout'

import styles from './styles.module.scss'

export const TeamSelectGroups = () => {
  const dispatch = useDispatch()
  const { logout } = useLogout()
  const {
    team: { workspaceId },
  } = useSelector(selectSessionStatus, shallowEqual)
  useEffect(() => {
    dispatch(getGroupList(workspaceId))
  }, [dispatch, workspaceId])
  useEffect(() => {
    dispatch(getWorkerList())
  }, [dispatch])

  const { groups } = useSelector(selectGroupsStatus, shallowEqual)
  const { partialWorkers } = useSelector(selectWorkersStatus, shallowEqual)

  const allGroups = useMemo(
    () =>
      partialWorkers
        .filter(worker => worker.workerType === WORKER_TYPES.REGULAR_MEMBER)
        .some(worker => worker.workspaceId === workspaceId && worker.groupId === null)
        ? // groupIdがnull(未所属)の作業者がいた場合に未所属のグループを追加する
          [
            ...groups,
            {
              id: NO_GROUP_ID,
              workDate: dayjs().local().format('YYYY-MM-DD'),
              revision: 1,
              name: '未所属',
              color: COLOR_TYPES.SILVER,
              updatedAt: dayjs().local().format('YYYY-MM-DD HH:mm:ss'),
              updatedByName: null,
            },
          ]
        : groups,
    [groups, partialWorkers, workspaceId]
  )

  const navigate = useNavigate()
  const handleClick = (groupId: number, groupName: string, groupColor: ColorType) => {
    dispatch(setTeamGroup({ groupId, groupName, groupColor }))
    navigate(`/team-select-workers`)
  }

  return (
    <>
      <div className={styles.header}>
        <i className="icf-chevron_left font-x-large" onClick={() => navigate('/team-select-workspaces')} />
        <div className="font-x-large fw-bold text-center">グループ選択</div>
        <div className="text-end" onClick={() => logout()}>
          ログアウト
        </div>
      </div>
      <div className={`position-sticky ${styles.list}`}>
        {allGroups.map(group => (
          <Card
            className={`${styles.singleTextCard} p-3 mb-2`}
            key={group.id}
            onClick={() => handleClick(group.id, group.name, group.color)}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="overflow-hidden">
                <CardText className="m-0 text-truncate">{group.name}</CardText>
              </div>
              <i className="icf-chevron_right font-large" />
            </div>
          </Card>
        ))}
      </div>
    </>
  )
}
