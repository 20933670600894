import { sortBy } from 'es-toolkit'
import { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectDashboardStatus } from 'slices/dashboardSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

type KeyScheduleTypesProps = {
  workspaceId: number
  workspaceName: string
  scheduleTypes: {
    id: number
    name: string
  }[]
}

export const useGraphSelectButton = () => {
  const [selectedGraphs, setSelectedGraphs] = useState<number[]>([])
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { tenantSummary } = useSelector(selectDashboardStatus, shallowEqual)

  const items = useMemo(() => {
    if (!tenantSummary) {
      return []
    }
    const tenantSummaryItems = tenantSummary.workspaceData
      .filter(o => o.data.length > 0)
      .map(data => {
        const filterItems = data.data.map(s => ({
          key: s.scheduleTypeId,
          label: s.scheduleTypeName,
          checked: selectedGraphs.includes(s.scheduleTypeId),
        }))
        return {
          label: data.workspaceName,
          filterItems,
        }
      })
    return sortBy(tenantSummaryItems, ['label'])
  }, [tenantSummary, selectedGraphs])

  // キー作業を取得し、ワークスペースごとにグループ化
  const keySchedules = useMemo(() => {
    const keyScheduleTypes = allScheduleTypes
      .filter(scheduleType => scheduleType.isKey)
      .reduce((acc: KeyScheduleTypesProps[], cur) => {
        const existing = acc.find(keySchedule => keySchedule.workspaceId === cur.workspaceId)
        if (existing) {
          // 既存のworkspaceIdの場合はscheduleTypes配列に追加
          existing.scheduleTypes = existing.scheduleTypes.concat({
            id: cur.id,
            name: cur.name,
          })
          return acc
        }
        // 新しいworkspaceIdの場合は追加
        return acc.concat({
          workspaceId: cur.workspaceId,
          workspaceName: cur.workspaceName,
          scheduleTypes: [{ id: cur.id, name: cur.name }],
        })
      }, [])
    return sortBy(keyScheduleTypes, ['workspaceName'])
  }, [allScheduleTypes])

  const keyItems = keySchedules.map(keyScheduleType => ({
    label: keyScheduleType.workspaceName,
    filterItems: keyScheduleType.scheduleTypes.map(scheduleType => ({
      key: scheduleType.id,
      label: scheduleType.name,
      checked: selectedGraphs.includes(scheduleType.id),
    })),
  }))
  return { items, keyItems, selectedGraphs, setSelectedGraphs }
}
