import { Card, Input, Label, CardBody } from 'reactstrap'

export type CardRadioButtonItem = {
  value: string
  label: string
  subtitle?: string
}
type ICardRadioButtonProps = {
  items: CardRadioButtonItem[]
  selected: string
  name: string
  onClick: (value: string) => void
}

const CardRadioButton = ({ items, selected, name, onClick }: ICardRadioButtonProps) => {
  return (
    <div className="col">
      {items.map(({ value, label, subtitle }, index) => (
        <Card
          role="button"
          className={index !== 0 ? 'mt-2' : ''}
          key={`card-${index}`}
          outline
          color={selected === value ? 'primary' : ''}
          onClick={() => onClick(value)}
        >
          <CardBody className="d-flex py-2">
            <Input
              className="align-self-center"
              key={`custom-input-${index}-${name}`}
              type="radio"
              id={`${value}-${index}`}
              name={name}
              value={value}
              checked={selected === value}
              onChange={e => onClick(e.target.value)}
            ></Input>
            <Label className="my-0 mx-1" role="button">
              <div>{label}</div>
              {subtitle && <small className="text-muted">{subtitle}</small>}
            </Label>
          </CardBody>
        </Card>
      ))}
    </div>
  )
}

export default CardRadioButton
