import { useState, useMemo, useCallback } from 'react'

import type { UnitCostsArrayToString } from 'components/BalanceOfPayments/types'

import useBopValidation from './useBopValidation'

const useBop = (unitCosts: UnitCostsArrayToString[] = []) => {
  const fixedValue = useCallback((value: number | string, toFixed: boolean) => {
    if (!toFixed) {
      return value.toString()
    }
    const numericValue = typeof value === 'string' ? parseFloat(value) : value
    if (!isNaN(numericValue) && isFinite(numericValue)) {
      return numericValue.toFixed(2)
    }
    return '0.00'
  }, [])

  const formattedValue = (value: number | undefined | string): string | undefined => {
    if (!value) {
      return ''
    }
    if (typeof value === 'number') {
      return value.toFixed(2)
    }
    if (typeof value === 'string' && !isNaN(Number(value)) && isFinite(Number(value))) {
      const numericValue = parseFloat(value)
      return numericValue.toFixed(2)
    }
    return value
  }

  // validity
  const [otherSalesValidity, setOtherSalesValidity] = useState(false)
  const [managementCostsValidity, setManagementCostsValidity] = useState(false)
  const [workspaceCostsValidity, setWorkspaceCostsValidity] = useState(false)

  // unitCosts全体を評価するValidityフラグ
  const [unitCostsValidity, setUnitCostsValidity] = useState(false)

  const { updateValidationList } = useBopValidation(unitCosts, setUnitCostsValidity)

  const disabled = useMemo(() => {
    const allValid = unitCostsValidity && otherSalesValidity && managementCostsValidity && workspaceCostsValidity
    return !allValid
  }, [unitCostsValidity, otherSalesValidity, managementCostsValidity, workspaceCostsValidity])

  return {
    fixedValue,
    formattedValue,
    setOtherSalesValidity,
    setManagementCostsValidity,
    setWorkspaceCostsValidity,
    updateValidationList,
    disabled,
  }
}

export default useBop
