import moment from 'moment'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import type { DailyPlan } from 'slices/plansSlice'
import { getPlanList, selectPlansStatus } from 'slices/plansSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import { BadgeButton, Table } from 'components/common'
import type { BadgeItem, TableHeaderType, TableCellType } from 'components/common/types'

import useBusinessTime from 'hooks/useBusinessTime'

import styles from './TeamSchedules.module.scss'

const MAX_DATE = 20

export const TeamSchedules = () => {
  const [selectedBadges, setSelectedBadges] = useState<number[]>([])
  const {
    team: { workspaceId },
  } = useSelector(selectSessionStatus, shallowEqual)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { planList } = useSelector(selectPlansStatus, shallowEqual)
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  const { getWorkDate } = useBusinessTime()

  const dispatch = useDispatch()

  const { fromDate, toDate } = useMemo(() => {
    const from = moment().format('YYYY-MM-DD')
    const to = moment().add(19, 'day').format('YYYY-MM-DD') // 当日から最大20日分

    return { fromDate: getWorkDate(from), toDate: getWorkDate(to) }
  }, [getWorkDate])

  useEffect(() => {
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, workspaceId])

  useEffect(() => {
    if (tenant) {
      // getWorkDateでbusinessStartTimeを使用するためtenant取得後にAPIを実行する
      dispatch(getPlanList(workspaceId, fromDate, toDate))
    }
  }, [dispatch, fromDate, toDate, workspaceId, tenant])

  const selectedDataList = useCallback(
    (dailyPlanData: DailyPlan) => {
      return selectedBadges.reduce((acc: TableCellType[], badgeId) => {
        const selected = dailyPlanData.data.find(item => item.scheduleTypeId === badgeId)
        if (!selected) {
          return acc
        }

        const planValue = Math.floor(selected.plan)
        const targetValue = Math.floor(selected.target)
        const difference = Math.floor(planValue - targetValue)

        acc.push({ value: targetValue.toLocaleString() })
        acc.push({ value: planValue.toLocaleString() })
        acc.push({
          value: difference === 0 ? '-' : difference.toLocaleString(),
          className: difference < 0 ? 'text-danger' : '',
        })
        return acc
      }, [])
    },
    [selectedBadges]
  )

  const tableData = useMemo((): TableCellType[][] => {
    return [...Array(MAX_DATE)].reduce((acc: TableCellType[][], _, index: number) => {
      const date = moment(getWorkDate(moment().format('YYYY-MM-DD'))).add(index, 'day')
      const plan = planList?.dailyPlans.find(w => w.workDate === date.format('YYYY-MM-DD'))

      if (!plan) {
        return acc
      }

      const data: TableCellType[] = [
        { value: date.format('YYYY/MM/DD(dddd)') },
        { value: plan.workerCount, className: 'border-end' },
      ]

      acc.push(data.concat(selectedDataList(plan)))

      return acc
    }, [])
  }, [getWorkDate, planList?.dailyPlans, selectedDataList])

  useEffect(() => {
    const allScheduleTypeIds = partialScheduleTypes.filter(type => type.dataConnection).map(type => type.id)
    setSelectedBadges(allScheduleTypeIds)
  }, [partialScheduleTypes])

  const badges = useMemo(
    (): BadgeItem[] =>
      partialScheduleTypes
        .filter(type => type.dataConnection)
        .map(type => ({ color: type.color, key: type.id, label: type.name })),
    [partialScheduleTypes]
  )

  const header = useMemo((): TableHeaderType[] => {
    const tableHeader: TableHeaderType[] = [
      { value: '日付', width: '60%' },
      { value: 'メンバー数', width: '40%', className: 'border-end' },
    ]

    selectedBadges.forEach(key => {
      const badge = badges.find(b => b.key === key)
      if (badge) {
        tableHeader.push({ value: `目標 - ${badge.label}`, width: '128px' })
        tableHeader.push({ value: `計画 - ${badge.label}`, width: '128px' })
        tableHeader.push({ value: `差分 - ${badge.label}`, width: '128px' })
      }
    })
    return tableHeader
  }, [badges, selectedBadges])

  const navigate = useNavigate()
  const onRowClick = (index: number) => {
    const date = tableData[index][0].value as string
    const formattedDate = date.replace(/(\d{4})\/(\d{2})\/(\d{2}).*/g, '$1-$2-$3')
    navigate(`/team-schedules/${formattedDate}`)
  }

  const onBadgesChange = useCallback(
    (list: number[]) => {
      // 名前順にするためにソート済みのbadgesでフィルターをかける
      setSelectedBadges(badges.filter(badge => list.includes(badge.key)).map(badge => badge.key))
    },
    [badges]
  )

  return (
    <>
      <div className="px-4">
        <div className="font-x-large fw-bold py-4">作業計画一覧</div>
        <div className="font-x-small text-muted my-2">リストの表示項目の編集</div>
        <div className="d-flex row my-2">
          <BadgeButton items={badges} selected={selectedBadges} onChange={onBadgesChange} />
        </div>
      </div>
      <div className="d-flex bg-white mw-100 mt-2">
        <div className="p-0 w-25">
          <Table header={header.slice(0, 2)} body={tableData.map(data => data.slice(0, 2))} onRowClick={onRowClick} />
        </div>
        <div className={styles.scTable}>
          <Table header={header.slice(2)} body={tableData.map(data => data.slice(2))} onRowClick={onRowClick} />
        </div>
      </div>
    </>
  )
}
