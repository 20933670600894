import { Table } from 'reactstrap'

import styles from './StickyTable.module.scss'

import type { TableCellType, TableHeaderType } from '../types'

const getCellStyle = (fixedLeft: boolean, fixedRight: boolean, index: number, itemsLength: number): string => {
  if (fixedLeft && index === 0) {
    return styles.leftStickyCell
  }
  if (fixedRight && index === itemsLength - 1) {
    return styles.rightStickyCell
  }
  return styles.cell
}

type HeaderProps = {
  items: TableHeaderType[]
  fixedRight: boolean
  fixedLeft: boolean
  headerClassName?: string
}

const Header = ({ items, fixedLeft, fixedRight, headerClassName }: HeaderProps) => (
  <thead className="font-x-small">
    <tr className={`d-flex ${headerClassName}`}>
      {items.map((col, index) => (
        <td
          key={`${index}-${col.value}`}
          className={`bg-light ${col.className ?? ''} ${getCellStyle(fixedLeft, fixedRight, index, items.length)}`}
        >
          <div className="text-truncate" title={(col.value ?? '').toString()}>
            {col.value}
          </div>
        </td>
      ))}
    </tr>
  </thead>
)

type RowProps = {
  items: TableCellType[]
  fixedRight: boolean
  fixedLeft: boolean
  rowClassName?: string
}

const Row = ({ items, fixedLeft, fixedRight, rowClassName }: RowProps) => (
  <tr className={`d-flex ${rowClassName ?? ''}`}>
    {items.map((cell, cellIndex) => (
      <td
        key={`${cellIndex}-${cell.value}`}
        className={`${cell.className} ${getCellStyle(fixedLeft, fixedRight, cellIndex, items.length)}`}
        onClick={cell.onClick}
        role={cell.onClick ? 'button' : ''}
      >
        {typeof cell.value === 'string' || typeof cell.value === 'number' ? (
          <div className="text-truncate" title={cell.value.toString()}>
            {cell.value}
          </div>
        ) : (
          cell.value
        )}
      </td>
    ))}
  </tr>
)

type StickyTableProps = {
  header: TableHeaderType[]
  body: TableCellType[][]
  fixedRight?: boolean
  fixedLeft?: boolean
  fixedTop?: boolean
  headerClassName?: string
  rowClassName?: string
}

export const StickyTable = ({
  header,
  body,
  fixedLeft,
  fixedRight,
  headerClassName,
  rowClassName,
}: StickyTableProps) => (
  <Table className="mb-0 w-100 font-small">
    <Header items={header} fixedLeft={!!fixedLeft} fixedRight={!!fixedRight} headerClassName={headerClassName} />
    <tbody>
      {body.map((row, rowIndex) => (
        <Row
          key={`row-${rowIndex}`}
          items={row}
          fixedLeft={!!fixedLeft}
          fixedRight={!!fixedRight}
          rowClassName={rowClassName}
        />
      ))}
    </tbody>
  </Table>
)
