import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import type { HourlyProductivitiesStringValue, WorkerEditTypeStringValue } from 'api/workers/types'

import { getRelatedScheduleType, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

import { WORKER_TYPES_LABEL } from 'components/common/constants'

const initData: WorkerEditTypeStringValue = {
  id: undefined,
  name: undefined,
  wmsMemberId: undefined,
  officialDuties: undefined,
  workspace: undefined,
  groupId: undefined,
  groupLeader: false,
  hiredAt: undefined,
  skills: [],
  hourlyProductivities: [],
  workerTypes: WORKER_TYPES_LABEL[0],
}
const useWorker = () => {
  const [editData, setEditData] = useState<WorkerEditTypeStringValue>(initData)
  const [nameValidity, setNameValidity] = useState(false)
  const [productivityValidity, setProductivityValidity] = useState(false)
  const dispatch = useDispatch()

  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  useEffect(() => {
    dispatch(getRelatedScheduleType())
  }, [dispatch])

  const setHourlyProductivities = useCallback(
    (value: HourlyProductivitiesStringValue[]) => setEditData(prev => ({ ...prev, hourlyProductivities: value })),
    []
  )

  const showHourlyProductivities = useMemo(() => allScheduleTypes.length > 0, [allScheduleTypes])

  const disabled = useMemo(() => !(nameValidity && productivityValidity), [nameValidity, productivityValidity])

  return {
    setHourlyProductivities,
    showHourlyProductivities,
    disabled,
    editData,
    setEditData,
    setNameValidity,
    setProductivityValidity,
    initData,
  }
}
export default useWorker
