import { Card, CardColumns } from 'reactstrap'

import { COLOR_TYPES } from 'api/constants'
import type { ColorType } from 'api/types'

export const COLORS: Array<ColorType> = Object.values(COLOR_TYPES)

type Props = {
  selected: ColorType
  disabled?: boolean
  onChange: (value: ColorType) => void
}

export const ColorPicker = (props: Props) => {
  const { selected, disabled, onChange } = props

  const selectedStyle = (color: ColorType) => {
    const squareStyle = {
      display: 'table',
      width: '20px',
      height: '20px',
      minWidth: '20px',
      minHeight: '20px',
      borderRadius: '0.2rem',
      border: '0.09rem solid #ffffff',
    }
    return selected === color
      ? { ...squareStyle, boxShadow: '0 0 0.1rem 0.1rem #007bff' }
      : { ...squareStyle, cursor: 'pointer' }
  }

  const handleClick = (color: ColorType) => {
    if (!disabled) {
      onChange(color)
    }
  }

  return (
    <Card className="d-block px-3 py-2 overflow-auto">
      <CardColumns className="d-flex column-gap-3">
        {COLORS.map(color => (
          <div
            key={color}
            className={`bg-${color}`}
            style={selectedStyle(color)}
            onClick={() => handleClick(color)}
          ></div>
        ))}
      </CardColumns>
    </Card>
  )
}

export default ColorPicker
