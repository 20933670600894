import CustomModal from 'components/common/CustomModal/CustomModal'

type Props = {
  isOpen: boolean
  onCloseDialogClick: () => void
  fileInfoLabel: string
  errorInfo: string[]
}

export const ImportFailDetailDialog = ({ isOpen, fileInfoLabel, errorInfo, onCloseDialogClick }: Props) => (
  <CustomModal isOpen={isOpen} onCancel={onCloseDialogClick} showApprove={false} title={'インポート失敗'}>
    <div className="d-flex flex-column row-gap-3">
      <div className="font-x-small text-muted">{fileInfoLabel}</div>
      <ul>
        {errorInfo.map(info => (
          <li key={info}>{info}</li>
        ))}
      </ul>
      <div>解決しない場合はサポートにお問い合わせください。</div>
    </div>
  </CustomModal>
)
