import { isEqual } from 'es-toolkit'
import { useState, useMemo, useEffect, useCallback } from 'react'

import type { DailyReportsScheduleType, DailyReportsWorkspaceType } from 'api/daily_reports/types'

import { PivotItem, PivotOuterIndex } from 'components/common'

import { ScheduleTypeMapping } from './ScheduleTypeMapping'

import styles from './WorkspaceTabs.module.scss'

import type { allHeadersType } from './types'

type WorkspaceTabsProps = {
  workspaces: DailyReportsWorkspaceType[]
  onChange: (id: DailyReportsWorkspaceType[]) => void
  onHeaderValidate: (isValid: boolean) => void
}

type WorkspaceId = number

export const WorkspaceTabs = ({ onChange, workspaces, onHeaderValidate }: WorkspaceTabsProps) => {
  const [activeTabId, setActiveTabId] = useState<WorkspaceId | null>(null)
  const [allHeaders, setAllHeaders] = useState<allHeadersType>([])

  useEffect(() => {
    setActiveTabId(prev => {
      if (prev === null && workspaces.length > 0) {
        return workspaces[0].id
      }
      // アクティブなworkspaceのチェックを外された場合、0番目をアクティブにする
      if (workspaces.some(ws => ws.id === prev)) {
        return prev
      }
      if (workspaces.length > 0) {
        return workspaces[0].id
      }
      return null
    })
  }, [workspaces])

  useEffect(() => {
    const headers = workspaces.map(ws => ({
      workspaceId: ws.id,
      schedules: ws.scheduleTypes.flatMap(type =>
        (type.header.length > 0 ? type.header : ['']).map((value, index) => ({
          scheduleTypeId: type.id,
          index,
          value,
          isValid: true,
        }))
      ),
    }))

    setAllHeaders(headers)
  }, [workspaces])

  const handleHeaderValidate = useCallback((newHeaders: allHeadersType) => {
    setAllHeaders(prev => {
      if (isEqual(prev, newHeaders)) {
        return prev
      }
      return newHeaders
    })
  }, [])

  useEffect(() => {
    const allValid = allHeaders.every(h => h.schedules.every(s => s.isValid === true))
    onHeaderValidate(allValid)
  }, [allHeaders, onHeaderValidate])

  const tabs = useMemo(
    () =>
      workspaces.map(ws => {
        const isActive = ws.id === activeTabId

        const isValid = allHeaders.find(w => w.workspaceId === ws.id)?.schedules.every(s => s.isValid) ?? false
        const hasEmptyHeader = ws.scheduleTypes.some(st => st.header.length === 0 || st.header.some(h => h === ''))
        const hasUnfilledSchedules = !isValid || hasEmptyHeader

        const handleScheduleTypeChange = (scheduleTypes: DailyReportsScheduleType[]) => {
          const newWorkspaces = workspaces.map(workspace =>
            workspace.id === ws.id ? { ...workspace, scheduleTypes } : workspace
          )
          onChange(newWorkspaces)
        }

        return {
          headerText: ws.name,
          key: ws.id,
          component: (
            <ScheduleTypeMapping
              workspace={ws}
              onChange={handleScheduleTypeChange}
              onHeaderValidate={handleHeaderValidate}
              allHeaders={allHeaders}
            />
          ),
          icon: hasUnfilledSchedules ? 'alert' : '',
          iconColor: isActive ? 'text-warning' : '',
        }
      }),
    [activeTabId, allHeaders, handleHeaderValidate, onChange, workspaces]
  )

  const handleTabChange = useCallback(
    (index: number) => {
      setActiveTabId(workspaces[index].id)
    },
    [workspaces]
  )

  return activeTabId === null ? (
    <></>
  ) : (
    <div className={`pt-3 mx-3 overflow-auto text-nowrap ${styles.noScrollbar}`}>
      <PivotOuterIndex selectedIndex={workspaces.findIndex(w => w.id === activeTabId)} onChange={handleTabChange}>
        {tabs.map(tab => (
          <PivotItem headerText={tab.headerText} key={tab.key} icon={tab.icon} iconColor={tab.iconColor}>
            {tab.component}
          </PivotItem>
        ))}
      </PivotOuterIndex>
    </div>
  )
}
