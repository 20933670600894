import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { AllocationListResponse, AllocationResponse, UpdateAllocationProps } from './types'

export const getAllocationList = async (workspaceId: number): Promise<AllocationListResponse> => {
  const url = `/workspaces/${workspaceId}/allocations`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const getAllocation = async (workspaceId: number, allocationId: number): Promise<AllocationResponse> => {
  const url = `/workspaces/${workspaceId}/allocations/${allocationId}`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const createAllocation = async (workspaceId: number, data: UpdateAllocationProps): Promise<void> => {
  const url = `/workspaces/${workspaceId}/allocations`

  const response = await axiosClientWithTenantId.post(url, data)
  return response.data
}

export const updateAllocation = async (
  workspaceId: number,
  allocationId: number,
  data: UpdateAllocationProps
): Promise<void> => {
  const url = `/workspaces/${workspaceId}/allocations/${allocationId}`

  const response = await axiosClientWithTenantId.patch(url, data)
  return response.data
}

export const deleteAllocation = async (workspaceId: number, allocationId: number): Promise<void> => {
  const url = `/workspaces/${workspaceId}/allocations/${allocationId}`

  const response = await axiosClientWithTenantId.delete(url)
  return response.data
}
