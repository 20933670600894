import dayjs from 'dayjs'
import { orderBy } from 'es-toolkit'
import { useEffect, useState, useMemo } from 'react'
import { ModalBody, ModalFooter, Button, Card, CardBody, CardText, FormGroup, Label, Row, Col, Input } from 'reactstrap'

import type { ColorType } from 'api/types'

import SupportConfirm from 'components/SupportConfirm/SupportConfirm'
import { BadgeLabel, Modal, DividedTimeSelect } from 'components/common'
import { TIME_INTERVAL } from 'components/common/constants'
import type { SelectedScheduleType } from 'components/common/types'
import { toFitDuringShiftTime } from 'components/common/utils'

import useAssignment from 'hooks/useAssignment'
import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'

import { TeamWorkerCard, WORKER_CARD_STATUSES } from './TeamWorkerCard'

import styles from './TeamWorkerReassign.module.scss'

import type { WorkerCard } from './TeamWorkerCard'

export type Worker = WorkerCard & {
  scheduleId: number | null
  startAt: string
  duration: number
  skillIds: number[]
}

export type ScheduleEditType = {
  scheduleTypeId: number
  name: string
  color?: ColorType
  workers: Worker[]
  requiredSkills: number[]
}

type Props = {
  isOpen: boolean
  selectedWorkerIds: number[]
  selectedScheduleType: SelectedScheduleType | undefined
  currentScheduleType: SelectedScheduleType | undefined
  editData: ScheduleEditType[]
  setEditData: (data: ScheduleEditType[]) => void
  onClose: () => void
  getWorkerPerformance: (workerId: number) => 'good' | 'bad' | 'normal'
}

const isSelected = (data: ScheduleEditType, compare: SelectedScheduleType | undefined) => {
  return data.scheduleTypeId === compare?.id && data.name === compare.name
}

export const TeamWorkerReassign = (props: Props) => {
  const {
    isOpen,
    selectedWorkerIds,
    selectedScheduleType,
    currentScheduleType,
    editData,
    setEditData,
    onClose,
    getWorkerPerformance,
  } = props
  const [invalid, setInvalid] = useState(false)
  const [openSupportConfirm, setOpenSupportConfirm] = useState(false)
  const [onApprove, setOnApprove] = useState<() => void>(() => {})
  const [noSkillWorkers, setNoSkillWorkers] = useState<Worker[]>([])
  // 直前の予定開始時刻を設定
  const [startHour, setStartHour] = useState('00')
  const [startMinute, setStartMinute] = useState('00')
  // 直前の予定開始時刻の１時間後を設定
  const [endHour, setEndHour] = useState('00')
  const [endMinute, setEndMinute] = useState('00')
  const [isEndTime, setEndTime] = useState(false)

  const { startTime } = useAssignment()
  const { getHourOver24h, businessStartTime, businessEndTime, flooredCurrentTime, timeRange } = useBusinessTime({
    interval: TIME_INTERVAL.FIVE,
  })
  const { planWorkDate, getFitDuringShiftTimeData } = usePlans()

  const shifts = useMemo(() => getFitDuringShiftTimeData(), [getFitDuringShiftTimeData])

  useEffect(() => {
    setInvalid(false)
    setNoSkillWorkers([])
    const [businessStartHour, businessStartMinute] = businessStartTime.split(':').map(Number)
    const [businessEndHour, businessEndMinute] = businessEndTime.split(':').map(Number)
    const date = dayjs(`${planWorkDate} ${startTime}`)

    const getCalcStartTime = () => {
      const currentHour = getHourOver24h(date.format('HH:mm'))
      const currentMinute = date.minute()

      return businessEndHour < Number(currentHour) ||
        (businessEndHour === Number(currentHour) && businessEndMinute <= Number(currentMinute))
        ? [businessStartHour.toString().padStart(2, '0'), businessStartMinute.toString().padStart(2, '0')]
        : [currentHour.toString().padStart(2, '0'), currentMinute.toString().padStart(2, '0')]
    }
    const [calcStartHour, calcStartMinute] = getCalcStartTime()
    setStartHour(calcStartHour)
    setStartMinute(calcStartMinute)

    const getCalcEndTime = () => {
      // endHourは、24時間以降を考慮したstartHourに、数値計算上+1した値とする。
      const tempEndHour = (Number(calcStartHour) + 1).toString().padStart(2, '0')
      const tempEndMinute = calcStartMinute.toString().padStart(2, '0')

      return businessEndHour < Number(tempEndHour) ||
        (businessEndHour === Number(tempEndHour) && businessEndMinute <= Number(tempEndMinute))
        ? [businessEndHour.toString().padStart(2, '0'), businessEndMinute.toString().padStart(2, '0')]
        : [tempEndHour, tempEndMinute]
    }
    const [calcEndHour, calcEndMinute] = getCalcEndTime()

    // 営業終了時間とcalcEndHour・calcEndMinuteを比較し、短い方をendHour・endMinuteに代入する
    if (
      businessEndHour < Number(calcEndHour) ||
      (businessEndHour === Number(calcEndHour) && businessEndMinute <= Number(calcEndMinute))
    ) {
      setEndHour(businessEndHour.toString().padStart(2, '0'))
      setEndMinute(businessEndMinute.toString().padStart(2, '0'))
    } else {
      setEndHour(calcEndHour)
      setEndMinute(calcEndMinute)
    }
    setEndTime(false)
  }, [getHourOver24h, isOpen, planWorkDate, businessEndTime, flooredCurrentTime, businessStartTime, startTime])

  // 選択したワークスペース情報
  const requiredSkills = useMemo(
    (): number[] => editData.find(data => isSelected(data, selectedScheduleType))?.requiredSkills || [],
    [editData, selectedScheduleType]
  )

  const workers = useMemo(
    (): Worker[] =>
      editData
        .find(data => isSelected(data, currentScheduleType))
        ?.workers.filter(w => selectedWorkerIds.includes(w.workerId)) || [],
    [currentScheduleType, editData, selectedWorkerIds]
  )

  const getUpdateTimes = (worker: Worker) => {
    const inputStartAt = dayjs(`${planWorkDate} ${startHour}:${startMinute}`).utc().format()
    const inputDuration = dayjs(`${planWorkDate} ${endHour}:${endMinute}`).utc().unix() - dayjs(inputStartAt).unix()
    const shiftsFilteredByWorkerId = shifts.filter(shift => shift.workerId === worker.workerId)
    const shiftsOrderedByStartAt = orderBy(shiftsFilteredByWorkerId, ['startAt'], ['asc'])
    return toFitDuringShiftTime(inputStartAt, inputDuration, shiftsOrderedByStartAt, isEndTime)
  }

  const updateData = (targetWorkers: Worker[]) => {
    const update = editData.map(data => {
      if (isSelected(data, currentScheduleType)) {
        return {
          ...data,
          workers: data.workers.filter(w => !targetWorkers.map(worker => worker.workerId).includes(w.workerId)),
        }
      } else if (isSelected(data, selectedScheduleType)) {
        return { ...data, workers: data.workers.concat(targetWorkers) }
      }
      return data
    })
    setEditData(update)
    onClose()
  }

  const memberSave = (func: () => void) => {
    if (!selectedScheduleType?.color) {
      setOnApprove(() => func)
      setOpenSupportConfirm(true)
    } else {
      func()
    }
  }

  const allWorkersSave = () => {
    // 全メンバーを移動して人員配置画面に戻る
    const updateWorkers: Worker[] = workers
      .map(w => {
        const { startAt, duration } = getUpdateTimes(w)
        return { ...w, startAt, duration, status: WORKER_CARD_STATUSES.CHANGED }
      })
      .filter(w => w.duration > 0)
    updateData(updateWorkers)
  }

  const skillWorkersSave = () => {
    // 必須スキルもちのメンバーのみ移動して人員配置画面に戻る
    const skillWorkers: Worker[] = workers
      .filter(worker => requiredSkills.some(skill => worker.skillIds?.includes(skill)))
      .map(w => {
        const { startAt, duration } = getUpdateTimes(w)
        return { ...w, startAt, duration, status: WORKER_CARD_STATUSES.CHANGED }
      })
      .filter(w => w.duration > 0)
    updateData(skillWorkers)
  }

  const onSaveClick = () => {
    // 保存ボタンを押した時の処理
    const flag =
      !isEndTime &&
      dayjs(`${planWorkDate} ${startHour}:${startMinute}`).isSameOrAfter(
        dayjs(`${planWorkDate} ${endHour}:${endMinute}`)
      )
    setInvalid(flag)

    if (!flag) {
      // 時刻判定がvalidだった時必須スキルチェックをする
      const noSkill =
        requiredSkills.length === 0
          ? []
          : workers.filter(worker => requiredSkills.every(skill => !worker.skillIds?.includes(skill)))
      setNoSkillWorkers(noSkill)
      if (noSkill.length === 0) {
        memberSave(allWorkersSave)
      }
    }
  }

  const getWorkerPerformanceHandler = (workerId: number) => {
    return getWorkerPerformance(workerId)
  }

  const isAlert = useMemo(() => noSkillWorkers.length > 0, [noSkillWorkers])
  const title = useMemo(() => (isAlert ? '必須スキルを持っていません' : '配置変更時間の入力'), [isAlert])

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalBody className="font-large fw-bold">{title}</ModalBody>

        <ModalBody className="font-small">
          {isAlert ? (
            <>
              <CardText className="mx-3">
                以下の作業者は作業に設定されている必須スキルを持っていません。そのまま配置変更しますか？
              </CardText>
              <CardBody className="p-0">
                <Card className="p-2 mx-3">
                  <Row md={3} className={`g-0 ${styles.workerCards}`}>
                    {noSkillWorkers.map(worker => (
                      <Col key={`no-skill-worker-${worker.workerId}`} className="p-1">
                        <TeamWorkerCard
                          disabled={true}
                          workerId={worker.workerId}
                          workerName={worker.workerName}
                          groupColor={worker.groupColor}
                          groupName={worker.groupName}
                          performance={getWorkerPerformanceHandler(worker.workerId)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Card>
              </CardBody>
            </>
          ) : (
            <>
              <CardBody>
                <Card className="p-2 mb-3">
                  <Row md={3} className={`g-0 ${styles.workerCards}`}>
                    {workers.map(worker => (
                      <Col key={`worker-${worker.workerId}`} className="p-1">
                        <TeamWorkerCard
                          disabled={true}
                          workerId={worker.workerId}
                          workerName={worker.workerName}
                          groupColor={worker.groupColor}
                          groupName={worker.groupName}
                          performance={getWorkerPerformanceHandler(worker.workerId)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Card>
              </CardBody>
              <CardBody className="mb-3">
                <Row className="pb-3">
                  <Col>配置変更先:</Col>
                  <Col md={9} className="d-flex">
                    <BadgeLabel label={selectedScheduleType?.name || ''} color={selectedScheduleType?.color} />
                  </Col>
                </Row>
                <CardText>
                  上記のメンバーの配置変更を行います。配置変更の開始する時間と終了する時間を設定してください。
                </CardText>
              </CardBody>
              <CardBody>
                <FormGroup row>
                  <Label md={3}>開始時間</Label>
                  <Col md={8}>
                    <DividedTimeSelect
                      hour={startHour}
                      minute={startMinute}
                      label="から"
                      onChange={(hour, minute) => {
                        setStartHour(hour)
                        setStartMinute(minute)
                      }}
                      range={timeRange.start}
                      roundingInterval={TIME_INTERVAL.FIVE}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3}>終了時間</Label>
                  <Col md={4}>
                    <DividedTimeSelect
                      hour={endHour}
                      minute={endMinute}
                      label="まで"
                      onChange={(hour, minute) => {
                        setEndHour(hour)
                        setEndMinute(minute)
                      }}
                      invalid={isEndTime}
                      range={timeRange.end}
                      roundingInterval={TIME_INTERVAL.FIVE}
                    />
                  </Col>
                  <Label md={4} className="form-check">
                    <Input
                      className="form-check-input"
                      id="end-time"
                      checked={isEndTime}
                      type="checkbox"
                      onChange={e => setEndTime(e.target.checked)}
                    />
                    <Label className="form-check-label" for="end-time">
                      終業時間まで
                    </Label>
                  </Label>
                </FormGroup>
                {invalid && (
                  <CardText className="text-danger">終了時刻は開始時刻より後の時刻を設定してください</CardText>
                )}
              </CardBody>
            </>
          )}
        </ModalBody>

        {isAlert ? (
          <ModalFooter className="d-flex justify-content-between">
            <div className="flex-grow-1">
              <Button outline onClick={onClose}>
                キャンセル
              </Button>
            </div>
            <Button outline onClick={() => memberSave(skillWorkersSave)}>
              スキルを持たない人のみキャンセル
            </Button>
            <Button color="primary" className="ms-2" onClick={() => memberSave(allWorkersSave)}>
              そのまま配置変更
            </Button>
          </ModalFooter>
        ) : (
          <ModalFooter className="d-flex justify-content-between">
            <Button outline onClick={onClose}>
              キャンセル
            </Button>
            <Button color="primary" className="px-4" onClick={onSaveClick}>
              保存
            </Button>
          </ModalFooter>
        )}
      </Modal>

      <SupportConfirm
        isOpen={openSupportConfirm}
        start={`${startHour}:${startMinute}`}
        end={`${endHour}:${endMinute}`}
        name={selectedScheduleType?.name || ''}
        onCancel={() => {
          onClose()
          setOpenSupportConfirm(false)
        }}
        onApprove={() => {
          onApprove?.()
          setOpenSupportConfirm(false)
        }}
      />
    </>
  )
}
