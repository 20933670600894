import { intersection, omit } from 'es-toolkit'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectGroupsStatus } from 'slices/groupsSlice'
import { selectSkillsStatus } from 'slices/skillsSlice'

import { FilteringButton, FilteringInputField } from 'components/common'
import type { FilterItem } from 'components/common/types'
import { isFilteredWorker } from 'components/common/utils'

import styles from './AssignmentWorkerFilter.module.scss'

import type { ScheduleEditType } from './DroppingWorkerCards'
import type { Dispatch, SetStateAction } from 'react'

type Props = {
  initSchedules: ScheduleEditType[]
  onChange: Dispatch<SetStateAction<ScheduleEditType[]>>
  setShouldReset: Dispatch<SetStateAction<boolean>>
  shouldReset: boolean
}

const BLANK_GROUP = {
  ID: -1,
  NAME: '未所属',
}

const SUPPORT_WORKER_GROUP = {
  ID: -2,
  NAME: '応援',
}

const AssignmentWorkerFilter = ({ initSchedules, onChange, setShouldReset, shouldReset }: Props) => {
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  const { groups } = useSelector(selectGroupsStatus, shallowEqual)
  const [filterWord, setFilterWord] = useState('')
  const [selectedFilterGroups, setSelectedFilterGroups] = useState<number[]>([])
  const [selectedFilterSkills, setSelectedFilterSkills] = useState<number[]>([])

  useEffect(() => {
    setSelectedFilterGroups([...(groups?.map(g => g.id) || []), SUPPORT_WORKER_GROUP.ID, BLANK_GROUP.ID])
  }, [groups])

  useEffect(() => {
    setSelectedFilterSkills([...(skills?.map(s => s.id) || []), BLANK_GROUP.ID])
  }, [skills])

  useEffect(() => {
    if (!shouldReset) {
      return
    }
    setSelectedFilterGroups([...(groups?.map(g => g.id) || []), SUPPORT_WORKER_GROUP.ID, BLANK_GROUP.ID])
    setSelectedFilterSkills([...(skills?.map(s => s.id) || []), BLANK_GROUP.ID])
    setFilterWord('')
    setShouldReset(false)
  }, [groups, skills, shouldReset, setShouldReset])

  const filterGroupItems = useMemo<FilterItem[]>(
    () => [
      ...groups.map(g => ({ key: g.id, label: g.name, checked: selectedFilterGroups.includes(g.id) })),
      {
        key: SUPPORT_WORKER_GROUP.ID,
        label: SUPPORT_WORKER_GROUP.NAME,
        checked: selectedFilterGroups.includes(SUPPORT_WORKER_GROUP.ID),
      },
      {
        key: BLANK_GROUP.ID,
        label: BLANK_GROUP.NAME,
        checked: selectedFilterGroups.includes(BLANK_GROUP.ID),
      },
    ],
    [groups, selectedFilterGroups]
  )

  const filterSkillItems = useMemo<FilterItem[]>(
    () =>
      skills
        .map(s => ({ key: s.id, label: s.name, checked: selectedFilterSkills.includes(s.id) }))
        .concat({ key: BLANK_GROUP.ID, label: '未設定', checked: selectedFilterSkills.includes(BLANK_GROUP.ID) }),
    [skills, selectedFilterSkills]
  )

  const selectedGroupNames = useMemo(() => {
    const names = groups.filter(g => selectedFilterGroups.includes(g.id)).map(g => g.name)
    selectedFilterGroups.includes(SUPPORT_WORKER_GROUP.ID) && names.push(SUPPORT_WORKER_GROUP.NAME)
    selectedFilterGroups.includes(BLANK_GROUP.ID) && names.push(BLANK_GROUP.NAME)
    return names
  }, [selectedFilterGroups, groups])

  useEffect(
    () =>
      onChange(prev => {
        const editWorkers = prev.flatMap(s => s.workers.map(w => ({ ...w, type: s.type })))
        return initSchedules.map(s => {
          const workers = s.workers
            .filter(w => !editWorkers.some(e => e.workerId === w.workerId))
            .concat(editWorkers.filter(w => w.type === s.type).map(w => omit(w, ['type'])))
            .map(w => {
              const hasGroups = w.supporter
                ? selectedGroupNames.includes(SUPPORT_WORKER_GROUP.NAME)
                : selectedGroupNames.includes(w.groupName || BLANK_GROUP.NAME)
              const hasBlankSkill = selectedFilterSkills.includes(BLANK_GROUP.ID) && w.skillIds?.length === 0
              const hasSelectedSkill = hasBlankSkill || intersection(selectedFilterSkills, w.skillIds || []).length > 0
              const hasFilterWord = isFilteredWorker(filterWord, {
                name: w.name,
                wmsMemberId: w.wmsMemberId,
                workerType: w.workerType,
              })

              return { ...w, visible: hasGroups && hasSelectedSkill && hasFilterWord }
            })
          return { ...s, workers }
        })
      }),
    [selectedGroupNames, selectedFilterSkills, filterWord, onChange, initSchedules]
  )

  return (
    <div className={`d-flex mb-3 ${styles.filterButtonGroup}`}>
      <div className="w-25">
        <FilteringInputField onChange={setFilterWord} value={filterWord} />
      </div>
      <FilteringButton
        items={filterGroupItems}
        onChange={setSelectedFilterGroups}
        value={selectedFilterGroups}
        label="グループで絞り込み"
        disabled={filterGroupItems.length === 0}
      />
      <FilteringButton
        items={filterSkillItems}
        onChange={setSelectedFilterSkills}
        value={selectedFilterSkills}
        label="スキルで絞り込み"
        disabled={skills.length === 0}
      />
    </div>
  )
}
export default AssignmentWorkerFilter
