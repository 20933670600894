import dayjs from 'dayjs'
import { floor } from 'es-toolkit/compat'
import { useCallback } from 'react'

import type { TenantData } from 'api/tenants/types'

import { TimeScale, ShiftBar } from 'components/common'
import { TIME_INTERVAL } from 'components/common/constants'
import { getShiftBarWidthByDuration } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import { PerformanceRatePopover } from './PerformanceRatePopover'

import styles from './PerformanceRates.module.scss'

import type { RefObject } from 'react'

export type PerformanceRateData = {
  performanceRateId: number | null
  startTime: string
  duration: number
  performanceRateValue: number | null
}

type PerformanceRate = {
  scheduleTypeId: number
  data: PerformanceRateData[]
}

type Props = {
  date: string
  displayRateData: DisplayPerformanceRate[]
  setDisplayRateData: (props: DisplayPerformanceRate[]) => void
  divElement: RefObject<HTMLDivElement>
  onScroll: () => void
  tenantWithDate?: TenantData
}

export type DisplayPerformanceRate = PerformanceRate & {
  name: string
}
export const PerformanceRates = ({
  date,
  displayRateData,
  setDisplayRateData,
  divElement,
  onScroll,
  tenantWithDate,
}: Props) => {
  const { businessStartTime, businessDuration, getTimesByShiftBarX, getShiftBarXbyStartTime } = useBusinessTime({
    tenantWithDate,
    interval: TIME_INTERVAL.FIVE,
  })

  const onChangePerformanceRate = (value: number, scheduleTypeId: number, index: number) => {
    const newDisplayRateData = displayRateData.map(rate => {
      if (rate.scheduleTypeId === scheduleTypeId) {
        const newData = rate.data.map((data, idx) => (idx === index ? { ...data, performanceRateValue: value } : data))
        return {
          ...rate,
          data: newData,
        }
      }
      return rate
    })
    setDisplayRateData(newDisplayRateData)
  }

  const onDeletePerformanceRate = (scheduleTypeId: number, index: number) => {
    const newDisplayRateData = displayRateData.map(rate => {
      if (rate.scheduleTypeId === scheduleTypeId) {
        // eslint-disable-next-line no-shadow
        const newData = rate.data.filter((_, idx) => idx !== index)
        return {
          ...rate,
          data: newData,
        }
      }
      return rate
    })
    setDisplayRateData(newDisplayRateData)
  }

  const handleShiftBarChange = useCallback(
    (index: number, x: number, width: number, scheduleTypeId: number) => {
      if (!date) {
        return
      }
      const time = getTimesByShiftBarX(x)
      const startTime = dayjs(`${date} ${time.hours}:${time.minutes}`).utc().format()
      const duration = width * 300

      const newDisplayRateData = displayRateData.map(rate => {
        if (rate.scheduleTypeId === scheduleTypeId) {
          return {
            ...rate,
            data: [
              ...rate.data.slice(0, index),
              {
                ...rate.data[index],
                startTime,
                duration,
              },
              ...rate.data.slice(index + 1),
            ],
          }
        }
        return rate
      })

      setDisplayRateData(newDisplayRateData)
    },
    [date, displayRateData, getTimesByShiftBarX, setDisplayRateData]
  )

  const handleShiftBarAdd = (startPos: number, endPos: number, scheduleTypeId: number) => {
    if (!date) {
      return
    }

    const startPos5min = startPos - (startPos % 3)
    const endPos5min = endPos > startPos5min + 3 ? endPos : startPos5min + 3
    const time = getTimesByShiftBarX(startPos5min)
    const startTime = dayjs(`${date} ${time.hours}:${time.minutes}`).utc().format()
    const newDisplayRateData = displayRateData.map(rate => {
      if (rate.scheduleTypeId === scheduleTypeId) {
        return {
          ...rate,
          data: [
            ...rate.data,
            {
              startTime,
              performanceRateId: null,
              performanceRateValue: 1.0,
              duration: (endPos5min - startPos5min) * 300,
            },
          ],
        }
      }
      return rate
    })

    setDisplayRateData(newDisplayRateData)
  }

  const handleTimeChange = useCallback(
    (
      scheduleTypeId: number,
      index: number,
      changeStartHour: string,
      changeStartMinute: string,
      changeEndHour: string,
      changeEndMinute: string
    ) => {
      const startDate = dayjs().hour(Number(changeStartHour)).minute(Number(changeStartMinute))
      const startX = getShiftBarXbyStartTime(startDate.toISOString(), dayjs().format('YYYY-MM-DD'))
      const endDate = dayjs().hour(Number(changeEndHour)).minute(Number(changeEndMinute))
      const endX = getShiftBarXbyStartTime(endDate.toISOString(), dayjs().format('YYYY-MM-DD'))

      handleShiftBarChange(index, startX, endX - startX, scheduleTypeId)
    },
    [getShiftBarXbyStartTime, handleShiftBarChange]
  )

  const getShiftBarItems = (data: PerformanceRateData[], scheduleTypeId: number) => {
    return data.map((rate, index) => {
      const x = getShiftBarXbyStartTime(rate.startTime, date)
      const width = getShiftBarWidthByDuration(rate.duration, TIME_INTERVAL.FIVE)
      const strValue = rate.performanceRateValue === null ? '' : floor(rate.performanceRateValue, 2).toFixed(2)
      const id = `sift-bar-item-${scheduleTypeId}-${rate.performanceRateId}-${index}`
      return {
        id,
        content: (
          <PerformanceRatePopover
            startTime={rate.startTime}
            duration={rate.duration}
            performanceRateValue={strValue}
            onChange={value => onChangePerformanceRate(value, scheduleTypeId, index)}
            onDelete={() => onDeletePerformanceRate(scheduleTypeId, index)}
            onTimeChange={(startHour, startMinute, endHour, endMinute) =>
              handleTimeChange(scheduleTypeId, index, startHour, startMinute, endHour, endMinute)
            }
            key={id}
          />
        ),
        x,
        width,
      }
    })
  }

  return (
    <div className={styles.container} ref={divElement} onScroll={onScroll}>
      <table>
        <thead>
          <tr className={styles.timeHeader}>
            <td className={`bg-secondary-pale px-4 ${styles.tableHeader}`}>作業</td>
            <td className="p-0">
              <TimeScale tenantWithDate={tenantWithDate} is5min />
            </td>
          </tr>
        </thead>
        <tbody>
          {displayRateData.map(rate => (
            <tr className={styles.tableRow} key={`tr-${rate.scheduleTypeId}`}>
              <td className={`px-4 ${styles.tableHeader} `}>
                <div className="text-truncate">{rate.name}</div>
              </td>
              <td className={styles.tableContent}>
                <ShiftBar
                  key={rate.name}
                  items={getShiftBarItems(rate.data, rate.scheduleTypeId)}
                  businessStartTime={businessStartTime}
                  shiftBarWidth={businessDuration}
                  onChange={(index, x, width) => handleShiftBarChange(index, x, width, rate.scheduleTypeId)}
                  onAdd={(startPos, endPos) => handleShiftBarAdd(startPos, endPos, rate.scheduleTypeId)}
                  is5min
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
