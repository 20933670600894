import { cloneDeep } from 'es-toolkit'
import { useMemo } from 'react'
import { Button, Input, Label } from 'reactstrap'

import type { ReactNode } from 'react'

export type FilterItem<T extends number | string = number> = {
  key: T
  label: string
  checked: boolean
  topBorder?: ReactNode
}

type Props<T extends number | string> = {
  filterItems: FilterItem<T>[]
  onChange: (filterItems: FilterItem<T>[]) => void
  isEnableUnCheckAll?: boolean
}

export const CheckboxGroup = <T extends number | string>({ filterItems, onChange, isEnableUnCheckAll }: Props<T>) => {
  const allSelected = useMemo(() => filterItems.every(item => item.checked), [filterItems])
  const allUnSelected = useMemo(() => filterItems.every(item => !item.checked), [filterItems])

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newFilterItems = cloneDeep(filterItems)
    newFilterItems.splice(index, 1, { ...filterItems[index], checked })
    onChange(newFilterItems)
  }

  const handleButtonClick = () => {
    const newFilterItems = filterItems.map(item => ({
      ...item,
      checked: isEnableUnCheckAll ? allUnSelected : !allSelected,
    }))
    onChange(newFilterItems)
  }

  const status = useMemo(() => {
    if (isEnableUnCheckAll) {
      return allUnSelected ? 'すべて選択' : 'すべて解除'
    }
    return allSelected ? 'すべて解除' : 'すべて選択'
  }, [isEnableUnCheckAll, allSelected, allUnSelected])

  return (
    <div className="p-2">
      <Button className="mb-2" color="primary" size="sm" block outline onClick={handleButtonClick}>
        {status}
      </Button>
      {filterItems.map((filterItem, index) => (
        <div key={`checkboxGroup-${filterItem.key}-${filterItem.label}`}>
          {filterItem.topBorder}
          <div className="form-check mb-0">
            <Input
              id={`checkboxGroup-${filterItem.key}-${filterItem.label}`}
              type="checkbox"
              checked={filterItem.checked}
              onChange={e => handleCheckboxChange(index, e.target.checked)}
              className="form-check-input text-break"
            />
            <Label
              className="text-break form-check-label mb-0"
              for={`checkboxGroup-${filterItem.key}-${filterItem.label}`}
            >
              {filterItem.label}
            </Label>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CheckboxGroup
