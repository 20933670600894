import { useRef, useCallback } from 'react'
import Popup from 'reactjs-popup'
import { Card, CardBody } from 'reactstrap'

import { TimeRangeSelect } from 'components/common'
import { TIME_INTERVAL } from 'components/common/constants'

import type { PopupActions } from 'reactjs-popup/dist/types'

type Props = {
  label: string
  startTime: string
  duration: number
  onDelete: () => void
  onTimeChange: (startHour: string, startMinute: string, endHour: string, endMinute: string) => void
}

export const ShiftPopover = (props: Props) => {
  const { label, startTime, duration, onDelete, onTimeChange } = props
  const ref = useRef<PopupActions>(null)
  const trigger = <div className="h-100">{label}</div>

  const handleTimeChange = useCallback(
    (changeStartHour: string, changeStartMinute: string, changeEndHour: string, changeEndMinute: string) => {
      onTimeChange(changeStartHour, changeStartMinute, changeEndHour, changeEndMinute)
    },
    [onTimeChange]
  )

  return (
    <Popup ref={ref} trigger={trigger} position="bottom center" contentStyle={{ width: '14rem' }}>
      <Card>
        <CardBody className="p-2 font-x-small">
          <div className="d-flex justify-content-end font-large">
            <i className="icf-delete" onClick={onDelete} />
            <i className="icf-close ps-1" onClick={() => ref.current?.close()} />
          </div>
          <div className="mb-1">{label}</div>
          <div className="d-flex mt-2 align-items-center justify-content-between">
            <TimeRangeSelect
              startTime={startTime}
              duration={duration}
              interval={TIME_INTERVAL.FIFTEEN}
              onTimeChange={handleTimeChange}
            />
          </div>
        </CardBody>
      </Card>
    </Popup>
  )
}
