import dayjs from 'dayjs'
import { useState, useMemo, useCallback, Fragment } from 'react'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { DropdownItem, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

import { CustomButton } from 'components/common/CustomButton/CustomButton'

import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'
import { useQuery } from 'hooks/useQuery'

const DASHBOARD_PATH = '/dashboard/:workspaceId/*'
const ASSIGNMENT_PATH = '/assignment/:workspaceId'
const WORK_PLAN_PATH = '/schedules/:workspaceId/:workDate'

type Props = {
  className?: string
}

const MoveDropdown = ({ className }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { pathname } = useLocation()

  const { workDate, workspaceId } = useParams<'workspaceId' | 'workDate'>()

  const { hasWorkersWithShifts, planWorkDate } = usePlans()

  const navigate = useNavigate()

  const [date] = useQuery('date', '')
  const { getWorkDate } = useBusinessTime()
  const currentBusinessDate = useMemo(() => getWorkDate(dayjs().format('YYYY-MM-DD')), [getWorkDate])

  // 現在時刻ではなく、現在の営業日とdate/workDateの日付比較でisTodayの真偽を算出する
  const isToday = useMemo(() => {
    if (date) {
      return dayjs(date).isSame(currentBusinessDate, 'day')
    }
    if (workDate) {
      return dayjs(workDate).isSame(currentBusinessDate, 'day')
    }
    // どちらの値も無い場合は、現在の営業日同士の比較となるため、必ずtrueを返す
    return true
  }, [date, workDate, currentBusinessDate])

  const isPathMatching = useCallback((path: string) => !!matchPath({ path }, pathname), [pathname])

  const isDashboard = useMemo(() => isPathMatching(DASHBOARD_PATH), [isPathMatching])
  const isWorkPlan = useMemo(() => isPathMatching(WORK_PLAN_PATH), [isPathMatching])
  const items = useMemo(() => {
    const paths = []
    if (!isDashboard) {
      paths.push({
        label: 'ダッシュボード',
        onClick: () => navigate(`/dashboard/${workspaceId}?date=${date || planWorkDate}`),
      })
    }
    if (!isWorkPlan && hasWorkersWithShifts) {
      paths.push({ label: '作業計画', onClick: () => navigate(`/schedules/${workspaceId}/${date || planWorkDate}`) })
    }
    if (!isPathMatching(ASSIGNMENT_PATH) && isToday) {
      paths.push({
        label: '人員配置',
        onClick: () => navigate(`/assignment/${workspaceId}`),
      })
    }
    paths.push({
      label: 'シフト管理',
      onClick: () => navigate(`/shifts?id=${workspaceId}&date=${date || planWorkDate}`),
    })
    return paths
  }, [
    isDashboard,
    isWorkPlan,
    hasWorkersWithShifts,
    isPathMatching,
    isToday,
    navigate,
    workspaceId,
    date,
    planWorkDate,
  ])

  const name = useMemo(() => {
    if (isWorkPlan) {
      return 'work-plan-move-dropdown'
    }
    if (isDashboard) {
      return 'individual-summary-move-dropdown'
    }

    return 'assignment-move-dropdown'
  }, [isDashboard, isWorkPlan])

  return items.length > 0 ? (
    <div className={className}>
      <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} name={name}>
        <DropdownToggle tag="div">
          <CustomButton icon="carot_down" iconPosition="right" outline>
            移動
          </CustomButton>
        </DropdownToggle>
        <DropdownMenu>
          {items.map(item => (
            <Fragment key={item.label}>
              <DropdownItem onClick={item.onClick}>{item.label}</DropdownItem>
            </Fragment>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  ) : (
    <></>
  )
}

export default MoveDropdown
