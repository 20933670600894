import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type {
  ImportHistoryParams,
  ImportHistoryResponse,
  ImportHistoryUrlResponse,
  ImportHistoryDownLoadRequestBody,
} from './types'

export const getImportHistory = async (params: ImportHistoryParams): Promise<ImportHistoryResponse> => {
  const url = `/import-history`

  const response = await axiosClientWithTenantId.get(url, { params })
  return response.data
}

export const importHistoryUrl = async (
  data: ImportHistoryDownLoadRequestBody,
  fileInfoId: number
): Promise<ImportHistoryUrlResponse> => {
  const url = `/import-history/${fileInfoId}/download-url`

  const response = await axiosClientWithTenantId.post(url, data)
  return response.data
}
