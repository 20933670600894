import { useMemo } from 'react'
import { CardText, Button } from 'reactstrap'

import { SuggestionFormat as Suggestion } from 'components/common/FormFormat/Suggestion'
import type { SuggestionItem } from 'components/common/types'

import styles from './styles.module.scss'

type Props = {
  items: SuggestionItem[]
  selectedItems: SuggestionItem[]
  label: string
  itemName: string
  disabled?: boolean
  onChange: (selectedItems: SuggestionItem[]) => void
  showAddAllItemsButton?: boolean
}

const ItemEdit = (props: Props) => {
  const { items, selectedItems, label, itemName, disabled, onChange, showAddAllItemsButton = false } = props

  const remainingItems = useMemo(
    () =>
      items.reduce((acc: SuggestionItem[], cur: SuggestionItem) => {
        if (selectedItems.every(item => item.id !== cur.id)) {
          acc.push({ id: cur.id, value: cur.value })
        }
        return acc
      }, []),
    [items, selectedItems]
  )

  const onSelectItem = (item: SuggestionItem) => {
    const target = items.find(i => i.id === item.id)
    if (target) {
      const newSelectedItems = [...selectedItems, target]
      onChange(newSelectedItems)
    }
  }

  const removeItem = (item: SuggestionItem) => {
    if (disabled) {
      return
    }
    const newSelectedItems = selectedItems.filter(i => i.id !== item.id)
    onChange(newSelectedItems)
  }

  return (
    <>
      <div className={styles.badgeContainer}>
        {selectedItems.map(item => (
          <div
            key={item.id}
            className="badge rounded-pill bg-primary fw-normal m-2 font-small"
            style={{ maxWidth: '90%' }}
          >
            <div className="d-flex align-items-center">
              <div className="text-truncate">{item.value}</div>
              <i className="icf-close ms-1" role="button" onClick={() => removeItem(item)} />
            </div>
          </div>
        ))}
      </div>
      <CardText className="mt-3 mb-0 text-muted">{label}</CardText>
      <div className="d-flex">
        <Suggestion
          className="w-50"
          suggestionList={remainingItems}
          onSelect={onSelectItem}
          placeholder={`${itemName}名で検索`}
          disabled={disabled}
        />
        {showAddAllItemsButton && (
          <Button outline className="ms-3" onClick={() => onChange(items)}>
            すべての{itemName}を追加
          </Button>
        )}
      </div>
    </>
  )
}

export default ItemEdit
