import type { CreateResponse, UpdateResponse, DeleteResponse, ColorType } from 'api/types'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { GroupListResponse, GroupResponse } from './types'

export const getGroupList = async (workspaceId: number): Promise<GroupListResponse> => {
  const url = `/workspaces/${workspaceId}/groups`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const getGroup = async (workspaceId: number, groupId: number): Promise<GroupResponse> => {
  const url = `/workspaces/${workspaceId}/groups/${groupId}`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const createGroup = async (workspaceId: number, name: string, color: ColorType): Promise<CreateResponse> => {
  const url = `/workspaces/${workspaceId}/groups`

  const response = await axiosClientWithTenantId.post(url, { name, color })
  return response.data
}

export const updateGroup = async (
  workspaceId: number,
  groupId: number,
  name: string,
  color: ColorType
): Promise<UpdateResponse> => {
  const url = `/workspaces/${workspaceId}/groups/${groupId}`

  const response = await axiosClientWithTenantId.patch(url, { name, color })
  return response.data
}

export const deleteGroup = async (workspaceId: number, groupId: number): Promise<DeleteResponse> => {
  const url = `/workspaces/${workspaceId}/groups/${groupId}`

  const response = await axiosClientWithTenantId.delete(url)
  return response.data
}
