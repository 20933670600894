import { useMemo } from 'react'

import type { SkipInfo } from 'api/import_history/types'

import CustomModal from 'components/common/CustomModal/CustomModal'
import { StickyTable } from 'components/common/Table/StickyTable'

import styles from './ImportSkipDetailDialog.module.scss'

type Props = {
  isOpen: boolean
  onCloseDialogClick: () => void
  fileInfoLabel: string
  skipInfo: SkipInfo[]
}

const HEADER = [{ value: '行番号', className: 'w-25' }, { value: 'スキップ理由' }]

export const ImportSkipDetailDialog = ({ isOpen, fileInfoLabel, onCloseDialogClick, skipInfo }: Props) => {
  const body = useMemo(
    () =>
      skipInfo.map(info => [{ value: info.lineNumber.toLocaleString(), className: 'w-25' }, { value: info.errorInfo }]),
    [skipInfo]
  )

  return (
    <CustomModal isOpen={isOpen} onCancel={onCloseDialogClick} showApprove={false} title={'スキップ詳細'}>
      <div className="d-flex flex-column row-gap-3">
        <div>スキップされた行の詳細を表示します。</div>
        <div className="font-x-small text-muted">{fileInfoLabel}</div>
        <div className={styles.skipTable}>
          <StickyTable header={HEADER} body={body} fixedTop />
        </div>
      </div>
    </CustomModal>
  )
}
