import type {
  BopMonitoringReportParam,
  BopReportsBopResponse,
  BopReportsLaborCostsResponse,
  BopReportsProfitAndLossResponse,
  BopReportsUnitCostsResponse,
  BopReportUnitCostsParam,
  BopMonitoringProfitAndLossParam,
  CheckExportDataReadyResponse,
  CreateBopExportDataInfo,
  CreateExportDataResponse,
} from 'api/bop_reports/types'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

export const getBopReportsProfitAndLoss = async (
  data: BopMonitoringProfitAndLossParam
): Promise<BopReportsProfitAndLossResponse> => {
  const url = '/bop-report-detail'

  const response = await axiosClientWithTenantId.get(url, { params: data })
  return response.data
}

export const getBopReportsBop = async (data: BopMonitoringReportParam): Promise<BopReportsBopResponse> => {
  const url = '/bop-report'

  const response = await axiosClientWithTenantId.get(url, { params: data })
  return response.data
}

export const getBopReportsLaborCosts = async (
  data: BopMonitoringReportParam
): Promise<BopReportsLaborCostsResponse> => {
  const url = '/bop-report-labor-costs'

  const response = await axiosClientWithTenantId.get(url, { params: data })
  return response.data
}

export const getBopReportsUnitCosts = async (data: BopReportUnitCostsParam): Promise<BopReportsUnitCostsResponse> => {
  const url = '/bop-report-unit-costs'

  const response = await axiosClientWithTenantId.get(url, { params: data })
  return response.data
}

export const createBopExportData = async (data: CreateBopExportDataInfo): Promise<CreateExportDataResponse> => {
  const url = '/bop-export/async'

  const response = await axiosClientWithTenantId.post(url, data)
  return response.data
}

export const getBopExportData = async (requestId: string): Promise<CheckExportDataReadyResponse> => {
  const url = `/bop-export/update-status/${requestId}`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}
