import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { IMPORT_HISTORY_DOWNLOAD_TYPE, IMPORT_HISTORY_TYPE, IMPORT_HISTORY_STATUS } from 'api/import_history/constants'
import type { FileInfoData, ImportHistoryType, ImportHistoryStatusType, SkipInfo } from 'api/import_history/types'

import { importTargetTypeCsvFromHistory } from 'slices/importHistorySlice'

import { BadgeLabel } from '../BadgeLabel/BadgeLabel'
import { StickyTable } from '../Table/StickyTable'

import { ImportFailDetailDialog } from './Dialogs/ImportFailDetailDialog'
import { ImportFilePreviewDialog } from './Dialogs/ImportFilePreviewDialog'
import { ImportSkipDetailDialog } from './Dialogs/ImportSkipDetailDialog'
import { BASE_STYLE, STATUS_LABELS } from './constants'

import styles from './ImportHistoryTable.module.scss'

const BaseNameCell = ({ name }: { name: string }) => (
  <div title={name} className="text-truncate">
    {name}
  </div>
)

type FileNameCellProps = {
  fileName: string
  fileInfoLabel: string
  preview: string[][]
}

const FileNameCell = ({ fileName, fileInfoLabel, preview }: FileNameCellProps) => {
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)

  const handleImportFilePreviewClick = () => {
    setIsPreviewDialogOpen(true)
  }

  const handleCloseDialogClick = () => {
    setIsPreviewDialogOpen(false)
  }

  return (
    <>
      <BaseNameCell name={fileName} />
      <i className="icf-info font-large text-muted" role="button" onClick={handleImportFilePreviewClick} />
      <ImportFilePreviewDialog
        isOpen={isPreviewDialogOpen}
        onCloseDialogClick={handleCloseDialogClick}
        fileInfoLabel={fileInfoLabel}
        preview={preview}
      />
    </>
  )
}

type StatusCellProps = {
  status: ImportHistoryStatusType
  fileInfoLabel: string
  errorInfo: string[]
}

const StatusCell = ({ status, fileInfoLabel, errorInfo }: StatusCellProps) => {
  const { label, bgColor, textColor } = STATUS_LABELS[status]
  const [isFailDetailOpen, setIsFailDetailOpen] = useState(false)

  const handleImportFailDetailClick = () => {
    setIsFailDetailOpen(true)
  }

  const handleCloseDialogClick = () => {
    setIsFailDetailOpen(false)
  }

  return (
    <>
      <BadgeLabel label={label} labelColor={bgColor} className={`text-${textColor} text-truncate`} />
      {status === IMPORT_HISTORY_STATUS.FAILED && (
        <button className="btn btn-link align-items-left" onClick={handleImportFailDetailClick}>
          詳細
        </button>
      )}
      <ImportFailDetailDialog
        isOpen={isFailDetailOpen}
        onCloseDialogClick={handleCloseDialogClick}
        fileInfoLabel={fileInfoLabel}
        errorInfo={errorInfo}
      />
    </>
  )
}

const DateCell = ({ date }: { date: string | null }) => {
  const baseDate = useMemo(() => dayjs(date), [date])
  return (
    <div className="d-flex flex-column">
      <div>{date ? baseDate.format('YYYY/MM/DD') : '-'}</div>
      <div className="text-muted font-x-small">{date ? baseDate.format('HH:mm:ss') : '-'}</div>
    </div>
  )
}

type SkipCellProps = {
  skipCount: number
  status: ImportHistoryStatusType
  fileInfoLabel: string
  skipInfo: SkipInfo[]
}

const SkipCountCell = ({ skipCount, status, fileInfoLabel, skipInfo }: SkipCellProps) => {
  const existSkip = status === IMPORT_HISTORY_STATUS.SUCCESS || status === IMPORT_HISTORY_STATUS.SKIP
  const [isSkipDetailOpen, setIsSkipDetailOpen] = useState(false)

  const handleImportSkipDetailClick = () => {
    setIsSkipDetailOpen(true)
  }

  const handleCloseDialogClick = () => {
    setIsSkipDetailOpen(false)
  }

  return (
    <>
      <div className={`${BASE_STYLE} flex-column`}>
        {existSkip ? (
          <>
            <div className="w-100 d-flex justify-content-start">{skipCount.toLocaleString()}</div>
            {skipCount > 0 && (
              <button className="btn btn-link p-0" onClick={handleImportSkipDetailClick}>
                詳細
              </button>
            )}
          </>
        ) : (
          '-'
        )}
      </div>
      <ImportSkipDetailDialog
        isOpen={isSkipDetailOpen}
        onCloseDialogClick={handleCloseDialogClick}
        fileInfoLabel={fileInfoLabel}
        skipInfo={skipInfo}
      />
    </>
  )
}

type FileImportCellProps = {
  fileId: number
  fileName: string
}

const FileImportCell = ({ fileId, fileName }: FileImportCellProps) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(importTargetTypeCsvFromHistory({ type: IMPORT_HISTORY_DOWNLOAD_TYPE.DAILY_REPORT }, fileId, fileName))
  }
  return <i className="icf-save font-large" role="button" onClick={handleClick} />
}

type Props = {
  selectedFileInfoData: FileInfoData[]
  importHistoryType: ImportHistoryType
}

export const ImportHistoryTable = ({ selectedFileInfoData, importHistoryType }: Props) => {
  const tableHeader = useMemo(() => {
    const headers = [
      { value: 'ファイル名', className: styles.fileNameCell },
      { value: importHistoryType === IMPORT_HISTORY_TYPE.DAILY_REPORT ? 'インポート設定' : 'カテゴリー' },
      { value: 'ステータス' },
      { value: '開始日時' },
      { value: '完了時間' },
      { value: '行数' },
      { value: 'スキップ' },
      { value: 'ユーザー' },
      { value: 'ファイル', className: `${styles.fileImportCell} justify-content-center` },
    ]

    return headers.map(header => ({
      ...header,
      className: header.className ? `${header.className} ${BASE_STYLE}` : BASE_STYLE,
    }))
  }, [importHistoryType])

  const body = useMemo(
    () =>
      selectedFileInfoData.map(file => {
        const fileInfoLabel = `${file.category.name} | ${file.name} | ${dayjs(file.startDate).format('YYYY/MM/DD HH:mm:ss')} | ${STATUS_LABELS[file.status].label}`

        return [
          {
            value: <FileNameCell fileName={file.name} preview={file.preview} fileInfoLabel={fileInfoLabel} />,
            className: `${styles.fileNameCell} ${BASE_STYLE} justify-content-between`,
          },
          { value: <BaseNameCell name={file.category.name} />, className: BASE_STYLE },
          {
            value: <StatusCell status={file.status} fileInfoLabel={fileInfoLabel} errorInfo={file.errorInfo} />,
            className: BASE_STYLE,
          },
          { value: <DateCell date={file.startDate} />, className: BASE_STYLE },
          { value: <DateCell date={file.endDate} />, className: BASE_STYLE },
          { value: file.numberOfRecord.toLocaleString(), className: BASE_STYLE },
          {
            value: (
              <SkipCountCell
                skipCount={file.skipCount}
                status={file.status}
                fileInfoLabel={fileInfoLabel}
                skipInfo={file.skipInfo}
              />
            ),
            className: BASE_STYLE,
          },
          { value: <BaseNameCell name={file.updatedByName} />, className: BASE_STYLE },
          {
            value: <FileImportCell fileId={file.id} fileName={file.name} />,
            className: `${BASE_STYLE} ${styles.fileImportCell} justify-content-center`,
          },
        ]
      }),
    [selectedFileInfoData]
  )

  return (
    <div className="overflow-auto">
      <StickyTable
        header={tableHeader}
        body={body}
        fixedLeft
        fixedRight
        headerClassName={styles.headerHeight}
        rowClassName={styles.rowHeight}
      />
    </div>
  )
}
