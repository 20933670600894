import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { deleteDailyReportsImportSettings, selectDailyReportsStatus } from 'slices/dailyReportsSlice'

import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  dailyReportId: number
  onSuccess: () => void
  onCancel: () => void
}

export const DailyReportSettingsDelete = (props: Props) => {
  const { isOpen, dailyReportId, onSuccess, onCancel } = props

  const [submitted, setSubmitted] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)

  const { isRequesting, errorMessage } = useSelector(selectDailyReportsStatus, shallowEqual)

  const dispatch = useDispatch()

  const handleDeleteClick = () => {
    if (dailyReportId) {
      setSubmitted(true)
      dispatch(deleteDailyReportsImportSettings(dailyReportId))
    }
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
      setModalErrorMessage('削除できませんでした。')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess])

  return (
    <CustomModal
      key={`daily-report-settings-delete-${isOpen}`}
      isOpen={isOpen}
      title="日報実績インポート設定の削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleDeleteClick}
    >
      <div className="pb-4">この日報実績インポート設定を本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}
