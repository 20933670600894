import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'

import { confirmPasswordReset, selectSessionStatus, clearErrorMessage } from 'slices/sessionSlice'
import { ERROR_STATUS_CODE } from 'slices/utils'

import { InputFormat, SinglePageCard } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'

import styles from './styles.module.scss'

import type { MouseEvent } from 'react'

type Props = {
  email: string
}

const NewPasswordSet = (props: Props) => {
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)
  const [confirmationCode, setConfirmationCode] = useState<string | undefined>(undefined)
  const [newPassword, setNewPassword] = useState<string | undefined>(undefined)
  const [newPasswordAgain, setNewPasswordAgain] = useState<string | undefined>(undefined)
  const [confirmationCodeValidity, setConfirmationCodeValidity] = useState(false)
  const [newPasswordValidity, setNewPasswordValidity] = useState(false)
  const [newPasswordAgainValidity, setNewPasswordAgainValidity] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isRequesting, errorMessage } = useSelector(selectSessionStatus, shallowEqual)
  const onSubmitNewPassword = (e: MouseEvent) => {
    e.preventDefault()
    setSubmitted(true)
    dispatch(confirmPasswordReset({ email: props.email, code: confirmationCode!, password: newPassword! }))
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      navigate('/password-reset-complete')
    } else if (errorMessage === ERROR_STATUS_CODE.UNREACHABLE) {
      // UNREACHABLEのときにはエラーダイアログが出るのでNotificationは出さない
      setModalErrorMessage(undefined)
    } else {
      // ログイン不要ページでUNAUTHORIZEDのときはNotificationを出す
      setModalErrorMessage('パスワードの再設定に失敗しました。')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, navigate])

  const disabled = useMemo(
    () =>
      !(
        confirmationCode &&
        confirmationCodeValidity &&
        newPassword &&
        newPasswordValidity &&
        newPasswordAgain &&
        newPasswordAgainValidity
      ),
    [
      confirmationCode,
      confirmationCodeValidity,
      newPassword,
      newPasswordValidity,
      newPasswordAgain,
      newPasswordAgainValidity,
    ]
  )

  return (
    <SinglePageCard
      subtitle="パスワード再設定"
      errorMessage={modalErrorMessage}
      onHideNotification={() => {
        setModalErrorMessage(undefined)
        dispatch(clearErrorMessage())
      }}
    >
      <Container>
        <Row>
          <Col className="text-center">
            入力いただいたメールに確認コードを送信しました。メールを確認の上、確認コードを入力し、新しいパスワードを作成してください。
          </Col>
        </Row>

        <Row>
          <Col className="text-left mt-3">
            <InputFormat
              label="確認コード"
              placeholder="確認コード"
              className="mt-4"
              value={confirmationCode}
              size={COLUMN_SIZES.MIDDLE}
              maxLength={100}
              onChange={value => setConfirmationCode(value)}
              validations={[Rules.Required]}
              onValidate={setConfirmationCodeValidity}
              formText="新しいパスワードを作成してください。"
            />
          </Col>
        </Row>

        <Row>
          <Col className="text-left mt-3">
            <InputFormat
              label="新しいパスワード"
              placeholder="新しいパスワード"
              className="mt-4"
              value={newPassword}
              type="password"
              size={COLUMN_SIZES.MIDDLE}
              onChange={value => setNewPassword(value)}
              validations={[Rules.Required]}
              onValidate={setNewPasswordValidity}
            />
          </Col>
        </Row>

        <Row>
          <Col className="text-left mt-3">
            <InputFormat
              label="パスワード（確認）"
              placeholder="パスワード（確認）"
              className="mt-4"
              value={newPasswordAgain}
              type="password"
              size={COLUMN_SIZES.MIDDLE}
              onChange={value => setNewPasswordAgain(value)}
              validations={[Rules.Required, Rules.PasswordConfirmation(newPassword)]}
              onValidate={setNewPasswordAgainValidity}
            />
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <Button disabled={disabled} color="primary" className={styles.button} onClick={onSubmitNewPassword}>
              パスワード再設定
            </Button>
          </Col>
        </Row>
      </Container>
    </SinglePageCard>
  )
}

export default NewPasswordSet
