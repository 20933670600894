import { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { deleteOfficialDuties, selectOfficialDutiesStatus } from 'slices/officialDutiesSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  officialDutyId: number | undefined
  onSuccess: () => void
  onCancel: () => void
}

const HourlyWageDelete = ({ isOpen, officialDutyId, onSuccess, onCancel }: Props) => {
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = useState(false)
  const { isRequesting, errorMessage } = useSelector(selectOfficialDutiesStatus, shallowEqual)
  const dispatch = useDispatch()

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    onCancel()
  }

  const handleDeleteClick = () => {
    if (officialDutyId) {
      setSubmitted(true)
      dispatch(deleteOfficialDuties(officialDutyId))
    }
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
      setModalErrorMessage('削除できませんでした。')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, onSuccess])

  return (
    <CustomModal
      isOpen={isOpen}
      title="職掌の削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleDeleteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">この職掌を本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}

export default HourlyWageDelete
