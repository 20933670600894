import { Card, CardBody, CardText, CardTitle } from 'reactstrap'

import type { ColorType } from 'api/types'

import styles from './styles.module.scss'

import type { ReactElement } from 'react'

export type ListItem<T extends string | number = number> = {
  title: string
  id: T
  data?: string | ReactElement
  option?: ReactElement
  color?: ColorType
}

type Props<T extends number | string> = {
  items: ListItem<T>[]
  selectedId: T | undefined
  onAction?: (selected: T) => void
}

const List = <T extends string | number>({ items, selectedId, onAction = () => null }: Props<T>) => {
  return (
    <div>
      {items.map((item: ListItem<T>, index: number) => {
        const cardTextColor = item.id === selectedId ? 'text-white-50' : 'text-black-50'
        return (
          <Card
            key={index}
            className={styles.card}
            onClick={() => onAction(item.id)}
            color={item.id === selectedId ? 'primary' : 'default'}
            inverse={item.id === selectedId}
          >
            <CardBody className="p-2 px-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="overflow-hidden">
                  <CardTitle className="m-0 text-truncate">{item.title || '\u00A0'}</CardTitle>
                  {item.data &&
                    (typeof item.data === 'string' ? (
                      <CardText className={`font-x-small mt-2 text-truncate ${cardTextColor}`}>{item.data}</CardText>
                    ) : (
                      item.data
                    ))}
                </div>
                <div>{item.option}</div>
              </div>
            </CardBody>
          </Card>
        )
      })}
    </div>
  )
}

export default List
