import dayjs from 'dayjs'
import { sortBy, isEqual, compact } from 'es-toolkit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'

import { PLAN_UPDATE_MODE_TYPES } from 'api/plans/constants'
import type { UpdatePlanBulk, UpdatePlanSchedule } from 'api/plans/types'

import { getWorkspaceSummary, selectDashboardStatus } from 'slices/dashboardSlice'
import { updatePlanBulkCreate, selectPlansStatus, getPlanByWorkerId } from 'slices/plansSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import type { InputScheduleType } from 'components/TeamSchedules/TeamWorkPlan/ShiftInputScale'
import ShiftInputScale from 'components/TeamSchedules/TeamWorkPlan/ShiftInputScale'
import { BadgeLabel, DatePicker, TimeScale, ShiftBar, ShiftPopover, MultipleFooter } from 'components/common'
import { TableScale } from 'components/common/AssignedNumberTable/TableScale'
import { Notification } from 'components/common/Notification/Notification'
import { TENTATIVE_SCHEDULE_TYPE_ID } from 'components/common/constants'
import type { ShiftBarItemType, EditSchedule } from 'components/common/types'
import { getRandomNumber, getShiftBarWidthByDuration } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import { useGroupPerformance } from 'hooks/useGroupPerformance'
import usePlans from 'hooks/usePlans'

import { TeamWorkerCard } from './TeamWorkerCard'
import { getWorkerPerformance } from './utils'

import styles from './TeamWorkerDetail.module.scss'

export const TeamWorkerDetail = () => {
  const params = useParams<'workerId'>()
  const workerId = Number(params.workerId)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    businessHourBlocks,
    businessStartTime,
    businessEndTime,
    businessDuration,
    getTimesByShiftBarX,
    getShiftBarXbyStartTime,
    getWorkDate,
    getTimeOver24h,
  } = useBusinessTime()

  const [scheduleDate, setScheduleDate] = useState(getWorkDate(dayjs().format('YYYY-MM-DD')))
  const [inputMode, setInputMode] = useState(false)
  const [inputSchedule, setInputSchedule] = useState<InputScheduleType | undefined>()
  const [workerShiftSchedule, setWorkerShiftSchedule] = useState<EditSchedule[]>([])
  const [initWorkerShiftSchedule, setInitWorkerShiftSchedule] = useState<EditSchedule[]>([])
  const [, setSubmitted] = useState(false)
  const [errorNotificationMessage, setErrorNotificationMessage] = useState('')

  const {
    team: { workspaceId, groupId, groupName, groupColor },
  } = useSelector(selectSessionStatus, shallowEqual)
  const { workspaceSummary } = useSelector(selectDashboardStatus, shallowEqual)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { plans, isRequesting, errorMessage } = useSelector(selectPlansStatus, shallowEqual)

  const { planWorkDate, getEditSchedulesFromWorkPlan, getWorkerPlanFromUpdatePlanScheduleForShift, getEmptyShiftData } =
    usePlans()
  const { getGroupPerformance } = useGroupPerformance()

  useEffect(() => {
    dispatch(getWorkspaceSummary(workspaceId, getWorkDate(dayjs().format('YYYY-MM-DD'))))
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, getWorkDate, workspaceId])

  useEffect(() => {
    dispatch(getPlanByWorkerId(workspaceId, scheduleDate, workerId))
  }, [dispatch, workspaceId, workerId, scheduleDate])

  // TODO エラー時にダイアログを出すのか､のティフィケーションを出すのか何もださないのかを確認
  // API側では元はエラーダイアログを出さないように実装していたが､一旦エラーダイアログを出すように変更
  useEffect(() => {
    setSubmitted(prev => {
      if (isRequesting || !prev) {
        return prev
      }
      if (errorMessage === '') {
        navigate(-1)
      } else if (ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        setErrorNotificationMessage('保存できませんでした。')
      }

      return false
    })
  }, [errorMessage, navigate, isRequesting])

  const { group, workerPlan } = useMemo(() => {
    const groupData = plans?.groups.find(g => g.workersPlan.find(w => w.workerId === workerId))
    const workerPlanData = groupData?.workersPlan.find(w => w.workerId === workerId)

    return {
      group: groupData,
      workerPlan: workerPlanData,
    }
  }, [plans, workerId])

  const workerSchedule = useMemo(() => {
    if (!workerPlan) {
      return
    }
    const isSupport = group?.groupName === groupName && false
    return getEditSchedulesFromWorkPlan(workerPlan, isSupport)
  }, [getEditSchedulesFromWorkPlan, group?.groupName, groupName, workerPlan])

  const badgeItem = useMemo(() => {
    if (!workerSchedule) {
      return
    }
    const now = dayjs()
    const currentTimeSchedule = workerSchedule.find(schedule => {
      if (schedule.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT) {
        return false
      }

      const from = dayjs(schedule.startAt)
      const to = dayjs(schedule.startAt).add(schedule.duration, 'seconds')
      return now.isBetween(from, to, 'minute', '[]')
    })
    if (!currentTimeSchedule) {
      return
    }
    if (currentTimeSchedule.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT) {
      return {
        key: currentTimeSchedule.supportWorkspaceId ?? TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT,
        label: currentTimeSchedule.supportWorkspaceName ?? '',
        color: undefined,
      }
    }
    const scheduleType = partialScheduleTypes.find(s => s.id === currentTimeSchedule.scheduleTypeId)
    return {
      key: scheduleType?.id || getRandomNumber(),
      label: scheduleType?.name || '',
      color: scheduleType?.color || undefined,
    }
  }, [workerSchedule, partialScheduleTypes])

  useEffect(() => {
    if (!workerSchedule) {
      return
    }
    const shifts = sortBy(workerSchedule, ['startAt']).filter(
      schedule => schedule.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT
    )

    setWorkerShiftSchedule(shifts)
    setInitWorkerShiftSchedule(shifts)
  }, [scheduleDate, workerSchedule])

  const onDelete = useCallback(
    (id: string) => {
      setWorkerShiftSchedule(workerShiftSchedule.filter(s => s.scheduleId?.toString() !== id))
    },
    [setWorkerShiftSchedule, workerShiftSchedule]
  )

  const shiftBarItems = useMemo(
    (): ShiftBarItemType[] =>
      workerShiftSchedule.map((schedule, index) => {
        const startAt = dayjs(schedule.startAt).local()
        const endAt = dayjs(startAt).add(schedule.duration, 'second').local()
        const x = getShiftBarXbyStartTime(schedule.startAt, planWorkDate)
        const width = getShiftBarWidthByDuration(schedule.duration)
        const id = schedule.scheduleId || getRandomNumber()

        return {
          id: id.toString(),
          content: (
            <ShiftPopover
              label={`勤務時間${index + 1}`}
              time={`${getTimeOver24h(startAt.format('HH:mm'), true)}〜${getTimeOver24h(endAt.format('HH:mm'))}`}
              deleteKey={id.toString()}
              onDelete={onDelete}
            />
          ),
          x,
          width,
        }
      }),
    [workerShiftSchedule, getShiftBarXbyStartTime, planWorkDate, getTimeOver24h, onDelete]
  )

  const workerPerformance = useMemo(() => {
    if (!workspaceSummary) {
      return
    }
    return getGroupPerformance(workspaceSummary.workspaceData.groups)
      .flatMap(g => g.workerDataList)
      .find(w => w.workerId === workerId)
  }, [getGroupPerformance, workerId, workspaceSummary])

  const unchanged = useMemo(
    () => isEqual(initWorkerShiftSchedule, workerShiftSchedule),
    [initWorkerShiftSchedule, workerShiftSchedule]
  )

  const handleDatePickerChange = (date: string | undefined) => {
    if (!date || dayjs(date).local().format('YYYY-MM-DD') === scheduleDate) {
      return
    }
    cancelInputMode()
    setScheduleDate(dayjs(date).local().format('YYYY-MM-DD'))
  }

  const shiftInputScaleClick = (x: number) => {
    if (!inputSchedule || inputSchedule.workerId !== workerId || inputSchedule.startX !== inputSchedule.endX) {
      setInputSchedule({ workerId, startX: x, endX: x })
    } else {
      setInputSchedule({ ...inputSchedule, endX: x })
    }
  }

  const cancelInputMode = () => {
    setInputMode(false)
    setInputSchedule(undefined)
  }

  const onDecisionButtonClick = () => {
    if (!inputSchedule) {
      return
    }
    const startTime = getTimesByShiftBarX(inputSchedule.startX)
    let addSchedule: EditSchedule = {
      scheduleId: null,
      scheduleTypeId: TENTATIVE_SCHEDULE_TYPE_ID.SHIFT,
      startAt: dayjs(`${scheduleDate} ${startTime.hours}:${startTime.minutes}`, 'YYYY-MM-DD HH:mm').utc().format(),
      duration: (inputSchedule.endX - inputSchedule.startX + 1) * 900,
      supportWorkspaceId: null,
      supportWorkspaceName: null,
    }

    const newWorkerSchedules = workerShiftSchedule.reduce((acc: EditSchedule[], cur) => {
      const end = dayjs(addSchedule.startAt).add(addSchedule.duration, 'seconds').format()
      const curEnd = dayjs(cur.startAt).add(cur.duration, 'seconds').format()
      if (
        dayjs(addSchedule.startAt).isBetween(cur.startAt, curEnd, 'minutes', '[]') &&
        dayjs(end).isBetween(cur.startAt, curEnd, 'minutes', '[]')
      ) {
        addSchedule = { ...cur, startAt: addSchedule.startAt, duration: addSchedule.duration }
      } else if (
        dayjs(cur.startAt).isBetween(addSchedule.startAt, end, 'minutes', '()') &&
        dayjs(curEnd).isBetween(addSchedule.startAt, end, 'minutes', '()')
      ) {
        return acc
      } else if (dayjs(addSchedule.startAt).isBetween(cur.startAt, curEnd, 'minutes', '[]')) {
        const diff = Math.abs(dayjs(curEnd).unix() - dayjs(addSchedule.startAt).unix())
        addSchedule = { ...cur, duration: cur.duration + addSchedule.duration - diff }
      } else if (dayjs(end).isBetween(cur.startAt, curEnd, 'minutes', '[]')) {
        const diff = Math.abs(dayjs(end).unix() - dayjs(cur.startAt).unix())
        addSchedule = { ...cur, startAt: addSchedule.startAt, duration: cur.duration + addSchedule.duration - diff }
      } else {
        acc.push(cur)
      }
      return acc
    }, [])

    setWorkerShiftSchedule(newWorkerSchedules.concat(compact([addSchedule])))
    cancelInputMode()
  }

  const onSubmit = () => {
    if (!workerPlan) {
      return
    }
    setSubmitted(true)
    // worker schedules の追加or更新
    const updateWorkerSchedules = workerShiftSchedule.reduce((acc: UpdatePlanSchedule[], cur) => {
      acc.push({
        scheduleId: !cur.scheduleId || cur.scheduleId < 1 ? null : cur.scheduleId,
        schedule: {
          scheduleTypeId: cur.scheduleTypeId,
          supportWorkspaceId: cur.supportWorkspaceId,
          startAt: cur.startAt,
          duration: cur.duration,
          workerId,
          groupId: null,
        },
      })
      return acc
    }, [])

    const updatePlanDate: UpdatePlanBulk = {
      updateMode: PLAN_UPDATE_MODE_TYPES.SHIFT_PLAN,
      workersPlan: getWorkerPlanFromUpdatePlanScheduleForShift(
        updateWorkerSchedules.length === 0 ? getEmptyShiftData(workerId) : updateWorkerSchedules
      ),
    }
    dispatch(updatePlanBulkCreate(workspaceId, planWorkDate, updatePlanDate))
  }

  const counts = useMemo(
    () =>
      businessHourBlocks.reduce((acc, cur) => {
        const workerData = workerPerformance?.workerData || {}
        const rate = workerData[`${cur}:00`]?.rate || null
        const warning = rate !== null && Math.abs(100 - rate) >= 10
        acc.set(cur, <div className={warning ? 'text-danger' : ''}>{rate ? `${rate}%` : ''}</div>)
        return acc
      }, new Map()),
    [businessHourBlocks, workerPerformance]
  )

  return (
    <>
      <Notification
        errorMessage={errorNotificationMessage}
        error={!!errorNotificationMessage}
        hide={() => setErrorNotificationMessage('')}
      />
      <div className={`${styles.container} d-flex flex-column`}>
        <div className={`d-flex align-items-center font-x-large ${styles.header}`}>
          <div className="fw-bold text-center flex-grow-1">メンバー詳細</div>
          <i className="icf-close px-3" onClick={() => navigate(-1)} />
        </div>

        <div className={`${styles.main} flex-grow-1`}>
          <TeamWorkerCard
            workerId={workerId}
            workerName={workerPlan?.workerName || ''}
            groupName={groupName}
            groupColor={groupColor}
            performance={getWorkerPerformance(workspaceSummary, groupId, workerId)}
            disabled={true}
          />

          <div className="d-flex mt-4">
            <div className="me-3">現在の作業</div>
            {badgeItem && <BadgeLabel {...badgeItem} />}
          </div>

          <div className={`${styles.tableWrapper} mt-3`}>
            <TimeScale round />
            <table className={styles.table}>
              <tbody>
                <tr>
                  <TableScale items={counts} />
                </tr>
              </tbody>
            </table>
          </div>

          <div className="font-large fw-bold">シフト情報</div>
          <Row className="py-3 pe-3">
            <Col md={6} className="d-flex align-items-center">
              <div className="me-5 text-nowrap">日付を選択</div>
              <DatePicker
                id="dateSelect"
                value={scheduleDate}
                minDate={dayjs(getWorkDate(dayjs().format('YYYY-MM-DD'))).toDate()}
                onChange={handleDatePickerChange}
              />
            </Col>
            <Col className="text-end p-0">
              <Button className="px-4" outline disabled={inputMode} onClick={() => setInputMode(true)}>
                シフト編集
              </Button>
            </Col>
          </Row>

          <div className={styles.tableWrapper}>
            <TimeScale />
            <div className={styles.tableContent}>
              <ShiftBar
                items={shiftBarItems}
                businessStartTime={businessStartTime}
                shiftBarWidth={businessDuration}
                disabled={inputMode}
                isTeam={true}
              />
              <ShiftInputScale
                show={inputMode}
                businessStartTime={businessStartTime}
                businessEndTime={businessEndTime}
                shiftBarWidth={businessDuration}
                inputSchedule={inputSchedule}
                selectedScheduleType={{ scheduleTypeId: 1, name: '勤務時間', color: 'secondary' }}
                onClick={shiftInputScaleClick}
              />
            </div>
          </div>
        </div>

        {inputMode ? (
          <MultipleFooter
            stepText="時間帯を選択"
            disabled={!inputSchedule}
            selectorOpen={false}
            decisionButtonClick={onDecisionButtonClick}
            onCancel={cancelInputMode}
          />
        ) : (
          <Container fluid className={styles.footer}>
            <Row>
              <Col>
                <Button outline onClick={() => navigate(-1)}>
                  キャンセル
                </Button>
              </Col>
              <Col className="text-end">
                <Button color="primary" disabled={unchanged} className="px-4" onClick={onSubmit}>
                  保存
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  )
}
