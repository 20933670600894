import dayjs from 'dayjs'
import { useState, useMemo } from 'react'

import { CELL_WIDTH, CELL_WIDTH_5MIN } from 'components/common/constants'

import { RndItem } from './RndItem'

import stylesDefault from './ShiftBar.module.scss'
import styles5min from './ShiftBar5min.module.scss'

import type { OnChangeProps } from './RndItem'
import type { ReactNode } from 'react'

export type ShiftBarItemType = {
  id: string
  content: ReactNode
  x: number
  width: number
  color?: string
  invertedColor?: boolean
  disabled?: boolean
}

type Props = {
  items: ShiftBarItemType[]
  businessStartTime: string
  shiftBarWidth: number
  onChange?: (index: number, x: number, width: number) => void
  onAdd?: (startPos: number, endPos: number) => void
  activeRange?: Array<[number, number]>
  disabled?: boolean
  isTeam?: boolean
  is5min?: boolean
}

export const ShiftBar = ({
  items,
  businessStartTime,
  shiftBarWidth,
  onChange = () => {},
  onAdd = () => {},
  activeRange = [[0, shiftBarWidth - 1]],
  disabled = false,
  isTeam = false,
  is5min = false,
}: Props) => {
  const [mousedownPos, setMousedownPos] = useState(0)
  const [mousedownFlag, setMousedownFlag] = useState(false)

  const cellWidth = useMemo(() => (is5min ? CELL_WIDTH_5MIN / 3 : CELL_WIDTH), [is5min])
  const styles = useMemo(() => (is5min ? styles5min : stylesDefault), [is5min])

  const isActiveRange = (x: number) => {
    return activeRange.some(range => range[0] <= x && x <= range[1])
  }

  const resizePosition = (index: number, start: boolean) => {
    const position = activeRange.reduce(
      (acc, range, idx, org) => {
        if (range[0] <= acc.num && acc.num <= range[1]) {
          return { num: acc.num, end: true }
        }
        if (!acc.end) {
          if (idx === org.length - 1) {
            const num = start && acc.num < range[0] ? range[0] : range[1] < acc.num ? range[1] + 1 : acc.num
            return { num, end: true }
          }
          if (idx === 0) {
            const num =
              range[1] <= acc.num && acc.num <= org[idx + 1][0] ? (start ? org[idx + 1][0] : range[1] + 1) : acc.num
            return acc.num < range[0] ? { num: range[0], end: true } : { num, end: false }
          }
        }
        return acc
      },
      { num: start ? index : index + 1, end: false }
    )

    return position.num
  }

  const handleMouseDown = (pos: number) => {
    if (!disabled && !isTeam) {
      setMousedownPos(resizePosition(pos, true))
      setMousedownFlag(true)
    }
  }
  const handleMouseUp = (pos: number) => {
    const resizedPos = resizePosition(pos, pos < mousedownPos)
    if (!disabled && !isTeam && mousedownFlag && mousedownPos !== resizedPos) {
      mousedownPos < resizedPos
        ? onAdd(mousedownPos, resizedPos)
        : onAdd(resizedPos, resizePosition(mousedownPos, false))
    }
    setMousedownFlag(false)
  }

  const handleChange = (index: number, x: number, width: number) => {
    if (items[index].x === x && items[index].width === width) {
      return
    }
    onChange(index, x, width)
  }

  const min = dayjs(businessStartTime, 'HH:mm').minute()
  const css = min % 2 ? styles[`scaleOdd${min}`] : styles[`scaleEven${min}`]

  return (
    <div className={styles.graphContainer} style={{ width: cellWidth * shiftBarWidth }}>
      {[...Array(shiftBarWidth)].map((_, i) => {
        const style = isActiveRange(i) ? {} : { backgroundColor: '#e0e4e7' }
        return (
          <div
            className={css}
            style={style}
            key={`shift-bar-${i}`}
            onMouseDown={() => handleMouseDown(i)}
            onMouseUp={() => handleMouseUp(i)}
          />
        )
      })}
      {items.map((item, index) => {
        return (
          <RndItem
            key={item.id}
            disabled={disabled || !!item.disabled}
            onChange={({ x, width }: OnChangeProps) => handleChange(index, Math.round(x), width)}
            initialX={item.x}
            initialWidth={item.width}
            color={item.color}
            invertedColor={item.invertedColor}
            activeRange={activeRange}
            isTeam={isTeam}
            is5min={is5min}
          >
            {item.content}
          </RndItem>
        )
      })}
    </div>
  )
}
