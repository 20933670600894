import dayjs from 'dayjs'
import { encode } from 'iconv-lite'
import { useState, useCallback } from 'react'

import { SPOT_WORKER_SHIFT_STATUS } from 'api/spot_workers/constants'
import type { SpotWorkerColumnDataType } from 'api/spot_workers/types'

import { exportData } from 'slices/utils'

import { CHARACTER_CODE_TYPES } from 'components/common/CharacterCodeSelect/CharacterCodeSelect'
import type { CharacterCodeType } from 'components/common/CharacterCodeSelect/CharacterCodeSelect'

const SPOT_WORKER_CSV_HEADER = [
  '名前',
  'メンバーID',
  '所属ワークスペース',
  '所属グループ',
  '識別子',
  '予定テンプレート',
  '欠勤フラグ',
  '削除フラグ',
  '出勤時間',
  '退勤時間',
  '出勤時間2',
  '退勤時間2',
  '出勤時間3',
  '退勤時間3',
]

export const useSpotWorkerCsv = () => {
  const [characterCode, setCharacterCode] = useState<CharacterCodeType>(CHARACTER_CODE_TYPES.SHIFT_JIS)

  // exportSpotWorker API呼び出し後に実行するコールバック関数
  const exportSpotWorkers = useCallback((data: SpotWorkerColumnDataType[]) => {
    const body = data.map(spotWorker => [
      spotWorker.workerName,
      spotWorker.workerId || '',
      spotWorker.workspace?.name || '',
      spotWorker.group?.name || '',
      spotWorker.wmsMemberId || '',
      spotWorker.template?.name || '',
      spotWorker.status === SPOT_WORKER_SHIFT_STATUS.SICK_OUT ? '1' : '',
      spotWorker.deletion ? '1' : '',
      spotWorker.workStart1 || '',
      spotWorker.workEnd1 || '',
      spotWorker.workStart2 || '',
      spotWorker.workEnd2 || '',
      spotWorker.workStart3 || '',
      spotWorker.workEnd3 || '',
    ])

    const csvData = [SPOT_WORKER_CSV_HEADER, ...body].map(row => row.join(',')).join('\n')
    const csvDataShiftJIS = encode(csvData, CHARACTER_CODE_TYPES.SHIFT_JIS)
    const blob = new Blob([csvDataShiftJIS], { type: 'text/csv' })
    const fileName = `スポットメンバーシフト_${dayjs().format('YYYY-MM-DD')}.csv`
    exportData(blob, fileName)
  }, [])

  const handleDownloadFormat = useCallback(() => {
    const formatHeader = [SPOT_WORKER_CSV_HEADER].map(row => row.join(',')).join('\n')
    const csvData = encode(formatHeader, characterCode)
    const blob = new Blob([csvData], { type: 'text/csv' })
    exportData(blob, 'スポットメンバーシフト登録シート.csv')
  }, [characterCode])

  return {
    exportSpotWorkers,
    handleDownloadFormat,
    characterCode,
    setCharacterCode,
  }
}

export default useSpotWorkerCsv
