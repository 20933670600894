import type { allHeadersType } from './types'

export const DuplicateCSVHeader =
  (allHeader: allHeadersType, selfWorkspaceId: number, selfScheduleTypeId: number, selfIndex: number) =>
  (value: string | undefined): string => {
    if (!value) {
      return ''
    }

    // 自分自身を除いた上で、他に同じ値が存在するかをチェック
    const duplicates = allHeader
      .flatMap(ws => ws.schedules.map(s => ({ ...s, workspaceId: ws.workspaceId })))
      .filter(
        h =>
          !(h.workspaceId === selfWorkspaceId && h.scheduleTypeId === selfScheduleTypeId && h.index === selfIndex) &&
          h.value === value
      )

    return duplicates.length > 0 ? '設定内に同じ文字列が存在します' : ''
  }
