import { useState } from 'react'

import NewPasswordSet from './NewPasswordSet'
import ResetRequestSend from './ResetRequestSend'

const PasswordReset = () => {
  const [isRequestStep, setIsRequestStep] = useState(true)
  const [email, setEmail] = useState('')
  const onEnterEmail = (enteredEmail: string) => {
    setEmail(enteredEmail)
    setIsRequestStep(false)
  }

  return isRequestStep ? <ResetRequestSend onEnterEmail={onEnterEmail} /> : <NewPasswordSet email={email} />
}

export default PasswordReset
