import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Col, Input, FormGroup, Label } from 'reactstrap'

import type { PlanType, AttendanceType } from 'api/optimization/types'

import { getTenantSummary, selectDashboardStatus } from 'slices/dashboardSlice'
import { showError } from 'slices/notificationSlice'
import { createDataAt, selectOptimizationStatus, clearError, getLocationConfig } from 'slices/optimizationSlice'
import { selectPlansStatus } from 'slices/plansSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

import { BadgeButton, CustomModal, SelectBoxFormat, TimeSelect } from 'components/common'
import { COLORS } from 'components/common/ColorPicker/ColorPicker'
import { TENTATIVE_SCHEDULE_TYPE_ID, COLUMN_SIZES } from 'components/common/constants'
import type { BadgeItem } from 'components/common/types'

import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'

import type { EditGroupsType, WorkPlanSchedulesType } from '../types'

dayjs.extend(isSameOrBefore)

const INTERVAL_MINUTES_UNIT = 15
const SLOTS_LENGTH_IN_A_DAY = 24 * 4

const SELECT_DATA = {
  ALL: 'all',
  SELECT: 'select',
}

const groupItems = [
  { key: SELECT_DATA.ALL, value: 'すべてのメンバー' },
  { key: SELECT_DATA.SELECT, value: '特定のメンバー' },
]
const initialInputData: SelectDataType = { hour: '09', minute: '00', work: SELECT_DATA.ALL, group: SELECT_DATA.ALL }

type HourlyTimeRecordData = {
  scheduleTypeId: number
  planValue: number | null
  actualValue: number | null
  ratio: number
}
type SelectDataType = {
  hour: string
  minute: string
  work?: string
  group?: string
}
type Props = {
  apiKey: string
  magiQannealTenant: string
  magiQannealLocation: string
  isOpen: boolean
  workspaceId: number
  editGroups: EditGroupsType[]
  onCancel: () => void
  workDate?: string
}

export const GROUP_BLANK_ID = -1

export const OptimizationDialog = (props: Props) => {
  const { apiKey, magiQannealTenant, magiQannealLocation, isOpen, editGroups, onCancel, workDate } = props

  const dispatch = useDispatch()
  const { data, optimizationError, isRequesting } = useSelector(selectOptimizationStatus, shallowEqual)
  const { plans } = useSelector(selectPlansStatus, shallowEqual)
  const { tenantSummary } = useSelector(selectDashboardStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const [modalErrorMessage, setModalErrorMessage] = useState<string>()
  const [inputData, setInputData] = useState<SelectDataType>(initialInputData)
  const [selectedGroups, setSelectedGroups] = useState<number[]>([])
  const [submit, setSubmit] = useState(false)
  const [priority, setPriority] = useState(true)

  const { timeRange, businessStartIndex, getHourOver24h, flooredCurrentTime } = useBusinessTime()
  const { planWorkDate, dailyTarget } = usePlans()

  useEffect(() => {
    if (!planWorkDate || !isOpen) {
      return
    }
    dispatch(getTenantSummary(planWorkDate, { dashboardFilter: false }))
  }, [dispatch, planWorkDate, isOpen])

  const targetWorkIds = useMemo(() => data?.works.map(w => w.workId) ?? [], [data])

  const approveDisabled = useMemo(
    () => !plans || targetWorkIds.length === 0 || selectedGroups.length === 0,
    [plans, targetWorkIds.length, selectedGroups.length]
  )

  const groupBadgeItems = useMemo<BadgeItem[]>(
    () =>
      plans?.groups.map(group => ({
        color: COLORS[0],
        key: group.groupId ?? GROUP_BLANK_ID,
        label: group.groupName ?? '未所属', // group.name が null の場合には未所属と表示する
      })) || [],
    [plans?.groups]
  )

  const planned = useMemo<PlanType[]>(
    () =>
      targetWorkIds.map<PlanType>(workId => {
        const quota = dailyTarget?.data.find(t => t.scheduleTypeId === workId)?.target ?? 0

        return { workId, quota }
      }),
    [dailyTarget?.data, targetWorkIds]
  )

  const processed = useMemo<PlanType[]>(() => {
    const body = targetWorkIds.map<PlanType>(workId => ({ workId, quota: 0 }))
    if (!tenantSummary) {
      return body
    }

    return tenantSummary.workspaceData
      .flatMap(scheduleTypeData => scheduleTypeData.data)
      .reduce((acc, cur) => {
        const target = acc.find(a => a.workId === cur.scheduleTypeId)
        if (target) {
          target.quota += cur.recordCount ?? 0
        }
        return acc
      }, body)
  }, [targetWorkIds, tenantSummary])

  const predicted = useMemo<PlanType[]>(() => {
    const body = targetWorkIds.map<PlanType>(workId => ({ workId, quota: 0 }))
    if (!tenantSummary) {
      return body
    }
    // predicted の集計に使う終了時刻(inputData.minute が 15, 30, 45 だったときのために 1 時間分多く集めておく)
    const lastHourStart = new Date(`${planWorkDate} ${inputData.hour}:00:00`)

    return (
      tenantSummary.hourlyWorkData
        // inputData.minute が 15, 30, 45 の場合には inputData.minute/60 だけ planValue を加えるために ratio を作る
        .flatMap<HourlyTimeRecordData>(rec =>
          rec.data.map(d => {
            const time = new Date(d.time)
            if (time < lastHourStart) {
              return {
                scheduleTypeId: rec.scheduleTypeId,
                planValue: d.planCount,
                actualValue: d.recordCount,
                ratio: 1,
              }
            }
            if (lastHourStart < time) {
              return {
                scheduleTypeId: rec.scheduleTypeId,
                planValue: d.planCount,
                actualValue: d.recordCount,
                ratio: 0,
              }
            }
            return {
              scheduleTypeId: rec.scheduleTypeId,
              planValue: d.planCount,
              actualValue: d.recordCount,
              ratio: Number(inputData.minute) / 60,
            }
          })
        )
        .reduce((acc, cur) => {
          const target = acc.find(a => a.workId === cur.scheduleTypeId)
          if (target) {
            target.quota += Math.round((cur.planValue ?? 0) * cur.ratio)
          }
          return acc
        }, body)
    )
  }, [targetWorkIds, tenantSummary, planWorkDate, inputData.hour, inputData.minute])

  const getWorkingSlotsSchedule = useCallback(
    (schedules: WorkPlanSchedulesType[]) => {
      // 先に作業者のシフトスケジュールを取得
      const shiftSlots = schedules
        .filter(s => s.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
        .reduce((acc, cur) => {
          const shiftBaseDate = dayjs(planWorkDate).startOf('day')
          const shiftStart = dayjs(cur.startAt)
          const shiftEnd = dayjs(cur.startAt).add(cur.duration || 0, 'second')
          return [...Array(SLOTS_LENGTH_IN_A_DAY)]
            .map((_, i) => {
              const addHour = businessStartIndex > i ? 24 : 0
              const time = shiftBaseDate.add(INTERVAL_MINUTES_UNIT * (i + 1), 'minutes').add(addHour, 'hour')
              const isActiveTime = time.isAfter(shiftStart) && time.isSameOrBefore(shiftEnd)
              return isActiveTime ? '1' : acc.charAt(i)
            })
            .join('')
        }, '0'.repeat(SLOTS_LENGTH_IN_A_DAY))
      // 優先にチェックがない場合はシフトのみで返す
      if (!priority) {
        return shiftSlots
      }

      return schedules
        .filter(s => s.scheduleTypeId !== TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
        .reduce((acc, cur) => {
          const isOptimizationWork = targetWorkIds.includes(cur.scheduleTypeId)

          const baseDate = dayjs(planWorkDate).startOf('day')
          const start = dayjs(cur.startAt)
          const end = dayjs(cur.startAt).add(cur.duration, 'seconds')
          // 最適配置対象作業は'1',それ以外は'0'
          return [...Array(SLOTS_LENGTH_IN_A_DAY)]
            .map((_, i) => {
              const addHour = businessStartIndex > i ? 24 : 0
              const time = baseDate.add(INTERVAL_MINUTES_UNIT * (i + 1), 'minutes').add(addHour, 'hour')
              const isActiveTime = time.isAfter(start) && time.isSameOrBefore(end)
              if (isActiveTime && isOptimizationWork) {
                return '1'
              }
              return isActiveTime ? '0' : acc.charAt(i)
            })
            .join('')
        }, shiftSlots)
    },
    [businessStartIndex, planWorkDate, priority, targetWorkIds]
  )

  const selectedEditGroups = useMemo(
    () => editGroups.filter(group => group.groupId && selectedGroups.includes(group.groupId)),
    [editGroups, selectedGroups]
  )

  const attendances = useMemo<AttendanceType[]>(
    () =>
      selectedEditGroups.flatMap(group =>
        group.workers.map(worker => {
          const flags = worker.skillIds.map(String).concat(worker.groupLeader ? ['leader'] : [])
          const skills = targetWorkIds.map(workId => {
            const workerProductivity = worker.partialHourlyProductivities?.find(p => p.scheduleTypeId === workId)?.value
            const defaultProductivity = allScheduleTypes.find(s => s.id === workId)?.defaultProductivity
            return {
              workId,
              skill: workerProductivity ?? defaultProductivity ?? 0,
            }
          })

          return {
            workerId: worker.wmsMemberId || '',
            workerName: worker.name,
            group: `${group.assignedWorkspaceName}_${group.isSupport ? '応援' : group.name}`,
            schedule: getWorkingSlotsSchedule(worker.schedules),
            skills,
            flags,
          }
        })
      ),
    [allScheduleTypes, getWorkingSlotsSchedule, selectedEditGroups, targetWorkIds]
  )

  const datetime = useMemo(() => {
    return dayjs(`${planWorkDate} ${inputData.hour}:${inputData.minute}`).format()
  }, [planWorkDate, inputData.hour, inputData.minute])

  const hasOnlyFifteenMinuteUnitSchedules = useMemo(
    () =>
      // シフトを除いていないのは5分単位で設定された応援作業をattendancesに含めないため
      selectedEditGroups
        .flatMap(group => group.workers)
        .flatMap(workers => workers.schedules)
        .every(schedule => {
          const startAt = dayjs(schedule.startAt)
          return startAt.minute() % 15 === 0 && startAt.add(schedule.duration, 'second').minute() % 15 === 0
        }),
    [selectedEditGroups]
  )

  const handleSubmitClick = useCallback(() => {
    if (!plans) {
      return
    }
    if (!hasOnlyFifteenMinuteUnitSchedules) {
      dispatch(
        showError({
          errorMessage:
            '15分単位ではない作業予定があるため、magiQannealとの連携をキャンセルしました。すべての作業予定を15分単位に編集してください。',
        })
      )
      onCancel()
      return
    }
    setSubmit(true)
    dispatch(
      createDataAt(apiKey, magiQannealTenant, magiQannealLocation, datetime, planned, processed, predicted, attendances)
    )
  }, [
    apiKey,
    attendances,
    datetime,
    dispatch,
    hasOnlyFifteenMinuteUnitSchedules,
    magiQannealLocation,
    magiQannealTenant,
    onCancel,
    planned,
    plans,
    predicted,
    processed,
  ])

  const onGroupBadgeClick = (groupIds: number[]) => {
    setSelectedGroups(groupIds)
  }

  useEffect(() => {
    if (!isOpen || isRequesting || !submit) {
      return
    }
    if (optimizationError) {
      // ここで NetworkErrorDialog が表示される
      dispatch(clearError())
    } else if (submit) {
      window.open(
        `${
          process.env.REACT_APP_OPTIMIZATION_SERVER
        }/${magiQannealTenant}/${magiQannealLocation}/board?datetime=${encodeURIComponent(datetime)}`,
        '_blank'
      )
    }
    setSubmit(false)
    onCancel()
  }, [
    dispatch,
    isOpen,
    isRequesting,
    onCancel,
    optimizationError,
    submit,
    datetime,
    magiQannealTenant,
    magiQannealLocation,
  ])

  useEffect(() => {
    if (!isOpen) {
      return
    }
    dispatch(getLocationConfig(apiKey, magiQannealTenant, magiQannealLocation, dayjs(workDate).startOf('day').format()))

    const calculatedHour = getHourOver24h(flooredCurrentTime)
    setInputData({
      ...initialInputData,
      ...{ hour: ('00' + calculatedHour).slice(-2), minute: flooredCurrentTime.split(':')[1] },
    })
    setPriority(true)
  }, [apiKey, magiQannealTenant, magiQannealLocation, dispatch, isOpen, workDate, getHourOver24h, flooredCurrentTime])

  useEffect(
    () => setSelectedGroups(inputData.group === SELECT_DATA.ALL ? groupBadgeItems.map(g => g.key) : []),
    [inputData.group, groupBadgeItems]
  )

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    onCancel()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="最適配置設定"
      approveLabel="設定をmagiQannealと連携"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleSubmitClick}
      approveDisabled={approveDisabled}
      onHideNotification={() => setModalErrorMessage(undefined)}
      overflow="visible"
    >
      <div className="mb-3">AI&amp;量子コンピュータが最適な配置を提案します。最適配置の設定を行ってください。</div>

      <FormGroup row>
        <Label md={4}>開始時間</Label>
        <Col md={8}>
          <TimeSelect
            hour={inputData.hour}
            minute={inputData.minute}
            label=""
            onChange={(hour, minute) => setInputData({ ...inputData, hour, minute })}
            menuZIndex={2} // customInputがzIndex:1のため、それ以上の値を設定
            range={timeRange.start}
          />
        </Col>
      </FormGroup>

      <SelectBoxFormat
        label="最適配置対象メンバー"
        value={inputData.group}
        size={COLUMN_SIZES.MIDDLE}
        items={groupItems}
        onChange={event => setInputData({ ...inputData, group: event.key?.toString() })}
        className="mb-3"
      />
      {inputData.group === SELECT_DATA.SELECT && (
        <>
          <div>最適配置対象としたいメンバーを選択してください。</div>
          <div className="d-flex row py-3 pe-2 me-0" style={{ marginLeft: '-0.5rem' }}>
            <BadgeButton items={groupBadgeItems} selected={selectedGroups} onChange={onGroupBadgeClick} hideColor />
          </div>
        </>
      )}
      <div className="form-check">
        <Input
          className="form-check-input"
          id="optimization-priority"
          checked={priority}
          type="checkbox"
          onChange={e => setPriority(e.target.checked)}
        />
        <Label className="form-check-label" for="optimization-priority">
          既に入力されている予定を優先する
        </Label>
      </div>
    </CustomModal>
  )
}
