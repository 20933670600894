import { sortBy } from 'es-toolkit'
import { useMemo, useCallback, useState, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Button, Card, CardBody, CardText } from 'reactstrap'

import { CONNECTION_TYPES } from 'api/schedule_types/constants'
import type { HourlyProductivitiesStringValue } from 'api/workers/types'

import { showSuccess } from 'slices/notificationSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { InputGroupFormat } from 'components/common/FormFormat/FormFormat'
import { COLUMN_SIZES } from 'components/common/constants'
import { Rules } from 'components/common/utils'

export type Props = {
  workerId?: number
  forecastColorScheduleTypeIds?: number[]
  onFocus?: (forecastColorScheduleTypeIds: number[]) => void
  hourlyProductivities: HourlyProductivitiesStringValue[]
  onChange: (hourlyProductivities: HourlyProductivitiesStringValue[]) => void
  onValidate?: (valid: boolean) => void
}

const HourlyProductivitiesInput = ({
  workerId,
  forecastColorScheduleTypeIds,
  onFocus,
  hourlyProductivities,
  onChange,
  onValidate,
}: Props) => {
  const dispatch = useDispatch()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const [productivityValidity, setProductivityValidity] = useState<Record<number, boolean>>({})

  const averageProductivitiesDisabled = useMemo(() => !workerId, [workerId])

  // 自動実績のみ自動入力が可能なので、自動実績が存在するかどうかを判定
  const connectionTypeAutoExistsInTenant = useMemo(
    () => allScheduleTypes.some(s => s.connectionType === CONNECTION_TYPES.AUTO),
    [allScheduleTypes]
  )

  const getBackgroundColor = useCallback(
    (scheduleTypeId: number) =>
      forecastColorScheduleTypeIds?.includes(scheduleTypeId) ? 'rgba(0,117,227, 0.1)' : undefined,
    [forecastColorScheduleTypeIds]
  )

  const onSetAverageProductivities = () => {
    // 自動入力したinputの背景色を変更
    const changedValue =
      hourlyProductivities
        .filter(p => p.average !== null && p.value !== null && Number(p.value) !== p.average)
        .flatMap(p => p.scheduleTypeId) || []
    onFocus && onFocus(changedValue)

    onChange(hourlyProductivities.map(p => (p.average === null ? p : { ...p, value: p.average.toFixed(1) })))
    dispatch(showSuccess({ successMessage: '過去実績を自動入力しました。' }))
  }

  const handleChange = (value: string, scheduleTypeId: number) => {
    onChange(
      allHourlyProductivities.map(p => {
        if (p.scheduleTypeId !== scheduleTypeId) {
          return p
        }
        return {
          ...p,
          value: value === '' ? null : value,
        }
      })
    )
  }

  const handleFocus = (scheduleTypeId: number) => {
    if (onFocus && forecastColorScheduleTypeIds) {
      onFocus(forecastColorScheduleTypeIds.filter(id => id !== scheduleTypeId))
    }
  }

  const allHourlyProductivities = useMemo(
    () =>
      allScheduleTypes.map(
        st =>
          hourlyProductivities?.find(w => w.scheduleTypeId === st.id) ?? {
            scheduleTypeId: st.id,
            value: null,
            average: null,
          }
      ),
    [allScheduleTypes, hourlyProductivities]
  )

  const listItem = useMemo(() => {
    if (!partialWorkspaces || allScheduleTypes.length === 0) {
      return []
    }
    return partialWorkspaces
      .map(pw => {
        const target = allScheduleTypes
          .filter(st => pw.id === st.workspaceId)
          .map(st => ({ ...st, hourlyProductivities: allHourlyProductivities.find(p => p.scheduleTypeId === st.id) }))

        const targetScheduleTypesSortedByName = sortBy(target, ['name'])

        return {
          ...pw,
          targetScheduleTypesSortedByName,
        }
      })
      .filter(w => w.targetScheduleTypesSortedByName.length > 0)
  }, [allScheduleTypes, partialWorkspaces, allHourlyProductivities])

  const averageProductivitiesDisabledAutoInput = useMemo(
    () => allHourlyProductivities.every(p => p.average === null),
    [allHourlyProductivities]
  )

  const handleValidate = useCallback((scheduleTypeId: number, isValid: boolean) => {
    setProductivityValidity(prev => {
      if (prev[scheduleTypeId] === isValid) {
        return prev
      }
      return { ...prev, [scheduleTypeId]: isValid }
    })
  }, [])

  useEffect(
    () => onValidate?.(Object.values(productivityValidity).every(valid => valid)),
    [productivityValidity, onValidate]
  )

  return (
    <Card>
      {connectionTypeAutoExistsInTenant && !averageProductivitiesDisabled && (
        <CardBody className="d-flex">
          <div className="flex-grow-1 align-self-center">過去実績最大30日の平均値を自動入力</div>
          <Button outline onClick={onSetAverageProductivities} disabled={averageProductivitiesDisabledAutoInput}>
            過去実績から自動入力
          </Button>
        </CardBody>
      )}

      {listItem.map(w => (
        <div key={w.id}>
          <hr className="m-0" />
          <CardBody>
            <CardText className="fw-bold">{w.name}</CardText>
            {w?.targetScheduleTypesSortedByName.map((s, i) => (
              <InputGroupFormat
                key={s.id}
                value={s.hourlyProductivities?.value?.toString() || ''}
                maxLength={8}
                labelSize={COLUMN_SIZES.MIDDLE}
                size={COLUMN_SIZES.MIDDLE}
                label={s.name}
                addonText={`${s.unit || '-'}/時間`}
                onChange={value => handleChange(value, s.id)}
                backgroundColor={getBackgroundColor(s.id)}
                onFocus={() => handleFocus(s.id)}
                className={i === w.targetScheduleTypesSortedByName.length - 1 ? '' : 'mb-3'}
                validations={[Rules.ValidateNumberWithMaxCharacters({ maxCharacters: 8, decimalPlaces: 1 })]}
                onValidate={valid => handleValidate(s.id, valid)}
              />
            ))}
          </CardBody>
        </div>
      ))}
    </Card>
  )
}

export default HourlyProductivitiesInput
