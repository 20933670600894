import { sortBy } from 'es-toolkit'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

/*
必ずworkspaceIdが選択されなければならないコンポーネントで使用する
workspaceIdが選択されていない場合(pathにworkspaceIdが存在しないとき)は、
user.userHasTenants[0].managedWorkspacesを用いて以下の要領で選択する
・managedWorkspacesが存在するとき、managedWorkspacesの1件目を選択する（＝管理中ワークスペースの1件目となる）
・managedWorkspacesが存在しないとき、partialWorkspacesの1件目を選択する（＝その他ワークスペースの1件目となる）
*/

const useWorkspaceSideBar = (path: string) => {
  const navigate = useNavigate()
  const { workspaceId: workspaceIdParam } = useParams<'workspaceId'>()
  const dispatch = useDispatch()

  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { user } = useSelector(selectSessionStatus, shallowEqual)

  const workspaceId = useMemo(() => {
    const id = Number(workspaceIdParam)
    return isNaN(id) ? undefined : id
  }, [workspaceIdParam])

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  useEffect(() => {
    if (partialWorkspaces.length === 0 || workspaceId) {
      return
    }

    const managedWorkspaces = user.userHasTenants[0].managedWorkspaces.filter(w => w.isManagement)
    if (managedWorkspaces.length > 0) {
      navigate(`${path}/${sortBy(managedWorkspaces, ['name'])[0].id}`, { replace: true })
      return
    }

    navigate(`${path}/${sortBy(partialWorkspaces, ['name'])[0].id}`, { replace: true })
  }, [workspaceId, navigate, partialWorkspaces, path, user.userHasTenants])

  const workspace = useMemo(
    () => partialWorkspaces.find(w => w.id === Number(workspaceId)),
    [partialWorkspaces, workspaceId]
  )

  const workspaceName = useMemo(() => workspace?.name || '', [workspace])

  return { workspaceName, workspaceId, workspace }
}
export default useWorkspaceSideBar
