import { Popup } from 'reactjs-popup'

export const TooltipHintIcon = ({ text }: { text: string }) => {
  return (
    <Popup
      trigger={<i className="icf-hint font-middle" />}
      position="bottom center"
      on="hover"
      arrow
      arrowStyle={{ color: '#000' }}
    >
      <div className="bg-black text-white p-2">{text}</div>
    </Popup>
  )
}
