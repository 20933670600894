import { useMemo, useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import type { WorkersPlanData, WorkersPlan, UpdatePlanSchedule } from 'api/plans/types'
import type { TenantData } from 'api/tenants/types'

import { selectPlansStatus } from 'slices/plansSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { TENTATIVE_SCHEDULE_TYPE_ID } from 'components/common/constants'
import type { EditSchedule, FitDuringShiftTimeData } from 'components/common/types'
import { getRandomNumber } from 'components/common/utils'

import useBusinessTime from './useBusinessTime'

export type WorkerEditSchedules = EditSchedule & {
  workerId: number
  shiftSchedules: EditSchedule[]
  supportShiftSchedules: EditSchedule[]
  workSchedules: EditSchedule[]
}

const usePlans = (tenantWithDate?: TenantData) => {
  const { plans, planList } = useSelector(selectPlansStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const { businessStartTime } = useBusinessTime({ tenantWithDate })

  const {
    planWorkspaceId,
    planWorkDate,
    planScheduleTypes,
    planLastUpdatedAt,
    planLastUpdater,
    shiftLastUpdatedAt,
    shiftLastUpdater,
  } = useMemo(() => {
    if (!plans) {
      return {
        planWorkspaceId: 0,
        planWorkDate: '',
        planScheduleTypes: [],
        planLastUpdatedAt: '',
        planLastUpdater: '',
        shiftLastUpdatedAt: '',
        shiftLastUpdater: '',
      }
    }

    return {
      planWorkspaceId: plans.workspace.id,
      planWorkDate: plans.workDate,
      planScheduleTypes: plans.partialScheduleTypes,
      planLastUpdatedAt: plans.planUpdatedAt,
      planLastUpdater: plans.planUpdatedAtByName,
      shiftLastUpdatedAt: plans.workerShiftUpdatedAt,
      shiftLastUpdater: plans.workerShiftUpdatedAtByName,
    }
  }, [plans])

  const planStartDateTime = useMemo(() => {
    const startTime = businessStartTime.split(':')
    const startDateTime = new Date(planWorkDate)
    startDateTime.setHours(Number(startTime[0]))
    startDateTime.setMinutes(Number(startTime[1]))
    return startDateTime
  }, [businessStartTime, planWorkDate])

  const dailyTarget = useMemo(
    () => planList?.dailyPlans.find(daily => daily.workDate === planWorkDate),
    [planList, planWorkDate]
  )

  const workerEditSchedules = useMemo(() => {
    if (!plans) {
      return []
    }

    const workersPlan = plans.groups.flatMap(g => g.workersPlan.flatMap(w => ({ ...w, isSupported: g.isSupported })))
    return workersPlan.map(workerPlan => {
      const workShift = workerPlan.workShifts
      const workType = workerPlan.workScheduleTypes

      // シフト用スケジュール
      const getShiftSchedules = () => {
        if (workerPlan.isSupported) {
          // シフト用スケジュール(応援先)
          return workShift
            .reduce((acc: { startIndex: number; endIndex: number; value: number }[], cur, index) => {
              if (!cur || cur !== planWorkspaceId) {
                return acc
              }

              const accIndex = acc.findIndex(a => a.endIndex === index - 1 && a.value === cur)
              if (accIndex < 0) {
                acc.push({ startIndex: index, endIndex: index, value: cur })
                return acc
              }

              acc[accIndex] = { ...acc[accIndex], endIndex: index }
              return acc
            }, [])
            .map(shift => {
              const startAt = new Date(planStartDateTime.getTime())
              const elapsedMin = shift.startIndex * 5
              startAt.setMinutes(startAt.getMinutes() + elapsedMin)

              return {
                scheduleId: getRandomNumber(),
                scheduleTypeId: TENTATIVE_SCHEDULE_TYPE_ID.SHIFT,
                supportWorkspaceId: null,
                supportWorkspaceName: null,
                startAt: startAt.toISOString(),
                duration: (shift.endIndex - shift.startIndex + 1) * 300,
              }
            })
        }

        return workShift
          .reduce((acc: { startIndex: number; endIndex: number }[], cur, index) => {
            if (!cur) {
              return acc
            }

            const accIndex = acc.findIndex(a => a.endIndex === index - 1)
            if (accIndex < 0) {
              acc.push({ startIndex: index, endIndex: index })
              return acc
            }

            acc[accIndex] = { ...acc[accIndex], endIndex: index }
            return acc
          }, [])
          .map(shift => {
            const startAt = new Date(planStartDateTime.getTime())
            const elapsedMin = shift.startIndex * 5
            startAt.setMinutes(startAt.getMinutes() + elapsedMin)

            return {
              scheduleId: getRandomNumber(),
              scheduleTypeId: TENTATIVE_SCHEDULE_TYPE_ID.SHIFT,
              supportWorkspaceId: null,
              supportWorkspaceName: null,
              startAt: startAt.toISOString(),
              duration: (shift.endIndex - shift.startIndex + 1) * 300,
            }
          })
      }

      // 応援シフト(他ワークスペース)用スケジュール
      const getSupportShiftSchedules = () => {
        if (workerPlan.isSupported) {
          return []
        }

        return workShift
          .reduce((acc: { startIndex: number; endIndex: number; value: number }[], cur, index) => {
            if (!cur || cur === planWorkspaceId) {
              return acc
            }

            const accIndex = acc.findIndex(a => a.endIndex === index - 1 && a.value === cur)
            if (accIndex < 0) {
              acc.push({ startIndex: index, endIndex: index, value: cur })
              return acc
            }

            acc[accIndex] = { ...acc[accIndex], endIndex: index }
            return acc
          }, [])
          .map(shift => {
            const startAt = new Date(planStartDateTime.getTime())
            const elapsedMin = shift.startIndex * 5
            startAt.setMinutes(startAt.getMinutes() + elapsedMin)
            const supportWorkspace = partialWorkspaces.find(w => w.id === shift.value)
            const supportWorkspaceName = supportWorkspace?.name || null

            return {
              scheduleId: getRandomNumber(),
              scheduleTypeId: TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT,
              supportWorkspaceId: shift.value,
              supportWorkspaceName: supportWorkspaceName,
              startAt: startAt.toISOString(),
              duration: (shift.endIndex - shift.startIndex + 1) * 300,
            }
          })
      }

      const shiftSchedules: EditSchedule[] = getShiftSchedules()
      const supportShiftSchedules: EditSchedule[] = getSupportShiftSchedules()
      // 作業用スケジュール
      const workSchedules: EditSchedule[] = workType
        .reduce((acc: { startIndex: number; endIndex: number; value: number }[], cur, index) => {
          if (!cur || workShift[index] !== planWorkspaceId) {
            return acc
          }

          const accIndex = acc.findIndex(a => a.endIndex === index - 1 && a.value === cur)
          if (accIndex < 0) {
            acc.push({ startIndex: index, endIndex: index, value: cur })
            return acc
          }

          acc[accIndex] = { ...acc[accIndex], endIndex: index }

          return acc
        }, [])
        .map(work => {
          const startAt = new Date(planStartDateTime.getTime())
          const elapsedMin = work.startIndex * 5
          startAt.setMinutes(startAt.getMinutes() + elapsedMin)

          return {
            scheduleId: getRandomNumber(),
            scheduleTypeId: work.value,
            supportWorkspaceId: null,
            supportWorkspaceName: null,
            startAt: startAt.toISOString(),
            duration: (work.endIndex - work.startIndex + 1) * 300,
          }
        })
      return { workerId: workerPlan.workerId, shiftSchedules, supportShiftSchedules, workSchedules }
    })
  }, [plans, planStartDateTime, planWorkspaceId, partialWorkspaces])

  const getEditSchedulesFromWorkPlan = useCallback(
    (workerPlan: WorkersPlanData, isSupport: boolean, isOnlyShift?: boolean): EditSchedule[] => {
      const scheduleData = workerEditSchedules.find(w => w.workerId === workerPlan.workerId)

      if (!scheduleData) {
        return []
      }

      if (isOnlyShift) {
        // シフトのみ使用(シフト管理画面)
        return scheduleData.shiftSchedules
      }

      // シフト+作業スケジュール
      const schedule = scheduleData.shiftSchedules.concat(scheduleData.workSchedules)

      if (isSupport) {
        // 応援先の場合は応援シフトスケジュールは含まない
        return schedule
      }

      // 応援シフトスケジュールを追加する
      return schedule.concat(scheduleData.supportShiftSchedules)
    },
    [workerEditSchedules]
  )

  const getWorkerPlanFromUpdatePlanSchedule = useCallback(
    (updateSchedule: UpdatePlanSchedule[]): WorkersPlan[] => {
      const targetUpdateSchedules = updateSchedule.filter(schedule => schedule.schedule?.scheduleTypeId)
      const workersPlan = targetUpdateSchedules.reduce((acc: WorkersPlan[], cur): WorkersPlan[] => {
        const workerId = cur.schedule?.workerId
        const startAt = cur.schedule!.startAt
        const duration = cur.schedule!.duration / 300 // 300秒(5分)区切りに計算
        const isSupport = cur.isSupport // 応援グループの判定

        const startDateTime = new Date(startAt)
        const diffTimeMin = (startDateTime.getTime() - planStartDateTime.getTime()) / (60 * 1000) // 差分を分単位にする
        const startIndex = diffTimeMin / 5 // 5分区切りに計算
        const endIndex = startIndex + duration
        const index = acc.findIndex(w => w.workerId === workerId)

        const originalWorkerPlan = plans!.groups.flatMap(g => g.workersPlan).find(w => w.workerId === workerId)

        const workerPlan =
          index >= 0
            ? acc[index]
            : {
                workerId: workerId!,
                workShifts: new Array<number>(288).fill(0),
                workScheduleTypes: new Array<number>(288).fill(0),
              }

        if (index < 0 && originalWorkerPlan) {
          workerPlan.workShifts = workerPlan.workShifts.map((workShift, shiftIndex) => {
            if (
              originalWorkerPlan.workShifts[shiftIndex] &&
              originalWorkerPlan.workShifts[shiftIndex] !== planWorkspaceId &&
              isSupport
            ) {
              return originalWorkerPlan.workShifts[shiftIndex]
            }
            return workShift
          })
          workerPlan.workScheduleTypes = workerPlan.workScheduleTypes.map((scheduleType, scheduleIndex) => {
            if (
              originalWorkerPlan.workShifts[scheduleIndex] &&
              originalWorkerPlan.workShifts[scheduleIndex] !== planWorkspaceId &&
              isSupport
            ) {
              return originalWorkerPlan.workScheduleTypes[scheduleIndex]
            }
            return scheduleType
          })
        }

        if (cur.schedule?.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT) {
          const updateWorkShifts = workerPlan.workShifts.map((workShift, shiftIndex) => {
            if (shiftIndex >= startIndex && shiftIndex < endIndex && workShift === 0) {
              return planWorkspaceId
            }

            return workShift
          })

          workerPlan.workShifts = updateWorkShifts
        } else if (cur.schedule?.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT) {
          const updateWorkShifts = workerPlan.workShifts.map((workShift, shiftIndex) => {
            if (shiftIndex >= startIndex && shiftIndex < endIndex) {
              return cur.schedule!.supportWorkspaceId
            }

            return workShift
          })
          const updateWorkScheduleTypes = workerPlan.workScheduleTypes.map((workScheduleType, scheduleTypeIndex) => {
            if (scheduleTypeIndex >= startIndex && scheduleTypeIndex < endIndex && originalWorkerPlan) {
              return originalWorkerPlan.workScheduleTypes[scheduleTypeIndex]
            }

            return workScheduleType
          })
          workerPlan.workShifts = updateWorkShifts
          workerPlan.workScheduleTypes = updateWorkScheduleTypes
        } else {
          const updateWorkShifts = workerPlan.workShifts.map((workShift, shiftIndex) => {
            if (shiftIndex >= startIndex && shiftIndex < endIndex) {
              return planWorkspaceId
            }

            return workShift
          })
          const updateWorkScheduleTypes = workerPlan.workScheduleTypes.map((workScheduleType, scheduleTypeIndex) => {
            if (scheduleTypeIndex >= startIndex && scheduleTypeIndex < endIndex && cur.schedule?.scheduleTypeId) {
              return cur.schedule.scheduleTypeId
            }

            return workScheduleType
          })
          workerPlan.workShifts = updateWorkShifts
          workerPlan.workScheduleTypes = updateWorkScheduleTypes
        }

        if (index < 0) {
          acc.push(workerPlan)
        }

        return acc
      }, [])

      return workersPlan
    },
    [planStartDateTime, planWorkspaceId, plans]
  )

  const getWorkerPlanFromUpdatePlanScheduleForShift = useCallback(
    (updateSchedule: UpdatePlanSchedule[]): WorkersPlan[] => {
      const updateShiftSchedule = updateSchedule.filter(
        schedule =>
          schedule.schedule?.scheduleTypeId && schedule.schedule?.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT
      )
      const workersPlan = updateShiftSchedule.reduce((acc: WorkersPlan[], cur): WorkersPlan[] => {
        const workerId = cur.schedule?.workerId
        const startAt = cur.schedule!.startAt
        const duration = cur.schedule!.duration / 300 // 300秒(5分)区切りに計算

        const startDateTime = new Date(startAt)
        const diffTimeMin = (startDateTime.getTime() - planStartDateTime.getTime()) / (60 * 1000) // 差分を分単位にする
        const startIndex = diffTimeMin / 5 // 5分区切りに計算
        const endIndex = startIndex + duration

        const index = acc.findIndex(w => w.workerId === workerId)
        if (index >= 0) {
          acc[index].workShifts = acc[index].workShifts.map((shift, i) => {
            if (i >= startIndex && i < endIndex) {
              return planWorkspaceId
            }
            return shift
          })
        } else {
          if (plans) {
            const workerPlan = plans!.groups.flatMap(g => g.workersPlan).find(w => w.workerId === workerId)
            const initWorkShifts = new Array<number>(288).fill(0)
            const newWorkShifts = initWorkShifts.map((shift, i) => {
              if (i >= startIndex && i < endIndex) {
                return planWorkspaceId
              }
              return shift
            })

            acc.push({
              workerId: workerId!,
              workShifts: newWorkShifts,
              workScheduleTypes:
                workerPlan && workerPlan.workScheduleTypes.length === 288
                  ? workerPlan.workScheduleTypes
                  : new Array<number>(288).fill(0),
            })
          }
        }

        return acc
      }, [])

      return workersPlan
    },
    [planStartDateTime, planWorkspaceId, plans]
  )

  const getFitDuringShiftTimeData = useCallback((): FitDuringShiftTimeData[] => {
    if (!plans) {
      return []
    }
    const workersPlan = plans?.groups.flatMap(g =>
      g.workersPlan.flatMap(w => ({ workerId: w.workerId, workShift: w.workShifts, isSupport: g.isSupported }))
    )

    const shiftData: FitDuringShiftTimeData[] = []

    const shiftCheck = (shiftValue: number, workShiftValue: number | null, isSupport: boolean) => {
      return isSupport ? shiftValue === workShiftValue : shiftValue !== 0
    }

    for (const worker of workersPlan) {
      const workShift = worker.workShift
      for (let i = 0; i < workShift.length; i++) {
        if (workShift[i]) {
          const shiftValue = workShift[i]!
          const startIndex = i
          const startAt = new Date(planStartDateTime.getTime())
          const elapsedMin = i * 5
          startAt.setMinutes(startAt.getMinutes() + elapsedMin)

          while (shiftCheck(shiftValue, workShift[i + 1], worker.isSupport) && i + 1 < workShift.length) {
            if (i + 1 < workShift.length) {
              i++
            }
          }
          const endIndex = i

          shiftData.push({
            workerId: worker.workerId,
            startAt: startAt.toISOString(),
            duration: (endIndex - startIndex + 1) * 300,
          })
        }
      }
    }

    return shiftData
  }, [planStartDateTime, plans])

  const hasWorkersWithShifts = useMemo(
    () =>
      plans && plans?.groups.some(group => group.workersPlan.some(worker => worker.workShifts.some(shift => shift))),
    [plans]
  )

  const getEmptyShiftData = useCallback(
    (workerId: number): UpdatePlanSchedule[] => [
      {
        scheduleId: null,
        schedule: {
          scheduleTypeId: TENTATIVE_SCHEDULE_TYPE_ID.SHIFT,
          supportWorkspaceId: null,
          startAt: new Date(planStartDateTime.getTime()).toISOString(),
          duration: 0,
          workerId: workerId,
          groupId: null,
        },
      },
    ],
    [planStartDateTime]
  )

  return {
    planStartDateTime,
    planWorkspaceId,
    planWorkDate,
    planScheduleTypes,
    planLastUpdatedAt,
    planLastUpdater,
    shiftLastUpdatedAt,
    shiftLastUpdater,
    dailyTarget,
    getEditSchedulesFromWorkPlan,
    getWorkerPlanFromUpdatePlanSchedule,
    getWorkerPlanFromUpdatePlanScheduleForShift,
    getFitDuringShiftTimeData,
    hasWorkersWithShifts,
    getEmptyShiftData,
  }
}

export default usePlans
