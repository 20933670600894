import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type {
  CheckExportDataReadyResponse,
  CreateExportDataInfo,
  CreateExportDataResponse,
  ReportAverageResponse,
  ReportDailyPlanAccuracyResponse,
  ReportHourlyPlanAccuracyResponse,
  ReportProductivityResponse,
  ReportPlanHistoryResponse,
} from './types'

export const getReportAverage = async (
  workspaceId: number,
  from: string,
  to: string,
  isDailyReport: boolean
): Promise<ReportAverageResponse> => {
  const url = `/workspaces/${workspaceId}/report-average`

  const response = await axiosClientWithTenantId.get(url, { params: { from, to, isDailyReport } })
  return response.data
}

export const getReportProductivity = async (
  workspaceId: number,
  from: string,
  to: string,
  isDailyReport: boolean
): Promise<ReportProductivityResponse> => {
  const url = `/workspaces/${workspaceId}/report-productivity`

  const response = await axiosClientWithTenantId.get(url, { params: { from, to, isDailyReport } })
  return response.data
}

export const getReportPlanAccuracy = async (
  workspaceId: number,
  from: string,
  to: string,
  isDailyReport: boolean
): Promise<ReportDailyPlanAccuracyResponse> => {
  const url = `/workspaces/${workspaceId}/report-plan-accuracy`

  const response = await axiosClientWithTenantId.get(url, { params: { from, to, isDailyReport } })
  return response.data
}

export const getReportHourlyPlanAccuracy = async (
  workspaceId: number,
  date: string,
  isDailyReport: boolean
): Promise<ReportHourlyPlanAccuracyResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${date}/report-hourly-plan-accuracy`

  const response = await axiosClientWithTenantId.get(url, { params: { isDailyReport } })
  return response.data
}

export const getReportPlanHistory = async (
  workspaceId: number,
  date: string,
  page: number,
  limit: number
): Promise<ReportPlanHistoryResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${date}/report-plan-history`

  const response = await axiosClientWithTenantId.get(url, { params: { page, limit } })
  return response.data
}

export const createExportData = async (data: CreateExportDataInfo): Promise<CreateExportDataResponse> => {
  const url = '/data-export/async'

  const response = await axiosClientWithTenantId.post(url, data)
  return response.data
}

export const getExportData = async (requestId: string): Promise<CheckExportDataReadyResponse> => {
  const url = `/data-export/update-status/${requestId}`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}
