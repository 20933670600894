import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  errorMessage: string
  errorMessageHide: () => void
  onSubmit: () => void
  onCancel: () => void
}

const TenantSuspend = ({ isOpen, errorMessage, errorMessageHide, onSubmit, onCancel }: Props) => {
  return (
    <CustomModal
      isOpen={isOpen}
      title="テナントの利用停止"
      approveColor="danger"
      approveLabel="利用停止"
      errorMessage={errorMessage}
      onCancel={onCancel}
      onApprove={onSubmit}
      onHideNotification={errorMessageHide}
    >
      <div className="pb-4">このテナントの利用を本当に停止しますか？停止した後でも利用再開させることができます。</div>
    </CustomModal>
  )
}

export default TenantSuspend
