import dayjs from 'dayjs'
import { useCallback } from 'react'

import type { WorkspaceSummaryGroup } from 'api/dashboard/types'

import type { GroupPerformance } from 'components/Dashboard/utils'
import { NULL_GROUP_ID, NULL_GROUP_NAME, SUPPORT_WORKER_GROUP_PREFIX } from 'components/Dashboard/utils'
import type { TimeReducerType } from 'components/common/types'
import { isSupportedWorkerGroup, timeDataReducer } from 'components/common/utils'

import useBusinessTime from './useBusinessTime'

export const useGroupPerformance = () => {
  const { getHourOver24h } = useBusinessTime()

  const getWorkerDataList = useCallback(
    (group: WorkspaceSummaryGroup) => {
      return group.workers
        .map(worker => {
          // worker内のスケジュール毎のデータを整形して1つの配列にまとめる
          const timeDataList = worker.hourlyWorkerData.flatMap(hourlyData =>
            hourlyData.data.map<TimeReducerType>(d => {
              const dayObject = dayjs(d.time)
              const hour = getHourOver24h(dayObject.format('H'))
              const minute = dayObject.format('mm')
              return {
                time: `${hour}:${minute}`,
                planCount: d.planCount,
                recordCount: d.recordCount,
                rate: null,
              }
            })
          )
          // 同じtimeのデータを集計
          const workerData: { [key: string]: TimeReducerType } = timeDataList.reduce(timeDataReducer, {})

          return {
            workerId: worker.workerId,
            workerName: worker.workerName,
            workerWmsMemberId: worker.workerWmsMemberId,
            workerData,
          }
        })
        .sort((a, b) => a.workerWmsMemberId.localeCompare(b.workerWmsMemberId, 'ja'))
    },
    [getHourOver24h]
  )

  // パフォーマンスリスト、メンバー詳細画面で使う進捗データを集計する
  const getGroupPerformance = useCallback(
    (groups: WorkspaceSummaryGroup[]): GroupPerformance[] =>
      groups.map(group => {
        const workerDataList = getWorkerDataList(group)
        // group内のworker毎のデータを集計
        const groupData: { [key: string]: TimeReducerType } = workerDataList
          .flatMap(d => Object.values(d.workerData))
          .reduce(timeDataReducer, {})

        // 応援作業者の場合
        if (isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName)) {
          return {
            groupId: `${SUPPORT_WORKER_GROUP_PREFIX}${group.supportedWorkspaceId}`,
            groupName: group.supportedWorkspaceName ?? '',
            groupData,
            workerDataList,
          }
        }

        return {
          groupId: group.groupId ?? NULL_GROUP_ID,
          groupName: group.groupName ?? NULL_GROUP_NAME,
          groupData,
          workerDataList,
        }
      }),
    [getWorkerDataList]
  )

  return { getGroupPerformance, getWorkerDataList }
}
