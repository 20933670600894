import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import type { WorkspaceSummaryData } from 'api/dashboard/types'

import { PivotOuterIndex, PivotItem } from 'components/common'

import useDateQuery from 'hooks/useDateQuery'

import GroupPerformanceGraph from './GroupPerformanceGraph'
import WorkerPerformanceGraph from './WorkerPerformanceGraph'
import { SUPPORT_WORKER_GROUP_PREFIX } from './utils'

export type Props = {
  workspaceId: number
  workspaceSummaryData: WorkspaceSummaryData | undefined
}

const PerformanceGraph = ({ workspaceId, workspaceSummaryData }: Props) => {
  const params = useParams<'groupId' | 'workerId'>()
  const groupId =
    params.groupId && (params.groupId.startsWith(SUPPORT_WORKER_GROUP_PREFIX) ? params.groupId : Number(params.groupId))
  const workerId = params.workerId ? Number(params.workerId) : undefined
  const navigate = useNavigate()
  const date = useDateQuery()

  const [pivotIndex, setPivotIndex] = useState(0)
  const { pathname } = useLocation()
  useEffect(() => {
    const nextIndex = /^\/dashboard\/\d+\/performance-graph\/workers/.test(pathname) ? 1 : 0
    setPivotIndex(nextIndex)
  }, [pathname])
  const onPivotChange = (index: number) => {
    // pivotの選択状態が変化したらpivotIndexを直接変更するのではなく、url遷移の副作用として変更する。
    const nextPath =
      index === 1
        ? `/dashboard/${workspaceId}/performance-graph/workers`
        : `/dashboard/${workspaceId}/performance-graph/workspace`
    if (pathname === nextPath) {
      return
    }
    navigate(nextPath + `?date=${date}`)
  }

  return (
    <Card className="mt-3">
      <CardBody className="fw-bold">グループ/メンバー別パフォーマンスグラフ</CardBody>
      <CardBody className="pt-0">
        <PivotOuterIndex selectedIndex={pivotIndex} onChange={onPivotChange}>
          {[
            { header: 'ワークスペース/グループ', key: 'groups' },
            { header: 'メンバー', key: 'workers' },
          ].map(({ header, key }) => (
            <PivotItem headerText={header} key={key}>
              {key === 'groups' ? (
                <GroupPerformanceGraph
                  workspaceId={workspaceId}
                  groupId={groupId}
                  workspaceSummaryData={workspaceSummaryData}
                />
              ) : key === 'workers' ? (
                <WorkerPerformanceGraph
                  workspaceId={workspaceId}
                  workerId={workerId}
                  workspaceSummaryData={workspaceSummaryData}
                />
              ) : null}
            </PivotItem>
          ))}
        </PivotOuterIndex>
      </CardBody>
    </Card>
  )
}

export default PerformanceGraph
