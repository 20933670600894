import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Badge, Button, DropdownItem } from 'reactstrap'

import { showError, showSuccess } from 'slices/notificationSlice'
import { getTenant, updateTenantStatus, selectTenantsStatus, clearErrorMessage } from 'slices/tenantsSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import TenantInformation from 'components/Tenants/TenantInformation'
import TenantServiceCooperation from 'components/Tenants/TenantServiceCooperation/TenantServiceCooperation'
import TenantUsers from 'components/Tenants/TenantUsers'
import UserInvitation from 'components/Users/UserInvitation'
import { DropdownList, NavMenu, PivotItem, PivotOuterIndex } from 'components/common'

import TenantSuspend from './TenantSuspend'

const PIVOT_ROUTES = {
  DETAIL: 'detail',
  USERS: 'users',
  SERVICES: 'services',
} as const
const pivotItems = [
  { header: 'テナント情報', route: PIVOT_ROUTES.DETAIL },
  { header: '登録ユーザー情報', route: PIVOT_ROUTES.USERS },
  { header: '各種サービス連携', route: PIVOT_ROUTES.SERVICES },
]

const TenantDetail = () => {
  const [dropdown, setDropdown] = useState(false)
  const [stopped, setStopped] = useState(false)
  const [openSuspend, setOpenSuspend] = useState(false)
  const [openInvite, setOpenInvite] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState('')
  const params = useParams<'tenantId'>()
  const tenantId = Number(params.tenantId)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getTenant(tenantId))
  }, [dispatch, tenantId])

  const { tenant, errorMessage } = useSelector(selectTenantsStatus, shallowEqual)

  const dropdownItems = useMemo(() => {
    const color = tenant?.isActive ? 'danger' : 'body'
    const text = tenant?.isActive ? '利用停止' : '利用再開'
    const onClick = () => {
      if (tenant?.isActive) {
        setOpenSuspend(true)
      } else {
        setStopped(true)
        dispatch(updateTenantStatus(tenantId, true))
      }
      setDropdown(false)
    }
    return (
      <DropdownItem className={`text-${color}`} onClick={onClick}>
        {text}
      </DropdownItem>
    )
  }, [dispatch, tenant?.isActive, tenantId])

  const toggleContent = <i className="icf-others text-secondary font-x-large" role="button" />

  const [pivotIndex, setPivotIndex] = useState(0)
  const { pathname } = useLocation()
  useEffect(() => {
    const index = pivotItems.findIndex(p => new RegExp(`^/tenants/\\d+/${p.route}$`).test(pathname))
    setPivotIndex(index < 0 ? 0 : index)
  }, [pathname])
  const onPivotChange = (index: number) => {
    // pivotの選択状態が変化したらpivotIndexを直接変更するのではなく、url遷移の副作用として変更する。
    const nextPath = `/tenants/${tenantId}/${pivotItems[index].route}`
    if (pathname !== nextPath) {
      navigate(nextPath)
    }
  }

  const onEditTenantClick = () => navigate(`/tenants/${tenantId}/edit`)

  const onSuspendSubmit = () => {
    dispatch(updateTenantStatus(tenantId, false))
  }

  const onSuspendCancel = () => {
    dispatch(clearErrorMessage())
    setModalErrorMessage('')
    setOpenSuspend(false)
  }

  useEffect(() => {
    if (openSuspend && !tenant?.isActive) {
      setOpenSuspend(false)
      dispatch(showSuccess())
    } else if (openSuspend && errorMessage !== '') {
      setModalErrorMessage('保存できませんでした。')
    } else if (stopped && tenant?.isActive) {
      setStopped(false)
      dispatch(showSuccess())
    } else if (stopped && errorMessage !== '' && !ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      dispatch(showError())
    }
  }, [openSuspend, errorMessage, stopped, dispatch, tenant?.isActive])

  return (
    <>
      <NavMenu>
        <div className="mt-3 mx-3">
          <div className="d-flex justify-content-between align-items-center mb-3 mx-0">
            <div className="font-x-large fw-bold mw-100">
              <div className="text-truncate">{tenant?.name}</div>
              <div className="text-truncate">{tenant?.salesOfficeName}</div>
            </div>

            <div className="d-flex align-items-center">
              <Badge className="px-4 py-2" color={tenant?.isActive ? 'success' : 'secondary'} pill>
                {tenant?.isActive ? '利用中' : '利用停止中'}
              </Badge>

              <div className="bg-white rounded mx-3">
                <Button outline className="d-flex align-items-center" onClick={() => setOpenInvite(true)}>
                  <i className="icf-plus pe-2 font-large" />
                  <div className="ps-1">テナント内ユーザーの追加</div>
                </Button>
              </div>
              <div className="bg-white rounded me-3">
                <Button outline className="d-flex align-items-center" onClick={onEditTenantClick}>
                  <i className="icf-edit pe-2 font-large" />
                  <div className="ps-1">テナント情報編集</div>
                </Button>
              </div>

              <DropdownList
                open={dropdown}
                setOpen={() => setDropdown(!dropdown)}
                content={toggleContent}
                direction="start"
              >
                {dropdownItems}
              </DropdownList>
            </div>
          </div>

          <PivotOuterIndex selectedIndex={pivotIndex} onChange={onPivotChange}>
            {pivotItems.map(({ header, route }) => (
              <PivotItem headerText={header} key={route}>
                {route === PIVOT_ROUTES.DETAIL ? (
                  <TenantInformation />
                ) : route === PIVOT_ROUTES.USERS ? (
                  <TenantUsers showSuccess={() => dispatch(showSuccess())} />
                ) : route === PIVOT_ROUTES.SERVICES ? (
                  <TenantServiceCooperation />
                ) : (
                  <div>{header}のコンテンツ</div>
                )}
              </PivotItem>
            ))}
          </PivotOuterIndex>
        </div>
      </NavMenu>

      <TenantSuspend
        isOpen={openSuspend}
        errorMessage={modalErrorMessage}
        errorMessageHide={() => setModalErrorMessage('')}
        onSubmit={onSuspendSubmit}
        onCancel={onSuspendCancel}
      />
      <UserInvitation
        isOpen={openInvite}
        tenantId={tenantId}
        onClose={() => setOpenInvite(false)}
        onSuccess={() => dispatch(showSuccess())}
      />
    </>
  )
}

export default TenantDetail
