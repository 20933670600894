import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type {
  AvailableSpotWorkersResponse,
  CreateSpotWorkersResponse,
  ImportSpotWorkersResponse,
  ImportSpotWorkerStatusResponse,
  SaveSpotWorkerResponse,
  SpotWorkerListResponse,
  SpotWorkersSaveDataType,
} from './types'

export const getSpotWorkers = async (workDate: string): Promise<SpotWorkerListResponse> => {
  const url = `/spot-workers/work-date/${workDate}`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const addSpotWorker = async (workerIds: number[], workDate: string): Promise<CreateSpotWorkersResponse> => {
  const url = `/spot-workers/work-date/${workDate}`

  const response = await axiosClientWithTenantId.post(url, { workerIds })
  return response.data
}

export const importSpotWorkers = async (data: FormData, workDate: string): Promise<ImportSpotWorkersResponse> => {
  const url = `/spot-workers/work-date/${workDate}/import/async`

  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  const response = await axiosClientWithTenantId.post(url, data, { headers })
  return response.data
}

export const getSpotWorkerImportStatus = async (
  workDate: string,
  requestId: string
): Promise<ImportSpotWorkerStatusResponse> => {
  const url = `/spot-workers/work-date/${workDate}/import/${requestId}/import-status`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const getAvailableSpotWorkers = async (workDate: string): Promise<AvailableSpotWorkersResponse> => {
  const url = `/spot-workers/work-date/${workDate}/available-workers`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const saveSpotWorker = async (
  data: SpotWorkersSaveDataType,
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}`

  const response = await axiosClientWithTenantId.put(url, data)
  return response.data
}

export const deleteSpotWorker = async (
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}`

  const response = await axiosClientWithTenantId.delete(url)
  return response.data
}

export const updateSpotWorker = async (
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/line-update`

  const response = await axiosClientWithTenantId.put(url)
  return response.data
}

export const SickoutSpotWorker = async (
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/sickout`

  const response = await axiosClientWithTenantId.put(url)
  return response.data
}

export const assignSpotWorkerNewId = async (
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/new`

  const response = await axiosClientWithTenantId.put(url)
  return response.data
}

export const assignSpotWorkerExistingId = async (
  workDate: string,
  lineNumber: number,
  revision: number,
  workerId: number
): Promise<SaveSpotWorkerResponse> => {
  const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/assign`

  const response = await axiosClientWithTenantId.put(url, { workerId })
  return response.data
}
