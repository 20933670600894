import dayjs from 'dayjs'
import { sortBy, compact, uniq } from 'es-toolkit'
import { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Popup from 'reactjs-popup'

import type { TenantData } from 'api/tenants/types'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

import SupportConfirm from 'components/SupportConfirm/SupportConfirm'
import { TableCheckbox, TimeScale } from 'components/common'
import { TENTATIVE_SCHEDULE_TYPE_ID, TIME_INTERVAL } from 'components/common/constants'
import { getRandomNumber, getShiftBarWidthByDuration } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'

import { AssignToWorkTableWorkerRow } from './AssignToWorkTableWorkerRow'
import { WorkPlanPopover } from './WorkPlanPopover'
import { AssignToWorkTableContext } from './context'

import styles from './AssignToWorkTable.module.scss'

import type { SelectItem } from './WorkPlanPopover'
import type { WorkPlanSchedulesType, EditGroupsWorkerType, EditGroupsType } from '../types'
import type { Dayjs } from 'dayjs'
import type { Dispatch, RefObject, SetStateAction } from 'react'

/*
 * このコンポーネントの呼び出し元である WorkPlan.tsx で実行しているため
 * このコンポーネントでは getWorkspaceList, getScheduleTypeList, getWork を呼び出す必要はない
 */

export type SelectedWorkerType = {
  groupId: number | null
  workerIds: number[]
}

type Props = {
  workspaceId: number
  date: string
  editGroups: EditGroupsType[]
  setEditGroups: (items: EditGroupsType[]) => void
  selectedWorker: number[]
  setSelectedWorker: Dispatch<SetStateAction<number[]>>
  divElement: RefObject<HTMLDivElement>
  onScroll: () => void
  className?: string
  tenantWithDate?: TenantData
}
export const AssignToWorkTable = (props: Props) => {
  const {
    workspaceId,
    date,
    editGroups,
    setEditGroups,
    selectedWorker,
    setSelectedWorker,
    divElement,
    onScroll,
    className,
    tenantWithDate,
  } = props

  const { shiftKeyDown, selectedSchedules, setSelectedSchedules } = useContext(AssignToWorkTableContext)

  const [openSupportConfirm, setOpenSupportConfirm] = useState(false)
  const [name, setName] = useState('')
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [openGroups, setOpenGroups] = useState<string[]>([])
  const [onApprove, setOnApprove] = useState<() => void>(() => {})
  const { pathname } = useLocation()

  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const { getShiftBarXbyStartTime, getTimesByShiftBarX, businessStartTime, businessDuration, getTimeOver24h } =
    useBusinessTime({ tenantWithDate, interval: TIME_INTERVAL.FIVE })

  const { planWorkDate } = usePlans()
  const selectedWorkerIncludes = useCallback(
    (workerId: number | undefined) => {
      return typeof workerId === 'number' && selectedWorker.includes(workerId)
    },
    [selectedWorker]
  )
  const selectedScheduleIncludes = useCallback(
    (scheduleId: number | null) => {
      return selectedSchedules.some(s => s.scheduleId === scheduleId)
    },
    [selectedSchedules]
  )

  const workerActiveRange = (worker: EditGroupsWorkerType) => {
    const shiftSchedules = worker.schedules.filter(s => s.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
    if (shiftSchedules.length === 0) {
      return
    }
    const sortedShifts = sortBy(shiftSchedules, ['startAt'])
    const range: Array<[number, number]> = sortedShifts.map(s => {
      const shiftStart = getShiftBarXbyStartTime(s.startAt, planWorkDate)
      const shiftEnd = getShiftBarWidthByDuration(s.duration, TIME_INTERVAL.FIVE) + shiftStart - 1
      return [shiftStart, shiftEnd]
    })
    return range
  }

  useEffect(() => {
    setOpenGroups([])
    setSelectedWorker([])
  }, [pathname, setSelectedWorker])

  const handleGroupNameClick = (group: string) => {
    const newOpenGroup = openGroups.includes(group)
      ? openGroups.filter(openGroupName => openGroupName !== group)
      : openGroups.concat([group])
    setOpenGroups(newOpenGroup)
  }
  const isOpen = (group: string) => openGroups.includes(group)

  const onSelectWorkerScheduleType = (
    scheduleId: number | null,
    workerId: number,
    scheduleTypeId: number,
    supportWorkspaceId: number | null,
    supportWorkspaceName: string | null
  ) => {
    const isMultipleSchedule = selectedScheduleIncludes(scheduleId)
    const newScheduleIds: Array<number | null> = []
    const newEditGroups = editGroups.map(group => {
      const workers = group.workers.map(w => ({
        ...w,
        schedules: w.schedules.reduce<WorkPlanSchedulesType[]>((acc, cur) => {
          // [未選択] の場合は scheduleTypeId が 0 になっている
          const isFirstChose = cur.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.UNSELECTED
          const isSameWorker = w.workerId === workerId || selectedWorkerIncludes(w.workerId) || isMultipleSchedule
          const isSameScheduleId =
            cur.scheduleId === scheduleId || (isMultipleSchedule && selectedScheduleIncludes(cur.scheduleId))
          if (isSameWorker && isSameScheduleId) {
            // ワークスペースの作業員の場合、または応援作業でない場合いはそのまま変更可能
            if (!group.isSupport || scheduleTypeId !== TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT) {
              const newScheduleId = !scheduleId || isFirstChose ? getRandomNumber() : cur.scheduleId
              newScheduleIds.push(newScheduleId)
              acc.push({
                ...cur,
                scheduleId: newScheduleId,
                scheduleTypeId,
                supportWorkspaceId,
                supportWorkspaceName,
                isGroup: false,
              })
            } else if (!isFirstChose) {
              // 応援の作業員で[未選択]以外の場合はスケジュール変更なし
              newScheduleIds.push(cur.scheduleId)
              acc.push(cur)
            }
          } else {
            acc.push(cur)
          }
          return acc
        }, []),
      }))
      return {
        ...group,
        workers,
      }
    })
    // scheduleId が変わった場合に予定の選択状態が変わらないようにする
    const newSelectedSchedules = compact(newScheduleIds).map(newScheduleId => ({
      scheduleId: newScheduleId,
      time: selectedSchedules.find(s => s.scheduleId === scheduleId)?.time ?? '',
    }))
    setSelectedSchedules(newSelectedSchedules)
    setEditGroups(newEditGroups)
  }

  const handleDeleteWorkerScheduleType = (
    scheduleId: number | null,
    workerId: number,
    isMultipleWorkers: boolean,
    isMultipleSchedule: boolean
  ) => {
    const newEditGroups = editGroups.map(group => {
      const workers = group.workers.map(w => ({
        ...w,
        schedules: w.schedules.filter(s =>
          s.scheduleId !== scheduleId || isMultipleSchedule
            ? !selectedScheduleIncludes(s.scheduleId)
            : isMultipleWorkers && w.workerId !== workerId && !selectedWorkerIncludes(w.workerId)
        ),
      }))
      return {
        ...group,
        workers,
      }
    })

    setSelectedSchedules([])
    setEditGroups(newEditGroups)
  }

  const handleSelectScheduleType = (
    item: SelectItem,
    scheduleId: number | null,
    startAt: string,
    duration: number,
    workerId: number
  ) => {
    const isSupport = !item?.color
    const scheduleTypeId = isSupport ? TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT : item.id
    const supportWorkspaceId = isSupport ? item.id : null
    const supportWorkspaceName = (isSupport && item.name) || null
    setName(item?.name || '')

    const startTime = dayjs(startAt).local().format('HH:mm')
    const endTime = dayjs(startAt).local().add(duration, 'seconds').format('HH:mm')
    setStart(getTimeOver24h(startTime, true))
    setEnd(getTimeOver24h(endTime))

    handleOpenSupportConfirm(() => {
      onSelectWorkerScheduleType(scheduleId, workerId, scheduleTypeId, supportWorkspaceId, supportWorkspaceName)
    }, isSupport)
  }

  const handleOpenSupportConfirm = (func: () => void, isSupport: boolean) => {
    setOnApprove(() => func)
    if (isSupport) {
      setOpenSupportConfirm(true)
    } else {
      func()
    }
  }

  const groupWorkerSchedule = (
    schedules: WorkPlanSchedulesType[],
    startAt: string,
    duration: number,
    scheduleId?: number | null
  ) => {
    // 開始時刻か終了時刻が含まれるシフトを入力可能域にする
    // 入力可能域がない場合はundefinedを返す
    const endAt = dayjs(startAt).add(duration, 'seconds').format()
    // シフト時間
    const baseSchedule = schedules
      .filter(s => s.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
      .reduce(
        (acc, cur) => {
          const curEndAt = dayjs(cur.startAt).add(cur.duration, 'seconds')
          if (
            acc.startAt === '' &&
            ((dayjs(startAt).isBefore(cur.startAt, 'minute') && dayjs(endAt).isAfter(curEndAt, 'minute')) ||
              dayjs(startAt).isBetween(cur.startAt, curEndAt, 'minute', '[)') ||
              dayjs(endAt).isBetween(cur.startAt, curEndAt, 'minute', '(]'))
          ) {
            return { startAt: cur.startAt, duration: cur.duration }
          }
          return acc
        },
        { startAt: '', duration: 0 }
      )
    if (baseSchedule.startAt === '') {
      return
    }

    // そのほかの作業が入力可能域にあった場合、作業入力可能域を狭める
    const schedule = schedules
      .filter(s => s.scheduleId !== scheduleId && s.scheduleTypeId !== TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
      .reduce(
        (acc: { startAt: string; duration: number }, cur) => {
          const accEndAt = dayjs(acc.startAt).add(acc.duration, 'seconds').format()
          const curEndAt = dayjs(cur.startAt).add(cur.duration, 'seconds').format()

          // シフト時間外の作業は無視
          if (!dayjs(cur.startAt).isBetween(acc.startAt, accEndAt, 'minute', '[]')) {
            return acc
          }
          if (dayjs(startAt).isBefore(cur.startAt, 'minute') && dayjs(cur.startAt).isAfter(acc.startAt, 'minute')) {
            return {
              startAt: acc.startAt,
              duration: dayjs(cur.startAt).unix() - dayjs(acc.startAt).unix(),
            }
          }
          if (dayjs(curEndAt).isBefore(accEndAt, 'minute')) {
            return {
              startAt: curEndAt,
              duration: dayjs(accEndAt).unix() - dayjs(curEndAt).unix(),
            }
          }
          return { startAt: '', duration: 0 }
        },
        { startAt: baseSchedule.startAt, duration: baseSchedule.duration }
      )

    if (schedule.startAt === '') {
      return
    }

    // 基準域の終了時間
    const shiftEndAt = dayjs(schedule.startAt).add(schedule.duration, 'seconds').format()

    // 開始位置・終了位置が基準開始前の時
    if (
      dayjs(startAt).isBefore(schedule.startAt, 'minute') &&
      dayjs(endAt).isSameOrBefore(schedule.startAt, 'minute')
    ) {
      return
    }

    // 開始位置・終了位置が基準域外の時
    if (dayjs(startAt).isBefore(schedule.startAt, 'minute') && dayjs(endAt).isAfter(shiftEndAt, 'minute')) {
      return {
        startAt: schedule.startAt,
        duration: schedule.duration,
      }
    }

    // 開始位置が基準域外の時
    if (dayjs(startAt).isBefore(schedule.startAt) && dayjs(endAt).isSameOrBefore(shiftEndAt)) {
      return {
        startAt: schedule.startAt,
        duration: duration - (dayjs(schedule.startAt).unix() - dayjs(startAt).unix()),
      }
    }

    // 終了位置が基準域外の時
    if (dayjs(startAt).isSameOrAfter(schedule.startAt) && dayjs(endAt).isAfter(shiftEndAt)) {
      return { startAt, duration: duration - (dayjs(endAt).unix() - dayjs(shiftEndAt).unix()) }
    }

    // 全て基準域に収まる場合
    return { startAt, duration }
  }

  const handleShiftBarAdd = (groupId: number | null, startPos: number, endPos: number, workerId: number) => {
    if (!groupId && !workerId) {
      return
    }
    // シフトキーを押している時は新しい作業は追加させない
    if (shiftKeyDown) {
      return
    }
    // 新しい作業を追加した時は選択済みの作業を全解除
    setSelectedSchedules([])

    const isMultiple = selectedWorkerIncludes(workerId)
    const startPos5min = startPos - (startPos % 3)
    const endPos5min = endPos > startPos5min + 3 ? endPos : startPos5min + 3
    const time = getTimesByShiftBarX(startPos5min)
    const startAt = dayjs(`${date} ${time.hours}:${time.minutes}`).utc().format()
    const addItem: WorkPlanSchedulesType = {
      scheduleId: getRandomNumber(),
      scheduleTypeId: TENTATIVE_SCHEDULE_TYPE_ID.UNSELECTED,
      supportWorkspaceId: null,
      supportWorkspaceName: null,
      startAt,
      duration: (endPos5min - startPos5min) * 300,
    }

    const newEditGroups = editGroups.map(group => {
      if (!isMultiple && group.groupId !== groupId) {
        return group
      }
      return {
        ...group,
        workers: group.workers.map(w => {
          const schedule = groupWorkerSchedule(w.schedules, startAt, addItem.duration)
          const getSchedules = () => {
            if (w.workerId === workerId) {
              return [...w.schedules, addItem]
            }
            if (isMultiple && schedule && selectedWorkerIncludes(w.workerId)) {
              return [
                ...w.schedules,
                { ...addItem, isGroup: true, startAt: schedule.startAt, duration: schedule.duration },
              ]
            }
            return w.schedules
          }
          return {
            ...w,
            schedules: getSchedules(),
          }
        }),
      }
    })

    setEditGroups(newEditGroups)
  }

  const getMultipleWorkerEditGroup = useCallback(
    (workerId: number, index: number, startAt: string, duration: number, currentSchedule: WorkPlanSchedulesType) => {
      return editGroups.map(group => {
        const workers = group.workers.map(w => {
          const schedule = groupWorkerSchedule(w.schedules, startAt, duration, currentSchedule.scheduleId)
          const schedules = w.schedules
            .filter(s => s.scheduleTypeId !== TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
            .reduce((acc: WorkPlanSchedulesType[], cur, idx) => {
              if (w.workerId === workerId && idx === index) {
                acc.push({ ...cur, startAt, duration })
              } else if (cur.scheduleId === currentSchedule.scheduleId && schedule) {
                acc.push({ ...cur, startAt: schedule.startAt, duration: schedule.duration })
              } else if (cur.scheduleId !== currentSchedule.scheduleId || schedule) {
                acc.push(cur)
              }
              return acc
            }, [])
            .concat(w.schedules.filter(s => s.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT))

          if (
            schedules.every(s => s.scheduleId !== currentSchedule.scheduleId) &&
            schedule &&
            selectedWorkerIncludes(w.workerId)
          ) {
            schedules.push({
              ...currentSchedule,
              isGroup: true,
              startAt: schedule.startAt,
              duration: schedule.duration,
            })
          }

          return { ...w, schedules }
        })
        return {
          ...group,
          workers,
        }
      })
    },
    [editGroups, selectedWorkerIncludes]
  )

  const getSingleWorkerEditGroup = useCallback(
    (groupId: number | null, workerId: number, index: number, startAt: string, duration: number) => {
      return editGroups.map(group => {
        if (group.groupId !== groupId) {
          return group
        }

        const workers = group.workers.map(w => {
          const shifts = w.schedules.filter(s => s.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
          const schedulesWithoutShifts = w.schedules
            .filter(s => s.scheduleTypeId !== TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
            .map((s, idx) => {
              if (w.workerId === workerId && idx === index) {
                return { ...s, scheduleId: s.scheduleId, startAt, duration }
              }
              return s
            })
          return {
            ...w,
            schedules: shifts.concat(schedulesWithoutShifts),
          }
        })

        return {
          ...group,
          workers,
        }
      })
    },
    [editGroups]
  )

  const getStartAtDuration = (
    movedObject: boolean,
    changeStartAt: Dayjs,
    changeDuration: number,
    workerSchedules: WorkPlanSchedulesType[],
    currentStartAt: string
  ) => {
    const shifts = workerSchedules.filter(s => s.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
    const baseShift = shifts.find(s => {
      // 移動した時のシフト位置の取得は移動後の値から取得
      const baseStartAt = movedObject ? changeStartAt : currentStartAt
      return (
        dayjs(s.startAt).isSameOrBefore(baseStartAt) && dayjs(s.startAt).add(s.duration, 'seconds').isAfter(baseStartAt)
      )
    })
    // 変更後の作業がシフト内に収まってない場合は元のシフトが基準になるようにする(movedObject=trueの場合)
    const currentShift = shifts.find(s => {
      return (
        dayjs(s.startAt).isSameOrBefore(currentStartAt) &&
        dayjs(s.startAt).add(s.duration, 'seconds').isAfter(currentStartAt)
      )
    })

    // それでも見つけられない場合は開始時間が一番近いシフトを基準にする
    const nearShifts = shifts.sort(
      (a, b) => Math.abs(changeStartAt.diff(a.startAt)) - Math.abs(changeStartAt.diff(b.startAt))
    )

    const shift = baseShift || currentShift || nearShifts[0]

    const shiftEndAt = dayjs(shift.startAt).add(shift.duration, 'seconds')
    const changeEndAt = changeStartAt.clone().add(changeDuration, 'seconds')
    if (changeEndAt.isSameOrBefore(shift.startAt)) {
      return { startAt: shift.startAt, duration: 300 }
    } else if (changeStartAt.isBefore(shift.startAt)) {
      return { startAt: shift.startAt, duration: Math.abs(changeEndAt.diff(shift.startAt, 'seconds')) }
    } else if (changeStartAt.isSameOrAfter(shiftEndAt)) {
      return { startAt: shiftEndAt.subtract(300, 'seconds').utc().format(), duration: 300 }
    } else if (changeEndAt.isAfter(shiftEndAt)) {
      return {
        startAt: changeStartAt.utc().format(),
        duration: changeDuration - changeEndAt.diff(shiftEndAt, 'seconds'),
      }
    }
    return { startAt: changeStartAt.utc().format(), duration: changeDuration }
  }

  const getMultipleScheduleEditGroup = useCallback(
    (startAt: string, duration: number, currentSchedule: WorkPlanSchedulesType) => {
      // 左端／右端／オブジェクト移動の判定
      const isSameStartAt = dayjs(startAt).isSame(currentSchedule.startAt)
      const isSameEndAt = dayjs(startAt)
        .add(duration, 'seconds')
        .isSame(dayjs(currentSchedule.startAt).add(currentSchedule.duration, 'seconds'))
      const changeLeft = !isSameStartAt && isSameEndAt
      const changeRight = isSameStartAt && !isSameEndAt
      const movedObject = !changeLeft && !changeRight

      const diffDuration = duration - currentSchedule.duration
      const diffSeconds = dayjs(startAt).diff(currentSchedule.startAt, 'seconds')

      return editGroups.map(group => {
        const workers = group.workers.map(w => ({
          ...w,
          schedules: w.schedules.reduce<WorkPlanSchedulesType[]>((acc, cur) => {
            if (selectedScheduleIncludes(cur.scheduleId)) {
              const changeDuration = Math.max(cur.duration + diffDuration, 300)
              const changeStartAt = movedObject
                ? dayjs(cur.startAt).add(diffSeconds, 'seconds')
                : changeLeft
                  ? cur.duration + diffDuration <= 0
                    ? dayjs(cur.startAt).add(cur.duration - 300, 'seconds')
                    : dayjs(cur.startAt).subtract(diffDuration, 'seconds')
                  : dayjs(cur.startAt)

              const startAtDuration = getStartAtDuration(
                movedObject,
                changeStartAt,
                changeDuration,
                w.schedules,
                cur.startAt
              )

              if (movedObject || changeLeft) {
                acc.push({ ...cur, startAt: startAtDuration.startAt, duration: startAtDuration.duration })
              } else if (changeRight) {
                acc.push({ ...cur, duration: startAtDuration.duration })
              } else {
                acc.push(cur)
              }
            } else {
              acc.push(cur)
            }
            return acc
          }, []),
        }))
        return {
          ...group,
          workers,
        }
      })
    },
    [editGroups, selectedScheduleIncludes]
  )

  const handleWorkerShiftBarChange = useCallback(
    (groupId: number | null, workerId: number, index: number, x: number, width: number) => {
      const time = getTimesByShiftBarX(x)
      const startAt = dayjs(`${date} ${time.hours}:${time.minutes}`).utc().format()
      const duration = width * 300
      const currentSchedule = editGroups
        .find(g => g.groupId === groupId)
        ?.workers.find(w => w.workerId === workerId)
        ?.schedules.filter(s => s.scheduleTypeId !== TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)?.[index]

      // チェックボックスに入ってる作業者の場合
      const isMultipleWorker =
        selectedWorkerIncludes(workerId) && currentSchedule?.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.UNSELECTED
      // 複数選択したスケジュールの場合(1件の場合は含まない)
      const isMultipleSchedule =
        selectedScheduleIncludes(currentSchedule?.scheduleId ?? null) && selectedSchedules.length > 1

      const newEditGroups =
        isMultipleSchedule && currentSchedule
          ? getMultipleScheduleEditGroup(startAt, duration, currentSchedule)
          : isMultipleWorker && currentSchedule
            ? getMultipleWorkerEditGroup(workerId, index, startAt, duration, currentSchedule)
            : getSingleWorkerEditGroup(groupId, workerId, index, startAt, duration)

      !isMultipleSchedule && setSelectedSchedules([{ scheduleId: currentSchedule?.scheduleId ?? 0, time: '' }])
      setEditGroups(newEditGroups)
    },
    [
      date,
      editGroups,
      getMultipleScheduleEditGroup,
      getMultipleWorkerEditGroup,
      getSingleWorkerEditGroup,
      getTimesByShiftBarX,
      selectedScheduleIncludes,
      selectedSchedules.length,
      selectedWorkerIncludes,
      setEditGroups,
      setSelectedSchedules,
    ]
  )

  const handleTimeChange = useCallback(
    (
      groupId: number | null,
      workerId: number,
      index: number,
      changeStartHour: string,
      changeStartMinute: string,
      changeEndHour: string,
      changeEndMinute: string
    ) => {
      const startDate = dayjs().hour(Number(changeStartHour)).minute(Number(changeStartMinute))
      const startX = getShiftBarXbyStartTime(startDate.toISOString(), dayjs().format('YYYY-MM-DD'))
      const endDate = dayjs().hour(Number(changeEndHour)).minute(Number(changeEndMinute))
      const endX = getShiftBarXbyStartTime(endDate.toISOString(), dayjs().format('YYYY-MM-DD'))

      handleWorkerShiftBarChange(groupId, workerId, index, startX, endX - startX)
    },
    [getShiftBarXbyStartTime, handleWorkerShiftBarChange]
  )

  const getShiftBarItems = (
    groupId: number | null,
    schedules: WorkPlanSchedulesType[],
    isSupportGroup: boolean,
    workerId: number
  ) => {
    return schedules
      .filter(
        d =>
          d.scheduleTypeId !== TENTATIVE_SCHEDULE_TYPE_ID.SHIFT &&
          (partialScheduleTypes.some(p => p.id === d.scheduleTypeId) ||
            d.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT ||
            d.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.UNSELECTED)
      )
      .map((d, index) => {
        const isSupport = d.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT
        const schedule = isSupport
          ? { id: d.supportWorkspaceId ?? 0, name: d.supportWorkspaceName ?? undefined, color: undefined }
          : partialScheduleTypes.reduce(
              (acc: SelectItem, cur) => {
                if (cur.id === d.scheduleTypeId) {
                  return { id: cur.id, name: cur.name, color: cur.color }
                }
                return acc
              },
              { id: TENTATIVE_SCHEDULE_TYPE_ID.UNSELECTED }
            )

        const x = getShiftBarXbyStartTime(d.startAt, planWorkDate)
        const width = getShiftBarWidthByDuration(d.duration, TIME_INTERVAL.FIVE)
        const selected = { id: schedule.id, name: schedule.name, color: schedule.color }
        const isMultipleWorker = selectedWorkerIncludes(workerId)
        const isMultipleSchedule = selectedScheduleIncludes(d.scheduleId)
        const viewWorkspace = isSupportGroup ? isMultipleWorker : isMultipleWorker || isMultipleSchedule
        return {
          id: `item-${groupId}-${index}`,
          content: (
            <WorkPlanPopover
              workspaceId={workspaceId}
              scheduleId={d.scheduleId || 0}
              selected={selected}
              viewWorkspace={viewWorkspace}
              startTime={d.startAt}
              duration={d.duration}
              isGroup={!!d?.isGroup}
              onSelect={item => handleSelectScheduleType(item, d.scheduleId, d.startAt, d.duration, workerId)}
              onDelete={() =>
                handleDeleteWorkerScheduleType(d.scheduleId, workerId, isMultipleWorker, isMultipleSchedule)
              }
              onTimeChange={(startHour, startMinute, endHour, endMinute) =>
                handleTimeChange(groupId, workerId, index, startHour, startMinute, endHour, endMinute)
              }
              key={`worker-${groupId}-${index}`}
            />
          ),
          x,
          width,
          color: schedule?.color,
          invertedColor: isMultipleSchedule,
        }
      })
  }

  const getWorkerHeaderClass = (workerId: number) => {
    if (selectedWorkerIncludes(workerId)) {
      return `${styles.selectedWorkerTableHeader} ${styles.workerName}`
    }
    return `${styles.tableHeader} ${styles.workerName}`
  }

  const getWorkerContentClass = (workerId: number) => {
    if (selectedWorkerIncludes(workerId)) {
      return `${styles.selectedWorkerTableContent}`
    }
    return `${styles.tableContent}`
  }

  const onClickWorkerHandler = (workerId: number) => {
    if (selectedWorkerIncludes(workerId)) {
      setSelectedWorker(selectedWorker.filter(id => id !== workerId))
    } else {
      setSelectedWorker([...selectedWorker, workerId])
    }
  }

  const groupFound = (groupId: number | null, groupName: string) =>
    editGroups.find(group => group.groupId === groupId && group.name === groupName)

  const onClickGroupHandler = (groupId: number | null, groupName: string, checked: boolean) => {
    const workerIds = groupFound(groupId, groupName)?.workers?.map(w => w.workerId) ?? []
    if (checked) {
      setSelectedWorker(uniq([...selectedWorker, ...workerIds]))
      setOpenGroups(uniq([...openGroups, groupName]))
    } else {
      setSelectedWorker(selectedWorker.filter(workerId => !workerIds.includes(workerId)))
    }
  }

  // 選択された作業者にフィルタリングを適用する
  useEffect(() => {
    const visibleWorkerIds = editGroups
      .flatMap(g => g.workers)
      .filter(w => w.visible)
      .map(w => w.workerId)

    if (selectedWorker.every(sw => visibleWorkerIds.includes(sw))) {
      return
    }

    setSelectedWorker(prev => prev.filter(sw => visibleWorkerIds.includes(sw)))
  }, [selectedWorker, editGroups, setSelectedWorker])

  const getGroupIndeterminate = (groupId: number | null, groupName: string) => {
    const found = groupFound(groupId, groupName)?.workers
    const filtered = found?.filter(worker => selectedWorkerIncludes(worker.workerId)) ?? []
    return found && filtered.length > 0 && filtered.length !== found.length
  }

  const getGroupChecked = (groupId: number | null, groupName: string) => {
    const workerIds = groupFound(groupId, groupName)?.workers?.map(w => w.workerId) ?? []
    return workerIds.some(workerId => selectedWorkerIncludes(workerId))
  }

  const allWorkerIds = useMemo(
    () => editGroups.flatMap(group => group.workers.map(worker => worker.workerId)),
    [editGroups]
  )
  const onClickAllHandler = (checked: boolean) => {
    if (checked) {
      setSelectedWorker(allWorkerIds)
      setOpenGroups(editGroups.map(group => group.name))
    } else {
      setSelectedWorker([])
    }
  }
  const allIndeterminate = useMemo(
    () => selectedWorker.length > 0 && selectedWorker.length !== allWorkerIds.length,
    [allWorkerIds, selectedWorker]
  )

  return (
    <>
      <div ref={divElement} className={`${styles.tableWrapper} ${className}`} onScroll={onScroll}>
        <table>
          <thead>
            <tr className={styles.timeHeader}>
              <td className={`bg-secondary-pale px-2 ${styles.tableHeader}`}>
                <div className="d-flex align-items-center">
                  <TableCheckbox
                    id="assign-to-work-table-check-all"
                    indeterminate={allIndeterminate}
                    checked={selectedWorker.length > 0}
                    onClick={onClickAllHandler}
                  />
                  <span className="ps-3">グループ</span>
                </div>
              </td>
              <td className="p-0">
                <TimeScale tenantWithDate={tenantWithDate} is5min />
              </td>
            </tr>
          </thead>
          {editGroups.map((g, index) => (
            <tbody key={`group-${g.groupId}-${index}`}>
              <tr className={styles.tableRow}>
                <td className={styles.groupHeader} role="button">
                  <div className="d-flex align-items-center ps-2">
                    <TableCheckbox
                      id={`assign-to-work-table-check-group-${g.groupId}`}
                      indeterminate={getGroupIndeterminate(g.groupId, g.name)}
                      checked={getGroupChecked(g.groupId, g.name)}
                      onClick={checked => onClickGroupHandler(g.groupId, g.name, checked)}
                    />
                    <Popup
                      trigger={
                        <div className="d-flex align-items-center px-2" onClick={() => handleGroupNameClick(g.name)}>
                          <i className={`icf-carot_${isOpen(g.name) ? 'down' : 'right'} me-1`} />
                          <div className={`${styles.groupName} text-truncate`}>{g.name}</div>
                        </div>
                      }
                      position="bottom center"
                      on="hover"
                      arrowStyle={{ color: 'black' }}
                    >
                      <span className="text-white bg-black p-1 px-3 rounded font-small">
                        {g.name}:{g.workers.filter(w => w.visible).length}人
                      </span>
                    </Popup>
                  </div>
                </td>
              </tr>
              {isOpen(g.name) &&
                sortBy(g.workers, ['wmsMemberId'])
                  .filter(worker => worker.visible)
                  .map(worker => (
                    <AssignToWorkTableWorkerRow
                      worker={worker}
                      businessStartTime={businessStartTime}
                      shiftBarWidth={businessDuration}
                      shiftBarActiveRange={workerActiveRange(worker)}
                      shiftBarItems={getShiftBarItems(g.groupId, worker.schedules, g.isSupport, worker.workerId)}
                      shiftBarClassName={getWorkerContentClass(worker.workerId)}
                      headerClassName={getWorkerHeaderClass(worker.workerId)}
                      onShiftBarAdd={(startPos, endPos) =>
                        handleShiftBarAdd(g.groupId, startPos, endPos, worker.workerId)
                      }
                      onShiftBarChange={(idx, x, width) =>
                        handleWorkerShiftBarChange(g.groupId, worker.workerId, idx, x, width)
                      }
                      onCheckBoxClick={() => onClickWorkerHandler(worker.workerId)}
                      checkboxChecked={selectedWorkerIncludes(worker.workerId)}
                      key={`worker-${worker.workerId}`}
                    />
                  ))}
            </tbody>
          ))}
        </table>
      </div>

      <SupportConfirm
        isOpen={openSupportConfirm}
        start={start}
        end={end}
        name={name}
        onCancel={() => setOpenSupportConfirm(false)}
        onApprove={() => {
          onApprove?.()
          setOpenSupportConfirm(false)
        }}
      />
    </>
  )
}
