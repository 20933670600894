import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { useState, useEffect, useMemo, useRef, useContext, useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { Card, CardBody, DropdownItem } from 'reactstrap'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { DropdownList, TimeRangeSelect } from 'components/common'
import { TENTATIVE_SCHEDULE_TYPE_ID, TIME_INTERVAL } from 'components/common/constants'

import useBusinessTime from 'hooks/useBusinessTime'

import { AssignToWorkTableContext } from './context'

import styles from './WorkPlanPopover.module.scss'

import type { PopupActions } from 'reactjs-popup/dist/types'

dayjs.extend(isSameOrAfter)

export type SelectItem = {
  id: number
  name?: string
  color?: string
}

type Props = {
  workspaceId: number
  scheduleId: number
  selected: SelectItem
  viewWorkspace: boolean
  startTime: string
  duration: number
  isGroup: boolean
  onSelect: (item: SelectItem) => void
  onDelete: () => void
  onTimeChange: (startHour: string, startMinute: string, endHour: string, endMinute: string) => void
}

export const WorkPlanPopover = (props: Props) => {
  const {
    workspaceId,
    scheduleId,
    selected,
    viewWorkspace,
    startTime,
    duration,
    isGroup,
    onSelect,
    onDelete,
    onTimeChange,
  } = props

  const [dropdown, setDropdown] = useState(false)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { getTimeOver24h } = useBusinessTime({ interval: TIME_INTERVAL.FIVE })

  const time = useMemo(() => {
    const start = dayjs(startTime).local().format('HH:mm')
    const endTime = dayjs(startTime).local().add(duration, 'seconds').format('HH:mm')
    return `${getTimeOver24h(start, true)}〜${getTimeOver24h(endTime)}`
  }, [duration, getTimeOver24h, startTime])

  useEffect(() => {
    if (!isGroup && selected.id === TENTATIVE_SCHEDULE_TYPE_ID.UNSELECTED && scheduleId < 1) {
      ref.current?.open()
    }
  }, [isGroup, selected, scheduleId])

  const dropdownToggle = (
    <DropdownItem onClick={() => null} className="px-0 d-flex align-items-center">
      {selected?.color && (
        <div>
          <div className={`${styles.square} bg-${selected.color} me-1`}></div>
        </div>
      )}
      <span className="flex-grow-1 text-truncate">{selected?.name || '作業を選択'}</span>
      <i className="icf-carot_right font-large" />
    </DropdownItem>
  )

  const filteredWorkspaceList = useMemo(
    () =>
      partialWorkspaces
        .filter(w => w.id !== workspaceId && viewWorkspace)
        .filter(w => w.id !== selected.id || !!selected?.color),
    [selected?.color, selected.id, viewWorkspace, workspaceId, partialWorkspaces]
  )

  const filteredScheduleTypeList = useMemo(
    () => partialScheduleTypes.filter(s => s.id !== selected.id || !selected?.color),
    [partialScheduleTypes, selected?.color, selected.id]
  )

  const handleDelete = () => {
    onDelete()
    ref.current?.close()
  }

  const handleWorkspaceSelect = (id: number, name: string) => {
    onSelect({ id, name })
  }

  const ref = useRef<PopupActions>(null)
  const trigger = <div className="h-100 w-100">{selected?.name || '未選択'}</div>

  const { shiftKeyDown, selectedSchedules, setSelectedSchedules } = useContext(AssignToWorkTableContext)
  const handleOpen = () => {
    const found = selectedSchedules.find(s => s.scheduleId === scheduleId)
    if (shiftKeyDown) {
      if (found) {
        setSelectedSchedules(selectedSchedules.filter(s => s.scheduleId !== scheduleId))
      } else {
        setSelectedSchedules(selectedSchedules.concat([{ scheduleId, time }]))
      }
    } else {
      if (found && found.time !== time) {
        setSelectedSchedules(selectedSchedules.map(s => (s.scheduleId === scheduleId ? { scheduleId, time } : s)))
      } else {
        setSelectedSchedules([{ scheduleId, time }])
      }
    }
  }
  const handleClose = () => {
    if (selected.id === TENTATIVE_SCHEDULE_TYPE_ID.UNSELECTED) {
      onDelete()
    }
    if (shiftKeyDown) {
      if (selectedSchedules.slice(-1)[0]?.scheduleId !== scheduleId) {
        setSelectedSchedules(selectedSchedules.filter(s => s.scheduleId !== scheduleId))
      }
    } else {
      if (selectedSchedules.some(s => s.scheduleId === scheduleId && s.time !== time)) {
        setSelectedSchedules(selectedSchedules.map(s => (s.scheduleId === scheduleId ? { scheduleId, time } : s)))
      }
    }
  }

  const handleTimeChange = useCallback(
    (changeStartHour: string, changeStartMinute: string, changeEndHour: string, changeEndMinute: string) => {
      onTimeChange(changeStartHour, changeStartMinute, changeEndHour, changeEndMinute)
    },
    [onTimeChange]
  )

  return (
    <Popup
      ref={ref}
      trigger={trigger}
      onClose={handleClose}
      onOpen={handleOpen}
      position="left center"
      contentStyle={{ width: '14rem' }}
    >
      <Card>
        <CardBody className="p-2 font-x-small">
          <div className="d-flex justify-content-end font-large mb-1">
            <i className="icf-delete" onClick={handleDelete} />
            <i className="icf-close ps-1" onClick={() => ref.current?.close()} />
          </div>
          <DropdownList
            open={dropdown}
            setOpen={() => setDropdown(!dropdown)}
            content={dropdownToggle}
            direction="end"
            dropdownClassName={styles.transform}
          >
            {filteredScheduleTypeList.map((schedule, index) => (
              <DropdownItem
                key={`schedule-${schedule.id}-${index}`}
                className={`d-flex font-x-small px-2 align-items-center ${styles.dropdownItems}`}
                onClick={() => onSelect({ id: schedule.id, name: schedule.name, color: schedule.color })}
              >
                <div>
                  <div className={`${styles.square} bg-${schedule.color} me-1`}></div>
                </div>
                <span className="text-truncate">{schedule.name}</span>
              </DropdownItem>
            ))}
            {filteredScheduleTypeList.length > 0 && filteredWorkspaceList.length > 0 && <DropdownItem divider />}
            {filteredWorkspaceList.map((workspace, index) => (
              <DropdownItem
                key={`workspace-${workspace.id}-${index}`}
                className={`d-flex font-x-small px-2 ${styles.dropdownItems}`}
                onClick={() => handleWorkspaceSelect(workspace.id, workspace.name)}
              >
                <span className="text-truncate">{workspace.name}</span>
              </DropdownItem>
            ))}
          </DropdownList>
          <div className="d-flex mt-2 align-items-center justify-content-between">
            <TimeRangeSelect
              startTime={startTime}
              duration={duration}
              interval={TIME_INTERVAL.FIVE}
              onTimeChange={handleTimeChange}
            />
          </div>
        </CardBody>
      </Card>
    </Popup>
  )
}
