import { useState, useEffect } from 'react'
import { Input, Label } from 'reactstrap'

import styles from './styles.module.scss'

import type { ChangeEvent } from 'react'

type Props = {
  id: string
  emptyLabel?: string
  accept?: string
  onChange: (file: File | null) => void
}

const FileInput = ({ id, emptyLabel = 'ファイルは選択されていません', accept, onChange }: Props) => {
  const [file, setFile] = useState<File | null>(null)
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    onChange(file)
  }, [file, onChange])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0]
      setFile(selectedFile)
      setFileName(selectedFile.name)
    } else {
      setFile(null)
      setFileName('')
    }
  }

  return (
    <div className="d-flex align-items-center">
      <Input type="file" id={id} accept={accept} className="d-none" onChange={handleInputChange} />
      <Label for={id} className={'btn btn-outline-secondary mb-0'}>
        ファイルを選択
      </Label>
      <span className={file ? styles.msg : styles.msg + ' ' + styles.msgEmpty}>{file ? fileName : emptyLabel}</span>
    </div>
  )
}

export default FileInput
