import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { showError } from 'slices/notificationSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'
import { checkWorkspaceExists } from 'slices/workspacesSlice'

type RelativeDateRange = {
  // 当日から数えて何日目かを指定する（過去の場合は-にする）
  fromDays?: number
  toDays?: number
}

const useDateChange = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { pathname } = useLocation()

  const checkTenantCreated = useCallback(
    (newDate: string) => {
      if (!tenant) {
        return
      }
      if (dayjs(newDate).isBefore(tenant.createdAt, 'day')) {
        dispatch(showError({ errorMessage: 'テナント作成日以前の日付は選択できません' }))
        return
      }
      navigate(`?date=${newDate}`, { replace: true })
    },
    [dispatch, navigate, tenant]
  )

  const checkWorkspaceCreated = useCallback(
    (newDate: string, workspaceId: number, targetPathnameWithDate?: string) => {
      const callback = (workspaceExists: boolean) => {
        if (workspaceExists) {
          navigate(targetPathnameWithDate || `${pathname}?date=${newDate}`)
        } else {
          dispatch(showError({ errorMessage: '指定された日付のワークスペースが存在しません' }))
        }
      }
      dispatch(checkWorkspaceExists(workspaceId, newDate, callback))
    },
    [dispatch, navigate, pathname]
  )

  const createDateFilter = (dateRange: RelativeDateRange) => {
    return (newDate: Date) => {
      const target = dayjs(newDate)
      return (
        (dateRange.fromDays === undefined || target.isAfter(dayjs().add(dateRange.fromDays, 'day'))) &&
        (dateRange.toDays === undefined || target.isBefore(dayjs().add(dateRange.toDays, 'day')))
      )
    }
  }

  return { checkTenantCreated, checkWorkspaceCreated, createDateFilter }
}
export default useDateChange
