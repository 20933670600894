import { isEqual } from 'es-toolkit'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import type { TenantEditDataType } from 'api/tenants/types'
import { ROLE } from 'api/users/constants'

import { selectPostcodeStatus } from 'slices/postcodeSlice'
import { getTenant, selectTenantsStatus } from 'slices/tenantsSlice'
import { selectUsersStatus } from 'slices/usersSlice'

// 新規作成時のみtenantIdを指定しないのでオプショナル型にする
const useTenant = (tenantId?: number) => {
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { allTenantUsers } = useSelector(selectUsersStatus, shallowEqual)
  const { postcodeList } = useSelector(selectPostcodeStatus, shallowEqual)

  const dispatch = useDispatch()

  const [initEditData, setInitEditData] = useState<TenantEditDataType>({
    name: '',
    salesOfficeName: null,
    postalCode: '',
    prefecture: '',
    municipality: '',
    otherAddress: '',
    phoneNumber: '',
    personInChargeId: '',
    businessStartHour: '00',
    businessStartMinute: '00',
    businessEndHour: '00',
    businessEndMinute: '15',
  })
  const [editData, setEditData] = useState<TenantEditDataType>(initEditData)
  const [nameValidity, setNameValidity] = useState(false)
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false)
  const [postalCodeValidity, setPostalCodeValidity] = useState(false)
  const [prefectureValidity, setPrefectureValidity] = useState(false)
  const [municipalityValidity, setMunicipalityValidity] = useState(false)
  const [otherAddressValidity, setOtherAddressValidity] = useState(false)
  const [businessTimeValidity, setBusinessTimeValidity] = useState(false)
  const [personInChargeValidity, setPersonInChargeValidity] = useState(false)

  const disabled = useMemo(
    () =>
      !(
        nameValidity &&
        postalCodeValidity &&
        prefectureValidity &&
        municipalityValidity &&
        otherAddressValidity &&
        phoneNumberValidity &&
        businessTimeValidity &&
        personInChargeValidity
      ),
    [
      nameValidity,
      postalCodeValidity,
      prefectureValidity,
      municipalityValidity,
      otherAddressValidity,
      phoneNumberValidity,
      businessTimeValidity,
      personInChargeValidity,
    ]
  )

  useEffect(() => {
    if (!tenantId) {
      return
    }
    if (tenant) {
      const businessStartTime = tenant?.businessStartTime.split(':')
      const businessEndTime = tenant?.businessEndTime.split(':')
      const updatedData = {
        name: tenant.name,
        salesOfficeName: tenant.salesOfficeName,
        postalCode: tenant?.postalCode || '',
        prefecture: tenant?.prefecture || '',
        municipality: tenant?.municipality || '',
        otherAddress: tenant?.otherAddress || '',
        phoneNumber: tenant?.phoneNumber || '',
        personInChargeId: tenant?.personInChargeId || '',
        businessStartHour: businessStartTime?.[0] || '08',
        businessStartMinute: businessStartTime?.[1] || '00',
        businessEndHour: businessEndTime?.[0] || '18',
        businessEndMinute: businessEndTime?.[1] || '00',
      }
      setInitEditData(updatedData)
      setEditData(updatedData)
      return
    }
    dispatch(getTenant(tenantId))
  }, [tenantId, tenant, setEditData, setInitEditData, dispatch])

  const unchanged = useMemo(() => isEqual(editData, initEditData), [editData, initEditData])

  const targetPostalCode = useMemo(
    () => postcodeList.find(p => p.postcode === editData.postalCode),
    [editData.postalCode, postcodeList]
  )

  const personInChargeItems: { key: string; value: string }[] = useMemo(
    () =>
      allTenantUsers
        .filter(user => user.partialUserHasTenants[0].role === ROLE.TENANT_ADMIN)
        .map(user => ({ key: user.userId, value: user.partialUserHasTenants[0]?.nickname ?? '' })),
    [allTenantUsers]
  )

  useEffect(() => {
    const start = editData.businessStartHour + editData.businessStartMinute
    const end = editData.businessEndHour + editData.businessEndMinute
    setBusinessTimeValidity(start < end || start + end === '')
  }, [editData.businessStartHour, editData.businessStartMinute, editData.businessEndHour, editData.businessEndMinute])

  // 営業時間範囲を計算する関数（親コンポーネントでも利用するため分割）
  const calculateTimeRange24Hours = (startHour: string, startMinute: string) => {
    const fromHour = startMinute === '45' ? (Number(startHour) + 1).toString().padStart(2, '0') : startHour
    const fromMin = startMinute === '45' ? '00' : (Number(startMinute) + 15).toString().padStart(2, '0')
    const toHour = (Number(fromHour) + (fromMin === '00' ? 23 : 24)).toString().padStart(2, '0')
    const toMin = fromMin === '00' ? '45' : (Number(fromMin) - 15).toString().padStart(2, '0')
    return { fromHour, fromMin, toHour, toMin }
  }

  // 営業終了時間の範囲をメモ化して保持
  const timeRange24Hours = useMemo(() => {
    return calculateTimeRange24Hours(editData.businessStartHour, editData.businessStartMinute)
  }, [editData.businessStartHour, editData.businessStartMinute])

  return {
    disabled,
    unchanged,
    editData,
    initEditData,
    targetPostalCode,
    personInChargeItems,
    businessTimeValidity,
    personInChargeValidity,
    calculateTimeRange24Hours,
    timeRange24Hours,
    setEditData,
    setInitEditData,
    setNameValidity,
    setPhoneNumberValidity,
    setPostalCodeValidity,
    setPrefectureValidity,
    setMunicipalityValidity,
    setOtherAddressValidity,
    setBusinessTimeValidity,
    setPersonInChargeValidity,
  }
}

export default useTenant
