import { useLayoutEffect, useState } from 'react'

import type { RefObject } from 'react'

export const useElementSize = (ref: RefObject<HTMLElement>) => {
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    setSize({
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight,
    })
  }, [ref])

  return size
}
