import { intersection } from 'es-toolkit'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSkillsStatus } from 'slices/skillsSlice'

import { FilteringButton, FilteringInputField } from 'components/common'
import { TENTATIVE_SCHEDULE_TYPE_ID } from 'components/common/constants'
import type { FilterItem } from 'components/common/types'
import { isFilteredWorker } from 'components/common/utils'

import styles from './WorkPlanFilter.module.scss'

import type { EditGroupsType } from '../types'
import type { Dispatch, SetStateAction } from 'react'

type Props = {
  onChange: Dispatch<SetStateAction<EditGroupsType[]>>
  onOpenChange: Dispatch<SetStateAction<boolean>>
  shouldReset: boolean
  setShouldReset: Dispatch<SetStateAction<boolean>>
}
// 同じKeyになり、チェックボックスの未設定の動作に不具合が出るので、分ける
const SKILL_BLANK_ID = -2
const SKILL_BLANK_LABEL = '未設定'
const SCHEDULE_TYPE_BLANK_ID = -1
const SCHEDULE_TYPE_BLANK_LABEL = '未設定'
const WorkPlanFilter = ({ onChange, onOpenChange, shouldReset, setShouldReset }: Props) => {
  const [openFilter, setOpenFilter] = useState(false)
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const [filterWord, setFilterWord] = useState('')
  const [selectedFilterScheduleTypes, setSelectedFilterScheduleTypes] = useState<number[]>([])
  const [selectedFilterSkills, setSelectedFilterSkills] = useState<number[]>([])

  useEffect(
    () =>
      partialScheduleTypes &&
      setSelectedFilterScheduleTypes(partialScheduleTypes.map(g => g.id).concat(SCHEDULE_TYPE_BLANK_ID)),
    [partialScheduleTypes]
  )

  useEffect(() => skills && setSelectedFilterSkills(skills.map(s => s.id).concat(SKILL_BLANK_ID)), [skills])

  useEffect(() => {
    if (!shouldReset) {
      return
    }
    skills && setSelectedFilterSkills(skills.map(s => s.id).concat(SKILL_BLANK_ID))
    partialScheduleTypes &&
      setSelectedFilterScheduleTypes(partialScheduleTypes.map(g => g.id).concat(SCHEDULE_TYPE_BLANK_ID))
    setFilterWord('')
    setShouldReset(false)
  }, [shouldReset, setShouldReset, skills, partialScheduleTypes])

  const filterScheduleTypeItems = useMemo<FilterItem[]>(
    () =>
      partialScheduleTypes
        .map(s => ({
          key: s.id,
          label: s.name,
          checked: selectedFilterScheduleTypes.includes(s.id),
        }))
        .concat({
          key: SCHEDULE_TYPE_BLANK_ID,
          label: SCHEDULE_TYPE_BLANK_LABEL,
          checked: selectedFilterScheduleTypes.includes(SCHEDULE_TYPE_BLANK_ID),
        }),
    [partialScheduleTypes, selectedFilterScheduleTypes]
  )

  const filterSkillItems = useMemo<FilterItem[]>(
    () =>
      skills
        .map(s => ({ key: s.id, label: s.name, checked: selectedFilterSkills.includes(s.id) }))
        .concat({
          key: SKILL_BLANK_ID,
          label: SKILL_BLANK_LABEL,
          checked: selectedFilterSkills.includes(SKILL_BLANK_ID),
        }),
    [skills, selectedFilterSkills]
  )

  useEffect(() => {
    onChange(prev =>
      prev.map(g => {
        const workers = g.workers.map(w => {
          const workerSchedules = w.schedules
            .map(s => s.scheduleTypeId)
            .filter(s => s !== SCHEDULE_TYPE_BLANK_ID && s !== TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT)

          const hasBlankScheduleTypes =
            selectedFilterScheduleTypes.includes(SCHEDULE_TYPE_BLANK_ID) && workerSchedules.length === 0
          const hasScheduleTypes =
            hasBlankScheduleTypes || intersection(selectedFilterScheduleTypes, workerSchedules).length > 0

          const hasBlankSkill = selectedFilterSkills.includes(SKILL_BLANK_ID) && w.skillIds.length === 0
          const hasSelectedSkill = hasBlankSkill || intersection(selectedFilterSkills, w.skillIds).length > 0

          const hasFilterWord = isFilteredWorker(filterWord, {
            name: w.name,
            wmsMemberId: w.wmsMemberId,
            workerType: w.workerType,
          })

          return { ...w, visible: hasScheduleTypes && hasSelectedSkill && hasFilterWord }
        })

        return { ...g, workers }
      })
    )
  }, [selectedFilterScheduleTypes, selectedFilterSkills, filterWord, onChange])
  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="me-2">
          <FilteringInputField onChange={setFilterWord} placeholder="名前 / 識別子で検索" value={filterWord} />
        </div>
        <button
          className={`d-flex align-items-center justify-content-center  ${
            openFilter ? styles.openIconButton : styles.closeIconButton
          }`}
          color="secondary"
          onClick={() => {
            setOpenFilter(prev => {
              onOpenChange(!prev)
              return !prev
            })
          }}
        >
          <i className="icf-filter font-large" />
        </button>
      </div>
      {openFilter && (
        <div className="d-flex mt-2">
          <div className="me-2">
            <FilteringButton
              items={filterScheduleTypeItems}
              onChange={setSelectedFilterScheduleTypes}
              value={selectedFilterScheduleTypes}
              label="作業で絞り込み"
              disabled={partialScheduleTypes.length === 0}
            />
          </div>
          <FilteringButton
            items={filterSkillItems}
            onChange={setSelectedFilterSkills}
            value={selectedFilterSkills}
            label="スキルで絞り込み"
            disabled={skills.length === 0}
          />
        </div>
      )}
    </div>
  )
}

export default WorkPlanFilter
