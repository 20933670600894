export const BOP_EXPORT_DATA_TYPES = {
  SUMMARY: 'summary',
  DETAIL: 'detail',
  LABOR_COSTS: 'laborCosts',
  UNIT_COSTS: 'unitCosts',
} as const

export const BOP_WORKSPACE_DATA_TYPES = {
  PLAN: 'plan',
  WARN_PLAN: 'warnPlan',
  DAILY_REPORT: 'dailyReport',
} as const
