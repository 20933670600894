import { BOP_WORKSPACE_DATA_TYPES } from 'api/bop_reports/constants'

import { BOP_TYPE } from 'components/Dashboard/utils'

export const DATA_TYPE_ITEMS = {
  [BOP_TYPE.ESTIMATE]: { dataTypeLabel: 'アーカイブ', dataTypeColor: 'emerald' },
  [BOP_WORKSPACE_DATA_TYPES.PLAN]: { dataTypeLabel: '作業計画', dataTypeColor: 'emerald' },
  [BOP_WORKSPACE_DATA_TYPES.WARN_PLAN]: {
    dataTypeLabel: (
      <>
        作業計画
        <i className="icf-alert ms-1" />
      </>
    ),
    dataTypeColor: 'emerald',
  },
  [BOP_WORKSPACE_DATA_TYPES.DAILY_REPORT]: { dataTypeLabel: '日報実績', dataTypeColor: 'violet' },
}
