import moment from 'moment'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import type { EditWorkspaceProps } from 'api/workspaces/types'

import { ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'
import { createWorkspace, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { InputFormat, Notification, SelectBoxFormat, SubmitFooter } from 'components/common'
import type { SuggestionItem } from 'components/common/FormFormat/Suggestion'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'

import RelatedWorkspaceEdit from './RelatedWorkspaceEdit'
import WorkspaceAdminEdit from './WorkspaceAdminEdit'

import styles from './WorkspaceCreate.module.scss'

type WorkspaceCreateType = {
  name: string | undefined
  admins: SuggestionItem[]
  members: SuggestionItem[]
  relatedWorkspaces: { id: number; value: string }[]
  autoArchiveTime: string
}

const initData: WorkspaceCreateType = {
  name: undefined,
  admins: [],
  members: [],
  relatedWorkspaces: [],
  autoArchiveTime: '08:00',
}

const WorkspaceCreate = () => {
  const [editData, setEditData] = useState<WorkspaceCreateType>(initData)
  const [nameValidity, setNameValidity] = useState(false)
  const [notificationErrorMessage, setNotificationErrorMessage] = useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectWorkspacesStatus, shallowEqual)

  const disabled = useMemo(() => !(editData.name && nameValidity), [editData, nameValidity])

  const archiveTimeItems = [...Array(48)].map((_, i) => {
    const h = moment()
      .startOf('day')
      .add(i * 30, 'minutes')
      .format('HH:mm')
    return { value: h }
  })

  const onSubmit = () => {
    setSubmitted(true)

    const data: EditWorkspaceProps = {
      name: editData.name || '',
      memberIds: editData.admins.concat(editData.members).map(member => member.id.toString()),
      relatedWorkspaceIds: editData.relatedWorkspaces.map(workspace => workspace.id),
      autoArchiveTime: editData.autoArchiveTime,
    }
    dispatch(createWorkspace(data))
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }

    setSubmitted(false)

    // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
    if (ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      return
    }

    if (errorMessage === '') {
      navigate('/workspaces')
    } else {
      if (errorMessage === ERROR_STATUS_CODE.CONFLICT) {
        setNotificationErrorMessage('ワークスペースの登録件数が上限に達しています。')
      } else {
        setNotificationErrorMessage('保存できませんでした。')
      }
    }
  }, [submitted, isRequesting, errorMessage, dispatch, navigate])

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large fw-bold text-center border-bottom py-3 bg-white">ワークスペースの登録</div>
          <Notification
            errorMessage={notificationErrorMessage}
            error={!!notificationErrorMessage}
            hide={() => setNotificationErrorMessage(undefined)}
          />
        </div>
        <div className="w-50 mx-auto mt-3 pb-1">
          <div className="d-flex justify-content-between py-1">
            <div className="font-middle fw-bold">ワークスペースの詳細</div>
            <small>※必須項目</small>
          </div>
          <InputFormat
            label="名称※"
            placeholder="ワークスペース名を入力"
            value={editData.name}
            maxLength={100}
            onChange={value => setEditData({ ...editData, name: value })}
            validations={[Rules.Required]}
            onValidate={setNameValidity}
          />

          <div className="font-middle fw-bold py-1 mt-5">ワークスペース管理者の登録</div>
          <div className="mt-2 mb-1">
            ワークスペースの管理者を登録します。オーナーは初期状態で管理者として登録されています。オーナーを管理者から除外することはできません。
          </div>
          <WorkspaceAdminEdit
            admins={editData.admins}
            members={editData.members}
            onChange={(admins, members) => setEditData({ ...editData, admins, members })}
          />

          <div className="font-middle fw-bold py-1 mt-5">連携ワークスペースの設定</div>
          <div className="mt-2 mb-1">
            このワークスペースの前工程となるワークスペース、後工程となるワークスペースを設定しておくことで、ダッシュボードから設定したワークスペースの進捗を確認することができます。
          </div>
          <div className="mt-3">連携するワークスペース</div>
          <RelatedWorkspaceEdit
            relatedWorkspaces={editData.relatedWorkspaces}
            onChange={relatedWorkspaces => setEditData({ ...editData, relatedWorkspaces })}
          />

          <div className="font-middle fw-bold py-1 mt-5">作業計画の自動アーカイブ</div>
          <div className="mt-2 mb-1">
            作業計画を自動アーカイブすることで、アーカイブされた作業計画と最終的な作業計画の変更割合を計画変更率として算出します。計画変更率は「レポート」で確認できます。デフォルトでは8:00が設定されています。
          </div>
          <div className="mt-3">
            <SelectBoxFormat
              label="自動アーカイブ時刻"
              value={editData.autoArchiveTime}
              size={COLUMN_SIZES.SHORT}
              items={archiveTimeItems}
              onChange={e => setEditData({ ...editData, autoArchiveTime: e.value })}
            />
          </div>
        </div>
      </div>
      <SubmitFooter onCancel={() => navigate(-1)} onSubmit={onSubmit} submitDisabled={disabled} />
    </>
  )
}

export default WorkspaceCreate
