import { BadgeLabel } from 'components/common'

type BadgeLabelForReportsProps = {
  isDailyReportUse: boolean
  label: string
}

export const BadgeLabelForReports = ({ isDailyReportUse, label }: BadgeLabelForReportsProps) => (
  <BadgeLabel
    label={label}
    dataSourceLabel={isDailyReportUse ? '日報実績' : '作業計画'}
    dataSourceColor={isDailyReportUse ? 'violet' : 'emerald'}
  />
)
