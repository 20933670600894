import dayjs from 'dayjs'
import { useMemo, useEffect, useState, useCallback } from 'react'

import type { TimeIntervalType } from 'components/common/types'

import useBusinessTime from 'hooks/useBusinessTime'

import { TimeSelect } from './TimeSelect'

type Props = {
  startTime: string
  duration: number
  interval: TimeIntervalType
  onTimeChange: (startHour: string, startMinute: string, endHour: string, endMinute: string) => void
}

export const TimeRangeSelect = (props: Props) => {
  const { startTime, duration, interval, onTimeChange } = props
  const [isStartTimeError, setIsStartTimeError] = useState(false)
  const [isEndTimeError, setIsEndTimeError] = useState(false)
  const { getTimeOver24h, timeRange } = useBusinessTime({ interval })

  useEffect(() => {
    setIsStartTimeError(false)
    setIsEndTimeError(false)
  }, [startTime, duration])

  const [startHour, startMinute, endHour, endMinute] = useMemo(() => {
    const start = dayjs(startTime).local().format('HH:mm')
    const endTime = dayjs(startTime).local().add(duration, 'seconds').format('HH:mm')

    return [...getTimeOver24h(start, true).split(':'), ...getTimeOver24h(endTime).split(':')]
  }, [duration, getTimeOver24h, startTime])

  const handleTimeChange = useCallback(
    (changeStartHour: string, changeStartMinute: string, changeEndHour: string, changeEndMinute: string) => {
      onTimeChange(changeStartHour, changeStartMinute, changeEndHour, changeEndMinute)
    },
    [onTimeChange]
  )

  const checkTimeError = useCallback(
    (changeStartHour: string, changeStartMinute: string, changeEndHour: string, changeEndMinute: string) => {
      const start = dayjs(`${changeStartHour}${changeStartMinute}`, 'HHmm')
      const end = dayjs(`${changeEndHour}${changeEndMinute}`, 'HHmm')

      return start.isSameOrAfter(end)
    },
    []
  )

  const handleStartTimeChange = useCallback(
    (hour: string, minute: string) => {
      const isError = checkTimeError(hour, minute, endHour, endMinute)
      setIsStartTimeError(isError)
      if (!isError) {
        handleTimeChange(hour, minute, endHour, endMinute)
      }
    },
    [checkTimeError, endHour, endMinute, handleTimeChange]
  )

  const handleEndTimeChange = useCallback(
    (hour: string, minute: string) => {
      const isError = checkTimeError(startHour, startMinute, hour, minute)
      setIsEndTimeError(isError)
      if (!isError) {
        handleTimeChange(startHour, startMinute, hour, minute)
      }
    },
    [checkTimeError, handleTimeChange, startHour, startMinute]
  )

  return (
    <>
      <TimeSelect
        hour={startHour}
        minute={startMinute}
        label=""
        onChange={handleStartTimeChange}
        range={timeRange.start}
        roundingInterval={interval}
        isError={isStartTimeError}
      />
      <div className="font-large">~</div>
      <TimeSelect
        hour={endHour}
        minute={endMinute}
        label=""
        onChange={handleEndTimeChange}
        range={timeRange.end}
        roundingInterval={interval}
        isError={isEndTimeError}
      />
    </>
  )
}
