import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import { useMemo, useEffect, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import { selectReportsStatus, getReportPlanAccuracy } from 'slices/reportsSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import { StickyTable, Pagination } from 'components/common'
import type { TableCellType, TableHeaderType } from 'components/common/types'

import { useQuery } from 'hooks/useQuery'
import { useReportsQuery } from 'hooks/useReportsQuery'

type Props = {
  workspaceId?: number
  isDailyReportUse?: boolean
}

const ITEMS_PER_PAGE = 15

const DEFAULT_PAGE = 1

export const ReportPlanAccuracy = ({ workspaceId, isDailyReportUse = false }: Props) => {
  const { queryStart, queryEnd } = useReportsQuery()
  const [queryPage, setQueryPage] = useQuery<number>('page', DEFAULT_PAGE)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { dailyPlanAccuracies, dailyPlanAccuraciesDailyReport } = useSelector(selectReportsStatus, shallowEqual)

  const selectedData = useMemo(() => {
    return isDailyReportUse ? dailyPlanAccuraciesDailyReport : dailyPlanAccuracies
  }, [dailyPlanAccuracies, dailyPlanAccuraciesDailyReport, isDailyReportUse])

  useEffect(() => {
    if (!workspaceId || !tenant) {
      return
    }
    // getWorkDateでbusinessStartTimeを使用するためtenant取得後にAPIを実行する
    if (
      !selectedData ||
      selectedData.workspaceId !== workspaceId ||
      selectedData.from !== queryStart ||
      selectedData.to !== queryEnd
    ) {
      // データが未取得、または取得済みのデータと選択期間が異なる場合にAPIを実行する
      dispatch(getReportPlanAccuracy(workspaceId, queryStart, queryEnd, isDailyReportUse))
    }
  }, [
    dispatch,
    workspaceId,
    tenant,
    isDailyReportUse,
    dailyPlanAccuraciesDailyReport,
    dailyPlanAccuracies,
    selectedData,
    queryStart,
    queryEnd,
  ])

  // 見出しテーブル作成
  const tableHeader: TableHeaderType[] = useMemo(() => {
    const title = [{ value: '日付' }]

    const data =
      selectedData?.data.map(item => ({
        value: item.scheduleTypeName,
      })) || []

    return [...title, ...data]
  }, [selectedData])

  // データテーブル作成
  const tableData: TableCellType[][] = useMemo(() => {
    if (!selectedData?.data?.length) {
      return []
    }

    return selectedData.data[0].data
      .toSorted((a, b) => new Date(b.workDate).getTime() - new Date(a.workDate).getTime())
      .map(({ workDate }) => {
        const formattedDate = `${dayjs(workDate).format('YYYY/MM/DD')}（${dayjs(workDate).locale('ja').format('dd')}）`

        const title = [
          {
            value: formattedDate,
            className: 'text-end',
            onClick: () => navigate(`/reports/${workspaceId}/${workDate}`),
          },
        ]
        const data = selectedData.data.map(scheduleType => {
          const percent = scheduleType.data.find(d => d.workDate === workDate)?.value
          return { value: percent ? `±${percent}%` : '-' }
        })

        return [...title, ...data]
      })
  }, [navigate, selectedData?.data, workspaceId])

  const totalPages = useMemo(() => Math.ceil(tableData.length / ITEMS_PER_PAGE), [tableData])

  const paginatedData = useMemo(() => {
    const startIndex = (queryPage - 1) * ITEMS_PER_PAGE
    return tableData.slice(startIndex, startIndex + ITEMS_PER_PAGE)
  }, [queryPage, tableData])

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryPage(page)
    },
    [setQueryPage]
  )

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="fw-bold">営業日別作業計画変更率</div>
        <div className="mt-3 mw-100 overflow-auto">
          <StickyTable header={tableHeader} body={paginatedData} fixedLeft />
        </div>
        <div className="d-flex justify-content-center mt-3">
          <Pagination currentPage={queryPage} totalPages={totalPages} onClick={handlePageChange} />
        </div>
      </CardBody>
    </Card>
  )
}
