import { Button, ButtonGroup, Col, Label } from 'reactstrap'

import { CONNECTION_TYPES } from 'api/schedule_types/constants'
import type { ConnectionType } from 'api/schedule_types/types'

import { CONNECTION_TYPE_LABEL } from 'components/common/constants'

import styles from './ConnectionTypeButton.module.scss'

type Props = {
  value: ConnectionType
  onChange: (value: ConnectionType) => void
  disabled: boolean
}

const ConnectionTypeButton = ({ value, onChange, disabled }: Props) => (
  <div className="d-flex align-items-center">
    <Label md={4} className="p-0">
      実績入力方法
    </Label>
    <Col md={8}>
      <ButtonGroup size="sm">
        {Object.entries(CONNECTION_TYPES).map(([key, connectionType]) => (
          <Button
            key={key}
            className={`shadow-none ${disabled && value === connectionType ? styles.active : ''}`}
            outline
            onClick={() => onChange(connectionType)}
            active={value === connectionType}
            disabled={disabled}
          >
            {CONNECTION_TYPE_LABEL[connectionType]}
          </Button>
        ))}
      </ButtonGroup>
    </Col>
  </div>
)
export default ConnectionTypeButton
