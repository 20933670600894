import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type {
  AuthToken,
  ActivateParams,
  ConfirmPasswordResetParams,
  LoginParams,
  RefreshParams,
  RequestPasswordResetParams,
  SessionResponse,
  UpdateUserPasswordParams,
} from './types'

export const login = async (data: LoginParams): Promise<SessionResponse> => {
  const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session`

  const response = await axios.post(url, data)
  return response.data
}

export const logout = async (token: AuthToken) => {
  const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session`

  const headers = {
    Authorization: token.idToken,
    'X-Access-Authorization': token.accessToken,
  }

  const response = await axios.delete(url, { headers })
  return response.data
}

export const activate = async (data: ActivateParams): Promise<SessionResponse> => {
  const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session/activate`

  const response = await axios.post(url, data)
  return response.data
}

export const refresh = async (data: RefreshParams): Promise<SessionResponse> => {
  const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session/refresh`

  const response = await axios.post(url, data)
  return response.data
}

export const requestPasswordReset = async (data: RequestPasswordResetParams): Promise<void> => {
  const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session/request-password-reset`

  await axios.post(url, data)
}

export const confirmPasswordReset = async (data: ConfirmPasswordResetParams): Promise<void> => {
  const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session/confirm-password-reset`

  await axios.post(url, data)
}

export const updateUserPassword = async (
  params: CommonParams,
  data: UpdateUserPasswordParams
): Promise<SessionResponse> => {
  const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session/password`

  const headers = {
    Authorization: params.idToken,
    'X-Access-Authorization': params.accessToken,
  }

  const response = await axios.put(url, data, { headers })
  return response.data
}
