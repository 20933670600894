import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'

import { WEEKDAY_SUNDAY } from 'components/common/constants'

import useBusinessTime from './useBusinessTime'
import { useQuery } from './useQuery'

export const useBopReportsQuery = () => {
  const [dateRange, setDateRange] = useQuery<string>('dateRange', '')
  const { getWorkDate } = useBusinessTime()

  // 営業時間前を加味して日付算出する。
  const [queryStart, queryEnd] = useMemo(() => {
    const baseDate = dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')))
    if (dateRange) {
      return dateRange.split(':')
    }
    if (baseDate.day() === WEEKDAY_SUNDAY) {
      return [baseDate.clone().subtract(1, 'day').day(1).format('YYYY-MM-DD'), baseDate.format('YYYY-MM-DD')]
    }
    return [baseDate.clone().day(1).format('YYYY-MM-DD'), baseDate.clone().day(1).add(6, 'day').format('YYYY-MM-DD')]
  }, [getWorkDate, dateRange])

  const setFunc = useCallback(
    (start: string, end: string) => {
      setDateRange(`${start}:${end}`)
    },
    [setDateRange]
  )

  return {
    queryStart,
    queryEnd,
    setDateRange: setFunc,
  }
}
