export const COLOR_TYPES = {
  SILVER: 'silver',
  TWILIGHT: 'twilight',
  SKY: 'sky',
  EMERALD: 'emerald',
  MAGENTA: 'magenta',
  BENGAL: 'bengal',
  MUSTARD: 'mustard',
  ROSE: 'rose',
  VIOLET: 'violet',
  GRASS: 'grass',
} as const
