import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/bop/bop'
import type { BopData, BopResponse, UpdateBopRequest, UpdateBopResponse } from 'api/bop/types'
import * as DashboardAPI from 'api/dashboard/dashboard'
import type { BopProfitAndLossResponse } from 'api/dashboard/types'

import { handleApiError } from './utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type BopState = {
  isRequesting: boolean
  errorMessage: string
  bop: BopData | undefined
  updateBopResponse: UpdateBopResponse | undefined
  profitAndLoss: BopProfitAndLossResponse | undefined
}

const initialState: BopState = {
  isRequesting: false,
  errorMessage: '',
  bop: undefined,
  updateBopResponse: undefined,
  profitAndLoss: undefined,
}

export const bopSlice = createSlice({
  name: 'bop',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getBopSuccess: (state, action: PayloadAction<BopResponse>) => {
      state.isRequesting = false
      state.bop = action.payload.bop
    },
    updateBopSuccess: (state, action: PayloadAction<UpdateBopResponse>) => {
      state.isRequesting = false
      state.updateBopResponse = action.payload
    },
    getBopProfitAndLossSuccess: (state, action: PayloadAction<BopProfitAndLossResponse>) => {
      state.isRequesting = false
      state.profitAndLoss = action.payload
    },
  },
})

export const { startRequest, apiFailure, getBopSuccess, updateBopSuccess, getBopProfitAndLossSuccess } =
  bopSlice.actions

export const getBop =
  (workspaceId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      const res = await API.getBop(workspaceId)
      dispatch(getBopSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const updateBop =
  (workspaceId: number, requestBody: UpdateBopRequest): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      const res = await API.updateBop(workspaceId, requestBody)
      dispatch(updateBopSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const getBopProfitAndLoss =
  (date: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      const res = await DashboardAPI.getBopProfitAndLoss(date)
      dispatch(getBopProfitAndLossSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const selectBopStatus = (state: RootState) => ({ ...state.bop })

export default bopSlice.reducer
