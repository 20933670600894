import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { ROLE } from 'api/users/constants'

import { selectSessionStatus } from 'slices/sessionSlice'

export const TENANT_ADMIN_ID = 0

const useAuthority = (workspaceId?: number) => {
  const { user } = useSelector(selectSessionStatus, shallowEqual)

  const role = useMemo(() => user.userHasTenants[0]?.role, [user])

  const isReadOnlyWorkspace = useMemo(() => {
    if (!user) {
      return true
    }
    if (role === ROLE.ADMIN) {
      return false
    }

    if (workspaceId === undefined) {
      return true
    }

    return !user.userHasTenants[0].managedWorkspaces.some(
      workspace => workspace.isManagement && workspace.id === workspaceId
    )
  }, [user, role, workspaceId])

  const isTenantAdmin = useMemo(() => role === ROLE.TENANT_ADMIN, [role])

  const isAdmin = useMemo(() => role === ROLE.ADMIN, [role])

  const isProcessAdmin = useMemo(() => role === ROLE.PROCESS_ADMIN, [role])

  return { isReadOnlyWorkspace, isTenantAdmin, isAdmin, isProcessAdmin }
}

export default useAuthority
