import { Chart, BadgeLabel } from 'components/common'

import type { Options } from 'highcharts'

type Props = {
  chartOptions?: Options
}

export const BopMonitoringChartProfitMargin = ({ chartOptions }: Props) => {
  if (!chartOptions) {
    return null
  }

  return (
    <>
      <Chart options={chartOptions} />
      <div className="d-flex">
        <BadgeLabel label="利益率" color="success" />
      </div>
    </>
  )
}
