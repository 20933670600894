import dayjs from 'dayjs'
import { range } from 'es-toolkit'
import { floor, ceil } from 'es-toolkit/compat'

import { COLOR_TYPES } from 'api/constants'
import type { ColorType } from 'api/types'

import type { TimeReducerType } from 'components/common/types'

export const NULL_GROUP_ID = 0 // supportedWorkspaceId=null かつ groupId=nullの場合は未所属の扱い
export const LIST_WORKSPACE_ID = -1 // 必ずリストの先頭にworkspaceが入るため、groupIdに衝突しない値を定義
export const SUPPORT_WORKER_GROUP_ID = -2 // 応援作業者のgroupIdは-2
export const SUPPORT_WORKER_GROUP_PREFIX = 'support-group-'
export const NULL_GROUP_NAME = '未所属'
export const ESTIMATE_POINT_PADDING = -0.3

export const formatPositiveNumber = (num: number) => {
  const isPositive = num > 0 ? '+' : ''
  return isPositive + num.toLocaleString()
}

export const getStyledColorClass = (num: number) => (num < 0 ? 'text-danger' : '')
export const colorTypeToCode = (color: ColorType): string => {
  switch (color) {
    case COLOR_TYPES.SILVER:
      return '#868b8d'
    case COLOR_TYPES.TWILIGHT:
      return '#7080d4'
    case COLOR_TYPES.SKY:
      return '#7ac4e5'
    case COLOR_TYPES.EMERALD:
      return '#00be8a'
    case COLOR_TYPES.MAGENTA:
      return '#e242ad'
    case COLOR_TYPES.BENGAL:
      return '#fb8049'
    case COLOR_TYPES.MUSTARD:
      return '#f3b11b'
    case COLOR_TYPES.ROSE:
      return '#f36f8f'
    case COLOR_TYPES.VIOLET:
      return '#945ecf'
    case COLOR_TYPES.GRASS:
      return '#61b326'
    default:
      return '#eeeeee'
  }
}

export const toLighterColorCode = (color: ColorType, amount: number): string => {
  const num = parseInt(colorTypeToCode(color).replace('#', ''), 16)
  // 各成分が255を超えないようにRGBをそれぞれ計算
  const [r, g, b] = [(num >> 16) + amount, ((num >> 8) & 0x00ff) + amount, (num & 0x0000ff) + amount].map(value =>
    Math.min(255, value)
  )
  return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`
}

export const createXAxis = (fromTime: string, toTime: string, every15mins = false, date?: string): string[] => {
  const targetDate = date || dayjs().format('YYYY-MM-DD')

  // fromTim〜toTimeをカバーする0分始点、終点のx軸のデータを生成する。
  const fixStartTime = dayjs(`${targetDate} ${fromTime}`).startOf('hour')
  const endTime = dayjs(`${targetDate} ${toTime}`)
  const fixEndTime = toTime.split(':')[1] === '00' ? endTime : endTime.add(1, 'hour').startOf('hour')
  const businessHourRange = range(fixStartTime.hour(), endTime.diff(fixStartTime, 'hour') + fixStartTime.hour())

  if (every15mins) {
    return businessHourRange
      .flatMap(hour => {
        const time = fixStartTime.hour(hour).startOf('hour')
        return [
          time.toISOString(),
          time.add(15, 'minute').toISOString(),
          time.add(30, 'minute').toISOString(),
          time.add(45, 'minute').toISOString(),
        ]
      })
      .concat(fixEndTime.toISOString())
  }
  // 1時間間隔
  return businessHourRange
    .map(hour => fixStartTime.hour(hour).startOf('hour').toISOString())
    .concat(fixEndTime.toISOString())
}

export type WorkerPerformance = {
  workerId: number
  workerName: string
  workerData: { [key: string]: TimeReducerType }
}

export type GroupPerformance = {
  groupId: number | string
  groupName: string
  groupData: { [key: string]: TimeReducerType }
  workerDataList: WorkerPerformance[]
}

const ceilWithAdjustment = (value: number) => (Object.is(value, -0) ? 0 : value)

type CalculateProductivityType = {
  targetCount: number | null // 目標値
  todayPlanCount: number | null // 1日分の合計計画値
  previousPlanCount: number | null // 15分単位で切り下げた計画値
  planningHour: number | null // 15分単位で切り下げた計画時間の合計
  todayPlanningHour: number | null // 当日の計画時間の合計
  previousRecordCount: number | null // 15分単位で切り下げた実績値
}
export const calculateProductivityMetrics = (data: CalculateProductivityType) => {
  const currentProductivity =
    data.previousRecordCount && data.planningHour
      ? ceilWithAdjustment(ceil(data.previousRecordCount / data.planningHour, 1))
      : 0

  const productivityDifferenceRatio =
    data.previousPlanCount && data.planningHour
      ? ceilWithAdjustment(Math.ceil((currentProductivity / (data.previousPlanCount / data.planningHour) - 1) * 100))
      : 0

  const planGoalForecasting =
    data.todayPlanCount && data.targetCount
      ? ceilWithAdjustment(
          Math.ceil(((100 + productivityDifferenceRatio) / 100) * data.todayPlanCount) - data.targetCount
        )
      : 0

  const forecastShortfallHour =
    data.todayPlanCount && data.todayPlanningHour
      ? ceilWithAdjustment(floor(planGoalForecasting / Math.ceil(data.todayPlanCount / data.todayPlanningHour), 1))
      : 0

  return { currentProductivity, productivityDifferenceRatio, planGoalForecasting, forecastShortfallHour }
}

export const BOP_TYPE = {
  ESTIMATE: 'estimate',
  ACTUAL: 'actual',
}
