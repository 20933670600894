import dayjs from 'dayjs'
import { useCallback } from 'react'
import Popup from 'reactjs-popup'

import type { TenantData } from 'api/tenants/types'

import { TimeScale, ShiftBar } from 'components/common'
import { TENTATIVE_SCHEDULE_TYPE_ID } from 'components/common/constants'
import { getRandomNumber, getShiftBarWidthByDuration } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import { ShiftPopover } from './ShiftPopover'

import styles from './WorkerSchedule.module.scss'

import type { EditShiftsType, WorkPlanSchedulesType } from '../Schedules/types'

type Props = {
  date: string
  editGroups: EditShiftsType[]
  onEditGroupsChange: (items: EditShiftsType[]) => void
  tenantWithDate?: TenantData
}

export const WorkerSchedule = ({ date, editGroups, onEditGroupsChange, tenantWithDate }: Props) => {
  const { businessStartTime, businessDuration, getTimesByShiftBarX, getShiftBarXbyStartTime } = useBusinessTime({
    tenantWithDate,
  })

  const isOpen = (group: string) => editGroups.find(g => g.name === group)?.isOpen

  const handleGroupNameClick = (group: string) => {
    const newEditGroups = editGroups.map(g => (g.name === group ? { ...g, isOpen: !g.isOpen } : g))
    onEditGroupsChange(newEditGroups)
  }

  const onDeleteWorkerScheduleType = (groupName: string, scheduleId: number | null, workerId: number | undefined) => {
    const newEditGroups = editGroups.map(group => {
      if (group.name !== groupName) {
        return group
      }
      const workers = group.workers.map(w => ({
        ...w,
        schedules: w.schedules.filter(s => w.workerId !== workerId || s.scheduleId !== scheduleId),
      }))
      return {
        ...group,
        isOpen: group.isOpen,
        workers,
      }
    })

    onEditGroupsChange(newEditGroups)
  }

  const handleShiftBarAdd = (groupName: string, startPos: number, endPos: number, workerId?: number) => {
    if (!groupName && !workerId) {
      return
    }

    const time = getTimesByShiftBarX(startPos)
    const startAt = dayjs(`${date} ${time.hours}:${time.minutes}`).utc().format()
    const addItem: WorkPlanSchedulesType = {
      scheduleId: getRandomNumber(),
      scheduleTypeId: TENTATIVE_SCHEDULE_TYPE_ID.SHIFT,
      supportWorkspaceId: null,
      supportWorkspaceName: null,
      startAt,
      duration: (endPos - startPos) * 900,
    }

    const newEditGroups = editGroups.map(group => {
      if (group.name !== groupName) {
        return group
      }
      return {
        ...group,
        workers: group.workers.map(w => {
          const getSchedules = () => {
            if (w.workerId === workerId) {
              return [...w.schedules, addItem]
            }
            return w.schedules
          }
          return {
            ...w,
            schedules: getSchedules(),
          }
        }),
      }
    })

    onEditGroupsChange(newEditGroups)
  }

  const getSingleWorkerEditGroup = useCallback(
    (groupName: string, workerId: number, index: number, startAt: string, duration: number) => {
      return editGroups.map(group => {
        if (group.name !== groupName) {
          return group
        }

        const workers = group.workers.map(w => {
          if (w.workerId !== workerId) {
            return w
          }
          return {
            ...w,
            schedules: w.schedules
              .filter(s => s.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
              .map((s, idx) => {
                if (idx === index) {
                  return { ...s, startAt, duration }
                }
                return s
              }),
          }
        })
        return {
          ...group,
          workers,
        }
      })
    },
    [editGroups]
  )

  const handleWorkerShiftBarChange = useCallback(
    (groupName: string, workerId: number, index: number, x: number, width: number) => {
      const time = getTimesByShiftBarX(x)
      const startAt = dayjs(`${date} ${time.hours}:${time.minutes}`).utc().format()
      const duration = width * 900
      const newEditGroups = getSingleWorkerEditGroup(groupName, workerId, index, startAt, duration)

      onEditGroupsChange(newEditGroups)
    },
    [date, getSingleWorkerEditGroup, getTimesByShiftBarX, onEditGroupsChange]
  )

  const handleTimeChange = useCallback(
    (
      groupName: string,
      workerId: number,
      index: number,
      changeStartHour: string,
      changeStartMinute: string,
      changeEndHour: string,
      changeEndMinute: string
    ) => {
      const startDate = dayjs().hour(Number(changeStartHour)).minute(Number(changeStartMinute))
      const startX = getShiftBarXbyStartTime(startDate.toISOString(), dayjs().format('YYYY-MM-DD'))
      const endDate = dayjs().hour(Number(changeEndHour)).minute(Number(changeEndMinute))
      const endX = getShiftBarXbyStartTime(endDate.toISOString(), dayjs().format('YYYY-MM-DD'))

      handleWorkerShiftBarChange(groupName, workerId, index, startX, endX - startX)
    },
    [getShiftBarXbyStartTime, handleWorkerShiftBarChange]
  )

  const getShiftBarItems = (groupName: string, schedules: WorkPlanSchedulesType[], workerId: number) =>
    schedules.map((schedule, index) => {
      const x = getShiftBarXbyStartTime(schedule.startAt, date)
      const width = getShiftBarWidthByDuration(schedule.duration)
      const id = schedule.scheduleId || getRandomNumber()

      return {
        id: id.toString(),
        content: (
          <ShiftPopover
            label={`勤務時間${index + 1}`}
            startTime={schedule.startAt}
            duration={schedule.duration}
            onDelete={() => onDeleteWorkerScheduleType(groupName, schedule.scheduleId, workerId)}
            onTimeChange={(startHour, startMinute, endHour, endMinute) =>
              handleTimeChange(groupName, workerId, index, startHour, startMinute, endHour, endMinute)
            }
            key={id}
          />
        ),
        x,
        width,
      }
    })

  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead>
          <tr className={styles.timeHeader}>
            <td className={`bg-secondary-pale px-4 ${styles.tableHeader}`}>名前</td>
            <td className="p-0">
              <TimeScale tenantWithDate={tenantWithDate} />
            </td>
          </tr>
        </thead>
        {editGroups.map(({ groupId, name, workers }, index) => (
          <tbody key={`group-${groupId}-${index}`}>
            <tr className={styles.tableRow}>
              <td className={styles.groupContent}>
                <Popup
                  trigger={
                    <div className="d-flex align-items-center px-4" onClick={() => handleGroupNameClick(name)}>
                      <i className={`icf-carot_${isOpen(name) ? 'down' : 'right'} me-1`} />
                      <div className="text-truncate">{name}</div>
                    </div>
                  }
                  position="bottom center"
                  on="hover"
                  arrowStyle={{ color: 'black' }}
                >
                  <span className="text-white bg-black p-1 px-3 rounded font-small">
                    {name}:{workers.length}人
                  </span>
                </Popup>
              </td>
            </tr>
            {isOpen(name) &&
              workers.map((worker, i) => (
                <tr key={`worker-${worker.workerId}-${i}`} className={styles.tableRow}>
                  <td className={`${styles.tableHeader} ${styles.workerName}`}>
                    <div className="d-flex align-items-center">
                      <div className="text-truncate me-auto">{worker.name}</div>
                    </div>
                  </td>

                  <td className={styles.tableContent}>
                    <ShiftBar
                      items={getShiftBarItems(name, worker.schedules, worker.workerId)}
                      businessStartTime={businessStartTime}
                      shiftBarWidth={businessDuration}
                      onAdd={(startPos, endPos) => handleShiftBarAdd(name, startPos, endPos, worker.workerId)}
                      onChange={(idx, x, width) => handleWorkerShiftBarChange(name, worker.workerId, idx, x, width)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        ))}
      </table>
    </div>
  )
}
