import { isEqual, maxBy } from 'es-toolkit'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText, CardColumns } from 'reactstrap'

import { COLOR_TYPES } from 'api/constants'
import { CONNECTION_TYPES } from 'api/schedule_types/constants'
import type { ConnectionType, ScheduleTypeData, ScheduleTypeEditData } from 'api/schedule_types/types'
import type { SkillData } from 'api/skills/types'

import { showError, showSuccess } from 'slices/notificationSlice'
import {
  getScheduleTypeList,
  getScheduleType,
  selectScheduleTypesStatus,
  updateScheduleType,
  createScheduleType,
} from 'slices/scheduleTypesSlice'
import { getSkillList, selectSkillsStatus } from 'slices/skillsSlice'
import { getDataConnectionInfo } from 'slices/tenantsSlice'
import { ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'
import { getWorkspace, selectWorkspacesStatus } from 'slices/workspacesSlice'

import {
  NavMenu,
  List,
  InputGroupFormat,
  InputFormat,
  SelectBoxFormat,
  ColorPickerFormat,
  CardSubmitFooter,
  CustomButton,
  ItemEdit,
  BadgeLabel,
  CheckBoxFormat,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'
import type { SuggestionItem, ListItem } from 'components/common/types'

import useAuthority from 'hooks/useAuthority'
import useScheduleType from 'hooks/useScheduleType'

import placeholder from 'images/allEmpty.svg'

import ConnectionTypeButton from './ConnectionTypeButton'
import FilterEdit from './FilterEdit'
import ScheduleTypeDelete from './ScheduleTypeDelete'

import styles from './WorkspaceDetail.module.scss'

const onDetailClick = () => window.open('https://help.smileboard.jp/setup_work', '_blank')

const NEW_SCHEDULE_TYPE_ID = 0

type EnhancedScheduleType = ScheduleTypeData & {
  requiredSkills: SkillData[]
}

const WorkspaceDetail = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)

  const [initEditData, setInitEditData] = useState<ScheduleTypeEditData | undefined>()
  const [selectedScheduleTypeId, setSelectedScheduleTypeId] = useState<number | undefined>(undefined)
  const [scheduleTypeListItems, setScheduleTypeListItems] = useState<ListItem[]>([])
  const [submitted, setSubmitted] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)

  const { workspace } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  const { partialScheduleTypes, scheduleType, scheduleTypeWorkspaceId, isRequesting, errorMessage } = useSelector(
    selectScheduleTypesStatus,
    shallowEqual
  )

  const hasScheduleTypeListItems = useMemo(() => scheduleTypeListItems.length > 0, [scheduleTypeListItems])

  const dispatch = useDispatch()

  const isNewItem = useMemo(() => selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID, [selectedScheduleTypeId])

  const {
    setNameValidity,
    setTargetDatabaseValidity,
    setTargetColumnValidity,
    setUnitValidity,
    setDefaultProductivityIndexValidity,
    disabled,
    editData,
    setEditData,
    scheduleTypeUpdateData,
    targetDatabases,
    targetColumns,
    filterColumns,
    initScheduleTypeEditData,
  } = useScheduleType()

  const { isReadOnlyWorkspace } = useAuthority(workspaceId)

  useEffect(() => {
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, workspaceId])

  useEffect(() => {
    dispatch(getDataConnectionInfo())
    dispatch(getSkillList())
  }, [dispatch])

  // scheduleType.requiredSkillIdsとマッチするskillsを、requiredSkillsに代入する（idとnameを使用するため）
  const enhancedScheduleType = useMemo(() => {
    if (!scheduleType || !skills) {
      return
    }
    return {
      ...scheduleType,
      requiredSkills: skills.filter(skill => scheduleType.requiredSkillIds.includes(skill.id)),
    }
  }, [scheduleType, skills])

  useEffect(() => {
    if (selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID) {
      setEditData(initScheduleTypeEditData)
      return
    }
    if (!selectedScheduleTypeId || !workspaceId) {
      return
    }
    dispatch(getScheduleType(workspaceId, selectedScheduleTypeId))
  }, [workspaceId, selectedScheduleTypeId, dispatch, setEditData, initScheduleTypeEditData])

  useEffect(() => {
    // 前WSのpartialScheduleTypesが残っているため、workspaceIdとscheduleTypeWorkspaceIdが不一致の間はsetScheduleTypeListItemsしない
    if (workspaceId !== scheduleTypeWorkspaceId) {
      return
    }
    setScheduleTypeListItems(
      partialScheduleTypes.map(s => {
        return {
          id: s.id,
          title: s.name,
          option: <div className={`${styles.square} bg-${s.color}`} />,
        }
      })
    )
  }, [partialScheduleTypes, workspaceId, scheduleTypeWorkspaceId])

  useEffect(() => {
    if (scheduleTypeListItems.length === 0) {
      return
    }

    setSelectedScheduleTypeId(prev => {
      // 追加アイテムを保存した時
      if (prev === NEW_SCHEDULE_TYPE_ID && !scheduleTypeListItems.some(item => item.id === NEW_SCHEDULE_TYPE_ID)) {
        return Number(maxBy(scheduleTypeListItems, item => item.id)!.id)
      }
      // 初期化時とアイテム削除時
      if (prev === undefined || !scheduleTypeListItems.some(item => item.id === prev)) {
        return Number(scheduleTypeListItems[0].id)
      }
      return prev
    })
  }, [scheduleTypeListItems])

  const updateEditData = useCallback(
    (data: EnhancedScheduleType | undefined) => {
      const newEditData = data
        ? {
            name: data.name,
            color: data.color,
            requiredSkills: data.requiredSkills,
            connectionType: data.connectionType,
            dataConnection: data.dataConnection,
            targetDatabase: data.targetDatabase ?? undefined,
            targetColumn: data.targetColumn ?? undefined,
            unit: data.unit ?? undefined,
            filters: data.filters,
            defaultProductivity: data.defaultProductivity?.toString(),
            isKey: data.isKey,
            isCostless: data.isCostless,
          }
        : initScheduleTypeEditData

      setEditData(newEditData)
      setInitEditData(newEditData)
    },
    [setEditData, setInitEditData, initScheduleTypeEditData]
  )

  useEffect(() => {
    if (selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID) {
      setEditData({
        name: undefined,
        color: COLOR_TYPES.SILVER,
        requiredSkills: [],
        connectionType: CONNECTION_TYPES.NONE,
        dataConnection: false,
        targetDatabase: undefined,
        targetColumn: undefined,
        unit: undefined,
        filters: [],
        isKey: false,
        isCostless: false,
      })
      return
    }
    updateEditData(enhancedScheduleType)
  }, [selectedScheduleTypeId, enhancedScheduleType, updateEditData, setEditData])

  const unchanged = useMemo(() => isEqual(initEditData, editData), [initEditData, editData])

  const handleItemSelect = useCallback((id: number) => {
    setSelectedScheduleTypeId(prevScheduleTypeId => {
      if (prevScheduleTypeId === id) {
        return prevScheduleTypeId
      }
      if (prevScheduleTypeId === NEW_SCHEDULE_TYPE_ID) {
        setScheduleTypeListItems(prev => prev.filter(item => item.id !== NEW_SCHEDULE_TYPE_ID))
      }

      return id
    })
  }, [])

  const onSubmit = () => {
    if (selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID && scheduleTypeUpdateData) {
      setSubmitted(true)
      scheduleTypeUpdateData && dispatch(createScheduleType(workspaceId, scheduleTypeUpdateData))
      return
    }

    if (!scheduleTypeUpdateData || !scheduleType) {
      return
    }

    setSubmitted(true)
    dispatch(updateScheduleType(workspaceId, scheduleType.id, scheduleTypeUpdateData))
  }

  const onCancel = useCallback(() => {
    if (selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID) {
      setScheduleTypeListItems(prev => prev.filter(item => item.id !== NEW_SCHEDULE_TYPE_ID))
      const newId = partialScheduleTypes.length > 0 && partialScheduleTypes[0].id
      setSelectedScheduleTypeId(newId || undefined)
    }
    updateEditData(enhancedScheduleType)
  }, [partialScheduleTypes, selectedScheduleTypeId, enhancedScheduleType, updateEditData])

  const onAddNewItem = useCallback(() => {
    setScheduleTypeListItems(prev =>
      prev.concat({
        title: '',
        id: NEW_SCHEDULE_TYPE_ID,
        option: <div className={`${styles.square} bg-${COLOR_TYPES.SILVER}`} />,
      })
    )
    setSelectedScheduleTypeId(NEW_SCHEDULE_TYPE_ID)
  }, [])

  const handleConnectionTypeChange = (value: ConnectionType) => {
    const dataConnection = value !== CONNECTION_TYPES.NONE
    editData &&
      setEditData({
        ...editData,
        connectionType: value,
        dataConnection,
        targetDatabase: undefined,
        targetColumn: undefined,
        unit: undefined,
        filters: [],
        defaultProductivity: undefined,
        isKey: false,
      })
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
      dispatch(getScheduleTypeList(workspaceId))
    } else {
      if (errorMessage === ERROR_STATUS_CODE.CONFLICT) {
        dispatch(showError({ errorMessage: '登録上限に達しているため、保存できませんでした。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, workspaceId])

  const handleSkillEdit = (items: SuggestionItem[]) => {
    if (!editData) {
      return
    }
    const requiredSkills = skills.filter(s => items.some(i => i.id === s.id))
    setEditData({ ...editData, requiredSkills })
  }

  const handleDelete = () => {
    setOpenDelete(false)
    dispatch(showSuccess())
  }

  return (
    <>
      <NavMenu>
        <div className="mt-3 mx-3">
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="font-x-large flex-grow-1 fw-bold">作業管理</div>
                <div className="px-2">{workspace && <BadgeLabel label={workspace?.name || ''} />}</div>
              </div>
              <CustomButton
                icon="plus"
                disabled={isReadOnlyWorkspace || scheduleTypeListItems.some(item => item.id === NEW_SCHEDULE_TYPE_ID)}
                className="ms-2"
                onClick={onAddNewItem}
              >
                作業の追加
              </CustomButton>
            </div>
          </div>
          <Row className={styles.row}>
            <Col md={4} className="h-100">
              <Card className={`h-100 ${styles.list}`}>
                {hasScheduleTypeListItems ? (
                  <List items={scheduleTypeListItems} selectedId={selectedScheduleTypeId} onAction={handleItemSelect} />
                ) : (
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                      <div className="font-middle fw-bold py-4">作業がまだ登録されていません</div>
                      <div>
                        作業を登録することで、メンバーの予定を作ったり、作業毎の生産性を見ることができます。まずは最初の作業を登録してみましょう。
                      </div>
                      <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                        作業についてもっと詳しく
                      </Button>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
            <Col md={8} className="h-100">
              <Card className="h-100">
                {hasScheduleTypeListItems ? (
                  <>
                    <div className="h-100 overflow-auto">
                      <CardBody>
                        <CardColumns className="d-flex">
                          <CardTitle className="font-large flex-grow-1 fw-bold">作業の詳細</CardTitle>
                          <span className="font-x-small text-muted">※必須項目</span>
                        </CardColumns>
                        <InputFormat
                          label="名称※"
                          placeholder="作業名を入力"
                          value={editData.name}
                          size={COLUMN_SIZES.MIDDLE}
                          maxLength={100}
                          disabled={isReadOnlyWorkspace}
                          onChange={value => setEditData({ ...editData, name: value })}
                          validations={[Rules.Required]}
                          onValidate={setNameValidity}
                          className="mb-3"
                        />
                        <ColorPickerFormat
                          label="キーカラー"
                          color={editData.color}
                          size={COLUMN_SIZES.MIDDLE}
                          disabled={isReadOnlyWorkspace}
                          onChange={color => setEditData({ ...editData, color })}
                        />
                      </CardBody>

                      <CardBody>
                        <CardTitle className="font-large fw-bold">必須スキル設定</CardTitle>
                        <CardText className="py-2">
                          作業の必須スキルを設定しておくことで、対象のスキルを持っていないメンバーの予定に作業が入れられた際に
                          アラートを出します。（アラートは出ますが予定に入れることは可能です。）
                        </CardText>
                        <ItemEdit
                          items={skills.map(s => ({ id: s.id, value: s.name }))}
                          selectedItems={editData.requiredSkills.map(s => ({ id: s.id, value: s.name }))}
                          label="作業に必須スキルを追加"
                          itemName="スキル"
                          onChange={handleSkillEdit}
                        />
                      </CardBody>

                      <CardBody className="pb-0">
                        <CardTitle className="font-large fw-bold">実績入力方法</CardTitle>

                        {isNewItem && (
                          <div className="mt-2 mb-1">
                            実績をSmile Board Connectに入力する方法を選択してください
                            <div className="text-danger">登録後の実績入力方法の変更はできません。</div>
                          </div>
                        )}
                        <div className="my-3">
                          <ConnectionTypeButton
                            value={editData.connectionType}
                            onChange={handleConnectionTypeChange}
                            disabled={
                              selectedScheduleTypeId !== NEW_SCHEDULE_TYPE_ID &&
                              initEditData?.connectionType !== CONNECTION_TYPES.NONE
                            }
                          />
                        </div>
                        {editData.connectionType === CONNECTION_TYPES.AUTO && (
                          <>
                            <CardText>抽出条件の設定</CardText>
                            <Card>
                              <CardBody>
                                <SelectBoxFormat
                                  className="mb-0"
                                  label="参照するデータベース※"
                                  placeholder="データベースを選択"
                                  value={editData.targetDatabase}
                                  size={COLUMN_SIZES.MIDDLE}
                                  items={targetDatabases}
                                  disabled={isReadOnlyWorkspace}
                                  onChange={e =>
                                    setEditData({
                                      ...editData,
                                      targetDatabase: e.key?.toString(),
                                      targetColumn: undefined,
                                      filters: [],
                                    })
                                  }
                                  validations={[Rules.Required]}
                                  onValidate={setTargetDatabaseValidity}
                                />
                              </CardBody>
                              <hr className="m-0" />
                              <CardBody>
                                <SelectBoxFormat
                                  label="実績が記載されている列※"
                                  placeholder="列を選択"
                                  value={targetColumns.find(t => t.key === editData.targetColumn)?.key}
                                  size={COLUMN_SIZES.MIDDLE}
                                  items={targetColumns}
                                  disabled={isReadOnlyWorkspace}
                                  onChange={e =>
                                    setEditData({
                                      ...editData,
                                      targetColumn: e.key?.toString(),
                                    })
                                  }
                                  validations={[Rules.Required]}
                                  onValidate={setTargetColumnValidity}
                                />
                                <InputFormat
                                  className="mt-3"
                                  label="実績の単位※"
                                  placeholder="単位を入力"
                                  value={editData.unit}
                                  size={COLUMN_SIZES.MIDDLE}
                                  maxLength={10}
                                  disabled={isReadOnlyWorkspace}
                                  onChange={value => setEditData({ ...editData, unit: value })}
                                  validations={[Rules.Required]}
                                  onValidate={setUnitValidity}
                                />
                              </CardBody>

                              <hr className="m-0"></hr>
                              <CardBody>
                                <CardTitle className="font-large fw-bold">フィルター</CardTitle>
                                <div className="pb-3">
                                  伝票種別などに応じて、より詳細にフィルタリングすることができます。
                                </div>
                                <FilterEdit
                                  filterColumns={filterColumns}
                                  filter={editData.filters}
                                  disabled={isReadOnlyWorkspace}
                                  onChange={filters => setEditData({ ...editData, filters })}
                                />
                              </CardBody>
                            </Card>
                          </>
                        )}
                        {editData.connectionType === CONNECTION_TYPES.MANUAL && (
                          <InputFormat
                            className="my-3"
                            label="実績の単位※"
                            placeholder="単位を入力"
                            value={editData.unit}
                            size={COLUMN_SIZES.MIDDLE}
                            maxLength={10}
                            disabled={isReadOnlyWorkspace}
                            onChange={value => setEditData({ ...editData, unit: value })}
                            validations={[Rules.Required]}
                            onValidate={setUnitValidity}
                          />
                        )}
                      </CardBody>
                      {editData.connectionType !== CONNECTION_TYPES.NONE && (
                        <>
                          <CardBody>
                            <CardTitle className="font-large fw-bold">デフォルトの人時生産性設定</CardTitle>
                            <CardText>
                              この作業の過去実績がない場合に設定されるデフォルトの人時生産性を設定できます。
                              過去実績がなくても作業者管理で各作業者に手動で人時生産性を設定することもできます。
                            </CardText>
                            <InputGroupFormat
                              label="デフォルト値※"
                              addonText={`${editData.unit || '-'}/時間`}
                              value={editData.defaultProductivity}
                              disabled={isReadOnlyWorkspace}
                              maxLength={10}
                              onChange={value =>
                                setEditData({
                                  ...editData,
                                  defaultProductivity: value,
                                })
                              }
                              validations={[
                                Rules.Required,
                                Rules.ValidateNumberWithMaxCharacters({ maxCharacters: 10, decimalPlaces: 1 }),
                              ]}
                              onValidate={setDefaultProductivityIndexValidity}
                            />
                          </CardBody>
                          <CardBody>
                            <CardTitle className="font-large fw-bold">キー作業設定</CardTitle>
                            <CardText>
                              キー作業に設定すると、同一ワークスペースの他の作業と配賦設定できるようになります。
                            </CardText>
                            <CheckBoxFormat
                              label="キー作業設定"
                              checkboxLabel="この作業をキー作業にする"
                              checked={editData.isKey}
                              disabled={isReadOnlyWorkspace || editData.isCostless}
                              onChange={e => setEditData({ ...editData, isKey: e.target.checked })}
                            />
                          </CardBody>
                        </>
                      )}

                      <CardBody>
                        <CardTitle className="font-large fw-bold">費用外作業設定</CardTitle>
                        <CardText>
                          費用外作業に設定すると、設定された作業の作業時間は費用に換算されません。休憩時間等に設定してください。
                        </CardText>
                        <CheckBoxFormat
                          label="費用外作業設定"
                          checkboxLabel="この作業を費用外作業にする"
                          checked={editData.isCostless}
                          disabled={isReadOnlyWorkspace || editData.isKey}
                          onChange={e => setEditData({ ...editData, isCostless: e.target.checked })}
                        />
                      </CardBody>

                      <CardBody>
                        <CardTitle className="font-large fw-bold">作業の削除</CardTitle>
                        <CardText className="py-2">
                          作業を削除すると、作業履歴などの情報はすべて失われ、復旧できません。
                        </CardText>
                        <Button
                          outline
                          color="danger"
                          disabled={isReadOnlyWorkspace || selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID}
                          onClick={() => setOpenDelete(true)}
                        >
                          作業を削除
                        </Button>
                      </CardBody>
                    </div>
                    <CardSubmitFooter
                      onCancel={onCancel}
                      onSubmit={onSubmit}
                      submitDisabled={disabled || unchanged || isReadOnlyWorkspace}
                      cancelDisabled={unchanged}
                      updatedBy={isNewItem ? undefined : scheduleType?.updatedByName}
                      updatedAt={isNewItem ? undefined : scheduleType?.updatedAt}
                    />
                  </>
                ) : (
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                      <div className="font-middle fw-bold py-4">作業が選択されていません</div>
                      <div>作業を選択して、詳細情報を編集しましょう。</div>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>

          <ScheduleTypeDelete
            isOpen={openDelete}
            workspaceId={workspaceId}
            scheduleTypeId={selectedScheduleTypeId}
            onSuccess={handleDelete}
            onCancel={() => setOpenDelete(false)}
          />
        </div>
      </NavMenu>
    </>
  )
}

export default WorkspaceDetail
