import type { WorkspaceSummaryResponse, HourlyWorkerRow } from 'api/dashboard/types'

import type { Dayjs } from 'dayjs'

export const NO_GROUP_ID = 0

export const getWorkerPerformance = (
  workspaceSummary: WorkspaceSummaryResponse | undefined,
  groupId: number,
  workerId: number,
  openDayjs?: Dayjs
): 'bad' | 'normal' | 'good' => {
  const targetHour = openDayjs ? openDayjs.hour() - 1 : new Date().getHours() - 1
  const data =
    workspaceSummary?.workspaceData.groups
      .find(group => (groupId === NO_GROUP_ID ? group.groupId === null : group.groupId === groupId))
      ?.workers?.find(worker => worker.workerId === workerId)
      ?.hourlyWorkerData.flatMap<HourlyWorkerRow>(d => d.data)
      .filter(d => new Date(d.time).getHours() === targetHour) || []
  const totalPlanCount = data.reduce((acc, cur) => acc + (cur.planCount || 0), 0)
  const totalRecordCount = data.reduce((acc, cur) => acc + (cur.recordCount || 0), 0)
  const performance = totalPlanCount === 0 ? 0 : Math.floor((totalRecordCount / totalPlanCount) * 100)
  if (totalPlanCount === 0 && totalRecordCount === 0) {
    return 'normal'
  } else if (performance < 90) {
    return 'bad'
  } else if (111 <= performance) {
    return 'good'
  }
  return 'normal'
}
