import { useMemo } from 'react'

import CustomModal from 'components/common/CustomModal/CustomModal'
import { StickyTable } from 'components/common/Table/StickyTable'

type Props = {
  isOpen: boolean
  onCloseDialogClick: () => void
  fileInfoLabel: string
  preview: string[][]
}

export const ImportFilePreviewDialog = ({ isOpen, onCloseDialogClick, preview, fileInfoLabel }: Props) => {
  const header = useMemo(() => preview[0].map(value => ({ value })), [preview])
  const body = useMemo(() => preview.slice(1).map(row => row.map(value => ({ value }))), [preview])

  return (
    <CustomModal isOpen={isOpen} onCancel={onCloseDialogClick} showApprove={false} title={'ファイル内容のプレビュー'}>
      <div className="d-flex flex-column row-gap-3">
        <div>先頭10行のみ表示します。全て確認したい場合はファイルをダウンロードしてください。</div>
        <div className="font-x-small text-muted">{fileInfoLabel}</div>
        <div className="w-100 overflow-auto">
          <StickyTable header={header} body={body} />
        </div>
      </div>
    </CustomModal>
  )
}
