import { CONNECTION_TYPES } from 'api/schedule_types/constants'
import { ROLE } from 'api/users/constants'
import { WORKER_TYPES } from 'api/workers/constants'

export const MAGIQANNEAL_APPLICATION_ID = 1

export const WEEKDAY_SUNDAY = 0

export const CELL_WIDTH = 20
export const CELL_WIDTH_5MIN = 24

const UNKNOWN_ACCOUNT_TYPE_LABEL = '不明'
export const ACCOUNT_TYPE_LABEL = {
  [ROLE.ROOT]: UNKNOWN_ACCOUNT_TYPE_LABEL,
  [ROLE.TENANT_ADMIN]: 'サービス管理者',
  [ROLE.ADMIN]: 'オーナー',
  [ROLE.PROCESS_ADMIN]: 'オペレーター',
  [ROLE.TEAM_ADMIN]: UNKNOWN_ACCOUNT_TYPE_LABEL,
}

export const CONNECTION_TYPE_LABEL = {
  [CONNECTION_TYPES.NONE]: '実績なし',
  [CONNECTION_TYPES.AUTO]: '自動アップロード',
  [CONNECTION_TYPES.MANUAL]: '手動アップロード',
}

export const TENTATIVE_SCHEDULE_TYPE_ID = {
  UNSELECTED: 0,
  SHIFT: -1,
  SUPPORT: -3,
}

export const CHARACTER_CODE_TYPES = {
  SHIFT_JIS: 'SHIFT_JIS',
  UTF8: 'UTF-8',
} as const

export const COLUMN_SIZES = {
  X_SHORT: 3,
  SHORT: 4,
  MIDDLE: 6,
  LARGE: 8,
  X_LARGE: 9,
  FULL: 12,
} as const

export const WORKER_TYPES_LABEL = [
  {
    key: WORKER_TYPES.REGULAR_MEMBER,
    value: 'レギュラーメンバー',
    description: '',
  },
  {
    key: WORKER_TYPES.SPOT_MEMBER,
    value: 'スポットメンバー',
    description:
      'スポットメンバーとして登録した場合、所属ワークスペース、所属グループ、識別子はスポットメンバー管理から営業日毎に登録してください。',
  },
]

export const TIME_INTERVAL = {
  FIVE: 5,
  FIFTEEN: 15,
}

export const PREFECTURE_ITEMS = [
  { value: '北海道' },
  { value: '青森県' },
  { value: '岩手県' },
  { value: '宮城県' },
  { value: '秋田県' },
  { value: '山形県' },
  { value: '福島県' },
  { value: '茨城県' },
  { value: '栃木県' },
  { value: '群馬県' },
  { value: '埼玉県' },
  { value: '千葉県' },
  { value: '東京都' },
  { value: '神奈川県' },
  { value: '新潟県' },
  { value: '富山県' },
  { value: '石川県' },
  { value: '福井県' },
  { value: '山梨県' },
  { value: '長野県' },
  { value: '岐阜県' },
  { value: '静岡県' },
  { value: '愛知県' },
  { value: '三重県' },
  { value: '滋賀県' },
  { value: '京都府' },
  { value: '大阪府' },
  { value: '兵庫県' },
  { value: '奈良県' },
  { value: '和歌山県' },
  { value: '鳥取県' },
  { value: '島根県' },
  { value: '岡山県' },
  { value: '広島県' },
  { value: '山口県' },
  { value: '徳島県' },
  { value: '香川県' },
  { value: '愛媛県' },
  { value: '高知県' },
  { value: '福岡県' },
  { value: '佐賀県' },
  { value: '長崎県' },
  { value: '熊本県' },
  { value: '大分県' },
  { value: '宮崎県' },
  { value: '鹿児島県' },
  { value: '沖縄県' },
]
