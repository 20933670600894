import { Card, CardBody, CardSubtitle, CardText } from 'reactstrap'

import suspendedIcon from './images/suspended.svg'

import styles from './styles.module.scss'

const Suspension = () => {
  return (
    <div className="d-flex vh-100 align-items-center justify-content-center">
      <Card className={styles.card}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={suspendedIcon} alt="" />
        </div>

        <CardBody className="text-center">
          <CardSubtitle tag="h4" className="fw-bold">
            ご利用を一時停止中
          </CardSubtitle>

          <CardText className="mt-4">
            スマイルボードのご利用を停止させていただいております。お問い合わせは下記サポートまでお問い合わせいただけますでしょうか。
          </CardText>

          <CardText>
            お問い合わせ
            <br />
            cs@smile-board.co.jp
          </CardText>
        </CardBody>
      </Card>
    </div>
  )
}

export default Suspension
