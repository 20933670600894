import dayjs from 'dayjs'
import { useMemo } from 'react'

import useBusinessTime from './useBusinessTime'
import { useQuery } from './useQuery'

export const useReportsQuery = () => {
  const { getPeriod } = useBusinessTime()
  const { start, end } = getPeriod()
  const [dateRange] = useQuery('dateRange', `${dayjs(start).format('YYYY-MM-DD')}:${dayjs(end).format('YYYY-MM-DD')}`)
  const [queryStart, queryEnd] = useMemo(() => dateRange.split(':'), [dateRange])

  return {
    queryStart,
    queryEnd,
  }
}
