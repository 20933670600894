import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const BOOLEAN_TRUE = '1'
const BOOLEAN_FALSE = '0'

export const useQuery = <T extends string | number | boolean = string>(
  name: string,
  initParameter: T
): [T, (v: T | null) => void] => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const value = useMemo(() => {
    const params = new URLSearchParams(search)
    const param = params.get(name)

    if (param === null) {
      return initParameter
    }

    switch (typeof initParameter) {
      case 'number': {
        const num = Number(param)
        return !isNaN(num) ? (num as T) : initParameter
      }
      case 'boolean':
        return param === BOOLEAN_TRUE || param === BOOLEAN_FALSE ? ((param === BOOLEAN_TRUE) as T) : initParameter
      default:
        return param as T
    }
  }, [initParameter, name, search])

  const setValue = useCallback(
    (v: T | null) => {
      const newParams = new URLSearchParams(search)

      if (v === null) {
        newParams.delete(name)
      } else {
        switch (typeof v) {
          case 'number':
            newParams.set(name, v.toString())
            break
          case 'boolean':
            newParams.set(name, v ? BOOLEAN_TRUE : BOOLEAN_FALSE)
            break
          default:
            newParams.set(name, v)
            break
        }
      }

      navigate(`?${newParams.toString()}`, { replace: true })
    },
    [name, navigate, search]
  )

  return [value, setValue]
}
