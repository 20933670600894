import { floor } from 'es-toolkit/compat'
import { pick, sortBy } from 'lodash'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'

import { BadgeLabel } from 'components/common'
import { TENTATIVE_SCHEDULE_TYPE_ID } from 'components/common/constants'

import useAssignment from 'hooks/useAssignment'

import WorkerCard from './WorkerCard'
import WorkerReassign from './WorkerReassign'

import styles from './DroppingWorkerCards.module.scss'

import type { Worker, DropdownScheduleType } from './WorkerCard'
import type { MouseEvent } from 'react'
import type { DropResult, DragStart } from 'react-beautiful-dnd'

type WorkerWithProductivity = Worker & {
  partialHourlyProductivities?: {
    scheduleTypeId: number
    value: number | null
  }[]
}

export type ScheduleEditType = DropdownScheduleType & {
  workers: WorkerWithProductivity[]
}

type selectedType = {
  droppableType: string
  ids: string[]
}

const INIT_SELECTED_SCHEDULE_TYPE: DropdownScheduleType = {
  type: '',
  label: '',
  scheduleTypeId: null,
  defaultProductivity: null,
  unit: null,
}

type Props = {
  scheduleEditData: ScheduleEditType[]
  otherWorkspaces: DropdownScheduleType[]
  currentTime: string
  isOpenSidebar?: boolean
  setScheduleEditData: (data: ScheduleEditType[]) => void
}

const DroppingWorkerCards = (props: Props) => {
  const { scheduleEditData, otherWorkspaces, currentTime, isOpenSidebar = true, setScheduleEditData } = props
  const [selectedWorkers, setSelectedWorkers] = useState<selectedType>({ droppableType: '', ids: [] })
  const [selectedScheduleType, setSelectedScheduleType] = useState<DropdownScheduleType>(INIT_SELECTED_SCHEDULE_TYPE)
  const [assignWorkers, setAssignWorkers] = useState<Worker[]>([])
  const [draggingId, setDraggingId] = useState<string | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)

  const [isUnscheduledWorkerViewOpen, setIsUnscheduledWorkerViewOpen] = useState(false)

  const { PREFIX_WORKSPACE_ID } = useAssignment()

  const handleClickUnscheduledWorkerViewOpenButton = useCallback(() => {
    setIsUnscheduledWorkerViewOpen(prevState => !prevState)
  }, [])

  const schedules: DropdownScheduleType[] = useMemo(
    () =>
      scheduleEditData
        .filter(s => s.type !== TENTATIVE_SCHEDULE_TYPE_ID.UNSELECTED.toString() && s.color)
        .map(s => pick(s, ['type', 'label', 'color', 'skillIds', 'scheduleTypeId', 'defaultProductivity', 'unit'])),
    [scheduleEditData]
  )

  useEffect(() => setSelectedWorkers({ droppableType: '', ids: [] }), [currentTime, scheduleEditData])

  const onDropDownMenuClick = useCallback(
    (schedule: DropdownScheduleType) => {
      setSelectedScheduleType(schedule)
      setAssignWorkers(
        scheduleEditData
          .find(edit => edit.type === selectedWorkers.droppableType)
          ?.workers.filter(worker => selectedWorkers.ids.includes(worker.workerId)) || []
      )
      setIsOpen(true)
    },
    [setSelectedScheduleType, setAssignWorkers, scheduleEditData, selectedWorkers, setIsOpen]
  )

  const onReassignWorkers = (workers: Worker[]) => {
    const newEditList: ScheduleEditType[] = scheduleEditData.map((edit: ScheduleEditType) => {
      if (edit.type === selectedScheduleType.type) {
        // 移動先にメンバーを追加
        return { ...edit, workers: edit.workers.concat(workers) }
      } else if (edit.type === selectedWorkers.droppableType) {
        // 移動元からメンバーを削除
        const newWorkers = edit.workers.filter(worker => !workers.map(w => w.workerId).includes(worker.workerId))
        return { ...edit, workers: newWorkers }
      }
      return edit
    })
    setScheduleEditData(newEditList)
    setIsOpen(false)
  }

  const onDragStart = (start: DragStart) => {
    const { source, draggableId } = start
    const selected = selectedWorkers.ids.find(workerId => workerId === draggableId)
    if (!selected) {
      setSelectedWorkers({ droppableType: source.droppableId, ids: [draggableId] })
    }
    setDraggingId(draggableId)
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    setDraggingId(undefined)

    // 移動元と移動先が同じ時・移動先が「予定未入力」の時は何もしない
    if (
      !destination ||
      source.droppableId === destination.droppableId ||
      destination.droppableId === TENTATIVE_SCHEDULE_TYPE_ID.UNSELECTED.toString()
    ) {
      return
    }

    // 移動先がワークスペースの時、応援に来ている作業者を別のワークスペースへ再応援させることは不可とする
    const moveToWorkspace = destination.droppableId.includes(PREFIX_WORKSPACE_ID)
    const filteredWorker =
      scheduleEditData
        .find(edit => edit.type === selectedWorkers.droppableType)
        ?.workers.filter(
          worker => selectedWorkers.ids.includes(worker.workerId) && (!moveToWorkspace || !worker.supporter)
        ) || []

    // 移動可能メンバーがいるときに処理を実行
    if (filteredWorker.length > 0) {
      const list = schedules.concat(otherWorkspaces)
      setSelectedScheduleType(list.find(data => data.type === destination.droppableId) || INIT_SELECTED_SCHEDULE_TYPE)
      setAssignWorkers(filteredWorker)
      setIsOpen(true)
    }
  }

  const workerCardClickHandler = useCallback(
    (event: MouseEvent, droppableType: string, id: string) => {
      if ((event.target as HTMLElement).tagName !== 'DIV') {
        return
      }

      // 無限レンダリングを回避するためにselectedWorkersを使わずにprevを使うようにしています
      setSelectedWorkers(prev => {
        if (droppableType !== prev.droppableType) {
          return { droppableType, ids: [id] }
        }

        if (event.shiftKey) {
          return {
            droppableType,
            ids: prev.ids.includes(id) ? prev.ids.filter(select => select !== id) : [...prev.ids, id],
          }
        }
        return {
          droppableType,
          ids: prev.ids.includes(id) && prev.ids.length === 1 ? [] : [id],
        }
      })
    },
    [setSelectedWorkers]
  )

  const filteredOtherWorkspaces = useCallback(
    (type: string, supporter: boolean) => {
      const workers = scheduleEditData.flatMap(d => d.workers).filter(d => selectedWorkers.ids.includes(d.workerId))
      const data = workers.filter(w => !!w.supporter)
      return otherWorkspaces.filter(w => w.type !== type && !supporter && data.length === 0)
    },
    [scheduleEditData, selectedWorkers.ids, otherWorkspaces]
  )

  const sortSchedule = (a: ScheduleEditType, b: ScheduleEditType) => {
    // workersの数でソートする
    const aHasWorkers = a.workers.length > 0
    const bHasWorkers = b.workers.length > 0

    // workersがいる場合は先、いない場合は後に来るようにする
    if (aHasWorkers && !bHasWorkers) {
      return -1
    }
    if (!aHasWorkers && bHasWorkers) {
      return 1
    }

    return 0
  }

  const workerCards = useMemo(() => {
    // ソート前に平均人時生産性を計算
    const averageScheduleEditData = scheduleEditData.map(schedule => {
      const totalProductivity = schedule.workers.reduce<number>((sum, worker) => {
        const productivityValue =
          worker.partialHourlyProductivities?.find(p => p.scheduleTypeId === schedule.scheduleTypeId)?.value ??
          schedule.defaultProductivity ??
          0

        return sum + productivityValue
      }, 0)

      const workerCount = schedule.workers.length
      const averageProductivity = workerCount > 0 ? floor(totalProductivity / workerCount, 1) : 0

      return { ...schedule, averageProductivity }
    })

    return averageScheduleEditData.map(s => ({ ...s, workers: s.workers.filter(w => w.visible) })).sort(sortSchedule)
  }, [scheduleEditData])

  const sortWorker = useCallback((workers: Worker[]) => {
    const { grouped, supporters, ungrouped } = workers.reduce(
      (acc, cur) => {
        if (cur.supporter) {
          acc.supporters.push(cur)
          return acc
        }
        if (cur.groupName === '未所属') {
          acc.ungrouped.push(cur)
          return acc
        }
        acc.grouped.push(cur)
        return acc
      },
      { grouped: [], supporters: [], ungrouped: [] } as { grouped: Worker[]; supporters: Worker[]; ungrouped: Worker[] }
    )
    return sortBy(grouped, ['groupName']).concat(supporters).concat(ungrouped)
  }, [])

  const droppableWorkerCard = useCallback(
    (item: ScheduleEditType) => {
      return (
        <Droppable droppableId={item.type}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} className={`user-select-none ${snapshot.isDraggingOver && 'bg-primary-pale'}`}>
              {item.workers.length > 0 ? (
                <CardBody className="pt-0 pb-3 px-3">
                  <div className="d-flex flex-wrap gap-2">
                    {sortWorker(item.workers).map((worker, index) => (
                      <div
                        key={`worker-card-${worker.workerId}`}
                        className={isOpenSidebar ? styles.cardPerSeven : styles.cardPerEight}
                      >
                        {snapshot.draggingFromThisWith === worker.workerId && (
                          <Card className="bg-light-gray" style={{ height: '74px' }} />
                        )}
                        <Draggable key={worker.workerId} draggableId={worker.workerId} index={index}>
                          {p => (
                            <div ref={p.innerRef} {...p.draggableProps} {...p.dragHandleProps}>
                              <WorkerCard
                                worker={worker}
                                selected={selectedWorkers.ids}
                                dropdownMenu={{
                                  schedules: schedules.filter(s => s.type !== item.type),
                                  otherWorkspaces: filteredOtherWorkspaces(item.type, !!worker.supporter),
                                }}
                                draggingId={draggingId}
                                onDropDownMenuClick={onDropDownMenuClick}
                                onClick={(e, id) => workerCardClickHandler(e, item.type, id)}
                              />
                            </div>
                          )}
                        </Draggable>
                      </div>
                    ))}
                  </div>
                </CardBody>
              ) : (
                <CardBody className="text-center text-muted pt-0">現在、誰もこの作業をしていません</CardBody>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )
    },
    [
      selectedWorkers,
      schedules,
      onDropDownMenuClick,
      workerCardClickHandler,
      draggingId,
      filteredOtherWorkspaces,
      sortWorker,
      isOpenSidebar,
    ]
  )

  const unscheduledWorkerViewButton = useMemo(() => {
    return (
      <Button
        className="d-flex align-items-center mx-auto text-white rounded-pill mb-2"
        onClick={handleClickUnscheduledWorkerViewOpenButton}
        color="gray"
        size="sm"
      >
        <i className={`${isUnscheduledWorkerViewOpen ? 'icf-upToDown' : 'icf-downToUp'} pe-2 font-large`} />
        {isUnscheduledWorkerViewOpen ? '閉じる' : '予定未入力'}
      </Button>
    )
  }, [handleClickUnscheduledWorkerViewOpenButton, isUnscheduledWorkerViewOpen])

  return (
    <div className={isUnscheduledWorkerViewOpen ? styles.unscheduledWorkerViewOpen : 'mb-0'}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Row className="pe-3">
          {workerCards
            .filter(item => item.label !== '予定未入力')
            .map(item => (
              <Col key={item.type} className="h-100 p-0" md={item.workers.length ? 12 : 4}>
                <Card className="mb-3 ms-3">
                  <CardBody className="d-flex align-items-center flex-wrap">
                    <BadgeLabel label={item.label} color={item.color} className="mb-1" />
                    <div className="text-nowrap overflow-hidden mb-1">
                      <span className="font-x-small text-muted mx-1">作業中</span>
                      <span className="font-normal">{item.workers.length}人</span>
                      {item.defaultProductivity !== null && (
                        <>
                          <span className="font-x-small text-muted mx-1">平均人時生産性</span>
                          <span className="font-small">
                            {item.workers.length > 0 ? item.averageProductivity : '-'}
                            {item.unit}
                          </span>
                        </>
                      )}
                    </div>
                  </CardBody>
                  {droppableWorkerCard(item)}
                </Card>
              </Col>
            ))}
        </Row>
        <div
          className={`position-fixed ${styles.bottomOffset}  ${isOpenSidebar ? styles.sidebarOpen : styles.sidebarClosed}`}
        >
          {unscheduledWorkerViewButton}
          {isUnscheduledWorkerViewOpen && (
            <div className={`bg-white ${styles.unscheduledSpace}`}>
              {workerCards
                .filter(item => item.label === '予定未入力')
                .map((item: ScheduleEditType) => (
                  <Col key={item.type} className="h-100 p-0" md="12">
                    <div className={`mb-3 ${styles.pseudoCard}`}>
                      <CardBody className="d-flex p-3">
                        <BadgeLabel label={item.label} color={item.color} />
                      </CardBody>
                      {droppableWorkerCard(item)}
                    </div>
                  </Col>
                ))}
            </div>
          )}
        </div>
      </DragDropContext>

      <WorkerReassign
        isOpen={isOpen}
        workers={assignWorkers}
        selected={selectedScheduleType}
        onReassignWorkers={onReassignWorkers}
        onClose={() => setIsOpen(false)}
      />
    </div>
  )
}

export default DroppingWorkerCards
