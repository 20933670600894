import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/postcode/postcode'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { makeErrorMessage } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type PostcodeType = {
  postcode: string
  address1: string
  address2: string
  address3: string
}

type PostcodeState = {
  postcodeList: PostcodeType[]
}

const initialState: PostcodeState = {
  postcodeList: [],
}

export const postcodeSlice = createSlice({
  name: 'postcode',
  initialState,
  reducers: {
    getPostcodeListSuccess: (state, action: PayloadAction<string>) => {
      const list = action.payload.split(`\n`).reduce((acc: PostcodeType[], cur, index) => {
        if (index > 0) {
          const data = cur.split(',')
          acc.push({ postcode: data[0], address1: data[1], address2: data[2], address3: data[3] })
        }
        return acc
      }, [])
      state.postcodeList = list
    },
  },
})

export const { getPostcodeListSuccess } = postcodeSlice.actions

export const getPostcodeList = (): AppThunk => async dispatch => {
  dispatch(Spinner.start())
  try {
    const res = await API.getPostcodeList()
    dispatch(getPostcodeListSuccess(res))
  } catch (res) {
    const errorCode = makeErrorMessage(res as AxiosError)
    dispatch(NetworkErrorDialog.open({ code: errorCode }))
  } finally {
    dispatch(Spinner.stop())
  }
}

export const selectPostcodeStatus = (state: RootState) => ({ ...state.postcode })

export default postcodeSlice.reducer
