import { axiosClient, axiosClientWithTenantId, axiosClientWithTenantIdAndUserId } from 'AxiosClientProvider'

import type {
  CreateTenantUserProps,
  CreateUserProps,
  DisplayFilterResponse,
  UpdateDisplayFilterProps,
  UpdateTenantUserProps,
  UpdateUserPermissionProps,
  UpdateUserProps,
  UpdateUserTenantProps,
  UserListResponse,
  UserResponse,
} from './types'

export const getUserList = async (): Promise<UserListResponse> => {
  const url = '/users'

  const response = await axiosClient.get(url)
  return response.data
}

export const createUser = async (data: CreateUserProps): Promise<{ userId: string }> => {
  const url = '/users'

  const response = await axiosClient.post(url, data)
  return response.data
}

export const getUser = async (userId: string): Promise<UserResponse> => {
  const url = `/users/${userId}`

  const response = await axiosClient.get(url)
  return response.data
}

export const updateUser = async (userId: string, data: UpdateUserProps): Promise<UserResponse> => {
  const url = `/users/${userId}`

  const response = await axiosClient.patch(url, data)
  return response.data
}

export const deleteUser = async (userId: string): Promise<{ userId: string }> => {
  const url = `/users/${userId}`

  const response = await axiosClient.delete(url)
  return response.data
}

export const updateUserTenant = async (
  userId: string,
  tenantId: number,
  data: UpdateUserTenantProps
): Promise<UserResponse> => {
  const url = `/users/${userId}/tenants/${tenantId}`

  const response = await axiosClient.patch(url, data)
  return response.data
}

export const getTenantUserList = async (tenantId: number): Promise<UserListResponse> => {
  const url = `/tenants/${tenantId}/users`

  const response = await axiosClient.get(url)
  return response.data
}

export const createTenantUser = async (tenantId: number, data: CreateTenantUserProps): Promise<{ userId: string }> => {
  const url = `/tenants/${tenantId}/users`

  const response = await axiosClient.post(url, data)
  return response.data
}

export const getTenantUser = async (tenantId: number | undefined, userId: string): Promise<UserResponse> => {
  const url = `/tenants/${tenantId}/users/${userId}`

  const response = await axiosClient.get(url)
  return response.data
}

export const updateTenantUser = async (data: UpdateTenantUserProps): Promise<UserResponse> => {
  const response = await axiosClientWithTenantIdAndUserId.patch('', data)
  return response.data
}

export const deleteTenantUser = async (tenantId: number, userId: string): Promise<{ userId: string }> => {
  const url = `/tenants/${tenantId}/users/${userId}`

  const response = await axiosClient.delete(url)
  return response.data
}

export const updateTenantUserPermission = async (
  userId: string,
  data: UpdateUserPermissionProps
): Promise<UserResponse> => {
  const url = `/users/${userId}/permission`

  const response = await axiosClientWithTenantId.patch(url, data)
  return response.data
}

export const getDisplayFilter = async (): Promise<DisplayFilterResponse> => {
  const url = '/display-filter'

  const response = await axiosClientWithTenantIdAndUserId.get(url)
  return response.data
}

export const updateDisplayFilter = async (data: UpdateDisplayFilterProps): Promise<DisplayFilterResponse> => {
  const url = '/display-filter'

  const response = await axiosClientWithTenantIdAndUserId.patch(url, data)
  return response.data
}
