import moment from 'moment'

import * as Spinner from 'slices/spinnerSlice'

import type { Action, ActionCreatorWithPayload, ThunkDispatch } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { RootState } from 'store'

const UNAUTHORIZED = '401'
const UNREACHABLE = '999'
export const ERROR_STATUS_CODE = {
  BAD_REQUEST: '400',
  UNAUTHORIZED,
  NOT_FOUND: '404',
  CONFLICT: '409',
  UNREACHABLE,
}
export const ENABLE_DIALOG_ERROR_STATUS_CODES = [UNAUTHORIZED, UNREACHABLE]
export const INCORRECT_OLD_PASSWORD_MESSAGE = 'Incorrect oldPassword.'

export type CommonParams = {
  accessToken: string | null
  idToken: string | null
  tenantId: number
  userId: string
  updatedBy: string
}

export const commonParams = (getState: () => RootState): CommonParams => {
  const {
    accessToken,
    idToken,
    user: {
      userId,
      userHasTenants: {
        0: { nickname, id },
      },
    },
    team: { workerName },
  } = getState().session
  const tenantId = id || 0
  return {
    accessToken,
    idToken,
    tenantId,
    userId,
    updatedBy: workerName || (nickname ?? ''),
  }
}

const SPLIT_PERIOD = 6
export const getSplitPeriods = (from: string, to: string): [string, string][] => {
  const totalDays = moment(to).diff(from, 'days') + 1
  // それぞれのリクエストで取得する日付
  return [...Array(SPLIT_PERIOD)].reduce((acc: [string, string][], _, index) => {
    const diffFrom = Math.ceil((totalDays * index) / SPLIT_PERIOD)
    const diffTo = Math.ceil((totalDays * (index + 1)) / SPLIT_PERIOD) - 1

    if (totalDays < SPLIT_PERIOD && diffTo < diffFrom) {
      return acc
    }
    return [
      ...acc,
      [moment(from).add(diffFrom, 'days').format('YYYY-MM-DD'), moment(from).add(diffTo, 'days').format('YYYY-MM-DD')],
    ]
  }, [])
}

export const checkStatus = async (
  callback: () => Promise<number | undefined>,
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>
) => {
  const retryInterval = await callback()
  if (!retryInterval) {
    return
  }
  dispatch(Spinner.start())
  await new Promise<void>(resolve => setTimeout(resolve, retryInterval))
  dispatch(Spinner.stop())
  await checkStatus(callback, dispatch)
}

export const makeErrorMessage = (res: AxiosError): string => {
  return (res.response?.status || UNREACHABLE).toString()
}

export const handleApiError = (
  res: AxiosError,
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>,
  apiFailure: ActionCreatorWithPayload<{ errorMessage: string }, string>
): void => {
  const errorCode = makeErrorMessage(res)
  dispatch(apiFailure({ errorMessage: errorCode }))
}

export const downloadByURL = async (url: string, filename: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  exportData(blob, filename)
}

export const exportData = (blob: Blob, filename: string) => {
  const objUrl = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = filename
  link.href = objUrl
  link.click()
  window.URL.revokeObjectURL(objUrl)
}
