import moment from 'moment/moment'

import useBusinessTime from './useBusinessTime'
import { useQuery } from './useQuery'

// pathに含まれるDateクエリパラメータを取得する

const useDateQuery = () => {
  const { getWorkDate } = useBusinessTime()
  const [queryDate] = useQuery('date', getWorkDate(moment().format('YYYY-MM-DD')))
  return queryDate
}

export default useDateQuery
