import { createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'es-toolkit'

import * as API from 'api/template/template'
import type { TemplateDataResponse, TemplateDetails, TemplateListResponse, TemplateProps } from 'api/template/types'

import { handleApiError } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type TemplateDetail = {
  id: number
  name: string
  ids: ({ id: number; isSupport: boolean } | null)[]
  updatedAt: string
  updatedByName: string
}

type TemplateState = {
  isRequesting: boolean
  errorMessage: string
  template?: TemplateDetail
  templateList: TemplateDetails[]
  templateWorkspaceId: number | undefined
}

const initialState: TemplateState = {
  isRequesting: false,
  errorMessage: '',
  templateList: [],
  templateWorkspaceId: undefined,
}

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getTemplateListSuccess: (state, action: PayloadAction<{ response: TemplateListResponse; workspaceId: number }>) => {
      state.isRequesting = false
      state.templateList = sortBy(action.payload.response.templates, ['name'])
      state.templateWorkspaceId = action.payload.workspaceId
    },
    getTemplateDataSuccess: (state, action: PayloadAction<TemplateDataResponse & { workspaceId: number }>) => {
      state.isRequesting = false
      const ids = action.payload.schedules.map((scheduleId, i) => {
        const workspaceId = action.payload.workspaces[i]
        const id = scheduleId || workspaceId
        return id ? { id, isSupport: workspaceId !== action.payload.workspaceId } : null
      })
      state.template = { ...action.payload, ids }
    },
    updateTemplateDataSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const { startRequest, apiFailure, getTemplateListSuccess, getTemplateDataSuccess, updateTemplateDataSuccess } =
  templateSlice.actions

export const getTemplateList =
  (workspaceId: number, callback?: (templateList: TemplateDetails[]) => void): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      const res = await API.getTemplateList(workspaceId)
      dispatch(getTemplateListSuccess({ response: res, workspaceId }))
      callback?.(res.templates)
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const getTemplateData =
  (workspaceId: number, templateId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      const res = await API.getTemplateData(workspaceId, templateId)
      dispatch(getTemplateDataSuccess({ ...res, workspaceId }))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const updateTemplateData =
  (workspaceId: number, data: TemplateProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      await API.updateTemplateData(workspaceId, data)
      dispatch(updateTemplateDataSuccess())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const selectTemplateStatus = (state: RootState) => ({ ...state.template })

export default templateSlice.reducer
