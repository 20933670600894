import { SelectBoxFormat } from 'components/common/FormFormat/FormFormat'
import { COLUMN_SIZES } from 'components/common/constants'
import type { ColumnSize } from 'components/common/types'

export const CHARACTER_CODE_TYPES = {
  SHIFT_JIS: 'SJIS',
  UTF8: 'UTF8',
} as const

export type CharacterCodeType = (typeof CHARACTER_CODE_TYPES)[keyof typeof CHARACTER_CODE_TYPES]

const characterCodeItem = [
  { key: CHARACTER_CODE_TYPES.SHIFT_JIS, value: 'SHIFT_JIS' },
  { key: CHARACTER_CODE_TYPES.UTF8, value: 'UTF-8' },
]

type Props = {
  characterCode: CharacterCodeType
  onChange: (characterCode: CharacterCodeType) => void
  size?: ColumnSize
}

export const CharacterCodeSelect = ({ characterCode, onChange, size = COLUMN_SIZES.MIDDLE }: Props) => {
  return (
    <SelectBoxFormat
      label="文字コード"
      placeholder="文字コードを選択"
      value={characterCode}
      size={size}
      items={characterCodeItem}
      onChange={item => {
        onChange(item.key as CharacterCodeType)
      }}
      className="mb-3"
    />
  )
}
