import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { deleteGroup, selectGroupsStatus } from 'slices/groupsSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  workspaceId: number
  groupId: number | undefined
  onSuccess: () => void
  onCancel: () => void
}

const GroupDelete = ({ isOpen, workspaceId, groupId, onSuccess, onCancel }: Props) => {
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = useState(false)
  const { isRequesting, errorMessage } = useSelector(selectGroupsStatus, shallowEqual)
  const dispatch = useDispatch()

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    onCancel()
  }

  const handleDeleteClick = () => {
    if (groupId) {
      setSubmitted(true)
      dispatch(deleteGroup(workspaceId, groupId))
    }
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      setModalErrorMessage('削除できませんでした。')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, onSuccess])

  return (
    <CustomModal
      isOpen={isOpen}
      title="グループの削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleDeleteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">このグループを本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}

export default GroupDelete
