import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ROLE } from 'api/users/constants'
import type { RoleType } from 'api/users/types'

import { selectSessionStatus } from 'slices/sessionSlice'
import { createUser, selectUsersStatus, clearErrorMessage, createTenantUser } from 'slices/usersSlice'

import { CardRadioButton, CustomModal, InputFormat } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { ACCOUNT_TYPE_LABEL, COLUMN_SIZES } from 'components/common/constants'

type Props = {
  isOpen: boolean
  tenantId?: number
  onClose: () => void
  onSuccess: () => void
}

const UserInvitation = (props: Props) => {
  const { isOpen, onClose, onSuccess } = props
  const [email, setEmail] = useState<string | undefined>()
  const [role, setRole] = useState<RoleType>(ROLE.ADMIN)
  const [emailValidity, setEmailValidity] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatch()
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const isAdmin = user.userHasTenants[0].role === ROLE.PROCESS_ADMIN || user.userHasTenants[0].role === ROLE.ADMIN
  const tenantId = props.tenantId || user.userHasTenants[0].id

  useEffect(() => {
    setRole(isAdmin || props.tenantId ? ROLE.ADMIN : ROLE.TENANT_ADMIN)
  }, [isAdmin, setRole, props])

  const handleInviteClick = () => {
    if (typeof email === 'undefined') {
      return
    }
    setSubmitted(true)
    if (tenantId && (isAdmin || props.tenantId)) {
      dispatch(createTenantUser(tenantId, { email, role }))
    } else {
      dispatch(createUser({ email }))
    }
  }

  const handleCancelClick = useCallback(() => {
    setEmail(undefined)
    setModalErrorMessage(undefined)
    setRole(isAdmin ? ROLE.ADMIN : ROLE.TENANT_ADMIN)
    onClose()
  }, [isAdmin, onClose])

  const radioButtonItems = [
    {
      value: ROLE.ADMIN,
      label: ACCOUNT_TYPE_LABEL[ROLE.ADMIN],
      subtitle: '全てのワークスペースの管理、ユーザーの招待/削除が行える管理者アカウント',
    },
    {
      value: ROLE.PROCESS_ADMIN,
      label: ACCOUNT_TYPE_LABEL[ROLE.PROCESS_ADMIN],
      subtitle: '特定のワークスペースの管理が行える利用者アカウント',
    },
  ]

  const disabled = useMemo(() => !(email && emailValidity), [email, emailValidity])

  const onRadioButtonClick = (value: string) => {
    setRole(value === ROLE.ADMIN ? ROLE.ADMIN : ROLE.PROCESS_ADMIN)
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
      handleCancelClick()
    } else {
      setModalErrorMessage('送信できませんでした。')
    }
    setSubmitted(false)
    dispatch(clearErrorMessage())
  }, [submitted, onSuccess, handleCancelClick, dispatch, isRequesting, errorMessage])

  return (
    <CustomModal
      isOpen={isOpen}
      title="ユーザーの追加"
      approveLabel={isAdmin ? '招待を送信' : '送信'}
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleInviteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div>
        <div className="mb-3">入力したメールアドレスに招待が送られます。</div>
        <InputFormat
          label="メールアドレス"
          placeholder="メールアドレス"
          value={email}
          size={COLUMN_SIZES.MIDDLE}
          onChange={value => setEmail(value)}
          validations={[Rules.Required, Rules.Email]}
          onValidate={setEmailValidity}
          maxLength={100}
        />
      </div>

      {isAdmin && (
        <div className="mt-5">
          <div className="mb-3">招待を送るユーザーの管理権限を設定してください。</div>
          <CardRadioButton
            items={radioButtonItems}
            name="userAuthorityDialog"
            selected={role}
            onClick={onRadioButtonClick}
          />
        </div>
      )}
    </CustomModal>
  )
}

export default UserInvitation
