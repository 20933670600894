import dayjs from 'dayjs'
import { uniqBy } from 'es-toolkit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import type { ImportHistoryType } from 'api/import_history/types'

import { getImportHistory, selectImportHistoryStatus } from 'slices/importHistorySlice'

import useBusinessTime from 'hooks/useBusinessTime'
import { useImportHistoryQuery, DEFAULT_PAGE } from 'hooks/useImportHistoryQuery'

import FilteringButton from '../FilteringButton/FilteringButton'
import PaginationAtoms from '../Pagination/Pagination'
import { PeriodSelect } from '../PeriodSelect/PeriodSelect'

import { ImportHistoryTable } from './ImportHistoryTable'
import { LIMIT_PER_PAGE } from './constants'

type Props = {
  importHistoryType: ImportHistoryType
}

export const ImportHistory = ({ importHistoryType }: Props) => {
  const dispatch = useDispatch()
  const { importHistory } = useSelector(selectImportHistoryStatus, shallowEqual)
  const { getWorkDate } = useBusinessTime()
  const { queryStart, queryEnd, setDateRange, queryPage, setQueryPage } = useImportHistoryQuery()

  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([])

  useEffect(() => {
    dispatch(
      getImportHistory({
        workDateFrom: queryStart,
        workDateTo: queryEnd,
        page: queryPage,
        limit: LIMIT_PER_PAGE,
        type: importHistoryType,
      })
    )
  }, [dispatch, queryStart, queryEnd, queryPage, importHistoryType])

  useEffect(() => {
    setSelectedCategoryIds(importHistory?.fileInfoData.map(file => file.category.id) || [])
  }, [importHistory])

  const handlePeriodChange = useCallback(
    (start: Date, end: Date) => {
      setDateRange(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'))
    },
    [setDateRange]
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryPage(page)
    },
    [setQueryPage]
  )

  const importHistoryFilterItems = useMemo(
    () =>
      uniqBy(importHistory?.fileInfoData || [], item => item.category.id).map(file => ({
        key: file.category.id,
        label: file.category.name,
        checked: selectedCategoryIds.includes(file.category.id),
      })),
    [selectedCategoryIds, importHistory]
  )

  const filteredImportHistories = useMemo(() => {
    const filterIds = importHistoryFilterItems.filter(category => category.checked).map(category => category.key)
    return importHistory?.fileInfoData.filter(file => filterIds.includes(file.category.id)) || []
  }, [importHistory?.fileInfoData, importHistoryFilterItems])

  const filteringButtonLabel = useMemo(
    () => (importHistoryFilterItems.every(item => item.checked) ? '全てのインポート設定' : 'インポート設定で絞り込み'),
    [importHistoryFilterItems]
  )

  const importHistoryPeriodList = useMemo(() => {
    const baseDate = dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')))
    return [
      {
        label: '今日',
        periodStart: baseDate.toDate(),
        periodEnd: baseDate.toDate(),
        id: 'today',
      },
      {
        label: '昨日',
        periodStart: baseDate.subtract(1, 'day').toDate(),
        periodEnd: baseDate.subtract(1, 'day').toDate(),
        id: 'yesterday',
      },
      {
        label: '開始日と終了日を選択',
        periodStart: baseDate.toDate(),
        periodEnd: baseDate.toDate(),
        id: 'user_setting',
      },
    ]
  }, [getWorkDate])

  return (
    <div className="d-flex flex-column row-gap-3">
      <div className="d-flex justify-content-between">
        <FilteringButton
          items={importHistoryFilterItems}
          label={filteringButtonLabel}
          onChange={setSelectedCategoryIds}
          value={selectedCategoryIds}
        />
        <PeriodSelect
          periodListData={importHistoryPeriodList}
          period={{ start: dayjs(queryStart).toDate(), end: dayjs(queryEnd).toDate() }}
          maxDate={dayjs(getWorkDate(dayjs().format('YYYY-MM-DD'))).toDate()}
          onChange={handlePeriodChange}
        />
      </div>
      <ImportHistoryTable selectedFileInfoData={filteredImportHistories} importHistoryType={importHistoryType} />
      <div className="d-flex justify-content-center">
        <PaginationAtoms
          currentPage={queryPage}
          totalPages={importHistory?.maxPage || DEFAULT_PAGE}
          onClick={handlePageChange}
        />
      </div>
    </div>
  )
}
