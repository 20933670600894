import { useState, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import { CheckboxGroup } from 'components/common'
import type { FilterItem } from 'components/common/types'

import styles from './WorkerFilter.module.scss'

type Props<T extends number | string> = {
  filterItems: FilterItem<T>[]
  onChange: (filterItems: FilterItem<T>[]) => void
}

const WorkerFilter = <T extends number | string>({ filterItems, onChange }: Props<T>) => {
  const [openFilter, setOpenFilter] = useState(false)
  const [popupStyle, setPopupStyle] = useState({ display: 'none' })

  useEffect(() => {
    if (openFilter) {
      setPopupStyle({ display: 'block' })
    } else {
      setPopupStyle({ display: 'none' })
    }
  }, [openFilter])

  return (
    <OutsideClickHandler onOutsideClick={() => setOpenFilter(false)} disabled={!openFilter}>
      <div
        className={[
          styles.filterToggle,
          'position-relative',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'border',
          'border-secondary',
          'text-secondary',
          'rounded',
        ].join(' ')}
        role="button"
        onClick={() => setOpenFilter(!openFilter)}
      >
        <i className="icf-filter font-large" />

        <div
          className={[
            styles.popup,
            'position-absolute',
            'border',
            'border-light-gray',
            'text-black',
            'bg-white',
            'rounded',
          ].join(' ')}
          style={popupStyle}
          onClick={e => {
            e.stopPropagation() // 上にclickイベントが伝播してpopupが閉じるのを防ぐ
          }}
        >
          <CheckboxGroup filterItems={filterItems} onChange={onChange} />
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default WorkerFilter
