import { Fragment, useMemo, useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap'

import CheckboxGroup from 'components/common/CheckboxGroup/CheckboxGroup'
import { CustomButton } from 'components/common/CustomButton/CustomButton'
import FilteringButton from 'components/common/FilteringButton/FilteringButton'
import type { FilterItem } from 'components/common/types'

import useBusinessTime from 'hooks/useBusinessTime'

import styles from './GraphSelectButton.module.scss'

type Item = { label: string; filterItems: FilterItem[] }

type Props = {
  items: Item[]
  date?: string
  onChange: (filterItems: number[]) => void
  selectedGraphs: number[]
  disabled?: boolean
  text: string
  onSaveButtonClick: () => void
}

export const GroupedGraphSelectButton = ({
  items,
  selectedGraphs,
  onChange,
  disabled,
  text,
  onSaveButtonClick,
  date,
}: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { getIsPast } = useBusinessTime()
  const handleCheckboxChange = (filterItems: FilterItem[]) => {
    const newSelectedGraphs = filterItems.reduce((acc, cur) => {
      if (cur.checked && !acc.includes(cur.key)) {
        return acc.concat(cur.key)
      }
      if (!cur.checked && acc.includes(cur.key)) {
        return acc.filter(key => key !== cur.key)
      }
      return acc
    }, selectedGraphs)
    onChange(newSelectedGraphs)
  }

  const isPast = useMemo(() => (date ? getIsPast(date) : false), [getIsPast, date])

  return (
    <div className="d-flex">
      <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
        <DropdownToggle tag="div" disabled={disabled}>
          <CustomButton outline icon="filter" size="sm" className="me-2" disabled={disabled}>
            {`${text}の選択`}
          </CustomButton>
        </DropdownToggle>
        <DropdownMenu end={true}>
          {items.map(({ filterItems, label }) => (
            <Fragment key={label}>
              <UncontrolledDropdown setActiveFromChild direction="end">
                <DropdownToggle tag="div" className={`px-3 py-2 d-flex ${styles.dropdownItem}`}>
                  <div className="px-1 text-nowrap">{label}</div>
                  <i className="font-large d-flex justify-content-end align-items-center w-100 icf-carot_right" />
                </DropdownToggle>
                <DropdownMenu key={label}>
                  <div className={styles.checkboxGroup}>
                    <CheckboxGroup filterItems={filterItems} onChange={handleCheckboxChange} />
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Fragment>
          ))}
        </DropdownMenu>
      </Dropdown>
      <CustomButton outline icon="save" size="sm" onClick={onSaveButtonClick} disabled={isPast || disabled}>
        {`${text}の保存`}
      </CustomButton>
    </div>
  )
}

type GraphSelectButtonProps = {
  date?: string
  text: string
  items: FilterItem[]
  selectedGraphs: number[]
  onChange: (filterItems: number[]) => void
  onSaveButtonClick: () => void
}

export const GraphSelectButton = ({
  text,
  date,
  items,
  selectedGraphs,
  onChange,
  onSaveButtonClick,
}: GraphSelectButtonProps) => {
  const { getIsPast } = useBusinessTime()
  const isPast = useMemo(() => (date ? getIsPast(date) : false), [getIsPast, date])
  return (
    <div className="w-100 d-flex justify-content-end">
      <div className="me-2">
        <FilteringButton items={items} value={selectedGraphs} onChange={onChange} label={`${text}の選択`} size="sm" />
      </div>
      <CustomButton outline icon="save" size="sm" onClick={onSaveButtonClick} disabled={isPast}>
        {`${text}の保存`}
      </CustomButton>
    </div>
  )
}
