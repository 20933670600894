import { createContext } from 'react'

export type SelectedScheduleType = {
  scheduleId: number
  time: string
}
type AssignToWorkTableContextType = {
  shiftKeyDown: boolean
  selectedSchedules: SelectedScheduleType[]
  setSelectedSchedules: (selectedSchedules: SelectedScheduleType[]) => void
}
export const AssignToWorkTableContext = createContext<AssignToWorkTableContextType>({
  shiftKeyDown: false,
  selectedSchedules: [],
  setSelectedSchedules: () => ({}),
})
