import { useCallback, useEffect, useState } from 'react'

export const localStorageKeys = {
  isHelpOpen: 'isHelpOpen',
} as const

type StorageValueProps<T> = {
  key: (typeof localStorageKeys)[keyof typeof localStorageKeys]
  initValue: T
}

export const useLocalStorage = <T>({ key, initValue }: StorageValueProps<T>) => {
  const getLocalStorageValue = useCallback((): T => {
    const item = localStorage.getItem(key)

    return item ? JSON.parse(item) : initValue
  }, [initValue, key])
  const [value, setValue] = useState<T>(getLocalStorageValue())

  useEffect(() => {
    const callback = (event: StorageEvent) => {
      if (event.key === key) {
        setValue(prev => getLocalStorageValue() ?? prev)
      }
    }

    // タブを複数開いている場合、他のタブでlocalStorageの値が変更された場合にも反映する
    window.addEventListener('storage', callback)
    // コンポーネントが破棄されたタイミングでイベントリスナーを削除する
    return () => {
      window.removeEventListener('storage', callback)
    }
  }, [getLocalStorageValue, key])

  const setLocalStorageValue = useCallback(
    (newValue: T) => {
      localStorage.setItem(key, JSON.stringify(newValue))
      setValue(newValue)
    },
    [key]
  )

  return { value, setLocalStorageValue }
}
