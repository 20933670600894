import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { TemplateDataResponse, TemplateListResponse, TemplateProps } from './types'

export const getTemplateList = async (workspaceId: number): Promise<TemplateListResponse> => {
  const url = `/workspaces/${workspaceId}/templates`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const getTemplateData = async (workspaceId: number, templateId: number): Promise<TemplateDataResponse> => {
  const url = `/workspaces/${workspaceId}/templates/${templateId}`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const updateTemplateData = async (workspaceId: number, data: TemplateProps): Promise<TemplateDataResponse> => {
  const url = `/workspaces/${workspaceId}/templates`

  const response = await axiosClientWithTenantId.put(url, data)
  return response.data
}
