import { isEqual } from 'es-toolkit'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Card, CardTitle, CardBody, Col, Row } from 'reactstrap'

import type { ScheduleTypesArray, UpdateBopRequest, UnitCostsArray, BopData } from 'api/bop/types'

import { getBop, updateBop, selectBopStatus } from 'slices/bopSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import type { UnitCostsArrayToString, CostArrayToString } from 'components/BalanceOfPayments/types'
import { FIELD_NAMES, BADGE_LABEL_TYPES } from 'components/BalanceOfPayments/types'
import { NavMenu, List, InputGroupFormatWithSalesBadge, CardSubmitFooter, BadgeLabel } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'

import useBop from 'hooks/useBop'

import placeholder from 'images/allEmpty.svg'

import { FormInput } from './FormInput'

import styles from './BalanceOfPayments.module.scss'

const MAX_ITEMS = 20
const MAX_MATERIAL_ITEMS = 10

const BalanceOfPayments = () => {
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<number | undefined>(undefined)
  const [submitted, setSubmitted] = useState(false)
  const [unitCosts, setUnitCosts] = useState<UnitCostsArrayToString[]>([])
  const [otherSales, setOtherSales] = useState<CostArrayToString[]>([])
  const [managementCosts, setManagementCosts] = useState<CostArrayToString[]>([])
  const [extraCostScheduleTypes, setExtraCostScheduleTypes] = useState<ScheduleTypesArray[]>([])
  const [workspaceCosts, setWorkspaceCosts] = useState<CostArrayToString[]>([])

  const dispatch = useDispatch()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { bop, isRequesting, errorMessage } = useSelector(selectBopStatus, shallowEqual)

  const {
    fixedValue,
    formattedValue,
    setOtherSalesValidity,
    setManagementCostsValidity,
    setWorkspaceCostsValidity,
    updateValidationList,
    disabled,
  } = useBop(unitCosts)
  // テナント内のワークスペースを取得する
  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  // リスト用のworkspaceItemsを生成する
  const workspaceItems = useMemo(
    () =>
      partialWorkspaces.map(workspace => ({
        id: workspace.id,
        title: workspace.name,
      })),
    [partialWorkspaces]
  )

  const hasWorkspaceItems = useMemo(() => workspaceItems.length > 0, [workspaceItems]) // workspaceItemsが空でないかどうか

  // workspaceIdを設定する
  useEffect(() => {
    if (workspaceItems.length === 0) {
      return
    }
    setSelectedWorkspaceId(prev => {
      if (prev === undefined) {
        return Number(workspaceItems[0].id) // 初期化時は[0]を返す
      }
      return prev
    })
  }, [workspaceItems])

  // selectedWorkspaceIdを検知し、getBop取得・getScheduleTypeList取得する
  useEffect(() => {
    if (!selectedWorkspaceId) {
      return
    }
    dispatch(getBop(selectedWorkspaceId))
  }, [dispatch, selectedWorkspaceId])

  // リストを選択した場合、workspaceIdを設定する
  const handleListItemChange = useCallback((selected: string | number) => {
    const id = typeof selected === 'number' ? selected : parseInt(selected, 10)
    setSelectedWorkspaceId(prevId => {
      if (prevId === id) {
        return prevId
      }
      return id
    })
  }, [])

  // bopの値をセットする共通関数
  const resetByBop = useCallback(
    (newBop: BopData) => {
      setUnitCosts(
        newBop?.unitCosts.map(unit => ({
          ...unit,
          unitCost: fixedValue(unit.unitCost, true),
          materialCosts: unit.materialCosts.map(material => ({ ...material, cost: fixedValue(material.cost, true) })),
        }))
      )
      setOtherSales(newBop?.otherSales.map(item => ({ ...item, cost: fixedValue(item.cost, false) })))
      setManagementCosts(newBop?.managementCosts.map(item => ({ ...item, cost: fixedValue(item.cost, false) })))
      setExtraCostScheduleTypes(newBop?.extraCostScheduleTypes || [])
      setWorkspaceCosts(newBop?.workspaceCosts.map(item => ({ ...item, cost: fixedValue(item.cost, false) })))
    },
    [fixedValue]
  )

  // getBop取得成功時
  useEffect(() => {
    if (!bop) {
      return
    }
    resetByBop(bop)
  }, [bop, resetByBop])

  // 単価の更新
  const updateUnitCost = useCallback(
    (index: number, field: keyof UnitCostsArray, value: string | number) => {
      const newItems = unitCosts.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: value }
        }
        return item
      })
      setUnitCosts(newItems)
    },
    [unitCosts]
  )

  // キャンセルボタン押下時、リセットする
  const onCancel = useCallback(() => {
    if (!bop) {
      return
    }
    resetByBop(bop)
  }, [bop, resetByBop])

  // 保存ボタン押下時、updateBop更新
  const onSubmit = useCallback(() => {
    if (!selectedWorkspaceId) {
      return
    }
    // requestBodyに揃える
    const filteredUnitCosts = unitCosts.map(({ scheduleTypeId, unitCost, materialCosts }) => ({
      scheduleTypeId,
      unitCost: Number(unitCost),
      materialCosts: materialCosts.map(({ name, cost }) => ({
        name,
        cost: Number(cost),
      })),
    }))
    const filteredOtherSales = otherSales.map(({ name, cost }) => ({ name, cost: Number(cost) }))
    const filteredManagementCosts = managementCosts.map(({ name, cost }) => ({ name, cost: Number(cost) }))
    const filteredWorkspaceCosts = workspaceCosts.map(({ name, cost }) => ({ name, cost: Number(cost) }))
    const requestBody: UpdateBopRequest = {
      unitCosts: filteredUnitCosts,
      otherSales: filteredOtherSales,
      managementCosts: filteredManagementCosts,
      workspaceCosts: filteredWorkspaceCosts,
    }
    dispatch(updateBop(selectedWorkspaceId, requestBody))
    setSubmitted(true)
  }, [dispatch, unitCosts, otherSales, managementCosts, workspaceCosts, selectedWorkspaceId])

  const unchanged = useMemo(() => {
    if (!bop) {
      return
    }
    // number型に戻してbopと比較
    const cleanUnitCosts = unitCosts.map(({ scheduleTypeId, scheduleTypeName, unit, unitCost, materialCosts }) => ({
      scheduleTypeId,
      scheduleTypeName,
      unit,
      unitCost: Number(unitCost),
      materialCosts: materialCosts.map(({ name, cost }) => ({
        name,
        cost: Number(cost),
      })),
    }))
    const cleanOtherSales = otherSales.map(({ name, cost }) => ({ name, cost: Number(cost) }))
    const cleanManagementCosts = managementCosts.map(({ name, cost }) => ({ name, cost: Number(cost) }))
    const cleanWorkspaceCosts = workspaceCosts.map(({ name, cost }) => ({ name, cost: Number(cost) }))
    return isEqual(
      {
        unitCosts: cleanUnitCosts,
        otherSales: cleanOtherSales,
        managementCosts: cleanManagementCosts,
        workspaceCosts: cleanWorkspaceCosts,
      },
      {
        unitCosts: bop.unitCosts,
        otherSales: bop.otherSales,
        managementCosts: bop.managementCosts,
        workspaceCosts: bop.workspaceCosts,
      }
    )
  }, [unitCosts, otherSales, managementCosts, workspaceCosts, bop])

  // エラーの検知
  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      if (selectedWorkspaceId) {
        dispatch(getBop(selectedWorkspaceId))
      }
      dispatch(showSuccess())
    } else {
      if (errorMessage === ERROR_STATUS_CODE.CONFLICT) {
        dispatch(showError({ errorMessage: '登録上限に達しているため、保存できませんでした。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, selectedWorkspaceId])

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="d-flex justify-content-between align-self-center mb-3 font-x-large fw-bold">
          ワークスペース収支管理
        </div>

        <Row className={styles.row}>
          <Col md={4} className="h-100">
            <Card className={`${styles.list} h-100 overflow-auto`}>
              {hasWorkspaceItems ? (
                <List items={workspaceItems} selectedId={selectedWorkspaceId} onAction={handleListItemChange} />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">ワークスペースがまだ登録されていません</div>
                    <div>ワークスペース管理で、まずは最初のワークスペースを登録してみましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100">
              {hasWorkspaceItems ? (
                <>
                  <div className="h-100 overflow-auto">
                    {/* 単価設定 */}
                    {unitCosts.length > 0 && (
                      <CardBody>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <CardTitle className="font-large fw-bold">単価設定</CardTitle>
                            <span className="font-small">※必須項目</span>
                          </div>
                          <div className="mb-3">ワークスペース内のキー作業の単価と資材・材料費を設定できます。</div>
                          <Card>
                            {unitCosts.map((unitItem, unitIndex) => (
                              <div key={unitIndex}>
                                <CardBody>
                                  <div className="mb-3">{unitItem.scheduleTypeName}</div>
                                  <InputGroupFormatWithSalesBadge
                                    label="単価"
                                    labelSize={COLUMN_SIZES.X_SHORT}
                                    size={COLUMN_SIZES.MIDDLE}
                                    className="unitCostMarginLeft"
                                    addonText={`円 / 1${unitItem.unit}`}
                                    maxLength={10}
                                    value={unitItem.unitCost.toString()}
                                    onChange={value => updateUnitCost(unitIndex, FIELD_NAMES.UNIT_COST, value)}
                                    validations={[
                                      Rules.ValidateNumberWithMaxCharacters({ maxCharacters: 10, decimalPlaces: 2 }),
                                    ]}
                                    onBlur={() => {
                                      const formattedCost = formattedValue(unitItem.unitCost)
                                      if (formattedCost !== undefined) {
                                        updateUnitCost(unitIndex, FIELD_NAMES.UNIT_COST, formattedCost)
                                      }
                                    }}
                                    onValidate={isValid => updateValidationList(unitIndex, 'first', isValid)}
                                  />

                                  <FormInput
                                    costItems={unitItem.materialCosts}
                                    toFixed={true}
                                    maxItems={MAX_MATERIAL_ITEMS}
                                    onChange={setMaterialCosts =>
                                      setUnitCosts(prev =>
                                        prev.map((p, i) =>
                                          i === unitIndex
                                            ? { ...p, materialCosts: setMaterialCosts(p.materialCosts) }
                                            : p
                                        )
                                      )
                                    }
                                    onBlur={formattedValue}
                                    titleText="資材・材料費"
                                    addButtonText="資材・材料費を追加"
                                    labelText="名称※"
                                    BadgeLabelInput={BADGE_LABEL_TYPES.COSTS}
                                    addonText="円 / 1pcs"
                                    validations={{
                                      nameValidations: [Rules.Required],
                                      costValidations: [
                                        Rules.ValidateNumberWithMaxCharacters({ maxCharacters: 10, decimalPlaces: 2 }),
                                      ],
                                    }}
                                    onValidate={isValid => updateValidationList(unitIndex, 'second', isValid)}
                                  />
                                </CardBody>
                                {unitIndex < unitCosts.length - 1 && <hr className="m-0" />}
                              </div>
                            ))}
                          </Card>
                        </div>
                      </CardBody>
                    )}

                    {/* その他売上設定 */}
                    <CardBody>
                      <div className="mb-3">
                        {unitCosts.length > 0 ? (
                          <CardTitle className="font-large fw-bold">その他売上設定</CardTitle>
                        ) : (
                          <div className="d-flex justify-content-between">
                            <CardTitle className="font-large fw-bold">その他売上設定</CardTitle>
                            <span className="font-small">※必須項目</span>
                          </div>
                        )}
                        <div className="mb-3">
                          その他の売上（営業日毎の売上）が設定できます。その他売上はワークスペースに所属するメンバーに勤務時間がある全ての日で計上されます。
                        </div>
                        <FormInput
                          costItems={otherSales}
                          toFixed={false}
                          maxItems={MAX_ITEMS}
                          onChange={setOtherSales}
                          addButtonText="その他売上を追加"
                          labelText="名称※"
                          BadgeLabelInput={BADGE_LABEL_TYPES.SALES}
                          addonText="円 / 1営業日"
                          onValidate={setOtherSalesValidity}
                        />
                      </div>
                    </CardBody>

                    {/* 管理費設定 */}
                    <CardBody>
                      <div className="mb-3">
                        <CardTitle className="font-large fw-bold">管理費設定</CardTitle>
                        <div className="mb-3">
                          管理人件費等の管理費（営業日毎の費用）が設定できます。管理費はワークスペースに所属するメンバーに勤務時間がある全ての日で計上されます。
                        </div>
                        <FormInput
                          costItems={managementCosts}
                          toFixed={false}
                          maxItems={MAX_ITEMS}
                          onChange={setManagementCosts}
                          addButtonText="管理費を追加"
                          labelText="人件費名称※"
                          BadgeLabelInput={BADGE_LABEL_TYPES.COSTS}
                          addonText="円 / 1営業日"
                          onValidate={setManagementCostsValidity}
                        />
                      </div>
                    </CardBody>

                    {/* 費用外作業一覧 */}
                    <CardBody>
                      <div className="mb-3">
                        <CardTitle className="font-large fw-bold">費用外作業一覧</CardTitle>
                        <div className="mb-3">
                          費用外作業に設定している作業の一覧です。設定の解除は作業管理から実施してください。
                        </div>
                        <Card>
                          <CardBody>
                            <div className="d-flex flex-wrap">
                              {extraCostScheduleTypes.length > 0 ? (
                                extraCostScheduleTypes.map((item, index) => (
                                  <BadgeLabel key={index} label={item.name} color={item.color} className="my-2" />
                                ))
                              ) : (
                                <div className="text-gray">費用外作業が設定されていません。</div>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </CardBody>

                    {/* ワークスペース費用設定 */}
                    <CardBody>
                      <div className="mb-3">
                        <CardTitle className="font-large fw-bold">ワークスペース費用設定</CardTitle>
                        <div className="mb-3">
                          システム利用料等の費用（営業日毎の費用）が設定できます。ワークスペース費用はワークスペースに所属するメンバーに勤務時間がある全ての日で計上されます。
                        </div>
                        <FormInput
                          costItems={workspaceCosts}
                          toFixed={false}
                          maxItems={MAX_ITEMS}
                          onChange={setWorkspaceCosts}
                          addButtonText="その他固定費を追加"
                          labelText="費用名称※"
                          BadgeLabelInput={BADGE_LABEL_TYPES.COSTS}
                          addonText="円 / 1営業日"
                          onValidate={setWorkspaceCostsValidity}
                        />
                      </div>
                    </CardBody>
                  </div>
                  <CardSubmitFooter
                    onCancel={() => onCancel()}
                    onSubmit={() => onSubmit()}
                    submitDisabled={disabled || unchanged}
                    cancelDisabled={unchanged}
                    updatedBy={bop?.updatedByName}
                    updatedAt={bop?.updatedAt}
                  />
                </>
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">ワークスペースが選択されていません</div>
                    <div>ワークスペースを選択して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </NavMenu>
  )
}

export default BalanceOfPayments
