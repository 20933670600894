import { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, FormText, Input, Label, FormFeedback } from 'reactstrap'

import { COLUMN_SIZES } from 'components/common/constants'
import type { ColumnSize } from 'components/common/types'
import { getUUID } from 'components/common/utils'

import type { ChangeEvent, FocusEvent } from 'react'

type FormProps = {
  label: string | JSX.Element
  labelSize?: ColumnSize
  size?: ColumnSize
  placeholder?: string
  formText?: string | JSX.Element
  className?: string
}

type InputFormatWithArrowProps = FormProps & {
  value?: string
  type?: 'text' | 'email' | 'password'
  maxLength?: number
  disabled?: boolean
  onChange?: (value: string) => void
  validations?: Array<(s: string | undefined) => string>
  onValidate?: (b: boolean) => void
  maxWidthClassName?: string
}

export const InputFormatWithArrow = ({
  label,
  value,
  labelSize = COLUMN_SIZES.SHORT,
  size = COLUMN_SIZES.LARGE,
  type = 'text',
  maxLength,
  disabled,
  placeholder,
  formText,
  className,
  onChange,
  validations,
  onValidate,
  maxWidthClassName = '',
}: InputFormatWithArrowProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')
  const invalid = useCallback(
    (inputValue: string | undefined) => validations?.find(validation => validation(inputValue))?.(inputValue),
    [validations]
  )

  useEffect(() => {
    setErrorMessage(invalid(value))
  }, [invalid, value])

  const handleChange = (e: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => onChange?.(e.target.value)

  const id = useMemo(() => `id-${getUUID()}`, [])

  // 直接onValidateを実行すると親のrender中に子コンポーネントから状態を変えることになりwarningが出る
  useEffect(() => onValidate?.(!errorMessage), [onValidate, value, errorMessage])
  return (
    <div className={`row ${className}`}>
      {/* lableが空の場合は表示しない */}
      {label && (
        <Label for={id} md={labelSize}>
          <span>{label}</span>
        </Label>
      )}
      <Col md={size}>
        <div className="d-flex justify-content-start align-items-center">
          <i className="icf-carot_left font-large me-2" />
          <div className="flex-grow-1">
            {/* エラーメッセージの表示位置を揃えるためのラップ用div */}
            <Input
              value={value || ''}
              invalid={!!errorMessage}
              type={type}
              maxLength={maxLength}
              disabled={disabled}
              id={id}
              placeholder={placeholder}
              onChange={handleChange}
              onBlur={handleChange}
              className={maxWidthClassName}
            />
            <FormText color="muted" hidden={!formText}>
              {formText}
            </FormText>
            <FormFeedback hidden={!errorMessage}>{errorMessage}</FormFeedback>
          </div>
        </div>
      </Col>
    </div>
  )
}
