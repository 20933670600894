import { isEqual } from 'es-toolkit'
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Card, CardBody, CardTitle, Input, Label } from 'reactstrap'

import { getBopReportsLaborCosts, selectBopReportsStatus } from 'slices/bopReportsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getDisplayFilter, selectUsersStatus, updateDisplayFilter } from 'slices/usersSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { BOP_TYPE } from 'components/Dashboard/utils'
import { BadgeLabel, Chart, GraphSelectButton, GroupRadioButton, NotSelectedPlaceholder } from 'components/common'
import { PLACE_HOLDER_TYPES } from 'components/common/NotSelectedPlaceholder/NotSelectedPlaceholder'
import { UpdateLabel } from 'components/common/UpdateLabel/UpdateLabel'

import { useBopLaborCosts } from 'hooks/useBopLaborCosts'
import { useBopReportsQuery } from 'hooks/useBopReportsQuery'

import { BopReportsCommon, toggleButtonItemList } from './BopReportsCommon'
import { DATA_TYPE_ITEMS } from './constants'

export const BopReportLaborCosts = () => {
  const dispatch = useDispatch()

  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { displayFilter, isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const { bopReportsLaborCosts } = useSelector(selectBopReportsStatus, shallowEqual)

  const [submitted, setSubmitted] = useState(false)
  const [isPercentageChecked, setIsPercentageChecked] = useState(false)
  const [selectedBopType, setSelectedBopType] = useState(BOP_TYPE.ACTUAL)
  const [selectedWorkspaceIds, setSelectedWorkspaceIds] = useState<number[]>([])
  const [isDailyReportChecked, setIsDailyReportChecked] = useState(true)
  const [isDataSourceOpen, setIsDataSourceOpen] = useState(false)

  const isInit = useRef(false)

  const { queryStart, queryEnd } = useBopReportsQuery()
  const { reportLaborCostsGraphOptions } = useBopLaborCosts(selectedBopType, isPercentageChecked)

  useEffect(() => {
    dispatch(getDisplayFilter())
  }, [dispatch])

  useEffect(() => {
    // 初回表示は、displayFilter: true, isDailyReport: trueの固定値で取得する
    if (isInit.current) {
      return
    }

    isInit.current = true

    const queryParams = {
      from: queryStart,
      to: queryEnd,
      displayFilter: true,
      isDailyReport: true,
    }
    dispatch(getBopReportsLaborCosts(queryParams))
  }, [dispatch, queryStart, queryEnd])

  useEffect(() => {
    setSelectedWorkspaceIds(
      displayFilter?.bopReport.workspaceData
        .filter(workspace => workspace.isFilteredInLaborCosts)
        .map(workspace => workspace.id) || []
    )
  }, [displayFilter])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  // データソースリストの作成
  const dataSourceItems = useMemo(
    () =>
      bopReportsLaborCosts?.workspaces?.map(({ id, dataType }) => {
        const name = partialWorkspaces.find(pw => pw.id === id)?.name || ''
        const dataTypeKey = selectedBopType === BOP_TYPE.ESTIMATE ? BOP_TYPE.ESTIMATE : dataType
        return { id, name, ...DATA_TYPE_ITEMS[dataTypeKey] }
      }) || [],
    [bopReportsLaborCosts?.workspaces, partialWorkspaces, selectedBopType]
  )

  const filterItems = useMemo(
    () =>
      partialWorkspaces?.map(workspace => ({
        key: workspace.id,
        label: workspace.name,
        checked: selectedWorkspaceIds.includes(workspace.id),
      })) || [],
    [partialWorkspaces, selectedWorkspaceIds]
  )

  const handleWorkspaceSelect = useCallback(
    (items: number[]) => {
      if (isEqual(items, selectedWorkspaceIds)) {
        return
      }

      setSelectedWorkspaceIds(items)

      const queryParams = {
        from: queryStart,
        to: queryEnd,
        workspaceIds: items.join(),
        ...(isDailyReportChecked && { isDailyReport: true }),
      }

      dispatch(getBopReportsLaborCosts(queryParams))
    },
    [dispatch, isDailyReportChecked, queryEnd, queryStart, selectedWorkspaceIds]
  )
  const handleWorkspaceFilterSaveButtonClick = useCallback(() => {
    if (!displayFilter) {
      return
    }

    setSubmitted(true)
    const updateWorkspaces = displayFilter.bopReport.workspaceData.map(df => {
      return { ...df, isFilteredInLaborCosts: selectedWorkspaceIds.includes(df.id) }
    })

    dispatch(updateDisplayFilter({ bopReport: { workspaceData: updateWorkspaces } }))
  }, [dispatch, displayFilter, selectedWorkspaceIds])

  const handleDataSourceOpen = useCallback(() => {
    setIsDataSourceOpen(prevState => !prevState)
  }, [])

  const handleIsDailyReportChecked = useCallback(
    (checked: boolean) => {
      setIsDailyReportChecked(checked)

      const queryParams = {
        from: queryStart,
        to: queryEnd,
        workspaceIds: selectedWorkspaceIds.join(),
        ...(checked && { isDailyReport: true }),
      }
      dispatch(getBopReportsLaborCosts(queryParams))
    },
    [dispatch, queryEnd, queryStart, selectedWorkspaceIds]
  )

  const handleToggleChange = useCallback((id: string) => {
    setSelectedBopType(id)
  }, [])

  const handleIsPercentageChecked = useCallback((checked: boolean) => {
    setIsPercentageChecked(checked)
  }, [])

  const handleDateChange = useCallback(
    (start: string, end: string) => {
      const queryParams = {
        from: start,
        to: end,
        displayFilter: true,
        ...(isDailyReportChecked && { isDailyReport: true }),
      }
      dispatch(getBopReportsLaborCosts(queryParams))

      setSelectedWorkspaceIds(
        displayFilter?.bopReport.workspaceData
          .filter(workspace => workspace.isFilteredInLaborCosts)
          .map(workspace => workspace.id) || []
      )
    },
    [dispatch, displayFilter?.bopReport.workspaceData, isDailyReportChecked]
  )

  return (
    <BopReportsCommon selectedWorkspaceIds={selectedWorkspaceIds} onDateChange={handleDateChange}>
      <Card className="mt-2">
        <CardBody className="p-4">
          <div className="d-flex align-items-baseline">
            <CardTitle className="fw-bold font-large text-nowrap">労務費バランス</CardTitle>
            <GraphSelectButton
              items={filterItems}
              selectedGraphs={selectedWorkspaceIds}
              onChange={handleWorkspaceSelect}
              text="表示ワークスペース"
              onSaveButtonClick={handleWorkspaceFilterSaveButtonClick}
            />
          </div>
          {selectedWorkspaceIds.length === 0 ? (
            <NotSelectedPlaceholder type={PLACE_HOLDER_TYPES.WORKSPACE} />
          ) : (
            <>
              <div className="d-flex align-items-baseline mt-2">
                <GroupRadioButton
                  items={toggleButtonItemList}
                  initSelectedId={toggleButtonItemList[1].id}
                  onChange={handleToggleChange}
                />
                {selectedBopType === BOP_TYPE.ACTUAL && (
                  <div className="form-check ms-3">
                    <Input
                      className="form-check-input"
                      id="daily-report"
                      checked={isDailyReportChecked}
                      type="checkbox"
                      onChange={e => handleIsDailyReportChecked(e.target.checked)}
                    />
                    <Label className="form-check-label mb-0" for="daily-report">
                      日報実績を利用
                    </Label>
                  </div>
                )}
                <div className="ms-auto">
                  <button
                    className="btn p-0 border-0 bg-transparent text-gray font-x-small"
                    onClick={handleDataSourceOpen}
                  >
                    <i className={`icf-carot_${isDataSourceOpen ? 'down' : 'right'}`} />
                    データソースを表示
                  </button>
                </div>
              </div>
              <div className="form-check mt-2">
                <Input
                  className="form-check-input"
                  id="percentage"
                  type="checkbox"
                  onChange={e => handleIsPercentageChecked(e.target.checked)}
                  checked={isPercentageChecked}
                />
                <Label className="form-check-label mb-0" for="percentage">
                  割合表示
                </Label>
              </div>

              {isDataSourceOpen && (
                <div className="d-flex flex-wrap my-2 gap-2">
                  {dataSourceItems.map(dataSource => (
                    <BadgeLabel
                      key={dataSource.id}
                      label={dataSource.name}
                      dataSourceLabel={dataSource.dataTypeLabel}
                      dataSourceColor={dataSource.dataTypeColor}
                    />
                  ))}
                </div>
              )}

              <div className="mt-4">
                <Chart options={reportLaborCostsGraphOptions} />
              </div>

              <div className="d-flex gap-2">
                <BadgeLabel label="変動直接労務費" color="danger-stronger-middle" />
                <BadgeLabel label="変動間接労務費" color="danger-middle" />
                <BadgeLabel label="間接労務費" color="danger-pale" />
              </div>
              <UpdateLabel updatedAt={bopReportsLaborCosts?.updatedAt} />
            </>
          )}
        </CardBody>
      </Card>
    </BopReportsCommon>
  )
}
