import dayjs from 'dayjs'
import { useMemo } from 'react'
import Select from 'react-select'

import { TIME_INTERVAL } from '../constants'

type Props = {
  hour: string
  minute: string
  label: string
  invalid?: boolean
  onChange: (hour: string, minute: string) => void
  onBlur?: () => void
  menuIsOpen?: boolean
  menuZIndex?: number
  menuPosition?: 'auto' | 'bottom' | 'top'
  range?: { fromHour: string; fromMin: string; toHour: string; toMin: string }
  portalTarget?: HTMLElement
  additionalOptions?: { label: string; value: { hour: string; minute: string } }[]
  roundingInterval?: number
  isError?: boolean
}

export const TimeSelect = (props: Props) => {
  const {
    hour,
    minute,
    label,
    invalid,
    onChange,
    onBlur,
    menuIsOpen,
    menuZIndex,
    menuPosition = 'auto',
    portalTarget,
    additionalOptions = [],
    range = { fromHour: '00', fromMin: '00', toHour: '00', toMin: '00' },
    roundingInterval = TIME_INTERVAL.FIFTEEN,
    isError = false,
  } = props
  const startDateTime = useMemo(() => {
    const dateTime = dayjs().hour(Number(range.fromHour)).minute(Number(range.fromMin))
    return dateTime
  }, [range.fromHour, range.fromMin])

  const options = (() => {
    const optionsArray: { label: string; value: { hour: string; minute: string } }[] = []

    const maxIndex = (60 / roundingInterval) * 24
    for (let index = 0; index < maxIndex; index++) {
      const nextDateTime = startDateTime.add(roundingInterval * index, 'minute')
      const add24H = nextDateTime.date() === startDateTime.date() && range.fromHour !== '24' ? 0 : 24
      const hourLabel = (nextDateTime.hour() + add24H).toString().padStart(2, '0')
      const minLabel = nextDateTime.minute().toString().padStart(2, '0')

      optionsArray.push({
        value: { hour: hourLabel, minute: minLabel },
        label: `${hourLabel}:${minLabel}`,
      })

      // 終了時刻に達したらループを終了
      if (hourLabel === range.toHour && minLabel === range.toMin) {
        break
      }
    }

    return additionalOptions.concat(optionsArray)
  })()

  const defaultValue = useMemo(() => {
    const target = options.find(val => val.value.hour === hour && val.value.minute === minute)
    return target || options[0]
  }, [options, hour, minute])

  return (
    <div className="d-flex align-items-center">
      <Select
        onBlur={onBlur}
        autoFocus={menuIsOpen}
        menuIsOpen={menuIsOpen}
        options={options}
        defaultValue={options[0]}
        value={defaultValue}
        isDisabled={invalid}
        onChange={val => val && onChange(val.value.hour, val.value.minute)}
        maxMenuHeight={200}
        required
        styles={{
          menu: base => (menuZIndex ? { ...base, zIndex: menuZIndex } : base),
          control: base => (isError ? { ...base, border: '2px solid var(--bs-danger)' } : base),
        }}
        menuPlacement={menuPosition}
        noOptionsMessage={() => '選択肢がありません'}
        menuPortalTarget={portalTarget}
        menuPosition={portalTarget && 'fixed'}
      />
      {label && <span className="ms-2">{label}</span>}
    </div>
  )
}
