export const IMPORT_HISTORY_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  RUNNING: 'running',
  SKIP: 'skip',
} as const

export const IMPORT_HISTORY_DOWNLOAD_TYPE = {
  DAILY_REPORT: 'dailyReport',
} as const

export const IMPORT_HISTORY_TYPE = {
  DAILY_REPORT: 'dailyReport',
} as const
