import { useState, useCallback, useMemo, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import {
  getDailyReportsImportSettingsList,
  importDailyReport,
  selectDailyReportsStatus,
} from 'slices/dailyReportsSlice'

import { CustomModal, FileInput, SelectBoxFormat } from 'components/common'
import { COLUMN_SIZES } from 'components/common/constants'

import type { LoadFileProps } from 'hooks/useImportCsv'
import { useImportCsv } from 'hooks/useImportCsv'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const MAXIMUM_IMPORT_ROW_TEXT = (10000).toLocaleString()

export const ImportDialog = ({ isOpen, onSuccess, onCancel }: Props) => {
  const [selectedDailyReportId, setSelectedDailyReportId] = useState<number>()
  const [submitted, setSubmitted] = useState(false)

  const { setModalErrorMessage, setFile, importCsvFile, importDisabled, modalErrorMessage } = useImportCsv()

  const dispatch = useDispatch()
  const { dailyReportsImportSettingsList, isRequesting, errorMessage } = useSelector(
    selectDailyReportsStatus,
    shallowEqual
  )

  const dailyReportImportSettingItems = useMemo(
    () =>
      dailyReportsImportSettingsList.map(item => ({
        key: item.id,
        value: item.name,
      })),
    [dailyReportsImportSettingsList]
  )

  useEffect(() => {
    if (!isOpen) {
      return
    }
    dispatch(getDailyReportsImportSettingsList())
  }, [dispatch, isOpen])

  useEffect(() => {
    if (dailyReportImportSettingItems.length === 0) {
      return
    }
    setSelectedDailyReportId(dailyReportImportSettingItems[0].key)
  }, [dailyReportImportSettingItems])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      setModalErrorMessage('エラーが発生しました。')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, setModalErrorMessage])

  const handleApprove = useCallback(() => {
    if (!selectedDailyReportId) {
      return
    }
    const handleFileLoad = (data: LoadFileProps) => {
      const uploadUrlData = {
        id: selectedDailyReportId,
        fileName: data.fileName,
      }
      dispatch(importDailyReport(uploadUrlData, data.content))
      setSubmitted(true)
    }
    importCsvFile(handleFileLoad)
  }, [dispatch, importCsvFile, selectedDailyReportId])

  return (
    <CustomModal
      isOpen={isOpen}
      title="日報実績インポート"
      approveLabel="CSVインポート"
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleApprove}
      onHideNotification={() => setModalErrorMessage(undefined)}
      submitName="targets-import-submit"
      approveDisabled={importDisabled || !selectedDailyReportId}
    >
      <div className="d-flex flex-column row-gap-3">
        <div>日報実績データを登録する事ができます。下記注意点をよく読み、操作を実行してください。</div>
        <ul>
          <li>
            日報実績インポートについて詳しくは
            <a target="_blank" rel="noreferrer" href="https://help.smileboard.jp/import-workreport">
              こちら
            </a>
            をご確認ください。
          </li>
          <li>過去32日分まで取り込み可能となります。</li>
          <li>
            一度にインポートできるのはヘッダーを除いて{MAXIMUM_IMPORT_ROW_TEXT}
            行までです。適宜分割してインポートしてください。 アップロードされたCSVファイルがヘッダーを除いて
            {MAXIMUM_IMPORT_ROW_TEXT}行より多い場合は処理は実施されません。
          </li>
        </ul>
        <div className="d-flex flex-column row-gap-4">
          <SelectBoxFormat
            label="インポート設定を選択"
            placeholder="インポート設定を選択"
            value={selectedDailyReportId?.toString()}
            size={COLUMN_SIZES.MIDDLE}
            items={dailyReportImportSettingItems}
            onChange={item => setSelectedDailyReportId(Number(item.key))}
          />
          <FileInput id="daily-report-import" accept=".csv" onChange={f => setFile(f)} />
        </div>
      </div>
    </CustomModal>
  )
}
