import { useCallback, useMemo, useState } from 'react'

import type { CharacterCodeType } from 'components/common/CharacterCodeSelect/CharacterCodeSelect'

export type LoadFileProps = { content: string; fileName: string }

export const useImportCsv = () => {
  const [file, setFile] = useState<File | null>(null)
  const [modalErrorMessage, setModalErrorMessage] = useState<string | undefined>(undefined)

  const importDisabled = useMemo(() => !(file && /\.csv$/i.test(file.name)), [file])

  const importCsvFile = useCallback(
    (onFileLoad: (data: LoadFileProps) => void, encoding?: CharacterCodeType) => {
      if (!file) {
        return
      }
      const reader = new FileReader()
      const fileLoad = () => {
        const content = reader.result
        if (!content) {
          setModalErrorMessage('ファイルが空です。')
          return
        }
        onFileLoad({
          content: content.toString(),
          fileName: file.name,
        })
      }

      reader.addEventListener('load', fileLoad)
      reader.addEventListener('error', () => setModalErrorMessage('ファイルの読み込みに失敗しました。'))
      reader.readAsText(file, encoding)
    },
    [file]
  )

  return { importDisabled, setFile, importCsvFile, setModalErrorMessage, modalErrorMessage }
}
