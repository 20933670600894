import axios from 'axios'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type {
  PlanCreateStatusResponse,
  PlanListResponse,
  PlanResponse,
  ProductivityPlanResponse,
  UpdatePlan,
  UpdatePlanAsyncResponse,
  UpdatePlanBulk,
  UpdatePlanResponse,
  UpdateProductivityPlan,
  UpdateProductivityPlanResponse,
  UpdateTargetValue,
  UpdateTargetValueResponse,
} from './types'

// Planデータを日付指定で取得
export const getPlanByDate = async (workspaceId: number, workDate: string): Promise<PlanResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${workDate}/plan`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

// Planデータを日付、workerId指定で取得
export const getPlanByWorkerId = async (
  workspaceId: number,
  workDate: string,
  workerId: number
): Promise<PlanResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${workDate}/workers/${workerId}/plan`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

// Planデータの一覧取得
export const getPlanList = async (workspaceId: number, from?: string, to?: string): Promise<PlanListResponse> => {
  const url = `/workspaces/${workspaceId}/plan-list`

  const queryParams = {
    'work-date-from': from,
    'work-date-to': to,
  }
  const response = await axiosClientWithTenantId.get(url, { params: queryParams })
  return response.data
}

// Planデータ作成、更新
export const updatePlan = async (
  workspaceId: number,
  workDate: string,
  workerId: number,
  data: UpdatePlan
): Promise<UpdatePlanResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${workDate}/workers/${workerId}/plan`

  const response = await axiosClientWithTenantId.put(url, { ...data })
  return response.data
}

// Planデータ一括作成、更新
export const updatePlanAsync = async (
  workspaceId: number,
  workDate: string,
  data: UpdatePlanBulk
): Promise<UpdatePlanAsyncResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${workDate}/plan/bulk-create`

  const response = await axiosClientWithTenantId.put(url, { ...data })
  return response.data
}

export const getPlanCreateStatus = async (
  workspaceId: number,
  workDate: string,
  requestId: string
): Promise<PlanCreateStatusResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${workDate}/plan/${requestId}/bulk-create-status`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

// 生産性調整情報一覧取得
export const getProductivityPlan = async (workspaceId: number, workDate: string): Promise<ProductivityPlanResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${workDate}/productivity-plan`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

// 生産性調整情報登録、更新
export const updateProductivityPlan = async (
  workspaceId: number,
  workDate: string,
  scheduleTypeId: number,
  data: UpdateProductivityPlan
): Promise<UpdateProductivityPlanResponse> => {
  const url = `/workspaces/${workspaceId}/work-date/${workDate}/schedule-types/${scheduleTypeId}/productivity-plan`

  const response = await axiosClientWithTenantId.put(url, { ...data })
  return response.data
}

// 作業種別の目標数を登録・更新
export const updateTargetValue = async (
  workspaceId: number,
  data: UpdateTargetValue
): Promise<UpdateTargetValueResponse> => {
  const url = `/workspaces/${workspaceId}/target-value`

  const response = await axiosClientWithTenantId.put(url, { ...data })
  return response.data
}

export const shiftUploadUrl = async (
  fileName: string
): Promise<{
  uploadUrl: string
}> => {
  const url = '/shift-upload-url'

  const response = await axiosClientWithTenantId.post(url, { fileName })
  return response.data
}

export const putUploadUrl = async (url: string, csvContent: string): Promise<void> => {
  const headers = {
    'Content-Type': 'text/csv',
  }
  await axios.put(url, csvContent, { headers })
}
