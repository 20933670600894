import { useMemo } from 'react'

import type { TenantData } from 'api/tenants/types'

import { CELL_WIDTH, CELL_WIDTH_5MIN } from 'components/common/constants'

import useBusinessTime from 'hooks/useBusinessTime'

import styles from './TimeScale.module.scss'

type Props = {
  round?: boolean
  tenantWithDate?: TenantData
  is5min?: boolean
}
export const TimeScale = ({ round, tenantWithDate, is5min = false }: Props) => {
  const { business15MinBlocks, businessHourBlocks } = useBusinessTime({ tenantWithDate })
  const blocks = business15MinBlocks.length
  const borderStyle = (time: number, index: number) => {
    if (time % 4 === 3) {
      return index === blocks - 1 ? '' : styles.borderRight
    }
    if (time % 4 === 0) {
      return index === 0 ? '' : styles.borderLeft
    }
    return ''
  }
  const getTime = (i: number, index: number) => {
    if (i % 4 === 0 && index < 4 * Math.floor(blocks / 4)) {
      return `${i / 4}:00`
    }
    return ''
  }

  const cellWidth = useMemo(() => (is5min ? CELL_WIDTH_5MIN : CELL_WIDTH), [is5min])

  return (
    <table>
      <tbody>
        {round ? (
          <tr className={styles.container} style={{ width: cellWidth * 4 * businessHourBlocks.length }}>
            {businessHourBlocks.map(time => (
              <td key={time} className={`${styles.box} ${styles.hourBorderRight}`} style={{ width: cellWidth * 4 }}>
                {`${time}:00`}
              </td>
            ))}
          </tr>
        ) : (
          <tr className={styles.container} style={{ width: cellWidth * blocks }}>
            {business15MinBlocks.map((time, i) => (
              <td key={time} className={`${styles.box} ${borderStyle(time, i)} p-0`} style={{ width: cellWidth }}>
                <div className={styles.timeText}>{getTime(time, i)}</div>
              </td>
            ))}
          </tr>
        )}
      </tbody>
    </table>
  )
}
