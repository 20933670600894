import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { BopResponse, UpdateBopRequest, UpdateBopResponse } from './types'

export const getBop = async (workspaceId: number): Promise<BopResponse> => {
  const url = `/workspaces/${workspaceId}/bop`

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const updateBop = async (workspaceId: number, requestBody: UpdateBopRequest): Promise<UpdateBopResponse> => {
  const url = `/workspaces/${workspaceId}/bop`

  const response = await axiosClientWithTenantId.patch(url, requestBody)
  return response.data
}
