import dayjs from 'dayjs'
import { merge, sortBy, uniqBy } from 'es-toolkit'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Card, CardBody, CardTitle, Input, Label } from 'reactstrap'

import type { HourlyPlanAccuracyRow } from 'api/reports/types'

import { getReportHourlyPlanAccuracy, selectReportsStatus, getReportPlanHistory } from 'slices/reportsSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'
import { getWorkspaceList } from 'slices/workspacesSlice'

import { createXAxis, colorTypeToCode } from 'components/Dashboard/utils'
import {
  NavMenu,
  BadgeButton,
  Chart,
  ScrollToTopOnMount,
  DateChangeButton,
  StickyTable,
  Pagination,
} from 'components/common'
import type { Series } from 'components/common/types'
import { createLineChartOptions } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import useDateChange from 'hooks/useDateChange'
import { useQuery } from 'hooks/useQuery'

import { BadgeLabelForReports } from './BadgeLabelForReports'

import styles from './PlanAccuracyDetail.module.scss'

import type { TooltipFormatterCallbackFunction, XAxisOptions } from 'highcharts'

const ITEMS_PER_PAGE = 15

const DEFAULT_PAGE = 1

const PlanAccuracyDetail = () => {
  const { search } = useLocation()
  const { checkWorkspaceCreated, createDateFilter } = useDateChange()

  const params = useParams<'workspaceId' | 'date'>()
  const workspaceId = Number(params.workspaceId)

  const [isDataSourceOpen, setIsDataSourceOpen] = useQuery<boolean>('data-source', false)
  const [isDailyReportUse, setIsDailyReportUse] = useQuery<boolean>('daily-report', false)
  const [currentPage, setCurrentPage] = useQuery<number>('page', DEFAULT_PAGE)

  const [selectedBadgeKeys, setSelectedBadgeKeys] = useState<number[]>([])

  const { tenantWithDate } = useSelector(selectTenantsStatus, shallowEqual)
  const { hourlyPlanAccuracies, hourlyPlanAccuraciesDailyReport, planHistory } = useSelector(
    selectReportsStatus,
    shallowEqual
  )

  const { businessStartTime, businessEndTime, getWorkDate, getTimeOver24h } = useBusinessTime({ tenantWithDate })

  const displayDate = useMemo(() => getWorkDate(String(params.date)), [params, getWorkDate])
  const formattedDisplayDate = useMemo(() => dayjs(displayDate).locale('ja').format('YYYY/MM/DD（ddd）'), [displayDate])

  const filterDate = useMemo(() => createDateFilter({ fromDays: -90, toDays: -1 }), [createDateFilter])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  useEffect(() => {
    if (!workspaceId || !displayDate) {
      return
    }
    if (isDailyReportUse === undefined) {
      return
    }
    dispatch(getReportHourlyPlanAccuracy(workspaceId, displayDate, isDailyReportUse))
  }, [dispatch, workspaceId, displayDate, isDailyReportUse])

  useEffect(() => {
    if (currentPage === undefined) {
      return
    }
    dispatch(getReportPlanHistory(workspaceId, displayDate, currentPage, ITEMS_PER_PAGE))
  }, [currentPage, displayDate, dispatch, workspaceId])

  const hourlyPlanAccuraciesData = useMemo(() => {
    const selectedData = isDailyReportUse ? hourlyPlanAccuraciesDailyReport : hourlyPlanAccuracies
    return selectedData?.data ?? []
  }, [hourlyPlanAccuracies, hourlyPlanAccuraciesDailyReport, isDailyReportUse])

  const badgeItems = useMemo(() => {
    const hourlyPlanAccuracyItems = hourlyPlanAccuraciesData.map(planAccuracy => ({
      color: planAccuracy.scheduleTypeColor,
      key: planAccuracy.scheduleTypeId,
      label: planAccuracy.scheduleTypeName,
    }))
    const hourlyPlanAccuraciesUniqueByKey = uniqBy(hourlyPlanAccuracyItems, data => data.key)
    return sortBy(hourlyPlanAccuraciesUniqueByKey, ['key'])
  }, [hourlyPlanAccuraciesData])

  useEffect(() => {
    // badgeItemsが更新されたら全選択
    setSelectedBadgeKeys(badgeItems.map(badgeItem => badgeItem.key))
  }, [badgeItems])

  const formatter: TooltipFormatterCallbackFunction = function () {
    const planAccuracyRows: HourlyPlanAccuracyRow[] = this.series.options.custom?.planAccuracyRows
    const unit = this.series.options.custom?.unit
    const row = planAccuracyRows.find(r => dayjs(r.date).isSame(this.x))
    const lastPlan = row?.lastPlanCount ? row.lastPlanCount.toLocaleString() : '-'
    const archivePlan = row?.archivePlanCount ? row.archivePlanCount.toLocaleString() : '-'
    return `<div style="display: flex; flex-direction: column; align-items: center;">
              <div>${dayjs(this.x).format('HH:mm')}</div>
              <div>最終計画：${lastPlan} ${unit}</div>
              <div>アーカイブ：${archivePlan} ${unit}</div>
            </div>`
  }

  const chartOptions = useMemo(() => {
    const selectedBadges = badgeItems.filter(badge => selectedBadgeKeys.includes(badge.key))

    // 20:45のようなケースでは20:00に集計されるため、分を切り捨ててxAxisを作成する
    const fixBusinessEndTime = `${businessEndTime.split(':')[0]}:00`
    const xAxisData = createXAxis(businessStartTime, fixBusinessEndTime, false, displayDate)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)

      const unit =
        hourlyPlanAccuraciesData.find(planAccuracy => planAccuracy.scheduleTypeId === graphBadge.key)?.unit || ''
      const planAccuracyRows = hourlyPlanAccuraciesData
        .filter(planAccuracy => planAccuracy.scheduleTypeId === graphBadge.key)
        .flatMap(planAccuracy => planAccuracy.data)

      const archivePlanData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        // row.time も time も UTC なので文字列として比較しても大丈夫そうだが、安全のため new Date を通す
        const target = planAccuracyRows.find(row => new Date(row.date).getTime() === new Date(time).getTime())
        return target ? target.archivePlanCount : null
      })
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: archivePlanData,
        name: graphBadge.label,
        custom: { planAccuracyRows, unit }, // tooltipに最終計画とアーカイブのペアを表示するため、customに格納
        dashStyle: 'Dash',
      })

      const lastPlanData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = planAccuracyRows.find(row => new Date(row.date).getTime() === new Date(time).getTime())
        return target ? target.lastPlanCount : null
      })
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: lastPlanData,
        name: graphBadge.label,
        custom: { planAccuracyRows, unit }, // tooltipに最終計画とアーカイブのペアを表示するため、customに格納
        dashStyle: 'Solid',
      })
    })

    const options = createLineChartOptions({
      xAxis: {
        data: xAxisData,
      },
      yAxis,
    })
    merge(options, {
      xAxis: {
        labels: {
          step: 2,
        },
        tickInterval: 1,
      },
      tooltip: { useHTML: true, formatter },
    })
    ;(options.xAxis as XAxisOptions).labels!.formatter = function () {
      if (this.isFirst) {
        return dayjs(this.value).format('HH:mm')
      }
      return getTimeOver24h(dayjs(this.value).format('HH:mm'))
    }

    return options
  }, [
    badgeItems,
    businessEndTime,
    businessStartTime,
    displayDate,
    getTimeOver24h,
    hourlyPlanAccuraciesData,
    selectedBadgeKeys,
  ])

  // 見出しテーブル作成
  const tableHeader = useMemo(() => {
    const title = [{ value: '日付', className: styles.leftStickyCell }]

    const data =
      planHistory && planHistory.data?.length > 0
        ? planHistory?.data[0]?.scheduleTypes.map(item => ({
            value:
              hourlyPlanAccuraciesData.find(schedule => schedule.scheduleTypeId === item.id)?.scheduleTypeName || '',
            className: styles.cell,
          })) || []
        : hourlyPlanAccuraciesData.map(schedule => ({
            value: schedule.scheduleTypeName,
            className: styles.cell,
          }))

    const editor = [{ value: '編集者' }]

    return [...title, ...data, ...editor]
  }, [hourlyPlanAccuraciesData, planHistory])

  // データテーブル作成
  const tableData = useMemo(() => {
    if (!planHistory?.data?.length) {
      return []
    }

    const workDates = planHistory.data.map(d => d.date).sort((a, b) => new Date(b).getTime() - new Date(a).getTime())

    return workDates.map(wd => {
      const formattedDate = `${dayjs(wd).format('YYYY/MM/DD')}（${dayjs(wd).locale('ja').format('dd')}）${dayjs(wd).format('HH:mm:ss')}`

      const title = [{ value: formattedDate, className: `text-end ${styles.leftStickyCell}` }]

      const data =
        planHistory.data
          .find(d => d.date === wd)
          ?.scheduleTypes.map(scheduleType => {
            return {
              value: `${scheduleType.planningHour}人時 ${scheduleType.planCount}${scheduleType.unit}`,
              className: styles.cell,
            }
          }) || []

      const editor = [{ value: planHistory.data.find(d => d.date === wd)?.updatedByName || '-' }]

      return [...title, ...data, ...editor]
    })
  }, [planHistory])

  const handleDateChange = useCallback(
    (newDate: string) => {
      const queryParams = new URLSearchParams(search)
      queryParams.delete('page')
      checkWorkspaceCreated(
        newDate,
        workspaceId,
        `/reports/${workspaceId}/${dayjs(newDate).format('YYYY-MM-DD')}?${queryParams.toString()}`
      )
    },
    [workspaceId, search, checkWorkspaceCreated]
  )

  const handleDataSourceChange = useCallback(
    (dataSource: boolean) => {
      setIsDataSourceOpen(dataSource)
    },
    [setIsDataSourceOpen]
  )

  const handleDailyReportChange = useCallback(
    (dailyReport: boolean) => {
      setIsDailyReportUse(dailyReport)
    },
    [setIsDailyReportUse]
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page)
    },
    [setCurrentPage]
  )

  return (
    <>
      <ScrollToTopOnMount />

      <NavMenu>
        <div className="mt-3 mx-3">
          <div
            className="d-flex align-items-center text-secondary pb-2 text-decoration-none"
            onClick={() => navigate(-1)}
            role="button"
          >
            <i className="icf-chevron_left" />
            戻る
          </div>
          <div className="d-flex mb-3">
            <DateChangeButton
              date={displayDate}
              onChange={newDate => handleDateChange(dayjs(newDate).format('YYYY-MM-DD'))}
              filterDate={filterDate}
              isWorkPlanView
            />
            <div className="font-x-large fw-bold ps-2">の作業計画変更詳細</div>
          </div>

          <div className="d-flex mb-3">
            <div>
              <Input
                className="form-check-input"
                id="use-daily-report"
                checked={isDailyReportUse}
                type="checkbox"
                onChange={e => handleDailyReportChange(e.target.checked)}
              />
              <Label className="form-check-label mb-0" for="use-daily-report">
                日報実績を利用
              </Label>
            </div>
            <div className="ms-auto">
              <i
                className={`icf-carot_${isDataSourceOpen ? 'down' : 'right'} text-gray`}
                role="button"
                onClick={() => handleDataSourceChange(!isDataSourceOpen)}
              >
                データソースを表示
              </i>
            </div>
          </div>

          {isDataSourceOpen && (
            <div className="d-flex flex-wrap my-3 gap-2">
              {hourlyPlanAccuraciesData.map(reportAverage => (
                <BadgeLabelForReports
                  key={`scheduleType-badges-${reportAverage.scheduleTypeId}`}
                  label={reportAverage.scheduleTypeName}
                  isDailyReportUse={isDailyReportUse ?? false}
                />
              ))}
            </div>
          )}

          <Card className="mb-3">
            <CardBody className="fw-bold">{formattedDisplayDate}の詳細</CardBody>
            <CardBody>
              <Chart options={chartOptions} />
            </CardBody>
            <CardBody className="d-flex row my-2">
              <BadgeButton
                items={badgeItems}
                selected={selectedBadgeKeys}
                onChange={selected => setSelectedBadgeKeys(selected)}
              />
            </CardBody>
          </Card>

          <Card className="mb-3">
            <CardBody>
              <CardTitle className="fw-bold">計画変更履歴</CardTitle>
              <div className="mt-3 mw-100 overflow-auto">
                <StickyTable header={tableHeader} body={tableData} fixedLeft fixedRight />
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Pagination
                  currentPage={currentPage ?? DEFAULT_PAGE}
                  totalPages={planHistory?.maxPage ? planHistory.maxPage : DEFAULT_PAGE}
                  onClick={handlePageChange}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </NavMenu>
    </>
  )
}

export default PlanAccuracyDetail
