import { createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'es-toolkit'

import type {
  EditWorkspaceProps,
  PartialWorkspaceData,
  WorkspaceData,
  WorkspaceListResponse,
  WorkspaceResponse,
} from 'api/workspaces/types'
import * as API from 'api/workspaces/workspaces'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import { commonParams, ERROR_STATUS_CODE, makeErrorMessage, handleApiError } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type WorkspacesState = {
  isRequesting: boolean
  errorMessage: string
  partialWorkspaces: PartialWorkspaceData[]
  workspace?: WorkspaceData
  workspaceExists: boolean
}

const initialState: WorkspacesState = {
  isRequesting: false,
  errorMessage: '',
  partialWorkspaces: [],
  workspace: undefined,
  workspaceExists: false,
}

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkspaceListSuccess: (state, action: PayloadAction<WorkspaceListResponse>) => {
      state.isRequesting = false
      state.partialWorkspaces = sortBy(
        action.payload.partialWorkspaces.map(workspace => ({
          ...workspace,
          scheduleTypeNames: workspace.scheduleTypeNames.toSorted(),
        })),
        ['name']
      )
    },
    getWorkspaceSuccess: (state, action: PayloadAction<WorkspaceResponse>) => {
      state.isRequesting = false
      state.workspace = action.payload.workspace
    },
    workspaceExistsSuccess: state => {
      state.isRequesting = false
      state.workspaceExists = true
    },
    workspaceExistsError: state => {
      state.isRequesting = false
      state.workspaceExists = false
    },
    requestSuccess: state => {
      state.isRequesting = false
    },
    clearWorkspaceList: state => {
      state.partialWorkspaces = []
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getWorkspaceListSuccess,
  getWorkspaceSuccess,
  requestSuccess,
  clearWorkspaceList,
  workspaceExistsSuccess,
  workspaceExistsError,
} = workspacesSlice.actions

export const getWorkspaceList =
  (data?: { workDate?: string; tenantId?: number }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const tenantId = data?.tenantId ?? commonParams(getState).tenantId

    try {
      const res = await API.getWorkspacesList(tenantId, data?.workDate)
      dispatch(getWorkspaceListSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const getWorkspace =
  (workspaceId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getWorkspace(workspaceId)
      dispatch(getWorkspaceSuccess(res))
    } catch (res) {
      const errorCode = makeErrorMessage(res as AxiosError)
      if (errorCode === ERROR_STATUS_CODE.UNAUTHORIZED) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    }
  }

export const checkWorkspaceExists =
  (workspaceId: number, date?: string, callback?: (workspaceExits: boolean) => void): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.getWorkspace(workspaceId, date)
      dispatch(workspaceExistsSuccess())
      callback?.(true)
    } catch {
      dispatch(workspaceExistsError())
      callback?.(false)
    }
  }

export const createWorkspace =
  (data: EditWorkspaceProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.createWorkspace(data)
      dispatch(requestSuccess())
      dispatch(getWorkspaceList())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const updateWorkspace =
  (workspaceId: number, data: EditWorkspaceProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.updateWorkspace(workspaceId, data)
      dispatch(requestSuccess())
      dispatch(getWorkspaceList())
      dispatch(getWorkspace(workspaceId))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const deleteWorkspace =
  (workspaceId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.deleteWorkspace(workspaceId)
      dispatch(requestSuccess())
      dispatch(getWorkspaceList())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const csvImportTargets =
  (workspaceId: string, fileData: FormData): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.uploadTargets(workspaceId, fileData)
      dispatch(requestSuccess())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const selectWorkspacesStatus = (state: RootState) => ({ ...state.workspaces })

export default workspacesSlice.reducer
