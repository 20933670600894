import { useState, useMemo, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { NavMenu, PivotItem, PivotOuterIndex, CustomButton } from 'components/common'

import { useQuery } from 'hooks/useQuery'

import { DailyReportHistory } from './DailyReportHistory'
import { DailyReportSettings } from './DailyReportSettings'
import { ImportDialog } from './ImportDialog'

const TAB_TYPES = {
  HISTORY: 'history',
  SETTINGS: 'settings',
} as const

type TabType = (typeof TAB_TYPES)[keyof typeof TAB_TYPES]

const tabs = [
  { header: '履歴', key: TAB_TYPES.HISTORY, component: <DailyReportHistory /> },
  { header: '設定', key: TAB_TYPES.SETTINGS, component: <DailyReportSettings /> },
]

export const DailyReport = () => {
  const navigate = useNavigate()
  const { search } = useLocation()

  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false)

  const [queryTab] = useQuery<TabType>('tab', TAB_TYPES.HISTORY)

  const selectedTab = useMemo(() => queryTab ?? TAB_TYPES.HISTORY, [queryTab])

  const handleTabChange = useCallback(
    (key: TabType) => {
      const params = new URLSearchParams(search)
      params.delete('page')
      params.delete('dateRange')
      params.set('tab', key)
      navigate(`?${params.toString()}`, { replace: true })
    },
    [search, navigate]
  )

  const handleImportSuccess = useCallback(() => {
    setIsImportDialogOpen(false)
  }, [])

  const handleImportCancel = useCallback(() => {
    setIsImportDialogOpen(false)
  }, [])

  return (
    <NavMenu>
      <>
        {/* pivotBodyが上下1remのpaddingを持つため、その分padding-bottomだけゼロとする */}
        <div className="d-flex flex-column h-100 p-3 pb-0">
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div className="font-x-large fw-bold">日報実績</div>
            <CustomButton outline onClick={() => setIsImportDialogOpen(true)}>
              日報実績インポート
            </CustomButton>
          </div>
          <PivotOuterIndex
            selectedIndex={tabs.findIndex(tab => tab.key === selectedTab)}
            onChange={index => handleTabChange(tabs[index].key)}
          >
            {tabs.map(({ header, key, component }) => (
              <PivotItem headerText={header} key={key}>
                {component}
              </PivotItem>
            ))}
          </PivotOuterIndex>
        </div>
        <ImportDialog
          key={`open-import-dialog-${isImportDialogOpen}`}
          isOpen={isImportDialogOpen}
          onSuccess={handleImportSuccess}
          onCancel={handleImportCancel}
        />
      </>
    </NavMenu>
  )
}
