export const SPOT_WORKER_SHIFT_STATUS = {
  // 情報不足
  INSUFFICIENT: 1,
  // idをのぞいて情報不足
  INSUFFICIENT_WITHOUT_ID: 2,
  // シフト未登録
  SHIFT_UNREGISTERED: 3,
  // シフト登録済
  SHIFT_REGISTERED: 4,
  // 未更新
  NON_UPDATED: 5,
  // 欠勤
  SICK_OUT: 6,
} as const

export const SPOT_WORKER_ADD_RESULT = {
  OK: 0,
  INVALID: 1,
  ALREADY_EXISTS: 2,
} as const
